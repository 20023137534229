import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { RatingModule } from 'primeng/rating';
import { FieldsetModule } from 'primeng/fieldset';
import { TagModule } from 'primeng/tag';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';
import { Angular2CountoModule } from '@awaismirza/angular2-counto';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { AgGridModule } from 'ag-grid-angular';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { TreeTableModule } from 'primeng/treetable';
import { SliderModule } from 'primeng/slider';

import { MazarsDescriptionComponent } from '@app/modules/mazars-common/sub-forms/mazars-description/mazars-description.component';
import { MazarsInputTextComponent } from './mazars-input-text/mazars-input-text.component';
import { MazarsInputNumberComponent } from './mazars-input-number/mazars-input-number.component';
import { MazarsInputTextAreaComponent } from './mazars-input-textarea/mazars-input-textarea.component';
import { MazarsDropdownComponent } from './mazars-dropdown/mazars-dropdown.component';
import { MazarsMultiselectComponent } from './mazars-multiselect/mazars-multiselect.component';
import { MazarsDatePickerComponent } from './mazars-datepicker/mazars-datepicker.component';
import { MazarsDateTimePickerComponent } from './mazars-datetimepicker/mazars-datetimepicker.component';
import { MazarsYearpickerComponent } from './mazars-yearpicker/mazars-yearpicker.component';
import { MazarsPartySelectorComponent } from './mazars-party-selector/mazars-party-selector.component';
import { MazarsTagsAutocompleteComponent } from './mazars-tags-autocomplete/mazars-tags-autocomplete.component';
import { MazarsTagsComponent } from './mazars-tags/mazars-tags.component';
import { MazarsSwitchComponent } from './mazars-switch/mazars-switch.component';
import { MazarsValidationMessageComponent } from './mazars-validation-message/mazars-validation-message.component';
import { MazarsDeadlineDatesComponent } from './mazars-deadline-dates/mazars-deadline-dates.component';
import { MazarsInformationIconComponent } from './mazars-information-icon/mazars-information-icon.component';
import { MazarsFlagIconComponent } from './mazars-flag-icon/mazars-flag-icon.component';
import { MazarsDisplayDialogComponent } from './mazars-display-dialog/mazars-display-dialog.component';
import { MazarsActionsDropdownMenuComponent } from './mazars-actions-dropdown-menu/mazars-actions-dropdown-menu.component';
import { MazarsActionsMenuComponent } from './mazars-actions-menu/mazars-actions-menu.component';
import { MazarsSplittButtonComponent } from './mazars-splitt-button/mazars-splitt-button.component';
import { MazarsGridComponent } from './mazars-grid/mazars-grid.component';
import { MazarsAngularGridComponent } from './mazars-ag-grid/mazars-ag-grid.component';
import { MazarsSearchbarComponent } from './mazars-searchbar/mazars-searchbar.component';
import { MazarsTabMenuComponent } from './mazars-tab-menu/mazars-tab-menu.component';
import { MazarsRadioGroupComponent } from './mazars-radio-group/mazars-radio-group.component';
import { MazarsTemplateDirective } from './mazars-template/mazars-template.directive';
import { MazarsTooltipDirective } from './mazars-tooltip/mazars-tooltip.directive';
import { MazarsTagsListComponent } from './mazars-tags-list/mazars-tags-list.component';
import { MazarsPropertyOwnersListComponent } from './mazars-property-owners-list/mazars-property-owners-list.component';
import { MazarsAttachmentsModalComponent } from './mazars-attachments/mazars-attachments-modal.component';
import { MazarsProfilesModalComponent } from './mazars-profiles/mazars-profiles-modal.component';
import { MazarsAttachmentsComponent } from './mazars-attachments/mazars-attachments.component';
import { MazarsProfilesComponent } from './mazars-profiles/mazars-profiles.component';
import { MazarsChannelModalComponent } from './mazars-channel/mazars-channel-modal.component';
import { MazarsStatusComponent } from './mazars-status/mazars-status.component';
import { MazarsStatusEditModalComponent } from './mazars-status/mazars-status-edit-modal.component';
import { MazarsStatusEditComponent } from './mazars-status/mazars-status-edit.component';
import { MazarsProcessComponent } from './mazars-process/mazars-process.component';
import { MazarsProcessStateComponent } from './mazars-process-state/mazars-process-state.component';
import { MazarsDueDateComponent } from './mazars-due-date/mazars-due-date.component';
import { MazarsAttachedSurveysComponent } from './mazars-attached-surveys/mazars-attached-surveys.component';
import { MazarsAttachSurveyModalComponent } from './mazars-attached-surveys/mazars-attach-survey-modal/mazars-attach-survey-modal.component';
import { MazarsSurveyCreatorComponent } from './mazars-survey-creator/mazars-survey-creator.component';
import { MazarsSurveyComponent } from './mazars-survey/mazars-survey.component';
import { SurveyComponent } from './surveyjs/survey.component';
import { SurveyCreatorComponent } from './surveyjs/survey.creator.component';
import { SurveysComponent } from './surveys/surveys.component';
import { MazarsChannelComponent } from './mazars-channel/mazars-channel.component';
import { MazarsCommunicationComponent } from './mazars-communication/mazars-communication.component';
import { MazarsFieldsetComponent } from './mazars-fieldset/mazars-fieldset.component';
import { MazarsPilsComponent } from './mazars-pils/mazars-pils.component';
import { MazarsCommunicationModalComponent } from './mazars-communication/mazars-communication-modal.component';
import { MazarsAssignableUserSelectEditModalComponent } from './mazars-assignable-user-select/mazars-assignable-user-select-edit-modal.component';
import { MazarsAssignableUserSelectComponent } from './mazars-assignable-user-select/mazars-assignable-user-select.component';
import { MazarsDesriptionWithMoreComponent } from './mazars-desription-with-more/mazars-desription-with-more.component';
import { QuillModule } from 'ngx-quill';
import { MazarsEditorComponent } from './mazars-editor/mazars-editor.component';
import { MazarsDisplayQuillComponent } from './mazars-display-quill/mazars-display-quill.component';
import { MazarsWhitelistModalComponent } from './mazars-whitelist-modal/mazars-whitelist-modal.component';
import { MazarsValidationResultComponent } from './mazars-validation-result/mazars-validation-result.component';
import { MazarsValidationResultMessageComponent } from './mazars-validation-result-message/mazars-validation-result-message.component';
import { MazarsValidationResultModalComponent } from './mazars-validation-result-modal/mazars-validation-result-modal.component';
import { MazarsStepsComponent } from './mazars-steps/mazars-steps.component';
import { MazarsAccessRestrictionComponent } from './mazars-access-restriction/mazars-access-restriction.component';
import { MazarsSubmitButtonComponent } from './mazars-submit-button/mazars-submit-button.component';
import { MazarsSubmissionComponent } from './mazars-submission/mazars-submission.component';
import { MazarsSubmissionItemComponent } from './mazars-submission/mazars-submission-item.component';
import { MazarsSubmissionInfoSectionComponent } from './mazars-submission/mazars-submission-item-sections/mazars-submission-info-section/mazars-submission-info-section.component';
import { MazarsFeedbackInfoSectionComponent } from './mazars-submission/mazars-submission-item-sections/mazars-feedback-info-section/mazars-feedback-info-section.component';
import { MazarsButtonComponent } from './mazars-button/mazars-button.component';
import { MazarsDateRangePickerComponent } from './mazars-daterangepicker/mazars-daterangepicker.component';
import { MazarsDialogComponent } from './mazars-dialog/mazars-dialog.component';
import { MazarsCreateUpdateDialogComponent } from './mazars-dialog/mazars-create-update-dialog.component';
import { MazarsDropdownMasterDataPartyComponent } from './mazars-dropdown-masterdataparty/mazars-dropdown-masterdataparty.component';
import { MazarsInputToggleComponent } from '@app/modules/mazars-common/components/mazars-input-toggle/mazars-input-toggle.component';
import { MazarsCloseableDialogComponent } from './mazars-dialog/mazars-closeable-dialog.component';
import { MazarsTableHeaderCheckboxComponent } from './mazars-grid/mazars-table-header-checkbox.component';
import { MazarsElsterTaxnumberConverterComponent } from './mazars-elster-taxnumber-converter/mazars-elster-taxnumber-converter.component';
import { MazarsFederalStateIconComponent } from './mazars-federal-state-icon/mazars-federal-state-icon.component';
import { MazarsProfilePictureComponent } from './mazars-profile-picture/mazars-profile-picture.component';
import { MazarsHeaderComponent } from './mazars-header/mazars-header.component';
import { MazarsReorderComponent } from './mazars-reorder/mazars-reorder.component';
import { MazarsReorderDialogComponent } from './mazars-reorder-dialog/mazars-reorder-dialog.component';
import { MazarsLogoComponent } from './mazars-logo/mazars-logo.component';
import { MazarsBurgerMenuComponent } from './mazars-burgermenu/mazars-burgermenu.component';
import { MazarsLanguageDropdownComponent } from './mazars-language-dropdown/mazars-language-dropdown.component';
import { MazarsSubmitDraftButtonComponent } from './mazars-submit-draft-button/mazars-submit-draft-button.component';

import { NumericEditorComponent } from './mazars-ag-grid/mazars-ag-grid-cell-editors/numeric-editor/numeric-editor.component';
import { DecimalEditorComponent } from './mazars-ag-grid/mazars-ag-grid-cell-editors/decimal-editor/decimal-editor.component';
import { TextEditorComponent } from './mazars-ag-grid/mazars-ag-grid-cell-editors/text-editor/text-editor.component';
import { AddRowCellRendererComponent } from './mazars-ag-grid/mazars-ag-grid-cell-renderers/add-row-cell-renderer/add-row-cell-renderer.component';
import { ActionsCellRendererComponent } from './mazars-ag-grid/mazars-ag-grid-cell-renderers/actions-cell-renderer/actions-cell-renderer.component';
import { MazarsReorderCellRendererComponent } from './mazars-reorder/mazars-reorder-cell-renderer/mazars-reorder-cell-renderer.component';
import { MazarsAdminAccessRestrictionsComponent } from './mazars-admin-access-restrictions/mazars-admin-access-restrictions.component';
import { MazarsBulkAssignUserModalComponent } from './mazars-assignable-user-select/mazars-bulk-assign-user/mazars-bulk-assign-user-modal.component';
import { MazarsManageTagsModalComponent } from './mazars-manage-tags/mazars-manage-tags-modal.component';
import { MazarsCardInputComponent } from './mazars-card-input/mazars-card-input.component';
import { DeleteRowCellRendererComponent } from './mazars-ag-grid/mazars-ag-grid-cell-renderers/delete-row-cell-renderer/delete-row-cell-renderer.component';
import { DropdownEditorComponent } from './mazars-ag-grid/mazars-ag-grid-cell-editors/dropdown-editor/dropdown-editor.component';
import { MazarsAvatarGroupComponent } from './mazars-avatar-group/mazars-avatar-group.component';
import { MazarsCommunicationViewModalComponent } from './mazars-communication/mazars-communication-view-modal/mazars-communication-view-modal.component';
import { MazarsAvatarComponent } from './mazars-avatar/mazars-avatar.component';
import { MazarsChannelUserDetailsComponent } from './mazars-channel/mazars-channel-user-details/mazars-channel-user-details.component';
import { MazarsBulkChangeAccessRestrictionModalComponent } from './mazars-bulk-change-access-restriction-modal/mazars-bulk-change-access-restriction-modal.component';
import { MazarsChartComponent } from './mazars-chart/mazars-chart.component';
import { ChartModule } from 'primeng/chart';
import { SafePipe } from '@shared/common/pipes/safe.pipe';
import { MazarsCustomPermissionsComponent } from './mazars-custom-permissions/mazars-custom-permissions.component';
import { MazarsFormGeneralCardComponent } from './mazars-form-general-card/mazars-form-general-card.component';
import { MazarsFormProjectManagementCardComponent } from './mazars-form-project-management-card/mazars-form-project-management-card.component';
import { MazarsTaskStatusDropdownComponent } from './mazars-task-status-dropdown/mazars-task-status-dropdown.component';
import { MazarsAccessRestrictionV2Component } from './mazars-access-restriction/mazars-access-restriction-v2.component';
import { MazarsCustomPermissionsV2Component } from './mazars-custom-permissions/mazars-custom-permissions-v2.component';
import { MazarsTreeTableComponent } from './mazars-tree-table/mazars-tree-table.component';
import { MazarsAssignableUserSelectEditModalV2Component } from './mazars-assignable-user-select/mazars-assignable-user-select-edit-modal-v2.component';
import { MazarsAssignableUserSelectV2Component } from './mazars-assignable-user-select/mazars-assignable-user-select-v2.component';
import { MazarsInputPasswordComponent } from './mazars-input-password/mazars-input-password.component';
import { PasswordModule } from 'primeng/password';
import { MazarsReferenceCommunicationsModalComponent } from './mazars-communication/mazars-reference-communications-modal/mazars-reference-communications-modal.component';
import { CellWithTooltipRendererComponent } from './mazars-ag-grid/mazars-ag-grid-cell-renderers/cell-with-tooltip-renderer/cell-with-tooltip-renderer.component';
import { MazarsWizardComponent } from './mazars-wizard/mazars-wizard.component';
import { MazarsYesNoQuestionComponent } from './mazars-yes-no-question/mazars-yes-no-question.component';
import { PickListModule } from 'primeng/picklist';
import { MazarsListPickerComponent } from './mazars-list-picker/mazars-list-picker.component';
import { MazarsExportOverviewDialogComponent } from './mazars-export-overview-dialog/mazars-export-overview-dialog.component';
import { MazarsSkeletonComponent } from './mazars-skeleton/mazars-skeleton.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TSActionsCellRendererComponent } from './mazars-ag-grid/mazars-ag-grid-cell-renderers/ts-actions-cell-renderer/ts-actions-cell-renderer.component';
import { MazarsGraphComponent } from './mazars-graph/mazars-graph.component';
import { MazarsMapComponent } from './mazars-map/mazars-map.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MazarsSliderComponent } from './mazars-slider/mazars-slider.component';
import { MazarsZoomComponent } from './mazars-zoom/mazars-zoom.component';
import { MazarsAutocompleteComponent } from './mazars-autocomplete/mazars-autocomplete.component';
import { MazarsInputLongNumberComponent } from './mazars-input-long-number/mazars-input-long-number.component';
import { IMaskModule } from 'angular-imask';
import { MazarsBulkDeleteModalComponent } from './mazars-bulk-delete-modal/mazars-bulk-delete-modal.component';
import { MazarsButtonLinkComponent } from './mazars-button-link/mazars-button-link.component';
import { MazarsUploadButtonComponent } from './mazars-upload-button/mazars-upload-button.component';
import { MazarsBulkArchiveModalComponent } from './mazars-bulk-archive-modal/mazars-bulk-archive-modal.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MazarsContextMenuComponent } from './mazars-archive-menu/mazars-context-menu.component';
import { MazarsChatComponent } from './mazars-chat/mazars-chat.component';
import { MazarsChatChannelsComponent } from './mazars-chat/mazars-chat-channels/mazars-chat-channels.component';
import { MazarsChatDiscussionComponent } from './mazars-chat/mazars-chat-discussion/mazars-chat-discussion.component';
import { MazarsParticipantsDialogComponent } from './mazars-chat/mazars-chat-channels/mazars-participants-dialog/mazars-participants-dialog.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MazarsChancelleryViewComponent } from './mazars-chancellery-view/mazars-chancellery-view.component';
import { MazarsChancelleryViewAdminSettingsComponent } from './mazars-chancellery-view/mazars-chancellery-view-admin-settings/mazars-chancellery-view-admin-settings.component';

import { MazarsPreviewModalComponent } from './mazars-preview-modal/mazars-preview-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { MazarsInputCheckboxComponent } from '@app/modules/mazars-common/components/mazars-input-checkbox/mazars-input-checkbox.component';
import { MazarsPdfOptionsComponent } from './mazars-pdf-options/mazars-pdf-options.component';
import { MazarsLazyDropdownComponent } from './mazars-lazy-dropdown/mazars-lazy-dropdown.component';
import { MazarsLazyMultiselectComponent } from './mazars-lazy-multiselect/mazars-lazy-multiselect.component';
import { MazarsFiltersDialogComponent } from './mazars-searchbar/mazars-filters-dialog/mazars-filters-dialog.component';

const declarations = [
    MazarsButtonComponent,
    MazarsDialogComponent,
    MazarsCloseableDialogComponent,
    MazarsCreateUpdateDialogComponent,
    MazarsDescriptionComponent,
    MazarsInputTextComponent,
    MazarsInputPasswordComponent,
    MazarsInputNumberComponent,
    MazarsInputTextAreaComponent,
    MazarsDropdownComponent,
    MazarsMultiselectComponent,
    MazarsDatePickerComponent,
    MazarsDateTimePickerComponent,
    MazarsDateRangePickerComponent,
    MazarsYearpickerComponent,
    MazarsPartySelectorComponent,
    MazarsTagsAutocompleteComponent,
    MazarsTagsComponent,
    MazarsSwitchComponent,
    MazarsValidationMessageComponent,
    MazarsDeadlineDatesComponent,
    MazarsInformationIconComponent,
    MazarsFlagIconComponent,
    MazarsDisplayDialogComponent,
    MazarsActionsDropdownMenuComponent,
    MazarsActionsMenuComponent,
    MazarsSplittButtonComponent,
    MazarsGridComponent,
    MazarsAngularGridComponent,
    ActionsCellRendererComponent,
    TSActionsCellRendererComponent,
    DeleteRowCellRendererComponent,
    MazarsReorderCellRendererComponent,
    MazarsSearchbarComponent,
    MazarsTemplateDirective,
    MazarsTooltipDirective,
    MazarsTabMenuComponent,
    MazarsTagsListComponent,
    MazarsPropertyOwnersListComponent,
    MazarsRadioGroupComponent,
    MazarsAttachmentsComponent,
    MazarsProfilesComponent,
    MazarsAttachmentsModalComponent,
    MazarsProfilesModalComponent,
    MazarsStatusComponent,
    MazarsStatusEditComponent,
    MazarsStatusEditModalComponent,
    MazarsAssignableUserSelectComponent,
    MazarsAssignableUserSelectEditModalComponent,
    MazarsProcessComponent,
    MazarsProcessStateComponent,
    MazarsDueDateComponent,
    MazarsSurveyCreatorComponent,
    MazarsSurveyComponent,
    SurveyComponent,
    SurveyCreatorComponent,
    SurveysComponent,
    MazarsAttachedSurveysComponent,
    MazarsAttachSurveyModalComponent,
    MazarsChannelComponent,
    MazarsChannelModalComponent,
    MazarsCommunicationComponent,
    MazarsFieldsetComponent,
    MazarsPilsComponent,
    MazarsDesriptionWithMoreComponent,
    MazarsCommunicationModalComponent,
    MazarsEditorComponent,
    MazarsDisplayQuillComponent,
    MazarsStepsComponent,
    MazarsWhitelistModalComponent,
    MazarsValidationResultComponent,
    MazarsValidationResultMessageComponent,
    MazarsValidationResultModalComponent,
    MazarsAccessRestrictionComponent,
    MazarsSubmitButtonComponent,
    MazarsSubmissionComponent,
    MazarsSubmissionItemComponent,
    MazarsSubmissionInfoSectionComponent,
    MazarsFeedbackInfoSectionComponent,
    MazarsDropdownMasterDataPartyComponent,
    MazarsInputToggleComponent,
    MazarsTableHeaderCheckboxComponent,
    MazarsElsterTaxnumberConverterComponent,
    MazarsFederalStateIconComponent,
    MazarsProfilePictureComponent,
    MazarsHeaderComponent,
    MazarsReorderComponent,
    MazarsReorderDialogComponent,
    MazarsLogoComponent,
    MazarsBurgerMenuComponent,
    MazarsSubmitDraftButtonComponent,
    MazarsLanguageDropdownComponent,
    NumericEditorComponent,
    DecimalEditorComponent,
    TextEditorComponent,
    DropdownEditorComponent,
    AddRowCellRendererComponent,
    MazarsAdminAccessRestrictionsComponent,
    MazarsBulkAssignUserModalComponent,
    MazarsManageTagsModalComponent,
    MazarsCardInputComponent,
    MazarsAvatarGroupComponent,
    MazarsCommunicationViewModalComponent,
    MazarsAvatarComponent,
    MazarsChannelUserDetailsComponent,
    MazarsBulkChangeAccessRestrictionModalComponent,
    MazarsChartComponent,
    MazarsCustomPermissionsComponent,
    MazarsFormGeneralCardComponent,
    MazarsFormProjectManagementCardComponent,
    MazarsTaskStatusDropdownComponent,
    MazarsAccessRestrictionV2Component,
    MazarsCustomPermissionsV2Component,
    MazarsTreeTableComponent,
    MazarsAssignableUserSelectEditModalV2Component,
    MazarsAssignableUserSelectV2Component,
    MazarsReferenceCommunicationsModalComponent,
    CellWithTooltipRendererComponent,
    MazarsWizardComponent,
    MazarsYesNoQuestionComponent,
    MazarsListPickerComponent,
    MazarsExportOverviewDialogComponent,
    MazarsSkeletonComponent,
    MazarsGraphComponent,
    MazarsMapComponent,
    MazarsSliderComponent,
    MazarsZoomComponent,
    MazarsAutocompleteComponent,
    MazarsInputLongNumberComponent,
    MazarsBulkDeleteModalComponent,
    MazarsButtonLinkComponent,
    MazarsUploadButtonComponent,
    MazarsBulkArchiveModalComponent,
    MazarsContextMenuComponent,
    MazarsChatComponent,
    MazarsChatChannelsComponent,
    MazarsChatDiscussionComponent,
    MazarsParticipantsDialogComponent,
	MazarsChancelleryViewComponent,
    MazarsChancelleryViewAdminSettingsComponent,
    MazarsPreviewModalComponent,
    MazarsInputCheckboxComponent,
    MazarsPdfOptionsComponent,
    MazarsLazyDropdownComponent,
    MazarsLazyMultiselectComponent,
    MazarsFiltersDialogComponent
];

@NgModule({
    declarations: [...declarations],
    imports: [
        CommonModule,
        AppCommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        UtilsModule,
        TableModule,
        PaginatorModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        Angular2CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        TooltipModule,
        RatingModule,
        FieldsetModule,
        TagModule,
        PanelModule,
        TabMenuModule,
        ButtonModule,
        SelectButtonModule,
        RadioButtonModule,
        CheckboxModule,
        FileUploadModule,
        AccordionModule,
        AgGridModule,
        SplitButtonModule,
        TieredMenuModule,
        DialogModule,
        AvatarModule,
        AvatarGroupModule,
        InputTextModule,
        InputTextareaModule,
        IMaskModule,
        DropdownModule,
        MultiSelectModule,
        CalendarModule,
        InputNumberModule,
        InputSwitchModule,
        QuillModule.forRoot(),
        StepsModule,
        ChartModule,
        TreeTableModule,
        PasswordModule,
        PickListModule,
        SkeletonModule,
        HighchartsChartModule,
        SliderModule,
        OverlayPanelModule,
        PdfViewerModule,
        ScrollPanelModule,
        DragScrollComponent,
        DragScrollItemDirective,
        
    ],
    exports: [...declarations],
    providers: [
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        SafePipe,
    ],
})
export class ComponentsModule { }
