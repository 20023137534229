import { Component, forwardRef, Injector, Input, OnChanges } from '@angular/core';
import { Dac6ComponentBase } from '@app/modules/dac6/dac6-component-base';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReportReferenceDataService } from '@app/modules/dac6/reports/report-reference-data.service';
import { ReferenceDataService } from '@app/modules/mazars-common/services/reference-data.service';
import { IInvolvedPartyConfig } from '../../interfaces/involved-party-config';

@Component({
    selector: 'app-other-details-sub-form',
    templateUrl: './other-details-sub-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OtherDetailsSubFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OtherDetailsSubFormComponent),
            multi: true,
        },
    ],
})
export class OtherDetailsSubFormComponent extends Dac6ComponentBase implements ControlValueAccessor, OnChanges {
    @Input() submitting: boolean;
    @Input() partyConfig: IInvolvedPartyConfig;

    form: FormGroup;
    subscriptions: Subscription[] = [];
    isDisabled: any;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        public referenceDataService: ReferenceDataService,
        public reportReferenceDataService: ReportReferenceDataService
    ) {
        super(injector);
    }

    get value(): any {
        return this.form.value;
    }

    get nationalExemptionFormContol() {
        return this.form.get('nationalExemptionCountries');
    }

    get notificationDateFormControl() {
        return this.form.get('notificationDate');
    }

    set value(value: any) {
        if (value.hasNationalExemption) {
            this.form.addControl('nationalExemptionCountries', this.fb.control(null));
        }

        if (value.isAffectedPerson) {
            this.form.addControl('isAffectedPerson', this.fb.control(null));
        }

        if (value.notifiedByIntermediary) {
            this.form.addControl('notificationDate', this.fb.control(null));
        }

        this.form.patchValue(value);
    }

    ngOnChanges(): void {
        if (!this.form) {
            this.createForm();
        } else {
            this.updateForm();
        }

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { address: { valid: false } };
    }

    onHasNationalExemptionChange(event: any) {
        if (event) {
            this.form.addControl('nationalExemptionCountries', this.fb.control(null));
        } else if (this.nationalExemptionFormContol) {
            this.form.removeControl('nationalExemptionCountries');
        }
    }

    onNotifiedByIntermediaryChange(event: any) {
        if (event) {
            this.form.addControl('notificationDate', this.fb.control(null));
        } else if (this.notificationDateFormControl) {
            this.form.removeControl('notificationDate');
        }
    }

    onDestroy(): void {
        // to be implemented if need it
    }

    private createForm(): void {
        this.form = this.fb.group({
            residenceCountryCodes: [null, [Validators.required]],
            email: [null, [Validators.email, Validators.maxLength(400)]],
        });

        this.updateForm();
    }

    private updateForm(): void {
        if (this.partyConfig?.showImplementationDate) {
            this.form.addControl('implementationDate', this.fb.control(null));
        }

        if (this.partyConfig?.showHasNationalExemption) {
            this.form.addControl('hasNationalExemption', this.fb.control(null));
        }

        if (this.partyConfig?.showIntermediaryLiabilityRoleInOtherDetails) {
            this.form.addControl('role', this.fb.control(null));
        }

        if (this.partyConfig?.showIsAffectedPerson) {
            this.form.addControl('isAffectedPerson', this.fb.control(null));
        }

        if (this.partyConfig?.showNotifiedByIntermediary) {
            this.form.addControl('notifiedByIntermediary', this.fb.control(null));
        }
    }
}
