import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidatorsConsts } from '@app/modules/mazars-common/consts/ValidatorsConsts';

@Component({
    selector: 'app-mazars-description',
    templateUrl: './mazars-description.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MazarsDescriptionComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsDescriptionComponent),
            multi: true,
        },
    ],
})
export class MazarsDescriptionComponent extends AppComponentBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() submitting: boolean;
    @Input() languages: any[];
    @Input() uid: string;
    @Input() textAreaLabel: string;
    @Input() buttonLabel: string;
    @Input() limit?: number;
    @Input() hasEmpty = true;

    form: FormGroup;
    subscriptions: Subscription[] = [];
    isDisabled = false;
    readonly validatorsConsts = ValidatorsConsts;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    get descriptions(): FormArray {
        return this.form.controls.descriptions as FormArray;
    }

    get value(): any {
        return this.form.value;
    }

    set value(value: any) {
        if (value && value.descriptions && value.descriptions.length) {
            this.descriptions.clear();
            value.descriptions.forEach((description) => {
                const descriptionForm = this.createDescriptionForm();
                descriptionForm.patchValue(description);
                this.descriptions.push(descriptionForm);
            });

            if (this.isDisabled) {
                this.form.disable();
            } else {
                this.form.enable();
            }

            this.onChange(value);
            this.onTouched();
        } else {
            this.form.reset();
            this.setDefaults();
        }
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    ngOnInit(): void {
        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { descriptions: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getControls() {
        return this.descriptions.controls as FormGroup[];
    }

    addDescription(): void {
        this.descriptions.push(this.createDescriptionForm());
    }

    createDescriptionForm(): FormGroup {
        return this.fb.group({
            text: [null, [Validators.maxLength(ValidatorsConsts.maxDescriprionTextAreaLength)]],
            language: [null, [Validators.maxLength(ValidatorsConsts.countryCodeLength)]],
        });
    }

    removeDescription(index: number): void {
        this.descriptions.removeAt(index);
    }

    private createForm(): void {
        this.form = this.fb.group({
            descriptions: this.fb.array([this.createDescriptionForm()]),
        });
    }

    private setDefaults() {
        const defaults = {
            descriptions: [
                {
                    text: null,
                    language: null,
                },
            ],
        };

        this.form.patchValue(defaults);
    }
}
