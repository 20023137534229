import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICommunicationDto } from '@shared/service-proxies/common-interfaces';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogResult } from '../../mazars-dialog/dialog-result';

@Component({
    selector: 'mazars-communication-view-modal',
    templateUrl: './mazars-communication-view-modal.component.html'
})
export class MazarsCommunicationViewModalComponent extends AppComponentBase {
    @ViewChild('modal', { static: true }) modal: ModalDirective;
    @Output() editCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Output() deleteCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Input({ required: true }) uid: string;

    isShown = false;
    hasEditPermission: boolean;
    hasDeletePermission: boolean;
    communication: ICommunicationDto;
    private promiseResult: { submit: (result?: any) => void; cancel: () => void };

    constructor(injector: Injector) {
        super(injector);
    }

    show(communication: ICommunicationDto, hasEditPermission: boolean, hasDeletePermission: boolean): DialogResult {
        this.hasEditPermission = hasEditPermission;
        this.hasDeletePermission = hasDeletePermission;
        this.communication = communication;

        return new DialogResult((submit, cancel) => {
          this.promiseResult = { submit: submit, cancel: cancel };
          this.modal.show();
      });
    }

    close(): void {
        this.modal.hide();
    }

    shown(): void {
        this.isShown = true;
    }

    showUpdateCommunicationModal(communication: ICommunicationDto) {
        this.editCommunicationClicked.emit(communication);
    }

    deleteCommunication(communication: ICommunicationDto) {
        this.deleteCommunicationClicked.emit(communication);
    }
}
