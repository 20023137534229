import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IReferenceDataFacade } from '@shared/service-proxies/interfaces/IReferenceDataFacade';

@Component({
    selector: 'app-mazars-form-general-card',
    templateUrl: './mazars-form-general-card.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsFormGeneralCardComponent,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsFormGeneralCardComponent),
            multi: true,
        },
    ],
})
export class MazarsFormGeneralCardComponent extends AppComponentBase implements ControlValueAccessor, OnInit {
    @Input() referenceDataFacade: IReferenceDataFacade;
    @Input() isDisabled: boolean;
    @Input({ required: true }) uid: string;
    @Input() dataLoading: boolean;
    @Input() notesMaxLength: number;

    referenceDataLoading: boolean;
    showWarnings: boolean;
    availableTags: string[];
    form!: FormGroup;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    get value(): any {
        return this.form?.value;
    }

    set value(generalDto: any) {
        if (generalDto.hasOwnProperty('name')) {
            if (this.form.controls.name) {
                this.form.controls.name.setValue(generalDto.name);
            } else {
                const nameControl = this.fb.control({ value: generalDto.name, disabled: this.isDisabled }, [Validators.required, Validators.maxLength(200)]);
                this.form.addControl('name', nameControl);
            }
        }
        if (generalDto.hasOwnProperty('note')) {
            if (this.form.controls.note) {
                this.form.controls.note.setValue(generalDto.note);
            } else {
                const noteControl = this.fb.control({ value: generalDto.note, disabled: this.isDisabled }, [Validators.maxLength(this.notesMaxLength)]);
                this.form.addControl('note', noteControl);
            }
        }
        if (generalDto.hasOwnProperty('tags')) {
            if (this.form.controls.tags) {
                this.form.controls.tags.setValue(generalDto.tags);
            } else {
                const tagsControl = this.fb.control({ value: generalDto.tags, disabled: this.isDisabled });
                this.form.addControl('tags', tagsControl);
            }
        }
        if (generalDto.hasOwnProperty('isArchived')) {
            if (this.form.controls.isArchived) {
                this.form.controls.isArchived.setValue(generalDto.isArchived);
            } else {
                const isArchivedControl = this.fb.control({ value: generalDto.isArchived, disabled: this.isDisabled });
                this.form.addControl('isArchived', isArchivedControl);
            }
        }

        this.form.updateValueAndValidity();
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({});
        this.referenceDataLoading = true;
        this.referenceDataFacade.getAllReferenceDataTags('');
        this.registerSubscriptions();
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { general: { valid: false } };
    }

    onArchivedClicked(event) {
        if (event) {
            abp.message.warn(this.l('ArchiveWarning'));
        }
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );

        this.subscriptions.push(
            this.referenceDataFacade.allReferenceDataTags$.subscribe((result) => {
                if (result) {
                    this.availableTags = result;
                    this.referenceDataLoading = false;
                }
            })
        );

        this.subscriptions.push(
            this.referenceDataFacade.showWarnings$.subscribe((result) => {
                if (result) {
                    this.showWarnings = result;
                }
            })
        );
    }
}
