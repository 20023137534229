<ng-container [formGroup]="form">
    <div class="mz-container h-100" [attr.data-testid]="uid">
        <div class="mz-card mz-card-insets h-100">
            <div class="mz-card-title">{{ l('General') }}</div>
            <div class="row">
                <div *ngIf="form?.controls?.name" class="col-12">
                    <div class="form-group">
                        <label for="Name">{{ l('Name') }} *</label>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="Name" shape="input"></app-mazars-skeleton>
                        <app-mazars-input-text
                            *ngIf="!dataLoading && !referenceDataLoading"
                            uid="Name"
                            formControlName="name"
                            [placeholder]="!isDisabled ? l('Name') : ''"
                        ></app-mazars-input-text>
                        <app-mazars-validation-message
                            [uid]="'Name'"
                            [key]="'Name'"
                            [control]="form.controls.name"
                            [submitting]="showWarnings"
                        ></app-mazars-validation-message>
                    </div>
                </div>

                <div *ngIf="form?.controls?.tags" class="col-12">
                    <div class="form-group">
                        <label for="Tags">{{ l('Tags') }}</label>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="Tags" shape="input"></app-mazars-skeleton>
                        <mazars-tags
                            *ngIf="!dataLoading && !referenceDataLoading"
                            uid="'workflow_tags'"
                            placeholder="{{ l('Tags') }}"
                            [availableTags]="availableTags"
                            formControlName="tags"
                        ></mazars-tags>
                    </div>
                </div>
                <div *ngIf="form?.controls?.note" class="col-12">
                    <div class="form-group">
                        <label for="Notes">{{ l('Notes') }}</label>
                        <app-mazars-information-icon uid="Notes_Tooltip" [message]="l('Notes_Tooltip')"></app-mazars-information-icon>
                        <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="Notes" shape="textarea"></app-mazars-skeleton>
                        <app-mazars-input-textarea
                            *ngIf="!dataLoading && !referenceDataLoading"
                            [uid]="'notes'"
                            formControlName="note"
                            [placeholder]="!isDisabled ? l('Notes') : ''"
                            [maxLength]="notesMaxLength"
                        ></app-mazars-input-textarea>
                        <app-mazars-validation-message
                            [uid]="'notes'"
                            [name]="l('Notes')"
                            [control]="form.controls.note"
                            [submitting]="showWarnings"
                        ></app-mazars-validation-message>
                    </div>
                </div>
                <div *ngIf="form?.controls?.isArchived" class="col-12">
                    <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="Archived" shape="textarea"></app-mazars-skeleton>
                    <p>
                        {{ l('Archive') }}
                    </p>

                    <div class="p-field-checkbox">
                        <app-mazars-skeleton
                            *ngIf="dataLoading || referenceDataLoading"
                            uid="ArchivedSkeleton"
                            shape="checkbox"
                        ></app-mazars-skeleton>
                        <app-mazars-input-checkbox
                            *ngIf="!dataLoading && !referenceDataLoading"
                            uid="General_IsArchived"
                            [binary]="true"
                            [formControl]="form.controls.isArchived"
                            (onToggleChanged)="onArchivedClicked($event)"
                        ></app-mazars-input-checkbox>
                        <label for="General_IsArchived" class="mb-0 ml-2" style="display: inline">
                            {{ l('ArchiveCheckboxLabel') }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
