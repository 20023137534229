import { Inject, Injectable } from '@angular/core';
import { FileParameter } from '@app/modules/master-data-management/master-data-management-proxies';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    IAttachmentDto,
    IAttachmentsFilterRequest,
    ICreateAttachmentInput,
    IFileDownloadDto,
    IPagedResultDtoOfAttachmentOverviewDto,
    IUpdateAttachmentInput,
} from '@shared/service-proxies/common-interfaces';
import { Observable } from 'rxjs';
import { CommonAttachmentsActions } from '../actions/common-attachments.actions';
import { CommonAttachmentsSelectors, CommonAttachmentsSelectorsToken } from '../selectors/common-attachments.selectors';
import { CommonAttachmentsState } from '../state/common-attachments.state';

@Injectable()
export class CommonAttachmentsFacade {
    public readonly allAttachments$: Observable<IPagedResultDtoOfAttachmentOverviewDto> = this.store.pipe(select(this.selectors.getAttachments));
    public readonly allAttachmentsTags$: Observable<string[]> = this.store.pipe(select(this.selectors.getTagsAttachments));
    public readonly allAllowedFileTypes$: Observable<string[]> = this.store.pipe(select(this.selectors.getAllowedFileTypes));
    public readonly createdAttachment$: Observable<number> = this.store.pipe(select(this.selectors.getCreatedAttachmentId));

    public readonly uploadedFile$: Observable<string> = this.store.pipe(select(this.selectors.getUploadedFile));
    public readonly uploadedFileError$: Observable<void> = this.actions$.pipe(ofType(this.commonAttachmentsActions.UploadFileFailure));
    public readonly downloadedFile$: Observable<IFileDownloadDto> = this.store.pipe(select(this.selectors.getDownloadedFile));
    public readonly previewedFile$: Observable<IFileDownloadDto> = this.store.pipe(select(this.selectors.getPreviewedFile));

    public readonly attachment$: Observable<IAttachmentDto> = this.store.pipe(select(this.selectors.getAttachment));
    public readonly entityUpdatedSuccessfully$: Observable<void> = this.actions$.pipe(ofType(this.commonAttachmentsActions.UpdateAttachmentSuccess));
    public readonly entityDeletedSuccessfully$: Observable<void> = this.actions$.pipe(ofType(this.commonAttachmentsActions.DeleteAttachmentSuccess));
    public readonly createOrUpdateError$: Observable<any> = this.store.pipe(select(this.selectors.getAttachmentError));
    public readonly loaded$: Observable<boolean> = this.store.pipe(select(this.selectors.loaded));
    public readonly createdByUsers$: Observable<any> = this.store.pipe(select(this.selectors.getCreatedByUsers));

    constructor(
        private readonly store: Store<CommonAttachmentsState>,
        private readonly actions$: Actions,
        @Inject(CommonAttachmentsSelectorsToken) private readonly selectors: CommonAttachmentsSelectors,
        private commonAttachmentsActions: CommonAttachmentsActions
    ) {}

    getAllAttachments(input: IAttachmentsFilterRequest) {
        this.store.dispatch(this.commonAttachmentsActions.GetAllAttachmentsRequest({ input }));
    }

    getAllAttachmentTags(input: string) {
        this.store.dispatch(this.commonAttachmentsActions.GetAllTagsRequest({ filter: input }));
    }
    
    getCreatedByUsers(referenceId: number) {
        this.store.dispatch(this.commonAttachmentsActions.GetCreatedByUsersRequest({ referenceId: referenceId }));
    }

    getAllowedFileTypes() {
        this.store.dispatch(this.commonAttachmentsActions.GetAllowedFileTypesRequest());
    }

    uploadFile(input: FileParameter) {
        this.store.dispatch(this.commonAttachmentsActions.UploadFileRequest({ input }));
    }

    downloadFile(referenceId: number, id: number) {
        this.store.dispatch(this.commonAttachmentsActions.DownloadFileRequest({ referenceId, id }));
    }

    previewFile(referenceId: number, id: number) {
        this.store.dispatch(this.commonAttachmentsActions.PreviewFileRequest({ referenceId, id }));
    }

    getByIdAttachment(referenceId: number, id: number) {
        this.store.dispatch(this.commonAttachmentsActions.GetByIdAttachmentRequest({ referenceId, id }));
    }

    create(input: ICreateAttachmentInput) {
        this.store.dispatch(this.commonAttachmentsActions.CreateAttachmentRequest({ input: input }));
    }

    update(input: IUpdateAttachmentInput) {
        this.store.dispatch(this.commonAttachmentsActions.UpdateAttachmentRequest({ input: input }));
    }

    delete(referenceId: number, id: number) {
        this.store.dispatch(this.commonAttachmentsActions.DeleteAttachmentRequest({ referenceId, id }));
    }
}
