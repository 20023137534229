import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class NotificationsSignalrService extends AppComponentBase {
    notificationHub: HubConnection;
    isNotificationConnected = false;

    constructor(injector: Injector, public _zone: NgZone) {
        super(injector);
    }

    configureConnection(connection): void {
        // Set the common hub
        this.notificationHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    reject();
                } else {
                    connection
                        .start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose((e) => {
            this.isNotificationConnected = false;

            if (e) {
                abp.log.debug('Notifications connection closed with error: ' + e);
            } else {
                abp.log.debug('Notifications disconnected');
            }

            start().then(() => {
                this.isNotificationConnected = true;
            });
        });

        // Register to get notifications
        this.registerNotificationsEvents(connection);
    }

    registerNotificationsEvents(connection): void {
        connection.on('notificationMessage', (result) => {
            abp.event.trigger('app.notifications.notificationMessage', result);
        });
        connection.on('refreshNotifications', (result) => {
            abp.event.trigger('app.notifications.refreshNotifications', result);
        });
    }

    init(): void {
        this._zone.runOutsideAngular(() => {
            abp.signalr.connect();
            abp.signalr
                .startConnection(abp.appPath + 'signalr-notifications', (connection) => {
                    this.configureConnection(connection);
                })
                .then(() => {
                    abp.event.trigger('app.notifications.connected');
                    this.isNotificationConnected = true;
                });
        });
    }
}
