import { Component, Injector, Input, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { CommunicationService } from '@app/modules/mazars-common/services/communication.service';
import { CommonCommunicationsFacade } from '@app/modules/mazars-common/state-management/facades/common-communications.facade';
import { CommonQuillMentionService } from '@app/shared/common/quill/common-quill-mention.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsEditorComponent } from '../../mazars-editor/mazars-editor.component';
import { IChannelOverviewDto, ICreateCommunicationInput } from '@shared/service-proxies/common-interfaces';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'mazars-chat-mazars-chat-discussion',
    templateUrl: './mazars-chat-discussion.component.html',
    styleUrls: ['./mazars-chat-discussion.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsChatDiscussionComponent extends AppComponentBase implements OnInit {
    @Input() commonQuillMentionService: CommonQuillMentionService;
    @Input({ required: true }) uid: string;
    @Input() referenceId: number;
    @Input() communicationMessageIdentifier: string;
    @Input() hasCreatePermission: boolean;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Input() hasViewPermission: boolean;
    availableShortcuts: IItem<string>[];
    mEditor: MazarsEditorComponent;
    currentChannel: IChannelOverviewDto | any;

    currentUserId = abp.session.userId;

    formControl: FormControl;

    SecurityContext = SecurityContext;
    defaultProfilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';

    constructor(injector: Injector, private communicationsFacade: CommonCommunicationsFacade, private fb: FormBuilder, private communicationService: CommunicationService) {
        super(injector);
    }

    ngOnInit(): void {
        this.formControl = this.fb.control(null, [Validators.required]);
        this.availableShortcuts = this.communicationService.getAvailableEditorShortcuts();
        this.registerSubscriptions();
    }

    sendMessage() {
        if (this.formControl.valid && this.formControl.value) {

            if(JSON.parse(this.formControl.value)[0].insert){
                const message = {
                    referenceId: this.currentChannel.id,
                    content: this.formControl.value,
                } as ICreateCommunicationInput;
                    
                this.communicationsFacade.sendCommunicationMessage(message);            
                this.formControl.reset();
            }
        }
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.communicationsFacade.currentChannel$.subscribe((channel) => {
                if (channel !== null) {
                    this.currentChannel = channel;
                }
            })
        );

        this.subscribeToEvent(`app.${this.communicationMessageIdentifier}.messageRecieved`, (message) => {
            this.communicationsFacade.communicationMessageRecieved(message);
        });
    }
}
