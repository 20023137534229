import { Component, InjectionToken, Injector, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsAssignableUserSelectEditModalV2Component } from './mazars-assignable-user-select-edit-modal-v2.component';
import { SetAssignedUserFacade } from '@app/shared/common/facades/set-assigned-user.facade';
import { IAssignableUserDto, ISetAssignedUserInput } from '@shared/service-proxies/common-interfaces';

export const ReferenceDataFacade = new InjectionToken<string>('ReferenceDataFacade');

@Component({
    selector: 'app-mazars-assignable-user-select-v2',
    templateUrl: './mazars-assignable-user-select-v2.component.html',
})
export class MazarsAssignableUserSelectV2Component extends AppComponentBase {
    @ViewChild('mazarsAssignableEditModal')
    mazarsAssignableUserEditModal: MazarsAssignableUserSelectEditModalV2Component;

    @Input({ required: true }) uid: string;
    @Input() mode: string;
    @Input() assignedUser: IAssignableUserDto;
    @Input() isEditable = false;
    @Input() entityId: number;
    @Input() rootEntityId?: number;
    @Input() permissionNeeded: string;
    @Input() setAssignedUserFacade: SetAssignedUserFacade;
    constructor(injector: Injector) {
        super(injector);
    }

    showAssignableUserEditModal(): void {
        if (!this.isEditable) {
            return;
        }
        this.mazarsAssignableUserEditModal.show(this.entityId, this.permissionNeeded, this.assignedUser?.id, this.setAssignedUserFacade, this.rootEntityId).then((assignedUserId) => {
            let input = {} as ISetAssignedUserInput;
            input.referenceId = this.entityId;
            input.assignedUserId = assignedUserId;
            if (this.rootEntityId) {
                input.rootEntityId = this.rootEntityId;
            }
            this.setAssignedUserFacade.setAssignedUser(input);
        });
    }
}
