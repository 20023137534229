import { Component, Input, OnChanges, SecurityContext, SimpleChanges } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { LocalStorageService } from '@shared/utils/local-storage.service';

@Component({
    selector: 'app-mazars-profile-picture',
    templateUrl: './mazars-profile-picture.component.html',
})
export class MazarsProfilePictureComponent implements OnChanges {
    @Input({ required: true }) uid: string;
    @Input() mode: string;
    @Input() user: any;
    profilePicture: string;
    isOrganizationUnit = false;
    securityContext = SecurityContext;

    constructor(
        private _localStorageService: LocalStorageService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user) {
            if (this.user?.id) {
                this.getProfilePicture(this.user.id);
            } else if (this.user?.value) {
                if (!isNaN(this.user.value)) {
                    this.getProfilePicture(this.user.value);
                    return;
                }
                const parts = this.user.value.split('_');
                const id = parts[0];
                const type = parts[1];
                if (type === 'User') {
                    this.getProfilePicture(id);
                } else if (type === 'OrganizationUnit') {
                    this.isOrganizationUnit = true;
                }
            }
        }
    }

    private getProfilePicture(userId: number) {
        if (isNaN(Number(userId)) || isNaN(userId - 0)) {
            return;
        }
        const context = this;
        this._localStorageService.getItem(AppConsts?.authorization?.encrptedAuthTokenName, function (err, value) {
            context.profilePicture =
                AppConsts.remoteServiceBaseUrl +
                '/Profile/GetProfilePictureByUser?userId=' +
                userId +
                '&' +
                AppConsts.authorization.encrptedAuthTokenName +
                '=' +
                encodeURIComponent(value.token);
        });
    }
}
