import { Injectable, Injector } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AssignableUserDto, MappingCustomGrantsServiceProxy, MappingReferenceDataServiceProxy } from '@app/modules/account-mapping/account-mapping-proxies';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AccountMappingComponentBase } from '../account-mapping-component-base';

@Injectable({
    providedIn: 'root',
})
export class MappingReferenceDataService extends AccountMappingComponentBase {
    mappingProcessStates: IItem<string>[] = [];

    constructor(
        injector: Injector,
        private mappingCustomGrantsServiceProxy: MappingCustomGrantsServiceProxy,
        private mappingReferenceDataServiceProxy: MappingReferenceDataServiceProxy
    ) {
        super(injector);
    }

    load() {
        this.getMappingProcessStates()
            .pipe(take(1))
            .subscribe((mappingProcessStates) => {
                this.mappingProcessStates = mappingProcessStates;
            });
    }

    getAllPossibleUsers(mappingId: number, permissionName: string, excludeUsers: boolean = true): Observable<IItem<number>[]> {
        return this.mappingCustomGrantsServiceProxy.getAllPossibleUsers(mappingId, permissionName, excludeUsers).pipe(
            take(1),
            map((result) =>
                result.items.map(
                    (user) =>
                        ({
                            id: user.userOrOrganizationId,
                            text: user.displayName,
                        } as IItem<number>)
                )
            )
        );
    }

    getAllPossibleUsersOverview(mappingId: number, permissionName: string, excludeUsers: boolean = true): Observable<AssignableUserDto[]> {
        return this.getAllPossibleUsers(mappingId, permissionName, excludeUsers).pipe(
            take(1),
            map((result) =>
                result.map(
                    (user) =>
                        ({
                            id: user.id,
                            fullName: user.text,
                        } as AssignableUserDto)
                )
            )
        );
    }

    onDestroy(): void {
        // to be implemented if need it
    }

    private getMappingProcessStates(): Observable<IItem<string>[]> {
        return this.mappingReferenceDataServiceProxy.getMappingProcessStates().pipe(
            take(1),
            map((mappingProcessStates) => this.mapMappingProcessStates(mappingProcessStates))
        );
    }

    private mapMappingProcessStates(mappingProcessStates: string[]): IItem<string>[] {
        return mappingProcessStates.map(
            (mappingProcessState) =>
                ({
                    id: mappingProcessState,
                    text: this.l(`WorkItemProcess_${mappingProcessState}`),
                } as IItem<string>)
        );
    }
}
