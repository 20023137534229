import { Injectable, InjectionToken } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonGridConfigurationState } from '../state/common-grid-configuration.state';

export const CommonGridConfigurationSelectorsToken = new InjectionToken<string>('CommonGridConfigurationSelectors');

@Injectable()
export class CommonGridConfigurationSelectors {
    public readonly gridConfigurationState = createFeatureSelector<CommonGridConfigurationState>(this.gridConfigurationFeatureKey);
    public readonly getGridColumnSettings = createSelector(this.gridConfigurationState, (state: CommonGridConfigurationState) => state.gridColumnSettings);
    public readonly loaded = createSelector(this.gridConfigurationState, (state: CommonGridConfigurationState) => state.loaded);
    public readonly getGridColumnSettingsError = createSelector(this.gridConfigurationState, (state: CommonGridConfigurationState) => state.error);
    public readonly getGridColumnConfig = createSelector(this.gridConfigurationState, (state: CommonGridConfigurationState) => state.gridColumnConfig);
    public readonly getSorting = createSelector(this.gridConfigurationState, (state: CommonGridConfigurationState) => state.sorting);

    constructor(private gridConfigurationFeatureKey: string) {}
}
