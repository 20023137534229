<div [ngClass]="{ 'd-flex flex-md-row': !isVertical }" [attr.data-testid]="uid">
    <div *ngFor="let item of items">
        <div [ngClass]="{ 'mr-5 col-lg-2 col-md-2': !isVertical }">
            <p-radioButton
                [disabled]="item.isDisabled || isDisabled || disabled"
                labelStyleClass="label-align"
                [label]="item.text"
                [value]="item.id"
                [(ngModel)]="selectedItem"
                (ngModelChange)="onChangeHandler()"
                [attr.data-testid]="'radio-group-item-' + item?.id"
            ></p-radioButton>
            <app-mazars-information-icon [uid]="'radio-group-tooltip-' + item?.id" *ngIf="item.tooltip" [message]="item.tooltip" class="information-align"></app-mazars-information-icon>
        </div>
    </div>
</div>
