import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-mazars-button',
    templateUrl: './mazars-button.component.html',
    styleUrls: ['./mazars-button.component.css'],
})
export class MazarsButtonComponent extends AppComponentBase {
    @Input() uid = 'mz-button';
    @Input() label: string;
    @Input() iconClass: string = null;
    @Input() busy = false;
    @Input() busyText: string = null;
    @Input() disabled = false;
    @Input() styleClass: string = null;
    @Input() inverted = false;
    @Input() isSubmit = false;
    @Input() large = false;
    @Input() bordered = false;
    @Output() onClick = new EventEmitter<void>();
}
