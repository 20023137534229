<form [formGroup]="form" *ngIf="form != null" (ngSubmit)="onButtonClick()" autocomplete="off">
    <app-mazars-radio-group
        [items]="types"
        [formControl]="form.controls.type"
        (onValueChange)="clearForm()"
        [uid]="'radio-group-taxnumber-converter-types'"
    ></app-mazars-radio-group>
    <div class="form-group my-2">
        <app-mazars-dropdown
            [uid]="'FederalState'"
            [options]="federalStates"
            [hasEmpty]="false"
            [filter]="false"
            [formControl]="form.controls.federalState"
        ></app-mazars-dropdown>
    </div>
    <div class="d-flex">
        <app-mazars-input-text [uid]="'Umwandeln_input'"
        class="flex-grow-1 mr-4" [placeholder]="this.form.value.type == TAXNUMBER ? 'Steuernummer' : 'Aktenzeichen'" [formControl]="form.controls.number"></app-mazars-input-text>
        <app-mazars-button
            [uid]="'UmwandelnButton'"
            [label]="'Umwandeln'"
            [busy]="submitting"
            [disabled]="!form.valid"
            (onClick)="onButtonClick()"
        ></app-mazars-button>
    </div>
</form>
<div *ngIf="result != null" class="alert alert-secondary mt-5" role="alert">
    Ergebnis:
    <strong class="mx-2">{{ result }}</strong>
    <i
        *ngIf="!copying"
        class="far fa-copy"
        style="cursor: pointer"
        aria-hidden="true"
        [mazarsTooltip]="'In Zwischenablage kopieren'"
        [escape]="false"
        (click)="copyToClipboard()"
    ></i>
    <i aria-hidden="true" *ngIf="copying" class="fas fa-check-double" aria-hidden="true"></i>
</div>
