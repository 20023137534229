<div *ngIf="value && value.length === 0 && !tagsAdded && !isDisabled">
    <app-mazars-button
        [uid]="uid + '_add'"
        [label]="l('btn_AddTag')"
        iconClass="pi pi-plus"
        [inverted]="true"
        (onClick)="onAddClick()"
        [attr.data-testid]="uid + '-add-tag-button'"
    ></app-mazars-button>
</div>

<app-mazars-input-text
    *ngIf="value && value.length === 0 && !tagsAdded && isDisabled"
    [uid]="uid"
    [isDisabled]="isDisabled"
></app-mazars-input-text>

<div *ngIf="value && tagsAdded">
    <p-autoComplete
        #tagsAutocomplete
        id="{{ uid }}"
        name="{{ uid }}"
        field="name"
        [styleClass]="!isDisabled ? 'width-percent-100' : 'width-percent-100 p-autocomplete-disabled'"
        inputStyleClass="form-control"
        [completeOnFocus]="true"
        [dropdown]="!isDisabled"
        [dropdownIcon]="'pi pi-plus'"
        [attr.data-testid]="uid"
        [ngModel]="value"
        [suggestions]="suggestedTags"
        [minLength]="0"
        [multiple]="true"
        [disabled]="isDisabled"
        [unique]="true"
        (ngModelChange)="onValueChange($event)"
        (onBlur)="onBlur()"
        (completeMethod)="filterTags($event)"
        (onFocus)="tagsAutocomplete.handleDropdownClick($event)"
        (onClear)="clearItems(tagsAutocomplete)"
        (onKeyUp)="onKeyUp($event)"
        [attr.data-testid]="uid"
    >
        <ng-template let-tag pTemplate="item">
            <span [attr.data-testid]="uid + '-' + tag.name">
                <i aria-hidden="true" class="pi pi-tag mr-1"></i>
                {{ tag.name }}
            </span>
        </ng-template>
        <ng-template let-tag pTemplate="selectedItem">
            <div [attr.data-testid]="uid + '-selected-item-' + tag.name">
                <i aria-hidden="true" class="pi pi-tag mr-1"></i>
                {{ tag.name }}
            </div>
        </ng-template>
    </p-autoComplete>
</div>
