import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-mazars-flag-icon',
    templateUrl: './mazars-flag-icon.component.html',
    styleUrls: ['./mazars-flag-icon.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsFlagIconComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() countryCode: string;

    getCountryCode() {
        return this.countryCode ? this.countryCode.toLowerCase() : '';
    }

    getCountryName() {
        return this.l(`Country_${this.countryCode}`);
    }
}
