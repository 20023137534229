import { Component, ViewChild, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsStatusChange } from './mazars-status-change';
import { Acceptor } from '../mazars-dialog/acceptor';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';

@Component({
    selector: 'app-mazars-status-edit-modal',
    templateUrl: './mazars-status-edit-modal.component.html',
})
export class MazarsStatusEditModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('editModal', { static: true }) modal: MazarsDialogComponent;
    @Input('uid') uid: string;

    form: FormGroup;
    statusItems: IItem<string>[];

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
    }

    show(statusValue: string, statusValues: IItem<string>[]): DialogResult {
        this.statusItems = statusValues;
        this.form.reset();

        if (this.statusItems.find((s) => s.id === statusValue) != null) {
            this.form.setValue({
                statusValue: statusValue,
                statusReason: null,
            });
        }

        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor): void {
        acceptor.accept(<MazarsStatusChange>{
            value: this.form.value.statusValue,
            reason: this.form.value.statusReason,
        });
    }

    private createForm(): void {
        this.form = this.fb.group({
            statusValue: [null],
            statusReason: [null, [Validators.maxLength(400)]],
        });
    }
}
