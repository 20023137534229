import { Component, Injector, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-mazars-validation-message',
    templateUrl: './mazars-validation-message.component.html'
})
export class MazarsValidationMessageComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() key: string; // key to be translated
    @Input() name: string; // name without translation, replaces key
    @Input() control: AbstractControl;
    @Input() submitting: boolean;
    @Input() isEnabled = true;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }
}
