<div class="row" style="padding-left: 12px">{{ l('Submission_Description') }}</div>
<div class="row">
    <mazars-submission-info-section
        [submission]="submission"
        [submissionsServiceProxy]="submissionsServiceProxy"
    ></mazars-submission-info-section>
</div>
<div class="row mt-5">
    <h6>{{ l('Submission_Feedback_Title') }}</h6>
</div>
<div class="row" style="padding-left: 12px">{{ subtitle }}</div>
<div class="row" style="padding-left: 12px">{{ l('Submission_Feedback_Description') }}</div>
<div class="row">
    <mazars-feedback-info-section
        [submission]="submission"
        [feedbackServiceProxy]="feedbackServiceProxy"
    ></mazars-feedback-info-section>
</div>
