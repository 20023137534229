import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IChannelOverviewDto, IInitiateEmailConversationInput } from '../common-interfaces';

export const IChannelsServiceToken = new InjectionToken<string>('IChannelService');

export interface IChannelService {
    getAll(input: any | undefined): Observable<IChannelOverviewDto[]>;
    create(input: any | undefined): Observable<void>;
    update(input: any | undefined): Observable<void>;
    delete(id: any | undefined, referenceId: any | undefined): Observable<void>;
    initiateEmailConversation(input: IInitiateEmailConversationInput | undefined): Observable<void>;
    getNumberOfUnreadChannels(rootId: number | undefined): Observable<number>;
}
