import { Component, ContentChildren, Injector, Input, QueryList, TemplateRef, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsWizardFacade } from '../../state-management/facades/mazars-wizard.facade';
import { Acceptor } from '../mazars-dialog/acceptor';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { MazarsTemplateDirective } from '../mazars-template/mazars-template.directive';
import { MazarsWizardButtonsActions } from './mazars-wizard-actions';

@Component({
    selector: 'app-mazars-wizard',
    templateUrl: './mazars-wizard.component.html',
    styleUrls: ['./mazars-wizard.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsWizardComponent extends AppComponentBase {
    @ContentChildren(MazarsTemplateDirective, { read: MazarsTemplateDirective })
    templateRefs: QueryList<MazarsTemplateDirective>;
    @ViewChild('modal', { static: true }) modal: MazarsDialogComponent;
    @Input({ required: true }) uid: string;
    @Input() title: string;
    @Input() items: any[];
    @Input() canContinueToNextStep: boolean;
    @Input() showSteps = true;
    @Input() size: string;
    @Input() styleClass: string;
    @Input() actions: MazarsWizardButtonsActions;
    @Input() isLoading = false;
    @Output() onDestroy: EventEmitter<void> = new EventEmitter<void>();

    activeIndex: number = 0;

    constructor(
        _injector: Injector,
        public wizardFacade: MazarsWizardFacade,
    ) {
        super(_injector);
    }

    onActiveIndexChange(event) {
        this.activeIndex = event;
        this.wizardFacade.setActiveStep(this.activeIndex);
    }

    getTemplate(identifier: string): TemplateRef<any> {
        for (let ref of this.templateRefs) {
            if (ref.identifier === identifier) {
                return ref.template;
            }
        }
        return null;
    }

    beforeSubmit(acceptor: Acceptor): void {
        acceptor.accept();
    }

    onNext(_: boolean) {
        this.onActiveIndexChange(this.activeIndex + 1);
    }

    onBack(_: boolean) {
        this.onActiveIndexChange(this.activeIndex - 1);
    }

    show(): DialogResult {
        this.onActiveIndexChange(0);
        return this.modal.show();
    }

    cancel() {
        this.modal.cancel();
    }

    onDestroyModal() {
        this.wizardFacade.clear();
        this.onDestroy.emit();
    }
}
