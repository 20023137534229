import { Inject, Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { IModule, AppModules  } from '@shared/AppEnums';
import { LocalizationService } from 'abp-ng2-module';
import { CommunicationShortcut } from '../components/mazars-communication/communication-shortcut';
import { CommunicationShortcutIcon } from '../components/mazars-communication/communication-shortcut-icon';
import { IItem } from '../interfaces/item.interface';

@Injectable()
export class CommunicationService {
    constructor(private als: LocalizationService, @Inject(IModule) private module: AppModules) {}

    getCommunicationShortcutIcon(type: string): string {
        switch (type) {
            case CommunicationShortcut.ShortcutLinkAttachments:
                return CommunicationShortcutIcon.ShortcutLinkAttachments;
            case CommunicationShortcut.ShortcutLinkDeclarations:
                return CommunicationShortcutIcon.ShortcutLinkDeclarations;
            case CommunicationShortcut.ShortcutLinkNotifyUsers:
                return CommunicationShortcutIcon.ShortcutLinkNotifyUsers;
            case CommunicationShortcut.ShortcutLinkSurveys:
                return CommunicationShortcutIcon.ShortcutLinkSurveys;
            default:
                return '';
        }
    }

    getAvailableEditorShortcuts(): IItem<string>[] {
        const availableShortcuts = [];

        availableShortcuts.push(<IItem<string>>{
            tooltip: this.als.localize(CommunicationShortcut.ShortcutLinkNotifyUsers, AppConsts.localization.defaultLocalizationSourceName),
            icon: this.getCommunicationShortcutIcon(CommunicationShortcut.ShortcutLinkNotifyUsers),
        });
        availableShortcuts.push(<IItem<string>>{
            tooltip: this.als.localize(CommunicationShortcut.ShortcutLinkAttachments, AppConsts.localization.defaultLocalizationSourceName),
            icon: this.getCommunicationShortcutIcon(CommunicationShortcut.ShortcutLinkAttachments),
        });

        if (this.module === AppModules.Dac6Module) {
            availableShortcuts.push(<IItem<string>>{
                tooltip: this.als.localize(CommunicationShortcut.ShortcutLinkSurveys, AppConsts.localization.defaultLocalizationSourceName),
                icon: this.getCommunicationShortcutIcon(CommunicationShortcut.ShortcutLinkSurveys),
            });
        } else if (this.module === AppModules.RealPropertyTaxModule) {
            availableShortcuts.push(<IItem<string>>{
                tooltip: this.als.localize(CommunicationShortcut.ShortcutLinkDeclarations, AppConsts.localization.defaultLocalizationSourceName),
                icon: this.getCommunicationShortcutIcon(CommunicationShortcut.ShortcutLinkDeclarations),
            });
        }

        return availableShortcuts;
    }
}
