<div id="segmentFeedback">
    <div class="row">
        <div class="col-lg-3 col-sm-6 mt-2">
            <div>{{ l('Submission_Feedback_ReceiptDate') }}</div>
            <div *ngIf="submission?.feedback?.externalDate">{{ getDate(submission?.feedback?.externalDate) }}</div>
            <div *ngIf="!submission?.feedback?.externalDate && submission?.feedback?.updatedOn">
                {{ getDate(submission?.feedback?.updatedOn) }}
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 mt-2">
            <div>{{ l('Submission_Feedback_Status') }}</div>
            <div>{{ submission?.feedback?.hasError ? l('Submission_Error') : l('Submission_Successfull') }}</div>
        </div>
        <div class="col-lg-3 col-sm-6 mt-2">
            <div>{{ l('Submission_Feedback_TransferticketID') }}</div>
            <div>{{ submission?.feedback?.externalId }}</div>
        </div>
        <div class="col-lg-3 col-sm-6 mt-2">
            <div>{{ l('Submission_FeedbackDocuments') }}</div>
            <div>
                <i
                    class="fas fa-file-pdf fa-fw"
                    (click)="downloadPdf()"
                    style="font-size: 1.5rem; cursor: pointer"
                    aria-hidden="true"
                ></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="!feedbackSuccessful() && feedbackErrors().length > 0">
            <span class="mediumFontSize bold">{{ l('Segment_Errors') }}</span>
            <div *ngFor="let error of feedbackErrors()">{{ error.code }} - {{ error.description }}</div>
            <br />
        </div>
        <div *ngIf="!feedbackSuccessful() && feedbackWarnings().length > 0">
            <span class="mediumFontSize bold">{{ l('Segment_Warnings') }}</span>
            <div *ngFor="let warning of feedbackWarnings()">{{ warning.code }} - {{ warning.description }}</div>
            <br />
        </div>
    </div>
</div>
