export class Acceptor {
    private _accept: (result?: any) => void;
    private _reject: () => void;
    private consumed = false;

    public constructor(accept: (result?: any) => void, reject: () => void) {
        this._accept = accept;
        this._reject = reject;
    }

    public accept(result?: any): void {
        if (this.consumed) {
throw new Error('Acceptor is already consumed');
}
        this.consumed = true;
        this._accept(result);
    }

    public reject(): void {
        if (this.consumed) {
throw new Error('Acceptor is already consumed');
}
        this.consumed = true;
        this._reject();
    }
}
