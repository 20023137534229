import { Injector, Component, ViewEncapsulation, Inject, Input, OnInit, SecurityContext } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';
import * as KTUtil from '@metronic/app/kt/_utils';
import { HttpClient } from '@angular/common/http';

@Component({
    templateUrl: './mazars-logo.component.html',
    selector: 'mazars-logo',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsLogoComponent extends AppComponentBase implements OnInit {
    @Input({ required: true }) uid: string;
    @Input() customHrefClass = '';
    @Input() skin = null;

    defaultLogo = '';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    logoContent: any;
    securityContext = SecurityContext;
    private tenantId;
    private logoId;

    constructor(injector: Injector, @Inject(DOCUMENT) private document: Document, private _httpClient: HttpClient) {
        super(injector);
    }

    ngOnInit(): void {
        this.setLogoUrl();
    }

    setLogoUrl(): void {
        let skinName = this.currentTheme.baseSettings.menu.asideSkin;
        if (KTUtil.isMobileDevice()) {
            skinName = this.currentTheme.baseSettings.layout.darkMode ? 'dark' : 'light';
        }

        this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + skinName + '.svg';
        this.getLogo();
    }

    getLogo() {
        if (!abp.session.userId || abp.session.userId === 0) {
            return;
        }

        if (this.tenantId === this.appSession.tenant?.id && this.logoId === this.appSession.tenant?.lightLogoId) {
            return;
        }

        let url =
            this.remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            this.currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            this.appSession.tenant.id +
            '&id=' +
            this.appSession.tenant.lightLogoId;
        this._httpClient.get(url, { responseType: 'blob' }).subscribe((data) => {
            this.createImageFromBlob(data);
        });
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.logoContent = reader.result;
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }
}
