<div class="ps-4 d-none d-md-block border-bottom">
    <div class="d-flex align-items-center channels-title-name">
        <h5 class="flex-grow-1 m-0">Channels</h5>
        <div class="d-flex justify-content-end" [hidden]="!hasCreatePermission">
            <app-mazars-button
                [uid]="uid + '_create'"
                iconClass="pi pi-plus"
                [inverted]="true"
                large="true"
                styleClass="mz-searchbar-create channels-heading-button"
                (onClick)="showCreateModal()"
            ></app-mazars-button>
        </div>
    </div>
</div>
<div class="channels-list">
    <div
        [ngClass]="currentChannel?.id === channel?.id && 'active ' + 'list-group-item list-group-item-action border-0 py-0 px-0'"
        *ngFor="let channel of channels"
    >
        <div class="ps-4 d-flex align-items-center">
            <div class="flex-grow-1 m-0 cursor-pointer" (click)="onSelectChannel(channel)">
                <strong>
                    {{ channel.title }}
                    <i *ngIf="!channel.isRead" class="fa fa-circle fa-xs ms-2 primary-color"></i>
                </strong>
            </div>
            <div class="d-flex justify-content-end" [hidden]="!hasEditPermission">
                <app-mazars-actions-menu
                    [uid]="'Channels_' + channel.id + '_actions_btn'"
                    [btnSize]="'btn-md'"
                    [fontSize]="'large'"
                    [actionItems]="getActionItems(channel)"
                ></app-mazars-actions-menu>
            </div>
        </div>
    </div>
</div>
<hr class="d-block d-lg-none mt-1 mb-0" />

<mazars-channel-modal #mazarsChannelModal [uid]="uid + '-channel-modal'"></mazars-channel-modal>
<mazars-whitelist-modal
    #mazarsWhitelistModal
    [uid]="uid + '-whitelist-modal'"
></mazars-whitelist-modal>
<mazars-participants-dialog #mazarsParticipantsDialog [uid]="uid + '-participants-dialog'"></mazars-participants-dialog>
