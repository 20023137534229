<!-- header -->
<div id="kt_header" class="header align-items-center" [attr.data-testid]="uid">
    <div class="{{ containerClass }} d-flex align-items-center justify-content-between flex-grow-1 h-90px">
        <mazars-logo [customHrefClass]="'d-block'" uid="header-app-logo"></mazars-logo>
        <div class="d-flex align-items-center justify-content-between">
            <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
        </div>
    </div>
    <!-- Tool header -->
    <div class="tool-header w-100 d-flex container-fluid">
        <div>
            <i aria-hidden="true" class="{{ toolHeaderIcon }} tool-header__icon"></i>
            <span class="tool-header__title">{{ toolHeaderTitle }}</span>
        </div>
        <mazars-burgermenu *ngIf="useBurgerMenu" (toggle)="toggleMenu($event)"></mazars-burgermenu>
    </div>
</div>
