<a *ngIf="chatMessageType === 'image'" [href]="chatMessage | safe: securityContext.URL" target="_blank">
    <img [src]="chatMessage | safe: securityContext.URL" class="chat-image-preview" alt="" />
</a>
<a *ngIf="chatMessageType === 'file'" [href]="chatMessage | safe: securityContext.URL" target="_blank" class="chat-file-preview">
    <i aria-hidden="true" class="fa fa-file"></i>
    {{ fileName }}
    <i aria-hidden="true" class="fa fa-download pull-right"></i>
</a>
<a *ngIf="chatMessageType === 'link'" [href]="chatMessage | safe: securityContext.URL" target="_blank" class="chat-link-message">
    <i aria-hidden="true" class="fa fa-link"></i>
    {{ chatMessage }}
</a>
<span *ngIf="chatMessageType === 'text'" [innerHTML]="chatMessage | safe: securityContext.HTML"></span>
