<a href="/" [class]="customHrefClass" [attr.data-testid]="uid">
    <img
        alt="Logo"
        *ngIf="defaultLogo && (!appSession.user?.id || !appSession.tenant || !appSession.tenant.lightLogoId)"
        [src]="defaultLogo | unsafe: securityContext.URL"
        class="logo"
    />
    <img
        alt="Logo"
        *ngIf="appSession.user?.id && appSession.tenant && appSession.tenant.lightLogoId"
        [src]="logoContent | safe: securityContext.HTML"
        class="logo"
    />
</a>
