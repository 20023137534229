import { Component, EventEmitter, Injector, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';

@Component({
    selector: 'app-mazars-radio-group',
    templateUrl: './mazars-radio-group.component.html',
    styleUrls: ['./mazars-radio-group.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsRadioGroupComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsRadioGroupComponent<TKey> extends AppComponentBase implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() items: IItem<TKey>[];
    @Input() isVertical: boolean;
    @Input() disabled = false;
    @Output() onValueChange = new EventEmitter<TKey>();

    selectedItem: any;
    isDisabled: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    writeValue(obj: any): void {
        if (obj !== null || obj !== undefined) {
            this.selectedItem = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onChangeHandler() {
        this.onChange(this.selectedItem);
        this.onValueChange.emit(this.selectedItem);
    }

    private onChange = (_) => {
        // This is intentional
    };

    private onTouched = () => {
        // This is intentional
    };
}
