<app-mazars-searchbar uid="search" [tableId]="tableId" [showConfigSection]="true"
    [gridConfigurationFacade]="gridConfigurationFacade" (onChange)="setFilter($event)"
    [showCreate]="hasCreatePermission" (onCreate)="showCreateModal()"
    [filters]="filtersDefinitions">
</app-mazars-searchbar>

<app-mazars-grid #mazarsTable [uid]="tableId" [primengTableHelper]="primengTableHelper" [actionItems]="getActionItems"
    [isActionColumnHidden]="false" [columnDefinitions]="columnDefinitions"
    [gridConfigurationFacade]="gridConfigurationFacade" (getAllRecords)="getAttachments($event)"
    [hasRowExpansion]="false" [isResizable]="true" [hasGridSettings]="true">
    <ng-template mazarsTemplate="nameTemplate" let-option>
        <span *ngIf="option.hasPreview" (click)="previewFile(option.id)" class="cursor-pointer underline-link">{{ option.name }}</span>
        <span *ngIf="!option.hasPreview">{{ option.name }}</span>

    </ng-template>
    <ng-template mazarsTemplate="fileContributionInfosTemplate" let-option>
        <div class="d-flex" *ngFor="let contributionInfo of option.fileContributionInfos">
            <div class="d-flex align-items-center" [mazarsTooltip]="contributionInfo.refName" [escape]="false">
                <a class="underline-link"
                    [routerLink]="this.absoluteModuleRootPath + '/' + referenceId + '/' + contributionInfo.refType + '/' + (includeRefIdInLink ? contributionInfo.refId : '')">
                    {{ contributionInfo.refName }}
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template mazarsTemplate="taglistTemplate" let-option>
        <mazars-tags-list [tagModel]="option.tags" [uid]="'attachments-taglist-' + option.id"></mazars-tags-list>
    </ng-template>
</app-mazars-grid>

<mazars-attachments-modal #mazarsAttachmentsModal [uid]="'ma_attachment_modal'"></mazars-attachments-modal>
<app-mazars-preview-modal #filePreviewDialog></app-mazars-preview-modal>