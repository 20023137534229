import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-mazars-datetimepicker',
    templateUrl: './mazars-datetimepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsDateTimePickerComponent,
        },
    ],
})
export class MazarsDateTimePickerComponent implements OnInit, ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() placeholder: string;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() selectionMode = 'single';

    value: Date;
    isDisabled: boolean;
    yearRange: string;
    private minYearRange = 1900;
    private yearThreshold = 100;

    constructor(private _dateTimeService: DateTimeService) {}

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    ngOnInit(): void {
        const today = new Date();
        const maxYearRange =
            this.maxDate instanceof Date ? this.maxDate.getFullYear() : today.getFullYear() + this.yearThreshold;
        this.minYearRange = this.minDate instanceof Date ? this.minDate.getFullYear() : this.minYearRange;
        this.yearRange = `${this.minYearRange}:${maxYearRange}`;
    }

    writeValue(obj: DateTime): void {
        this.value = obj?.toJSDate();
        this.onValueChange();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onValueChange() {
        if (this.value == null) {
            this.onChange(null);
        } else {
            const dateTime = DateTime.fromJSDate(this.value);
            this.onChange(this._dateTimeService.convertToUtcDateTime(dateTime));
        }
    }

    onBlur() {
        this.onTouched();
    }
}
