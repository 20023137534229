import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITabMenuItem } from '@app/modules/mazars-common/components/mazars-tab-menu/tab-menu-interfaces';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs';
import { ActionIcon } from '../mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { MainMenuTab, IroMenuTab } from './common/enums/iro-menu-tabs';

@Injectable()
export abstract class IroComponentBase extends AppComponentBase implements OnDestroy {
    subscriptions: Subscription[] = [];
    public absoluteModuleRootPath = '/app/iro';
    public absoluteModuleRootPathForms: string = this.absoluteModuleRootPath + '/forms';
    public absoluteModuleRootPathFormsCreate: string = this.absoluteModuleRootPathForms + '/create';
    public absoluteModuleRootPathDynamicTemplates: string = this.absoluteModuleRootPath + '/dynamic-templates';
    public absoluteModuleRootPathDynamicTemplatesCreate: string = this.absoluteModuleRootPathDynamicTemplates + '/create';
    public absoluteModuleRootPathAdminSettings: string = this.absoluteModuleRootPath + '/admin-settings';
    public absoluteModuleRootPathProfiles: string = this.absoluteModuleRootPath + '/profiles';

    public activatedRoute: ActivatedRoute;

    public staticTemplateLocalizationSourceName: string;

    mainMenuItems: ITabMenuItem[];

    constructor(injector: Injector) {
        super(injector);
        this.localizationSourceName = AppConsts.localization.iroSourceName;
        this.staticTemplateLocalizationSourceName = AppConsts.localization.iroStaticTemplateSourceName;
        this.activatedRoute = injector.get(ActivatedRoute);
        this.mainMenuItems = this.getMainMenuItems();
    }

    updateMenuItem(
        menuItems: ITabMenuItem[],
        tabId: IroMenuTab,
        disabled: boolean,
        canEditData?: boolean,
        customIcon?: string
    ) {
        let tab = menuItems?.find(mi => mi.id === tabId);
        if (tab) {
            tab.disabled = disabled;
            if ((typeof canEditData !== 'undefined') || (typeof customIcon !== 'undefined')) {
                tab.icon = customIcon ?? (canEditData ? ActionIcon.CheckCircle : ActionIcon.Lock);
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    private getMainMenuItems(): ITabMenuItem[] {
        return [
            {
                id: MainMenuTab.Forms,
                label: this.l('Forms_TabName'),
                hidden: !this.permission.isGranted('Iro.Form.View'),
                routerLink: this.absoluteModuleRootPathForms,
            },
            {
                id: MainMenuTab.DynamicTemplates,
                label: this.l('DynamicTemplates_TabName'),
                hidden: !this.permission.isGranted('Iro.DynamicTemplate.View'),
                routerLink: this.absoluteModuleRootPathDynamicTemplates,
            },
            {
                id: MainMenuTab.Profiles,
                label: this.ld('Profiles_TabName'),
                hidden: !this.permission.isGranted('Iro.Form.View'),
                routerLink: this.absoluteModuleRootPathProfiles,
            },
            {
                id: MainMenuTab.Settings,
                label: '<i aria-hidden="true" class="fas fa-cog"></i>',
                labelAsHtml: true,
                hidden: !this.permission.isGranted('Iro.Admin'),
                routerLink: this.absoluteModuleRootPathAdminSettings,
            },
        ] as ITabMenuItem[];
    }
}
