import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITabMenuItem } from '@app/modules/mazars-common/components/mazars-tab-menu/tab-menu-interfaces';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MappingBalanceDecimalSeparatorType } from '@app/modules/account-mapping/account-mapping-proxies';
import { Observable, Subscription, of } from 'rxjs';
import { ActionIcon } from '../mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { IColumnDefinition } from '../mazars-common/components/mazars-grid/grid-interfaces';
import { FormBuilder } from '@angular/forms';
import { IItem } from '../mazars-common/interfaces/item.interface';

@Injectable()
export abstract class AccountMappingComponentBase extends AppComponentBase implements OnDestroy {
    subscriptions: Subscription[] = [];
    public activatedRoute: ActivatedRoute;
    public isDraft = true;
    public absoluteModuleRootPath = '/app/account-mapping';
    public absoluteModuleRootPathMappings: string = this.absoluteModuleRootPath + '/mappings';
    public absoluteModuleRootPathMappingsCreate: string = this.absoluteModuleRootPathMappings + '/create';
    public absoluteModuleRootPathTargetStructures: string = this.absoluteModuleRootPath + '/structures';
    public absoluteModuleRootPathTargetStructuresCreate: string = this.absoluteModuleRootPathTargetStructures + '/create';
    public absoluteModuleRootPathAdminSettings: string = this.absoluteModuleRootPath + '/admin-settings';

    amapTabMenuItems: ITabMenuItem[];
    mappingTabMenuItems: ITabMenuItem[];
    mappingAdditionalTabMenuItems: ITabMenuItem[];
    structureTabMenuItems: ITabMenuItem[];
    protected fb: FormBuilder;
    readonly MappingBalanceDecimalSeparatorType = MappingBalanceDecimalSeparatorType;

    constructor(injector: Injector) {
        super(injector);
        this.localizationSourceName = AppConsts.localization.accountMappingSourceName;
        this.activatedRoute = injector.get(ActivatedRoute);
        this.fb = injector.get(FormBuilder);
        this.amapTabMenuItems = [
            {
                id: 'mi-mappings',
                label: this.l('Mappings_TabName'),
                routerLink: this.absoluteModuleRootPath,
            },
            {
                id: 'mi-target-structure',
                label: this.l('TargetStructureManagement_TabName'),
                hidden: !this.permission.isGranted('AccountMapping.TargetStructures.View'),
                routerLink: this.absoluteModuleRootPathTargetStructures,
            },
            {
                id: 'mi-settings',
                label: '<i aria-hidden="true" class="fas fa-cog"></i>',
                labelAsHtml: true,
                routerLink: this.absoluteModuleRootPathAdminSettings,
                hidden: !this.permission.isGranted('AccountMapping.Admin'),
            },
        ];
        this.mappingTabMenuItems = [
            {
                id: 'mi-general',
                label: this.l('mi_General'),
                icon: ActionIcon.CheckCircle,
                routerLink: `${this.absoluteModuleRootPathMappings}/${this.activatedRoute.snapshot.params.id}/general`,
                disabled: false,
            },
            {
                id: 'mi-accounts',
                label: this.l('Accounts_TabName'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathMappings}/${this.activatedRoute.snapshot.params.id}/accounts`,
                disabled: true,
            },
            {
                id: 'mi-mapping',
                label: this.l('mi_AccountMappings'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathMappings}/${this.activatedRoute.snapshot.params.id}/mapping`,
                disabled: true,
            },
            {
                id: 'mi-attachment',
                label: this.l('mi_AttachmentsAndAssessments'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathMappings}/${this.activatedRoute.snapshot.params.id}/attachments`,
                disabled: true,
            },
        ];
        this.mappingAdditionalTabMenuItems = [
            {
                id: 'mi-communications',
                icon: ActionIcon.Comment,
                routerLink: `${this.absoluteModuleRootPathMappings}/${this.activatedRoute.snapshot.params.id}/communications`,
                disabled: true,
                showIconCounter: true,
                tooltip: this.ld('Channels_Tooltip'),
            },
            {
                id: 'mi-history',
                icon: ActionIcon.History,
                routerLink: `${this.absoluteModuleRootPathMappings}/${this.activatedRoute.snapshot.params.id}/history`,
                disabled: true,
                tooltip: this.ld('StateHistory_Tooltip'),
            },
        ];
        this.structureTabMenuItems = [
            {
                id: 'mi-general',
                label: this.l('mi_General'),
                icon: ActionIcon.CheckCircle,
                routerLink: `${this.absoluteModuleRootPathTargetStructures}/${this.activatedRoute.snapshot.params.id}/general`,
                disabled: false,
            },
            {
                id: 'mi-accounts',
                label: this.l('mi_TargetStructureAccounts'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathTargetStructures}/${this.activatedRoute.snapshot.params.id}/accounts`,
                disabled: true,
            },
        ];
    }

    localizer: (key: string) => string = (key: string) => this.l(key);

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.onDestroy();
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    setTabIcon(tabName: string, canEditData: boolean) {
        let menuItem = this.mappingTabMenuItems.find((mi) => mi.id === tabName);
        if (menuItem) {
            menuItem.icon = canEditData ? ActionIcon.CheckCircle : ActionIcon.Lock;
        }
    }

    setTargetStructureTabIcon(tabName: string, canEditData: boolean) {
        let menuItem = this.structureTabMenuItems.find((mi) => mi.id === tabName);
        if (menuItem) {
            menuItem.icon = canEditData ? ActionIcon.CheckCircle : ActionIcon.Lock;
        }
    }

    getRadioItemsDecimalSeparator(): Observable<IItem<number>[]> {
        return of([
            {
                id: this.MappingBalanceDecimalSeparatorType.Comma,
                text: this.l('UseCommaAsDecimalSeparator'),
            },
            {
                id: this.MappingBalanceDecimalSeparatorType.Dot,
                text: this.l('UseDotAsDecimalSeparator'),
            },
        ]);
    }

    abstract onDestroy(): void;
}
