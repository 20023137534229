import { Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsDownloadService } from '../../services/mazars-download.service';
import { ActionIcon } from '../mazars-actions-dropdown-menu/action-icon';
import { IActionInfo } from '../mazars-actions-dropdown-menu/action-info';
import { MazarsSurveyComponent } from '../mazars-survey/mazars-survey.component';

@Component({
    selector: 'app-mazars-attached-surveys',
    templateUrl: './mazars-attached-surveys.component.html',
    styles: [],
})
export class MazarsAttachedSurveysComponent extends AppComponentBase implements OnInit {
    @ViewChildren('mazarsSurveys') mazarsSurveys: QueryList<MazarsSurveyComponent>;
    @Input() attachedSurveys: any[];
    @Input({ required: true }) uid: string;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Input() hasViewPermission: boolean;
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSave = new EventEmitter<any>();

    actionItems: IActionInfo[];
    actionItemsCache: Map<any, IActionInfo[]> = new Map<any, IActionInfo[]>();

    constructor(injector: Injector, private mazarsDownloadService: MazarsDownloadService) {
        super(injector);
    }

    ngOnInit(): void {
        this.actionItems = [
            {
                visible: this.hasDeletePermission,
                label: this.l('Delete'),
                icon: ActionIcon.Delete,
                requireConfirmation: false
            },
            {
                visible: true,
                label: this.l('DownloadPdf'),
                icon: ActionIcon.DownloadPdf,
            },
        ];
    }

    onSaveSurveyAnswers(survey) {
        let mazarsSurvey = this.findSurvey(survey.id);
        if (mazarsSurvey) {
            survey.answers = mazarsSurvey.survey.surveyModel.data;
            this.onSave.emit(survey);
        }
    }

    cancel(survey) {
        if (typeof survey.answers === 'string') {
            survey.answers = JSON.parse(survey.answers);
        }
        let mazarsSurvey = this.findSurvey(survey.id);
        if (mazarsSurvey) {
            mazarsSurvey.survey.surveyModel.data = survey.answers;
        }
    }

    findSurvey(surveyId) {
        return this.mazarsSurveys.find((mz) => mz.uid === surveyId);
    }

    delete(attachedSurvey: any) {
        this.onDelete.emit(attachedSurvey);
    }

    getActionItems(actionItems: IActionInfo[] | ((record: any) => IActionInfo[]), record: any): IActionInfo[] {
        if (Array.isArray(actionItems)) {
            return actionItems;
        } else {
            if (this.actionItemsCache.has(record)) {
                return this.actionItemsCache.get(record);
            }
            let items = actionItems(record);
            this.actionItemsCache.set(record, items);
            return items;
        }
    }

    handleActionItemClick(event: any, record: any): void {
        switch (event.label) {
            case this.actionItems[0].label:
                this.delete(record);
                break;
            case this.actionItems[1].label:
                this.downloadSurveyPdf(record);
                break;
        }
    }

    downloadSurveyPdf(survey: any) {
        this.mazarsDownloadService.downloadSurveyPdf(survey);
    }

    preventAccordionClickOnDropdown(event: any): void {
        event.stopPropagation();
    }
}
