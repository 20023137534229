import { Component, Input, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import * as Survey from 'survey-angular';
import * as widgets from 'surveyjs-widgets';
import { MazarsDisplayDialogComponent } from '../mazars-display-dialog/mazars-display-dialog.component';

import { init as initCustomWidget } from './customwidget';
import { AppComponentBase } from '@shared/common/app-component-base';

widgets.icheck(Survey);
widgets.select2(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey);
widgets.jqueryuidatepicker(Survey);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey);
widgets.bootstrapslider(Survey);
widgets.prettycheckbox(Survey);
//widgets.emotionsratings(Survey);
initCustomWidget(Survey);

Survey.JsonObject.metaData.addProperty('questionbase', 'popupdescription:text');
Survey.JsonObject.metaData.addProperty('page', 'popupdescription:text');

Survey.StylesManager.applyTheme('darkblue');
Survey.Serializer.addProperty('question', 'popupdescription:text');
Survey.Serializer.addProperty('page', 'popupdescription:text');
let defaultThemeColors = Survey.StylesManager.ThemeColors['default'];
defaultThemeColors['$main-color'] = '#009ce8';
defaultThemeColors['$main-hover-color'] = '#0071ce';
defaultThemeColors['$text-color'] = '#036';
defaultThemeColors['$header-color'] = '#3699ff';
defaultThemeColors['$header-background-color'] = '#ffffff';
defaultThemeColors['$body-container-background-color'] = '#ffffff';

Survey.StylesManager.applyTheme();

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'survey',
    templateUrl: './survey.component.html',
})
export class SurveyComponent extends AppComponentBase implements AfterViewInit {
    @ViewChild('moreInfoDisplayDialog', { static: false }) moreInfoDisplayDialog: MazarsDisplayDialogComponent;
    @Output() submitSurvey = new EventEmitter<any>();
    @Input() json: object;
    @Input() result: any;
    @Input() isDisabled = false;
    @Input({ required: true }) uid: any;
    public surveyModel: any;
    public moreInfoText: string;

    ngAfterViewInit() {
        let that = this;
        this.surveyModel = new Survey.SurveyModel(this.json);
        this.surveyModel.locale = this.localization.currentLanguage.name;
        if (typeof this.result === 'string') {
            this.result = JSON.parse(this.result);
        }
        this.surveyModel.data = this.result;
        this.surveyModel.showCompletedPage = false;
        this.surveyModel.onAfterRenderQuestion.add((survey, options) => {
            if (!options.question.popupdescription) {
                return;
            }
            // Add a button;
            const btn = document.createElement('button');
            btn.className = 'btn btn-primary ml-2 pt-0 pr-1 pb-0 pl-1';
            btn.innerHTML = that.l('btn_MoreInfo');
            btn.onclick = () => {
                that.showMoreInfoDialog(options.question);
            };
            let header = options.htmlElement.querySelector('h5');
            if (!header) {
                header = options.htmlElement;
            }
            let headerDiv = header.children ? header.children[header.children.length - 1] : header;
            headerDiv.appendChild(btn);
        });
        this.surveyModel.onComplete.add((result, options) => {
            this.submitSurvey.emit(result.data);
        });
        let id = this.getId();
        if (this.isDisabled) {
            this.surveyModel.mode = 'display';
        }

        Survey.SurveyNG.render(id, { model: this.surveyModel });
    }

    showMoreInfoDialog(question: any): void {
        let code = this.localization.currentLanguage.name;
        this.moreInfoText =
            typeof question.popupdescription === 'object'
                ? question.popupdescription[code]
                    ? question.popupdescription[code]
                    : question.popupdescription['en']
                : question.popupdescription;

        if (this.moreInfoDisplayDialog) {
            document.getElementById('questionDescriptionText').innerHTML = this.moreInfoText;
            this.moreInfoDisplayDialog.show();
        }
    }

    getId() {
        return 'surveyElement' + this.uid;
    }
}
