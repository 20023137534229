import { Component, Injector, ViewEncapsulation, ViewChild, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { IActionInfo } from '@app/modules/mazars-common/components/mazars-actions-dropdown-menu/action-info';
import { IColumnDefinition } from '@app/modules/mazars-common/components/mazars-grid/grid-interfaces';
import { DatePipe } from '@angular/common';
import { MazarsGridComponent } from '@app/modules/mazars-common/components/mazars-grid/mazars-grid.component';
import { ActionIcon } from '@app/modules/mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MazarsProfilesModalComponent } from './mazars-profiles-modal.component';
import { IProfilesServiceProxy } from './IProfilesServiceProxy';
import { TableStateService } from '@shared/utils/table-state.service';
import { Filter } from '@app/modules/master-data-management/master-data-management-proxies';
import { ITaxOffice, ITaxOfficeConfiguration, ITaxOfficeProfileOverviewDto } from '@shared/service-proxies/common-interfaces';
import { ISearchBarFilter } from '../mazars-searchbar/search-bar-filter.interface';
import { FilterType } from '../mazars-searchbar/filter-type';

@Component({
    selector: 'app-mazars-profiles',
    templateUrl: './mazars-profiles.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsProfilesComponent extends AppComponentBase implements OnInit {
    @ViewChild('mazarsProfilesModal', { static: true }) mazarsProfilesModal: MazarsProfilesModalComponent;
    @ViewChild('mazarsTable', { static: true }) overviewTable: MazarsGridComponent;

    @Input('profilesServiceProxy') profilesServiceProxy: IProfilesServiceProxy;
    @Input('referenceDataServiceProxy') referenceDataServiceProxy: any;
    @Input('referenceDataService') referenceDataService: any;
    @Input('hasCreatePermission') hasCreatePermission = false;
    @Input('hasEditPermission') hasEditPermission = false;
    @Input('hasDeletePermission') hasDeletePermission = false;

    tableId = 'ma_profiles';
    filterText = '';
    taxAuthorityTypesFilter: any[];
    countriesFilter: any[];
    countriesItems: IItem<string>[] = [];
    taxAuthorityTypes: IItem<string>[] = [];
    taxOfficeConfig: ITaxOffice;
    taxOfficeConfigurations: ITaxOfficeConfiguration[] = [];

    filtersDefinitions: { [key: string]: ISearchBarFilter };
    columnDefinitions: IColumnDefinition[] = [
        {
            label: '',
            field: '',
            template: 'statusTemplate',
            width: 30,
        },
        {
            label: this.l('Profiles_ProfileName'),
            sortableName: 'profileName',
            field: 'profileName',
        },
        {
            label: this.l('Profiles_TaxAuthority'),
            field: 'taxOfficeName',
        },
        {
            label: this.l('Profiles_ReceiverForCountries'),
            field: 'countryCodes',
            template: 'receiverForCountriesTemplate',
        },
        {
            label: this.l('Profiles_DateOfExpiry'),
            sortableName: 'expirationDate',
            field: 'expirationDate',
            customRenderer: (v) => this.datePipe.transform(v),
        },
    ];

    constructor(
        injector: Injector,
        private router: Router,
        private datePipe: DatePipe,
        private tableStateService: TableStateService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.setFilterDefinitions();
        if (this.referenceDataServiceProxy) {
            this.referenceDataServiceProxy
                .getTaxOfficeConfigurations()
                .pipe(take(1))
                .subscribe((taxOfficeConfigurations: ITaxOfficeConfiguration[]) => {
                    this.taxOfficeConfigurations = taxOfficeConfigurations;
                });
            this.getTaxAuthorityTypes()
                .pipe(take(1))
                .subscribe((taxAuthorityTypes) => {
                    this.taxAuthorityTypes = taxAuthorityTypes;
                    this.setFilterDefinitions();
                });
            this.referenceDataServiceProxy
                .getTaxOfficeConfig()
                .pipe(take(1))
                .subscribe((taxOfficeConfig) => {
                    this.taxOfficeConfig = taxOfficeConfig;
                });
            this.referenceDataService.getCountries(Filter.All).subscribe((result) => {
                this.countriesItems = result.map((c) => <IItem<string>>{ id: c.id, text: c.text, icon: `pi pi-${c.id}` });
                this.setFilterDefinitions();
            });
            this.reloadPage();
        } else {
            throw 'Not Imlemented';
        }
    }

    setFilterDefinitions() {
        this.filtersDefinitions = {
            taxAuthorityTypesFilter: {
                items: this.taxAuthorityTypes,
                placeholder: this.l('TaxAuthorityType_Name'),
                type: FilterType.Multiselect,
            },
            countriesFilter: {
                items: this.countriesItems,
                placeholder: this.l('Country'),
                type: FilterType.Multiselect,
            },
        };
    }

    setFilter(event: any): void {
        this.filterText = event?.filter;
        this.countriesFilter = event?.countriesFilter;
        this.taxAuthorityTypesFilter = event?.taxAuthorityTypesFilter;
        this.getProfiles();
    }

    getActionItems = (profile: ITaxOfficeProfileOverviewDto) =>
        <IActionInfo[]>[
            {
                visible: this.hasEditPermission,
                label: this.l('Edit'),
                icon: ActionIcon.Edit,
                action: () => this.showCreateUpdateModal(profile),
            },
            {
                visible: this.hasDeletePermission,
                label: this.l('Delete'),
                icon: ActionIcon.Delete,
                action: () => this.deleteProfile(profile.id),
            },
        ];

    getProfiles(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.overviewTable.paginator.changePage(0);

            if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
                return;
            }
        }

        this.primengTableHelper.showLoadingIndicator();
        if (this.profilesServiceProxy) {
            this.profilesServiceProxy
                .getOverview(
                    this.filterText ?? '',
                    this.taxAuthorityTypesFilter,
                    this.countriesFilter ? this.countriesFilter : [],
                    this.overviewTable.primengTableHelper.getSorting(this.overviewTable.dataTable),
                    this.overviewTable.primengTableHelper.getSkipCount(this.overviewTable.paginator, event),
                    this.overviewTable.primengTableHelper.getMaxResultCount(this.overviewTable.paginator, event),
                )
                .subscribe(
                    (result) => {
                        this.overviewTable.primengTableHelper.totalRecordsCount = result.totalCount;
                        let items = result.items.map((item: any) => {
                            item.style = '';
                            if (item.isExpirationTresholdReached) {
                                item.style = 'error';
                            } else {
                                if (item.isExpirationWarningTresholdReached) {
                                    item.style = 'warning';
                                }
                            }
                            return item;
                        });
                        this.overviewTable.primengTableHelper.records = items;
                        this.overviewTable.primengTableHelper.hideLoadingIndicator();
                    },
                    () => {
                        this.overviewTable.primengTableHelper.hideLoadingIndicator();
                    },
                );
        } else {
            this.primengTableHelper.hideLoadingIndicator();
            throw 'Not Imlemented';
        }
    }

    reloadPage(): void {
        this.overviewTable.paginator.changePage(this.overviewTable.paginator.getPage());
    }

    showCreateModal() {
        this.showCreateUpdateModal(null);
    }

    private getTaxAuthorityTypes(): Observable<IItem<string>[]> {
        return this.referenceDataServiceProxy.getTaxAuthorityTypes().pipe(
            take(1),
            map((taxAuthorityTypes) => this.mapTaxAuthorityTypes(taxAuthorityTypes)),
        );
    }

    private mapTaxAuthorityTypes(taxAuthorityTypes: any): IItem<string>[] {
        return taxAuthorityTypes.map(
            (taxAuthorityType) =>
                ({
                    id: taxAuthorityType,
                    text: taxAuthorityType,
                }) as IItem<string>,
        );
    }
    private showCreateUpdateModal(profile: any): void {
        this.mazarsProfilesModal.show(profile, this.taxOfficeConfigurations, this.taxOfficeConfig, this.profilesServiceProxy).then(() => {
            this.reloadPage();
            this.notify.success(profile == null ? this.l('SuccessfullyCreated') : this.l('SuccessfullySaved'));
        });
    }

    private deleteProfile(profileId): void {
        this.profilesServiceProxy.delete(profileId).subscribe(() => {
            this.reloadPage();
            this.notify.success(this.l('SuccessfullyDeleted'));
        });
    }
}
