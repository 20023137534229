<div class="mb-5">
    <div class="mz-searchbar">
        <div class="mz-searchbar-first d-flex align-items-center">
            <app-mazars-input-checkbox
                uid="SearchWithThreeDot_checkbox"
                *ngIf="hasSelectAll"
                [binary]="true"
                [label]=""
                class="ml-4"
                (onChange)="onSelectAllClick($event)"
            ></app-mazars-input-checkbox>
            <i aria-hidden="true" class="pi pi-search ml-4 mr-2 mz-searchbar-blue"></i>
            <div class="w-100" [formGroup]="filtersForm">
                <input
                    #searchInput
                    type="text"
                    id="{{ uid }}"
                    name="{{ uid }}"
                    class="form-control filter-input"
                    [placeholder]="l('SearchWithThreeDot')"
                    [attr.data-testid]="uid"
                    formControlName="filter"
                />
            </div>
            <div class="text-nowrap" *ngIf="showSwitchSection">
                <div class="d-flex align-items-center">
                    <app-mazars-switch
                        [uid]="'Switch_OrganizationalEntities_OrganizationalChart'"
                        [(ngModel)]="isSwitched"
                        (click)="switchSectionClicked()"
                        [mazarsTooltip]="switchSectionTooltip"
                        [escape]="false"
                        class="mt-1"
                    ></app-mazars-switch>
                    <i aria-hidden="true" [ngClass]="[switchIconClass, 'mz-searchbar-blue', 'p-3']"></i>
                </div>
            </div>
            <div
                #contextMenuContainer
                class="text-nowrap"
                *ngIf="contextMenuItems && contextMenuItems.length > 0"
                [tooltipPosition]="'left'"
                [mazarsTooltip]="contextMenuItemsTooltip ? contextMenuItemsTooltip : ''"
            >
                <button class="mz-searchbar-filterbtn" (click)="contextMenuClicked()">
                    <span class="position-relative">
                        <i aria-hidden="true" class="pi pi-bars mz-searchbar-blue"></i>
                    </span>
                </button>
                <mazars-context-menu #contextMenu [uid]="'ContextMenu_' + uid" [items]="contextMenuItems"></mazars-context-menu>
            </div>
            <div class="text-nowrap" *ngIf="showFiltersSection">
                <button class="mz-searchbar-filterbtn" (click)="showFiltersToggle()" [tooltipPosition]="'left'" [mazarsTooltip]="l('Filters_Tooltip')">
                    <span class="position-relative">
                        <i aria-hidden="true" class="pi pi-filter mz-searchbar-blue"></i>
                        <span *ngIf="hasAppliedFilters" class="position-absolute top-0 start-100 translate-middle badge badge-circle mz-searchbar-badge"></span>
                    </span>
                </button>
            </div>
            <div class="text-nowrap" *ngIf="showConfigSection">
                <button class="mz-searchbar-filterbtn" (click)="showConfig()" [tooltipPosition]="'left'" [mazarsTooltip]="l('ColumnOptions_Tooltip')">
                    <i aria-hidden="true" class="fas fa-wrench mz-searchbar-blue"></i>
                </button>
            </div>
        </div>
        <app-mazars-button
            *ngIf="showCreate"
            [uid]="uid + '_create'"
            [label]="createButtonTitle != null ? createButtonTitle : l('Create')"
            iconClass="pi pi-plus"
            [inverted]="true"
            large="true"
            styleClass="mz-searchbar-create"
            (onClick)="onCreateClick()"
        ></app-mazars-button>
    </div>

    <mazars-filters-dialog #mazarsFiltersDialog [uid]="'filters-dialog-' + tableId">
        <ng-template mazarsTemplate="content">
            <div class="row mx-2">
                <ng-container [formGroup]="filtersForm">
                    <div class="filters-content">
                        <div *ngFor="let item of filters | defaultOrderKeyValue" class="col-12">
                            <app-mazars-daterangepicker
                                *ngIf="item.value.type === FilterType.DateRangePicker; else elseBlock"
                                [uid]="item.key + '-filter'"
                                formControlName="{{ item.key }}"
                                [placeholder]="item.value.placeholder"
                                [appendTo]="'body'"
                            ></app-mazars-daterangepicker>

                            <ng-template #elseBlock>
                                <app-mazars-multiselect
                                    [uid]="item.key + '-filter'"
                                    [items]="item.value.items"
                                    [showToggleAll]="true"
                                    [filter]="true"
                                    [placeholder]="item.value.placeholder"
                                    formControlName="{{ item.key }}"
                                    [isUserOrOrganization]="item.value.isUserOrOrganization"
                                    [isCountries]="item.value.isCountries"
                                    [appendTo]="'body'"
                                ></app-mazars-multiselect>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
        <ng-template mazarsTemplate="footer">
            <app-mazars-button
                styleClass="mt-1"
                [uid]="uid + '_clearFilters'"
                [inverted]="true"
                [label]="l('ClearAll')"
                [disabled]="disableClearFilter"
                (onClick)="clearFilter()"
            ></app-mazars-button>
        </ng-template>
    </mazars-filters-dialog>
</div>
<mazars-reorder-dialog
    #mazarsReorderDialog
    [tableId]="tableId"
    [gridConfigurationFacade]="gridConfigurationFacade"
    [uid]="'searchbar-reorder-dialog-' + tableId"
></mazars-reorder-dialog>
<mazars-export-overview-dialog
    #mazarsExportOverviewDialog
    [uid]="'export-overview-dialog-' + tableId"
    [tableId]="tableId"
    [hasArchivableElements]="hasArchivableElements"
    [gridConfigurationFacade]="gridConfigurationFacade"
    (onClicked)="this.onExportClicked($event)"
></mazars-export-overview-dialog>
