<div class="row">
    <div [class]="isFullScreen ? '' : 'col-10 offset-1'">
        <p-fieldset
            [toggleable]="isFullScreen ? false : true"
            (onAfterToggle)="onToggle($event)"
            [collapsed]="isFullScreen ? false : true"
            [styleClass]="!isFullScreen && isCollapsed ? '' : 'fieldset-shadow'"
        >
            <ng-template pTemplate="header">
                <div *ngIf="isFullScreen" class="d-flex justify-content-end">
                    <button class="btn btn-outline-brand btn-sm btn-icon mz-btn-icon btn-icon-md" (click)="closeCommunication(communication)">
                        <i aria-hidden="true" class="fas fa-times fa-fw"></i>
                    </button>
                </div>
                <div class="fieldset-header px-2">
                    <div class="row">
                        <div class="col-3 align-self-center">
                            <app-mazars-avatar-group [userDetails]="communication.userDetails" [uid]="'fieldset-communication-avatar-group-' + communication?.id"></app-mazars-avatar-group>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex">
                            <app-mazars-avatar *ngIf="author" [userDetail]="author" size="large" [uid]="'author_avatar-' + author?.id"></app-mazars-avatar>
                            <p class="my-auto ms-2">{{ author.fullName }}</p>
                        </div>
                    </div>
                    <div class="row mt-3 ms-1" *ngIf="isCollapsed && !isFullScreen">
                        <app-mazars-desription-with-more #descWithMore [uid]="communication.id + '-description'">
                            <app-mazars-display-quill
                                text
                                [description]="communication.content"
                                (onHtmlLoaded)="onContentHtmlLoaded($event)"
                                [uid]="'fieldset-description-quill-editor-' + communication?.id"
                            ></app-mazars-display-quill>
                        </app-mazars-desription-with-more>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="ml-1">
                    <div class="row">
                        <app-mazars-display-quill
                            text
                            [description]="communication.content"
                            (onHtmlLoaded)="onContentHtmlLoaded($event)"
                            [uid]="'fieldset-quill-editor-' + communication?.id"
                        ></app-mazars-display-quill>
                    </div>
                    <div class="d-flex justify-content-end me-3 mb-3" *ngIf="hasEditPermission || hasDeletePermission">
                        <button
                            *ngIf="hasEditPermission"
                            class="btn btn-outline-brand btn-sm btn-icon mz-btn-icon btn-icon-md"
                            (click)="editCommunication(communication)"
                        >
                            <i aria-hidden="true" class="far fa-edit fa-fw"></i>
                        </button>
                        <button
                            *ngIf="hasDeletePermission"
                            class="btn btn-outline-brand btn-sm btn-icon mz-btn-icon btn-icon-md"
                            (click)="deleteCommunication(communication)"
                        >
                            <i aria-hidden="true" class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </ng-template>
        </p-fieldset>
    </div>
    <div class="col-1" *ngIf="!isFullScreen && !isCollapsed">
        <button class="btn btn-outline-brand btn-sm btn-icon mz-btn-icon btn-icon-md" (click)="openCommunication(communication)">
            <i aria-hidden="true" class="far fa-window-maximize fa-fw"></i>
        </button>
    </div>
</div>

<mazars-communication-modal #mazarsCommunicationModal [uid]="uid + '-communication'"></mazars-communication-modal>
