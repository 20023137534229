<div *ngIf="showSearch" class="mb-5">
    <div>
        <div class="d-flex align-items-center">
            <app-mazars-input-checkbox uid="reorder-checkbox"
            [binary]="true" [label]="" class="ml-4" (onToggleChanged)="selectDeselectAll($event)"></app-mazars-input-checkbox>
            <i aria-hidden="true" class="pi pi-search ml-4 mr-2 mz-searchbar-blue"></i>
            <div class="w-100">
                <input
                    type="text"
                    class="form-control"
                    [placeholder]="l('SearchWithThreeDot')"
                    [(ngModel)]="search"
                    (blur)="onDefinitionsFilterChange()"
                    (input)="onDefinitionsFilterChange()"
                />
            </div>
        </div>
    </div>
</div>
<mazars-ag-grid
    #reorderTable
    id="reorderTable"
    headerHeight="0"
    [columnDefinitions]="reorderColumnDefs"
    [defaultColumnDefinition]="defaultColDef"
    [data]="this.gridDefinitionDto?.configuration"
    [suppressRowDrag]="!isEditable"
    [rowDragMultiRow]="false"
    [rowSelection]="'multiple'"
    [rowDragManaged]="true"
    [getRowId]="getRowId"
    [sizeColumngToFitOnGridResize]="true"
    (rowSelected)="onRowSelected($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    [isVisible]="isVisible"
></mazars-ag-grid>
