<mz-dialog
    #modal
    [uid]="'wizard_dialog_' + uid"
    [title]="title"
    (beforeSubmit)="beforeSubmit($event)"
    (onNextClick)="actions?.next ? actions?.next?.action() : onNext($event)"
    (onBackClick)="onBack($event)"
    (onDestroy)="onDestroyModal()"
    (onAditionalActionButtonClick)="actions?.additionalAction?.action()"
    [additionalActionButtonLabel]="actions?.additionalAction?.label"
    [showAdditionalActionButton]="actions?.additionalAction?.visible"
    [submitButtonLabel]="actions?.submit?.label || this.l('Save')"
    [submitButtonIcon]="actions?.submit?.icon || 'pi pi-save'"
    [showNextButton]="items.length > activeIndex + 1"
    [showBackButton]="activeIndex !== 0"
    [showCancelButton]="activeIndex === 0 || actions?.cancel?.visible"
    [canClickNext]="canContinueToNextStep"
    [canSubmit]="canContinueToNextStep"
    [showSubmitButton]="actions?.submit?.visible || (!actions && items.length <= activeIndex + 1)"
    [attr.data-testid]="uid"
    [size]="size"
    [styleClass]="styleClass"
    [isLoading]="isLoading"
>
    <div class="wizard-steps" *ngIf="showSteps">
        <p-steps
            [model]="items"
            [readonly]="true"
            [activeIndex]="activeIndex"
            (activeIndexChange)="onActiveIndexChange($event)"
            [attr.data-testid]="uid + '-steps'"
        ></p-steps>
    </div>
    <div *ngFor="let item of items; index as i">
        <ng-container *ngIf="activeIndex == i" [ngTemplateOutlet]="getTemplate('step' + i)"></ng-container>
    </div>
</mz-dialog>
