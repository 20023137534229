
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">
            {{ title }}
        </h4>
        <button [attr.data-testid]="uid + '_close'" type="button" class="btn-close" (click)="cancel()" aria-label="Close" [disabled]="saving"></button>
    </div>
    <div [class]="bodyStyleClass">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer" *ngIf="showFooter">
        <app-mazars-button
            *ngIf="showAdditionalActionButton"
            [uid]="uid + '_additionalActionButton'"
            [label]="additionalActionButtonLabel"
            [disabled]="saving || isLoading"
            [inverted]="true"
            (onClick)="additionalActionButtonClick()"
        ></app-mazars-button>

        <app-mazars-button
            *ngIf="showCancelButton"
            [uid]="uid + '_close'"
            [label]="cancelButtonLabel"
            [disabled]="saving || isLoading"
            [iconClass]="showCancelButtonIcon ? 'fa fa-times' : null"
            [inverted]="true"
            (onClick)="cancel()"
        ></app-mazars-button>

        <app-mazars-button
            *ngIf="showBackButton"
            [uid]="uid + '_back'"
            [label]="backButtonLabel"
            [disabled]="saving || isLoading"
            [inverted]="true"
            (onClick)="backButtonClick()"
        ></app-mazars-button>

        <app-mazars-button
            *ngIf="showNextButton"
            [uid]="uid + '_next'"
            [label]="nextButtonLabel"
            [disabled]="saving || !canClickNext || isLoading"
            (onClick)="nextButtonClick()"
        ></app-mazars-button>

        <app-mazars-button
            *ngIf="showSubmitButton"
            [uid]="uid + '_submit'"
            [label]="submitButtonLabel"
            [iconClass]="submitButtonIcon"
            [busy]="saving || isLoading"
            [busyText]="l('SavingWithThreeDot')"
            [disabled]="!canSubmit"
            isSubmit="true"
            (onClick)="canSubmit ? submit() : false"
        ></app-mazars-button>
    </div>
</ng-template>
