import { Component, Injector, OnInit, forwardRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-mazars-chancellery-admin-settings',
    templateUrl: './mazars-chancellery-view-admin-settings.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsChancelleryViewAdminSettingsComponent,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsChancelleryViewAdminSettingsComponent),
            multi: true,
        },
    ],
})
export class MazarsChancelleryViewAdminSettingsComponent extends AppComponentBase implements ControlValueAccessor, OnInit {
    form: FormGroup;
    isDisabled: boolean;

    constructor(injector: Injector, private formBuilder: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
        this.registerSubscriptions();
    }

    get value(): any {
        return this.form?.value;
    }

    set value(chancelleryViewAdminSettings: any) {
        this.form.patchValue(chancelleryViewAdminSettings);
        this.form.updateValueAndValidity();

        this.onChange(chancelleryViewAdminSettings);
        this.onTouched();
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }
        this.onChange(obj);
        this.onTouched();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { chancelleryViewAdminSettings: { valid: false } };
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );

        this.subscriptions.push(
            this.form.controls.isChancelleryViewActive.valueChanges.subscribe((checked) => {
                if (checked) {
                    this.form.controls.chancelleryViewClientsEndpointUrl.setValidators(Validators.required);
                    this.form.controls.chancelleryViewProjectsEndpointUrl.setValidators(Validators.required);
                    this.form.controls.chancelleryViewUsername.setValidators(Validators.required);
                    this.form.controls.chancelleryViewPassword.setValidators(Validators.required);
                }
                else if(!checked && this.form.valid)
                {
                    this.form.controls.chancelleryViewClientsEndpointUrl.clearValidators();
                    this.form.controls.chancelleryViewProjectsEndpointUrl.clearValidators();
                    this.form.controls.chancelleryViewUsername.clearValidators();
                    this.form.controls.chancelleryViewPassword.clearValidators();
                }
            })
        );

        this.subscriptions.push(
            this.form.controls.chancelleryViewIsClientRequired.valueChanges.subscribe((isClientRequired) => {
                if (!isClientRequired) {
                    this.form.controls.chancelleryViewIsProjectRequired.setValue(false);
                }
            })
        );

        this.subscriptions.push(
            this.form.controls.chancelleryViewIsProjectRequired.valueChanges.subscribe((isProjectRequired) => {
                if (isProjectRequired) {
                    let isClientRequired = this.form.controls.chancelleryViewIsClientRequired.value === true;
                    if (!isClientRequired) {
                        this.form.controls.chancelleryViewIsClientRequired.setValue(true);
                    }
                }
            })
        );
    }

    private createForm() {
        this.form = this.formBuilder.group({
            isChancelleryViewActive: [false],
            chancelleryViewClientsEndpointUrl: [null],
            chancelleryViewProjectsEndpointUrl: [null],
            chancelleryViewUsername: [null],
            chancelleryViewPassword: [null],
            chancelleryViewIsClientRequired: [false],
            chancelleryViewIsProjectRequired: [false],
        });
    }
}
