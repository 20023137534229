import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITagDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'app-mazars-tags-autocomplete',
    templateUrl: './mazars-tags-autocomplete.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsTagsAutocompleteComponent,
        },
    ],
    styleUrls: ['./mazars-tags-autocomplete.component.css'],
})
export class MazarsTagsAutocompleteComponent implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() placeholder: string;
    @Input() availableTags: string[];

    suggestedTags: ITagDto[];

    value: ITagDto[];
    isDisabled: boolean;

    onValueChange(event) {
        this.value = event;
        this.onChange(this.value);
    }

    filterTags(event) {
        this.suggestedTags = [];
        let s = event.query.trim();

        let tagModelLower = !this.value ? [] : this.value.map((v) => v.name.toLowerCase());
        let sLower = s.toLowerCase();

        if (this.availableTags != null) {
            this.availableTags.forEach((t) => {
                let tLower = t.toLowerCase();
                if (tagModelLower.indexOf(tLower) === -1) {
                    if (tLower.indexOf(sLower) !== -1) {
                        this.suggestedTags.push({ name: t } as ITagDto);
                    }
                }
            });
        }

        // Add current input, if not an existing tag
        if (s !== '' && tagModelLower.indexOf(sLower) === -1 && this.suggestedTags.map((v) => v.name.toLowerCase()).indexOf(sLower) === -1) {
            this.suggestedTags.unshift({ name: s } as ITagDto);
        }
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj == null) {
            this.value = [];
        } else {
            this.value = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlur() {
        this.onTouched();
    }
}
