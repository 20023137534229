import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-mazars-admin-access-restrictions',
    templateUrl: './mazars-admin-access-restrictions.component.html',
    styles: [],
})
export class MazarsAdminAccessRestrictionsComponent extends AppComponentBase implements OnInit {
  @Input({ required: true }) uid: string;
  @Input() checkBoxMessage: string;
  @Input() showHeadline: boolean = true;
  @Input() subHeadline: string;
  @Input() control: FormControl = new FormControl();
  @Output() onValueChange = new EventEmitter<any>();
    constructor(injector: Injector) {
        super(injector);
    }
    ngOnInit(): void {
    }

}
