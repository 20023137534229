export enum FormProcessState {
    EditFormPhase = 'EditFormPhase',
    ReviewFormPhase = 'ReviewFormPhase',
    ReadyForSubmissionPhase = 'ReadyForSubmissionPhase',
    ManualSubmissionPhase = 'ManualSubmissionPhase',
    WaitingForAuthorityFeedbackPhase = 'WaitingForAuthorityFeedbackPhase',
    Completed = 'Completed',
    Unknown = 'Unknown'
}

export enum FormProcessStateGroup {
    Preparation = 'Preparation',
    Submission = 'Submission',
    Completed = 'Completed'
}
