<p-password
    inputId="{{ uid }}"
    name="{{ uid }}"
    [(ngModel)]="value"
    [feedback]="false"
    [toggleMask]="showMask"
    [inputStyleClass]="inputStyleClass"
    [styleClass]="styleClass"
    placeholder="{{ placeholder }}"
    [disabled]="isDisabled"
    [mazarsTooltip]="tooltipText"
    [escape]="false"
    tooltipPosition="bottom"
    (onBlur)="onBlurChange()"
></p-password>
