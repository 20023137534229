import { Component, EventEmitter, Input, Output, SecurityContext, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-mazars-information-icon',
    templateUrl: './mazars-information-icon.component.html',
    styleUrls: ['./mazars-information-icon.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsInformationIconComponent {
    @Input({ required: true }) uid: string;
    @Input() message: string;
    @Input() position = 'right';
    @Input() iconClass = 'pi pi-info-circle ml-2';
    @Input() escape = true;
    @Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    securityContext = SecurityContext;

    get tooltipStyleClass() {
        if (this.message) {
            const words = this.message.split(' ');
            const hasLongWord = words?.filter((w) => w.length > 20);
            if (hasLongWord.length > 0 || this.message.length > 100) {
                return 'mazars-tooltip';
            }
        }

        return '';
    }

    handleOnClick() {
        this.onClick.emit();
    }
}
