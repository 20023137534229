import { Component, Injector, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SurveyCreatorComponent } from '@app/modules/mazars-common/components/surveyjs/survey.creator.component';

@Component({
    selector: 'app-mazars-survey-creator',
    templateUrl: './mazars-survey-creator.component.html',
})
export class MazarsSurveyCreatorComponent extends AppComponentBase implements OnDestroy {
    @Input({ required: true }) uid: string;
    @ViewChild('surveyCreator', { static: true }) surveyCreator: SurveyCreatorComponent;
    @Input() json: string;

    constructor(injector: Injector, private elementRef: ElementRef) {
        super(injector);
    }

    onSurveySaved(surveyJson) {
        this.json = surveyJson;
    }

    loadJson(json) {
        if (this.surveyCreator) {
            this.surveyCreator.surveyCreator.text = JSON.stringify(json);
        }
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.remove();
    }
}
