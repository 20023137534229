<div class="row">
    <div class="col-lg-3 col-sm-6 mt-2">
        <div>{{ l('Submission_Date') }}</div>
        <div>{{ getDate(submission?.submissionDate) }}</div>
    </div>
    <div class="col-lg-3 col-sm-6 mt-2">
        <div>{{ l('Submission_State') }}</div>
        <div>{{ submission?.transactionId ? l('Submission_Successfull') : l('Submission_Error') }}</div>
    </div>
    <div class="col-lg-3 col-sm-6 mt-2">
        <div>{{ l('Submission_SubmittedTo') }}</div>
        <div>{{ submission?.submittedTo }}</div>
    </div>
    <div class="col-lg-3 col-sm-6 mt-2">
        <div>{{ l('Submission_SubmissionDocuments') }}</div>
        <div>
            <i
                class="fas fa-file-pdf fa-fw"
                (click)="downloadPdf()"
                style="font-size: 1.5rem; cursor: pointer"
                aria-hidden="true"
            ></i>
        </div>
    </div>
</div>
