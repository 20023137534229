<p-accordion [multiple]="true" *ngIf="hasViewPermission">
    <div class="mz-container" *ngFor="let survey of attachedSurveys; let i = index">
        <div class="mz-card">
            <p-accordionTab [(selected)]="survey.isSelected" [(disabled)]="survey.disabled">
                <ng-template pTemplate="header">
                    <div class="d-flex justify-content-between w-100">
                        <span class="h4 py-2 m-0 align-middle">{{ survey.name }}</span>
                    </div>
                    <div class="col-1 align-self-center" (click)="preventAccordionClickOnDropdown($event)" [hidden]="!hasEditPermission">
                        <app-mazars-actions-menu
                            [uid]="'Surveys_' + survey.id + '_actions_btn'"
                            [btnSize]="'btn-md'"
                            [fontSize]="'x-large'"
                            [actionItems]="getActionItems(actionItems, survey)"
                            (itemClick)="handleActionItemClick($event, survey)"
                        ></app-mazars-actions-menu>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="row">
                        <div class="col-3 ml-3">{{ l('LastEdited') }}: {{ survey.lastModificationTime | date : 'dd.MM.yyyy' }}</div>
                        <div class="col-3">{{ l('EditedBy') }}: {{ survey.lastModifier }}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="mz-container">
                                <app-mazars-survey
                                    #mazarsSurveys
                                    [uid]="survey.id"
                                    [json]="survey.questionnaire"
                                    [answers]="survey.answers"
                                    [isDisabled]="!hasEditPermission"
                                ></app-mazars-survey>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="hasEditPermission">
                        <div class="col-lg-12 col-md-12">
                            <div role="actions" class="ml-3 mb-3">
                                <button
                                    (click)="onSaveSurveyAnswers(survey)"
                                    class="btn btn-primary blue float-right"
                                    [attr.data-testid]="uid + 'save-btn_' + i"
                                >
                                    <i aria-hidden="true" class="fa fa-save"></i>
                                    <span>{{ l('Save') }}</span>
                                </button>
                                <button
                                    (click)="cancel(survey)"
                                    class="btn btn-outline-primary blue float-right mr-3"
                                    [attr.data-testid]="uid + 'cancel-btn_' + i"
                                >
                                    <i aria-hidden="true" class="fa fa-times"></i>
                                    <span>{{ l('Cancel') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
        </div>
    </div>
</p-accordion>
