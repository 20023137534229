import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-mazars-daterangepicker',
    templateUrl: './mazars-daterangepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsDateRangePickerComponent,
        },
    ],
})
export class MazarsDateRangePickerComponent implements OnInit, ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() placeholder: string;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() getExactDate: boolean = false;
    @Input() appendTo?: any;

    value: Date[];
    isDisabled: boolean;
    yearRange: string;
    private minYearRange = 1900;
    private yearThreshold = 100;

    constructor(private _dateTimeService: DateTimeService) {}

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    ngOnInit(): void {
        const today = new Date();
        const maxYearRange =
            this.maxDate instanceof Date ? this.maxDate.getFullYear() : today.getFullYear() + this.yearThreshold;
        this.minYearRange = this.minDate instanceof Date ? this.minDate.getFullYear() : this.minYearRange;
        this.yearRange = `${this.minYearRange}:${maxYearRange}`;
    }

    writeValue(obj: DateTime[]): void {
        if (obj) {
            this.value = [];
            for (const dateTime of obj) {
                this.value.push(dateTime.toJSDate());
            }
        }
        this.onValueChange();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onValueChange() {
        if (this.value == null) {
            this.onChange(null);
        } else {
            let datetimeArr: DateTime[] = [];
            for (let i = 0; i < this.value.length; i++) {
                let dateTime = DateTime.fromJSDate(this.value[i]);

                if (this.getExactDate) {
                    dateTime = this._dateTimeService.toUtcDate(this.value[i]);
                }

                if (i === 0) {
                    dateTime = this._dateTimeService.getStartOfDayForDate(dateTime);
                } else {
                    dateTime = this._dateTimeService.getEndOfDayForDate(dateTime);
                }

                if(this.getExactDate) {
                    datetimeArr.push(this._dateTimeService.changeToUtcDateTime(dateTime));
                } else {
                    datetimeArr.push(this._dateTimeService.convertToUtcDateTime(dateTime));
                }
            }

            this.onChange(datetimeArr);
        }
    }

    onBlur() {
        this.onTouched();
    }
}
