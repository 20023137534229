<div [class]="footerStyle" id="kt_footer">
    <div [class]="containerClass + ' d-flex flex-column flex-md-row align-items-center justify-content-between'">
        <div class="text-dark order-2 order-md-1">
            <span class="text-muted mr-2">
                Digital Tax Portal
                <!--<span
                    *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                    {{appSession.tenant.edition.displayName}}</span> | API: v{{appSession.application.version}} | Client: v{{webAppGuiVersion}}-->
                | {{ releaseDate }}
                {{ appSession?.tenant?.tenancyName ? '| ' + appSession?.tenant?.tenancyName : '' }}
                |
                <a routerLink="/details/release-notes">{{ l('ReleaseNotes') }}</a>
                |
                <a routerLink="/details/user-manuals">{{ l('UserManual') }}</a>
                |
                <a routerLink="/account/information/imprint">{{ l('Imprint') }}</a>
                |
                <a routerLink="/account/information/privacy-policy">{{ l('PrivacyPolicy') }}</a>
            </span>
        </div>
        <div class="nav nav-dark nav nav-dark order-1 order-md-2">
            <a href="javascript:;" target="_blank" class="nav-link pl-0 pr-5">&nbsp;</a>
        </div>
    </div>
</div>
