<div #mapChart (mousedown)="onMouseDown($event)" (mouseup)="onMouseLeave($event)" (mouseleave)="onMouseLeave($event)" [class]="mousePointer">
    <highcharts-chart
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [options]="chartOptions"
        [style]="style"
        [callbackFunction]="chartCallback"
        [(update)]="updateFlag"
    ></highcharts-chart>
</div>
