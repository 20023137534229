import { Injector, Component, ViewEncapsulation, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as Highcharts from 'highcharts';
import MapModule from 'highcharts/modules/map';
import HC_exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import Accessibility from 'highcharts/modules/accessibility';
import domToImage from 'dom-to-image';
import jsPDF from 'jspdf';
import proj4 from 'proj4';
HC_exporting(Highcharts);
offlineExporting(Highcharts);
MapModule(Highcharts);
Accessibility(Highcharts);

@Component({
    templateUrl: './mazars-map.component.html',
    styleUrls: ['./mazars-map.component.css'],
    selector: 'mazars-map',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsMapComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() data: any;
    @Input() tooltip: Highcharts.TooltipOptions;
    @Input() seriesKeys: string[];
    @Input() style = 'width: 100%; height: 80vh; display: block';
    @Input() showExport = true;
    @Input() showCountryNames = true;
    @Input() showZoomControls = false;

    @ViewChild('mapChart', { static: false }) public mapChart: ElementRef;
    @Output() onZoomLevelChanged = new EventEmitter<number>();

    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = 'mapChart';
    worldMap = require('./world.geo.json');
    chartOptions: Highcharts.Options = {};
    chartRef: Highcharts.MapChart;
    updateFlag: boolean;
    mousePointer = '';
    updateRef = true;

    constructor(injector: Injector) {
        super(injector);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            setTimeout(() => {
                this.refreshData(this.data);
            }, 0);
        }
    }
    ngOnInit(): void {
        let self = this;

        this.chartOptions = {
            chart: {
                map: self.worldMap,
                proj4: proj4,
            },
            title: {
                text: ' ',
            },
            mapNavigation: {
                enabled: self.showZoomControls,
                buttonOptions: {
                    alignTo: 'spacingBox',
                },
            },
            legend: {
                enabled: false,
            },
            tooltip: self.tooltip,
            series: [
                {
                    type: 'map',
                    name: 'Map data',
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    animation: false,
                    dataLabels: {
                        enabled: self.showCountryNames,
                        format: '{point.name}',
                    },
                    allAreas: true,
                    data: [],
                    keys: self.seriesKeys,
                },
            ],
            exporting: {
                enabled: self.showExport,
                sourceWidth: 2400,
                sourceHeight: 1600,
                scale: 5,
            },
        };
    }

    refreshData(data: any) {
        let self = this;
        const countries = data.map((data) => data['hc-key'].toUpperCase()) as string[];
        this.worldMap.features.forEach((feature, i) => {
            if (countries.includes(feature?.id)) {
                let properties = feature?.properties;
                if (properties != null) {
                    properties['name'] = properties['label'];
                }
            } else {
                let properties = feature?.properties;
                if (properties != null) {
                    properties['name'] = '';
                }
            }
        });
        this.chartOptions.chart = {
            map: self.worldMap,
            proj4: proj4,
        };
        this.chartRef?.series[0]?.update({
            type: 'map',
            name: 'Map data',
            states: {
                hover: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}',
            },
            point: {
                events: {
                    mouseOver() {
                        const point = this as any;
                        point.update({
                            color: point.hoverColor,
                        });
                    },
                    mouseOut() {
                        const point = this as any;
                        point.update({
                            color: point.mainColor,
                        });
                    },
                },
            },
            animation: false,
            allAreas: true,
            data: data,
            keys: self.seriesKeys,
        });
        this.updateFlag = false;
    }

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        if (this.updateRef) {
            this.chartRef = chart;
            this.updateRef = false;
        }
    };

    zoom(value?: number) {
        const currentZoom = this.chartRef.mapView.zoom;
        this.chartRef.mapView.zoomBy(value - currentZoom);
        this.updateFlag = false;
    }

    zoomBack() {
        this.chartRef.mapView.zoomBy(undefined);
        this.updateFlag = false;
        this.onZoomLevelChanged.emit(1);
    }

    downloadAsPdf(fileName: string, format: string, downloadFull: boolean): void {
        this.showMainSpinner();
        if (downloadFull) {
            this.zoomBack();
        }
        setTimeout(() => {
            let width = this.mapChart.nativeElement.clientWidth;
            let height = this.mapChart.nativeElement.clientHeight + 40;
            let orientation = '';
            if (width > height) {
                orientation = 'l';
            } else {
                orientation = 'p';
            }
            let scale = 2;
            let imageWidth = width * scale;
            let imageHeight = height * scale;
            domToImage
                .toPng(this.mapChart.nativeElement, {
                    width: imageWidth,
                    height: imageHeight,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left',
                    },
                })
                .then((result) => {
                    const pdf = new jsPDF({
                        orientation: 'landscape',
                        unit: 'pt',
                        format: format,
                    });
                    const maxWidth = pdf.internal.pageSize.getWidth();
                    const maxHeight = pdf.internal.pageSize.getHeight();

                    const ratio = Math.min(maxWidth / imageWidth, maxHeight / imageHeight);

                    const widthToFit = imageWidth * ratio;
                    const heightToFit = imageHeight * ratio;

                    pdf.addImage(result, 'png', 0, 0, widthToFit, heightToFit);
                    pdf.save(fileName + '.pdf');
                    this.hideMainSpinner();
                })
                .catch((error) => {
                    console.error(error);
                    this.hideMainSpinner();
                });
        }, 500); // needed for downloading full map
    }

    openFullScreen() {
        this.chartRef.fullscreen.open();
    }

    printMap() {
        this.chartRef.print();
    }

    downloadPNG() {
        this.chartRef.exportChartLocal({ type: 'image/png', fallbackToExportServer: false, scale: 5 });
    }

    downloadJpeg() {
        this.chartRef.exportChartLocal({ type: 'image/jpeg', fallbackToExportServer: false, scale: 5 });
    }

    downloadSvg() {
        this.chartRef.exportChartLocal({ type: 'image/svg+xml', fallbackToExportServer: false, scale: 5 });
    }

    onMouseDown($event: any) {
        this.mousePointer = 'grabbable';
    }

    onMouseLeave($event: any) {
        this.mousePointer = '';
    }
}
