import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { AppComponentBase } from '@shared/common/app-component-base';
import { findIndex as _findIndex, remove as _remove } from 'lodash-es';
import { Table } from 'primeng/table';
import { cloneDeep as _cloneDeep } from 'lodash-es';

@Component({
    selector: 'mazars-key-value-list-manager',
    templateUrl: './mazars-key-value-list-manager.component.html',
})
export class MazarsKeyValueListManagerComponent extends AppComponentBase implements OnChanges, OnInit {
    @ViewChild(Table, { read: Table }) pTable: Table;

    @Input() header: string;
    @Input() keyPlaceHolder: string;
    @Input() valuePlaceHolder: string;
    @Input() items: { key: string; value: string }[] = [];
    clonedItems: { key: string; value: string; id?: number }[] = [];
    editingItems: { key: string; value: string; id?: number }[] = [];

    constructor(injector: Injector) {
        super(injector);
        if (!this.items) {
            this.items = [];
        }

        if (!this.keyPlaceHolder) {
            this.l('Key');
        }

        if (!this.valuePlaceHolder) {
            this.l('Value');
        }
    }
    ngOnInit(): void {
        if (this.items?.length > 0) {
            this.setClonedItems([...this.items]);
        } else {
            this.setClonedItems([]);
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            if (this.items?.length > 0) {
                this.setClonedItems([...this.items]);
            } else {
                this.setClonedItems([]);
            }
        }
    }

    getItems(): { key: string; value: string }[] {
        const result = _cloneDeep(this.clonedItems);
        result.map(function (item) {
            delete item.id;
            return item;
        });
        return result;
    }

    addNew() {
        let newData: { key: string; value: string } = {
            key: '',
            value: '',
        };
        this.clonedItems.unshift(newData);
        this.setClonedItems(this.clonedItems);
        this.pTable.editingRowKeys[this.clonedItems[0][this.pTable.dataKey]] = true;
        this.onRowEditInit(this.clonedItems[0]);
    }

    onRowEditInit(keyValueItem: { key: string; value: string; id?: number }) {
        this.editingItems[keyValueItem.id] = { ...keyValueItem };
    }

    onRowEditSave(keyValueItem: { key: string; value: string; id?: number }) {
        const item = this.clonedItems.find((item) => item.id === keyValueItem.id);
        if (item) {
            item.key = keyValueItem.key;
            item.value = keyValueItem.value;
        }
    }

    onRowEditCancel(keyValueItem: { key: string; value: string; id?: number }, index: number) {
        this.clonedItems[index] = this.editingItems[keyValueItem.id];
        delete this.editingItems[keyValueItem.id];
    }

    onRowDelete(keyValueItem: { key: string; value: string; id?: number }) {
        this.message.confirm('', this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.clonedItems = this.clonedItems.filter((item) => item.id !== keyValueItem.id);
                this.setClonedItems(this.clonedItems);
            }
        });
    }

    private setClonedItems(items: any[]) {
        if (items.length > 0) {
            this.clonedItems = items?.map((item, index) => ({ ...item, id: index + 1 }));
        } else {
            this.clonedItems = [];
        }
    }
}
