import { Component, Input, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ContextMenuActionType, IContextMenuActionInfo } from './context-menu-action-info';

@Component({
    selector: 'mazars-context-menu',
    templateUrl: './mazars-context-menu.component.html',
    host: {
        class: 'p-element',
    },
    styleUrls: ['./mazars-context-menu.component.css'],
})
export class MazarsContextMenuComponent {
    @Input() uid!: string;
    @Input() appendTo: any;
    @Input() items: IContextMenuActionInfo[];
    @ViewChild('menu') menu: OverlayPanel;

    ContextMenuActionType = ContextMenuActionType;
    isSwitched: boolean;

    toggle(event: any) {
        this.menu.toggle(event);
    }
}
