import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DeclarationTaxNumberConverterServiceProxy, EconomicUnitsServiceProxy } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { IItem } from '../../interfaces/item.interface';

@Component({
    selector: 'mz-elster-taxnumber-converter',
    templateUrl: './mazars-elster-taxnumber-converter.component.html',
})
export class MazarsElsterTaxnumberConverterComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() federalState: string;
    readonly FILENUMBER: number = 1;
    readonly TAXNUMBER: number = 2;

    federalStates: IItem<string>[] = [];

    form: FormGroup = null;
    submitting = false;
    result: string = null;
    copying = false;

    types: IItem<number>[] = [
        { id: this.FILENUMBER, text: 'Aktenzeichen' },
        { id: this.TAXNUMBER, text: 'Steuernummer' },
    ];

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        private taxNumberConverter: DeclarationTaxNumberConverterServiceProxy,
        private economicUnitsServiceProxy: EconomicUnitsServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.refresh();

        this.economicUnitsServiceProxy
            .getGermanFederalStates()
            .pipe(take(1))
            .subscribe((result) => {
                this.federalStates = result.map<IItem<string>>((fs) => <IItem<string>>{ id: fs, text: this.l(fs) });
                this.form.patchValue({
                    federalState: this.federalState,
                });
            });
    }

    refresh(): void {
        this.createForm();
        this.form.patchValue({
            federalState: this.federalState,
        });
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (!this.form) {
            return;
        }

        if (simpleChanges.federalState) {
            this.form.patchValue({
                federalState: simpleChanges.federalState.currentValue,
            });
        }
    }

    onButtonClick(): void {
        this.result = null;
        this.submitting = true;

        let observable: Observable<string>;
        if (this.form.value.type === this.FILENUMBER) {
            observable = this.taxNumberConverter.convertFileNumberToElsterFormat(this.form.value.number, this.form.value.federalState);
        } else if (this.form.value.type === this.TAXNUMBER) {
            observable = this.taxNumberConverter.convertTaxNumberToElsterFormat(this.form.value.number, this.form.value.federalState);
        }

        observable
            .pipe(
                take(1),
                finalize(() => {
                    this.submitting = false;
                })
            )
            .subscribe((convertedNumber) => {
                this.result = convertedNumber;
            });
    }

    clearForm(): void {
        this.form.patchValue({
            number: '',
        });
        this.result = null;
    }

    copyToClipboard(): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.result;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.copying = true;
        setTimeout(() => (this.copying = false), 2000);
    }

    private createForm(): void {
        this.form = this.fb.group({
            type: [null, [Validators.required]],
            number: [null, [Validators.required]],
            federalState: [null, [Validators.required]],
        });
        this.form.controls.type.setValue(this.FILENUMBER);
    }
}
