import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs/internal/Subscription';
import { ITabMenuItem } from '@app/modules/mazars-common/components/mazars-tab-menu/tab-menu-interfaces';
import { ActivatedRoute } from '@angular/router';
import { ActionIcon } from '../mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { ReferenceDataService } from '../mazars-common/services/reference-data.service';

@Injectable()
export abstract class Dac6ComponentBase extends AppComponentBase implements OnDestroy {
    subscriptions: Subscription[] = [];
    public activatedRoute: ActivatedRoute;

    public absoluteModuleRootPath = '/app/dac6';
    public absoluteModuleRootPathReports: string = this.absoluteModuleRootPath + '/reports';
    public absoluteModuleRootPathSurveys: string = this.absoluteModuleRootPath + '/surveys';
    public absoluteModuleRootPathAdminSettings: string = this.absoluteModuleRootPath + '/admin-settings';
    public absoluteModuleRootPathProfiles: string = this.absoluteModuleRootPath + '/profiles';
    public absoluteModuleRootPathReportsCreate: string = this.absoluteModuleRootPathReports + '/create';
    public absoluteModuleRootPathSurveysEdit: string = this.absoluteModuleRootPathSurveys + '/edit';
    public absoluteModuleRootPathSurveysCreate: string = this.absoluteModuleRootPathSurveys + '/create';

    referenceDataService: ReferenceDataService;
    dac6TabMenuItems: ITabMenuItem[];
    reportTabMenuItems: ITabMenuItem[];
    reportAdditionalTabMenuItems: ITabMenuItem[];

    protected constructor(injector: Injector) {
        super(injector);
        this.localizationSourceName = AppConsts.localization.dac6SourceName;
        this.referenceDataService = injector.get(ReferenceDataService);
        this.activatedRoute = injector.get(ActivatedRoute);

        this.dac6TabMenuItems = [
            {
                id: 'mi-reports',
                label: this.l('Reports'),
                routerLink: this.absoluteModuleRootPathReports,
            },
            {
                id: 'mi-surveys',
                label: this.l('Surveys'),
                hidden: !this.permission.isGranted('Dac6.Survey.View'),
                routerLink: this.absoluteModuleRootPathSurveys,
            },
            {
                id: 'mi-profiles',
                label: this.ld('Profiles_TabName'),
                routerLink: this.absoluteModuleRootPathProfiles,
                hidden: !this.permission.isGranted('Dac6.Profiles') || !abp.features.isEnabled('ReportingServiceFeature'),
            },
            {
                id: 'mi-settings',
                label: '<i aria-hidden="true" class="fas fa-cog"></i>',
                labelAsHtml: true,
                routerLink: this.absoluteModuleRootPathAdminSettings,
                hidden: !this.permission.isGranted('Dac6.Admin'),
            },
        ];
        this.reportTabMenuItems = [
            {
                id: 'mi-general',
                label: this.l('mi_General'),
                icon: ActionIcon.CheckCircle,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/general`,
                disabled: false,
            },
            {
                id: 'mi-surveys',
                label: this.ld('mi_Surveys'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/surveys`,
                disabled: true,
            },
            {
                id: 'mi-disclosure',
                label: this.l('mi_DisclosureInformation'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/disclosure-information`,
                disabled: true,
            },
            {
                id: 'mi-involved-parties',
                label: this.l('mi_InvolvedParties'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/involved-parties`,
                disabled: true,
            },
            {
                id: 'mi-attachment',
                label: this.l('mi_AttachmentsAndAssessments'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/attachments`,
                disabled: true,
            },
        ];
        this.reportAdditionalTabMenuItems = [
            {
                id: 'mi-communications',
                label: this.ld('mi_Communications'),
                icon: ActionIcon.Comment,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/communications`,
                disabled: true,
                showIconCounter: true,
                tooltip: this.ld('Channels_Tooltip'),
            },
            {
                id: 'mi-history',
                label: this.ld('mi_History'),
                icon: ActionIcon.History,
                routerLink: `${this.absoluteModuleRootPathReports}/${this.activatedRoute.snapshot.params.id}/history`,
                disabled: true,
                tooltip: this.ld('StateHistory_Tooltip'),
            },
        ];
    }

    setTabIcon(tabName: string, canEditData: boolean) {
        let menuItem = this.reportTabMenuItems.find((mi) => mi.id === tabName);
        if (menuItem) {
            menuItem.icon = canEditData ? ActionIcon.CheckCircle : ActionIcon.Lock;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.onDestroy();
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    abstract onDestroy(): void;
}
