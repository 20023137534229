import { Component, forwardRef, Injector, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ITaxIdDto } from '@app/modules/master-data-management/master-data-management-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-tin-sub-form',
    templateUrl: './tin-sub-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TinSubFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TinSubFormComponent),
            multi: true,
        },
    ],
})
export class TinSubFormComponent extends AppComponentBase implements ControlValueAccessor, OnDestroy {
    @Input() uid: string;
    @Input() submitting: boolean;
    @Input() countries: any[];

    form: FormGroup;
    subscriptions: Subscription[] = [];
    isDisabled: boolean;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    get value(): ITaxIdDto[] {
        return this.form.value as ITaxIdDto[];
    }

    get taxIds(): FormArray {
        return this.form.controls.taxIds as FormArray;
    }

    set value(taxIds: ITaxIdDto[]) {
        taxIds = this.checkTaxIdsCollection(taxIds);
        if (taxIds) {
            this.taxIds.clear();
            taxIds.forEach((taxId) => {
                const taxIdForm = this.createTaxIdForm();
                taxIdForm.patchValue(taxId);
                this.taxIds.push(taxIdForm);
            });

            this.onChange(taxIds);
            this.onTouched();
        }
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    getTaxIdDescription(taxId) {
        return taxId.controls.issuedBy.value === 'DE' ? this.l('DeTinDescription') : this.l('TinDescription');
    }

    addTaxId(): void {
        this.taxIds.push(this.createTaxIdForm());
    }

    createTaxIdForm(): FormGroup {
        return this.fb.group({
            value: [null, [Validators.required, Validators.minLength(0), Validators.maxLength(200)]],
            issuedBy: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
        });
    }

    removeTaxId(index: number): void {
        this.taxIds.removeAt(index);
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }

        if (obj === null) {
            this.form.reset();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { taxIds: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getControls() {
        return this.taxIds.controls as FormGroup[];
    }

    private createForm(): void {
        this.form = this.fb.group({
            taxIds: this.fb.array([]),
        });
    }

    private checkTaxIdsCollection(values: any) {
        if (values instanceof Array) {
            return values;
        } else if (values && values.taxIds instanceof Array) {
            return values.taxIds;
        } else {
            return null;
        }
    }
}
