<p-calendar
    id="{{ uid }}"
    name="{{ uid }}"
    [attr.data-testid]="uid"
    [inputStyleClass]="'form-control'"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [style]="{ width: '100%' }"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [yearRange]="yearRange"
    dateFormat="dd. MM yy"
    (onBlur)="onBlur()"
    (onSelect)="onValueChange()"
    (onInput)="onValueChange()"
    [showTime]="true"
    [selectionMode]="selectionMode"
>
</p-calendar>
