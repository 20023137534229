import { Injectable, Injector } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Pillar2ComponentBase } from '../pillar2-component-base';
import {
    BasisForAdjustmentsType,
    CheckResultStatus,
    FinancialAccountingStandard,
    FinancialYearType,
    ProjectsReferenceDataServiceProxy,
} from '../pillar2-proxies';
import { CheckResultSettings } from './project-tab-healthcheck/check-result-settings';
import { ReferenceDataService } from '@app/modules/mazars-common/services/reference-data.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectReferenceDataService extends Pillar2ComponentBase {
    checkResultStatuses: IItem<number>[] = [];
    basisForAdjustments: IItem<number>[] = [];
    basisForAdjustmentsGloBEAllocationIncomeLoss: IItem<number>[] = [];
    basisForAdjustmentsACTCrossAllocationOfTaxes: IItem<number>[] = [];
    financialYears: IItem<number>[] = [];
    etrAndTutEtrAdjustmentArt: IItem<number>[] = [];
    etrAndTutFinancialAccountingStandard: IItem<number>[] = [];

    constructor(
        injector: Injector,
        private projectsReferenceDataServiceProxy: ProjectsReferenceDataServiceProxy,
        private referenceService: ReferenceDataService,
    ) {
        super(injector);
    }

    onDestroy(): void {
        // Implement if necessary
    }

    public load() {
        this.getCheckResultStatuses()
            .pipe(take(1))
            .subscribe((statuses) => {
                this.checkResultStatuses = statuses;
            });

        this.getBasisForAdjustmentsGloBEAllocationIncomeLoss()
            .pipe(take(1))
            .subscribe((basisForAdjustmentsGloBEAllocationIncomeLoss) => {
                this.basisForAdjustmentsGloBEAllocationIncomeLoss = basisForAdjustmentsGloBEAllocationIncomeLoss;
            });

        this.getBasisForAdjustmentsACTCrossAllocationOfTaxes()
            .pipe(take(1))
            .subscribe((basisForAdjustmentsACTCrossAllocationOfTaxes) => {
                this.basisForAdjustmentsACTCrossAllocationOfTaxes = basisForAdjustmentsACTCrossAllocationOfTaxes;
            });

        this.getFinancialYears()
            .pipe(take(1))
            .subscribe((financialYears) => {
                this.financialYears = financialYears;
            });

        this.getEtrAndTutEtrAdjustmentArt()
            .pipe(take(1))
            .subscribe((etrAndTutEtrAdjustmentArt) => {
                this.etrAndTutEtrAdjustmentArt = etrAndTutEtrAdjustmentArt;
            });

        this.getEtrAndTutFinancialAccountingStandard()
            .pipe(take(1))
            .subscribe((etrAndTutFinancialAccountingStandard) => {
                this.etrAndTutFinancialAccountingStandard = etrAndTutFinancialAccountingStandard;
            });
    }

    public getConsolidationMethods(): Observable<IItem<string>[]> {
        return this.projectsReferenceDataServiceProxy.getConsolidationMethods().pipe(
            take(1),
            map((methods) =>
                methods.map(
                    (method) =>
                        ({
                            id: method,
                            text: this.l('ConsolidationMethod' + method),
                        }) as IItem<string>,
                ),
            ),
        );
    }

    public getGloBEStatuses(): Observable<IItem<string>[]> {
        return this.projectsReferenceDataServiceProxy.getGloBEStatuses().pipe(
            take(1),
            map((statuses) =>
                statuses.map(
                    (status) =>
                        ({
                            id: status,
                            text: this.l('GloBEStatus' + status),
                        }) as IItem<string>,
                ),
            ),
        );
    }

    public getCurrencies(): Observable<IItem<string>[]> {
        return this.referenceService.getCurrencies().pipe(
            take(1),
            map((currencies) =>
                currencies.map(
                    (currency) =>
                        ({
                            id: currency.id,
                            text: currency.text,
                        }) as IItem<string>,
                ),
            ),
        );
    }

    private getCheckResultStatuses(): Observable<IItem<number>[]> {
        return of(
            Object.keys(CheckResultStatus)
                .map((key) => CheckResultStatus[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (status: string) =>
                        ({
                            id: CheckResultStatus[status],
                            text: this.l(`CheckResultStatus_${status}`),
                            icon: CheckResultSettings.getDataIcon(CheckResultStatus[status]),
                            iconColor: CheckResultSettings.getIconColor(CheckResultStatus[status]),
                        }) as IItem<number>,
                ),
        );
    }

    private getBasisForAdjustmentsGloBEAllocationIncomeLoss(): Observable<IItem<number>[]> {
        return of(
            Object.keys(BasisForAdjustmentsType)
                .map((key) => BasisForAdjustmentsType[key])
                .filter((value) => typeof value === 'string' && BasisForAdjustmentsType[value] < 10)
                .map(
                    (key: string) =>
                        ({
                            id: BasisForAdjustmentsType[key],
                            text: this.l(`BasisForAdjustmentsType_${key}`),
                        }) as IItem<number>,
                ),
        );
    }

    private getBasisForAdjustmentsACTCrossAllocationOfTaxes(): Observable<IItem<number>[]> {
        return of(
            Object.keys(BasisForAdjustmentsType)
                .map((key) => BasisForAdjustmentsType[key])
                .filter((value) => typeof value === 'string' && BasisForAdjustmentsType[value] > 10 && BasisForAdjustmentsType[value] < 20)
                .map(
                    (key: string) =>
                        ({
                            id: BasisForAdjustmentsType[key],
                            text: this.l(`BasisForAdjustmentsType_${key}`),
                        }) as IItem<number>,
                ),
        );
    }

    private getFinancialYears(): Observable<IItem<number>[]> {
        return of(
            Object.keys(FinancialYearType)
                .map((key) => FinancialYearType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (key: string) =>
                        ({
                            id: FinancialYearType[key],
                            text: this.l(`FinancialYearType_${key}`),
                        }) as IItem<number>,
                ),
        );
    }

    private getEtrAndTutEtrAdjustmentArt(): Observable<IItem<number>[]> {
        return of(
            Object.keys(BasisForAdjustmentsType)
                .map((key) => BasisForAdjustmentsType[key])
                .filter((value) => typeof value === 'string' && BasisForAdjustmentsType[value] >= 100)
                .map(
                    (key: string) =>
                        ({
                            id: BasisForAdjustmentsType[key],
                            text: this.l(`BasisForAdjustmentsType_${key}`),
                        }) as IItem<number>,
                ),
        );
    }

    private getEtrAndTutFinancialAccountingStandard(): Observable<IItem<number>[]> {
        return of(
            Object.keys(FinancialAccountingStandard)
                .map((key) => FinancialAccountingStandard[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (key: string) =>
                        ({
                            id: FinancialAccountingStandard[key],
                            text: this.l(`FinancialAccountingStandard${key}`),
                        }) as IItem<number>,
                ),
        );
    }
}
