import { Input, Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-mazars-input-textarea',
    templateUrl: './mazars-input-textarea.component.html',
    styleUrls: ['./mazars-input-textarea.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsInputTextAreaComponent,
        },
    ],
})
export class MazarsInputTextAreaComponent implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() placeholder?: string;
    @Input() rows?: number = 5;
    @Input() maxLength;

    cols = 100;
    value: string;
    isDisabled: boolean;
    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onInputChange() {
        this.onChange(this.value);
    }

    onBlur() {
        this.onTouched();
    }
}
