import { NameValuePair } from '@shared/utils/name-value-pair';
import { IThemeAssetContributor } from '../ThemeAssetContributor';
import { ThemeHelper } from '../ThemeHelper';

export class MazarsThemeAssetContributor implements IThemeAssetContributor {
    public getAssetUrls(): string[] {
        return [
        ];
    }

    public getMenuWrapperStyle(): string {
        return 'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500';
    }

    public getSubheaderStyle(): string {
        return 'text-dark fw-bold my-1 me-5';
    }

    public getFooterStyle(): string {
        return 'footer py-4 d-flex flex-lg-column';
    }

    getBodyAttributes(): NameValuePair[] {
        return [];
    }

    getAppModuleBodyClass(): string {
        return 'page-fluid toolbar-enabled aside-left-offcanvas header-tablet-and-mobile-fixed header-fixed header-mobile-fixed aside-fixed quick-panel-right demo-panel-right aside-fixed aside-left-enabled aside-enabled toolbar-solid toolbar-fixed';
    }
}
