export class ValidatorsConsts {
    static readonly countryCodeLength = 2;
    static readonly maxDisclosureNameLength = 500;
    static readonly maxAmount = 9223372036854775807;
    static readonly maxDescriprionTextAreaLength = 4000;
    static readonly maxHallmarkCommentTextAreaLength = 4000;
    static readonly maxLegalDeclarantLastNameLength = 60;
    static readonly maxLegalDeclarantTaxIdLength = 50;
    static readonly maxLegalDeclarantPostalCodeLength = 50;
    static readonly maxLegalDeclarantCityLength = 50;
    static readonly maxLegalDeclarantHouseNumberAndStreetLength = 70;
    static readonly maxContactDetailsLastNameLength = 60;
    static readonly maxContactDetailsEmailLength = 400;
    static readonly maxContactDetailsPhoneLength = 20;
    static readonly maxContactPersonContactDetailsLength = 400;
    static readonly maxLuContactDetailsFirstNameLength = 200;
    static readonly maxLuContactDetailsLastNameLength = 200;
    static readonly maxLuContactDetailsEmailLength = 254;
    static readonly maxLuContactDetailsPhoneNumberLength = 200;
}
