import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MazarsWizardActions } from '../actions/mazars-wizard.actions';
import { MazarsWizardSelectors, MazarsWizardSelectorsToken } from '../selectors/mazars-wizard.selectors';
import { MazarsWizardState } from '../state/mazars-wizard.state';

@Injectable()
export class MazarsWizardFacade {
    public readonly activeStep$: Observable<number> = this.store.pipe(select(this.selectors.activeStep));

    constructor(
        private readonly store: Store<MazarsWizardState>,
        @Inject(MazarsWizardSelectorsToken) private readonly selectors: MazarsWizardSelectors,
        private mazarsWizardActions: MazarsWizardActions
    ) {}

    setActiveStep(data: number) {
        this.store.dispatch(this.mazarsWizardActions.SetActiveStep({ data: data }));
    }

    clear() {
        this.store.dispatch(this.mazarsWizardActions.Clear());
    }
}
