import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-mazars-button-link',
    templateUrl: './mazars-button-link.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsButtonLinkComponent,
        },
    ],
})
export class MazarsButtonLinkComponent{
    @Input({ required: true }) uid: string;
    @Input() isLink: boolean;
    @Input() label: string;
    @Output() onClicked = new EventEmitter();

    onClick(event) {
        this.onClicked.emit(event);
    }
}