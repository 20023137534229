<span
    title="{{ assignedUser?.userName }}"
    class="text-nowrap"
    [ngStyle]="{ cursor: isEditable ? 'pointer' : 'inherit' }"
    (click)="showAssignableUserEditModal()"
>
    <div *ngIf="!assignedUser">-</div>
    <app-mazars-profile-picture *ngIf="assignedUser" [mode]="mode" [user]="assignedUser" [uid]="uid + '-profile-picture-' + assignedUser?.id"></app-mazars-profile-picture>
</span>

<app-mazars-assignable-user-select-edit-modal-v2 #mazarsAssignableEditModal *ngIf="isEditable" [uid]="uid"></app-mazars-assignable-user-select-edit-modal-v2>
