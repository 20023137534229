<span
    title="{{ title }}"
    class="text-nowrap"
    [ngStyle]="{ cursor: isEditable ? 'pointer' : 'inherit' }"
    (click)="showStatusEditModal()"
>
    <i
        class="fas fa-circle mr-1"
        [class.text-primary]="statusValue == 'Active'"
        [class.text-warning]="statusValue == 'Review'"
        [class.text-success]="statusValue == 'Closed'"
        [class.text-secondary]="statusValue != 'Active' && statusValue != 'Review' && statusValue != 'Closed'"
        style="font-size: 0.9rem;"
        aria-hidden="true"
    ></i>
    {{ l('WorkItemStatus_' + statusValue) }}
</span>

<app-mazars-status-edit-modal #mazarsStatusEditModal *ngIf="isEditable" [uid]="uid"></app-mazars-status-edit-modal>
