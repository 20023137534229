<div
    appBsModal
    #mySettingsModal="bs-modal"
    (onShown)="onShown()"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'MySettings' | localize }}</span>
                    </h5>
                    <button type="button" class="btn-close" [attr.aria-label]="l('Close')" (click)="close()"></button>
                </div>
                <div class="modal-body" *ngIf="user">
                    <tabset>
                        <tab heading="{{ 'Profile' | localize }}">
                            <div class="mb-5 mt-5">
                                <label class="form-label required" for="Name">{{ 'FirstName' | localize }}</label>
                                <input
                                    id="Name"
                                    #nameInput="ngModel"
                                    class="form-control"
                                    type="text"
                                    name="Name"
                                    [(ngModel)]="user.name"
                                    required
                                    maxlength="64"
                                    [disabled]="appSession?.user?.hasExternalLogin"
                                />
                                <span class="form-text text-muted" *ngIf="appSession?.user?.hasExternalLogin">
                                    {{ 'UserHasExternalLogin' | localize }}
                                </span>
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="mb-5">
                                <label class="form-label required" for="Surname">{{ 'Surname' | localize }}</label>
                                <input
                                    id="Surname"
                                    #surnameInput="ngModel"
                                    type="text"
                                    name="Surname"
                                    class="form-control"
                                    [(ngModel)]="user.surname"
                                    required
                                    maxlength="64"
                                    [disabled]="appSession?.user?.hasExternalLogin"
                                />
                                <span class="form-text text-muted" *ngIf="appSession?.user?.hasExternalLogin">
                                    {{ 'UserHasExternalLogin' | localize }}
                                </span>
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="mb-5">
                                <label class="form-label required" for="EmailAddress">{{ 'EmailAddress' | localize }}</label>
                                <input
                                    id="EmailAddress"
                                    #emailAddressInput="ngModel"
                                    type="email"
                                    name="EmailAddress"
                                    class="form-control"
                                    [(ngModel)]="user.emailAddress"
                                    required
                                    email
                                    maxlength="256"
                                    [disabled]="appSession?.user?.hasExternalLogin"
                                />
                                <span class="form-text text-muted" *ngIf="appSession?.user?.hasExternalLogin">
                                    {{ 'UserHasExternalLogin' | localize }}
                                </span>
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>
                            <div *ngIf="smsEnabled">
                                <div class="mb-5">
                                    <label class="form-label">{{ 'PhoneNumber' | localize }}</label>
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            name="PhoneNumber"
                                            class="form-control"
                                            [(ngModel)]="user.phoneNumber"
                                            maxlength="32"
                                        />

                                        <span id="SpanSmsVerificationUnverified" class="input-group-append">
                                            <button
                                                id="btnSmsVerification"
                                                (click)="smsVerify()"
                                                [disabled]="
                                                    isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber
                                                "
                                                class="btn btn-primary"
                                                type="button"
                                                tooltip="{{ 'YourPhoneNumberIsNotVerified' | localize }}"
                                            >
                                                <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                                                {{ 'Verify' | localize }}
                                            </button>
                                        </span>

                                        <div
                                            *ngIf="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                            class="input-group-append"
                                            id="SpanSmsVerificationVerified"
                                        >
                                            <span
                                                class="input-group-text"
                                                tooltip="{{ 'YourPhoneNumberIsVerified' | localize }}"
                                            >
                                                <i
                                                    class="la la-check label-success"
                                                    [attr.aria-label]="l('Verified')"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-5">
                                <label class="form-label required" for="UserName">{{ 'UserName' | localize }}</label>
                                <input
                                    id="UserName"
                                    #userNameInput="ngModel"
                                    type="text"
                                    [disabled]="!canChangeUserName"
                                    class="form-control"
                                    [(ngModel)]="user.userName"
                                    name="UserName"
                                    required
                                    maxlength="256"
                                    [disabled]="appSession?.user?.hasExternalLogin"
                                />
                                <span class="form-text text-muted" *ngIf="!canChangeUserName">
                                    {{ 'CanNotChangeAdminUserName' | localize }}
                                </span>
                                <span class="form-text text-muted" *ngIf="appSession?.user?.hasExternalLogin">
                                    {{ 'UserHasExternalLogin' | localize }}
                                </span>
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>

                            <div class="mb-5" *ngIf="showTimezoneSelection">
                                <label for="Timezone" class="form-label">{{ 'Timezone' | localize }}</label>
                                <timezone-combo
                                    name="Timezone"
                                    [(ngModel)]="user.timezone"
                                    defaultTimezoneScope="{{ defaultTimezoneScope }}"
                                ></timezone-combo>
                            </div>

                            <div *ngIf="!isMultiTenancyEnabled || isTwoFactorLoginEnabledForApplication">
                                <div class="mb-5">
                                    <div *ngIf="isGoogleAuthenticatorEnabled; else googleAuthenticatorEnabled">
                                        <div class="mb-3 fw-semibold">
                                            <h4
                                                class="fw-bold fs-3 d-flex align-items-center"
                                                style="color: #2b9348 !important"
                                            >
                                                <i class="fa-solid fa-lock me-2"></i>
                                                {{ 'AuthenticatorAppEnabled' | localize }}
                                            </h4>
                                            <span class="form-text text-muted">
                                                {{ 'AuthenticatorAppEnabledDescription' | localize }}
                                            </span>
                                        </div>
                                        <button
                                            (click)="viewRecoveryCodesModal.show()"
                                            type="button"
                                            id="btnViewRecoveryCodes"
                                            class="btn btn-primary btn-sm"
                                        >
                                            {{ 'ViewRecoveryCodes' | localize }}
                                        </button>
                                        <button
                                            (click)="verifyCodeModal.show()"
                                            type="button"
                                            id="btnRemoveAuthenticator"
                                            class="btn btn-danger btn-sm"
                                        >
                                            {{ 'DisableAuthenticatorApp' | localize }}
                                        </button>
                                    </div>

                                    <ng-template #googleAuthenticatorEnabled>
                                        <div
                                            class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6"
                                        >
                                            <!--begin::Icon-->
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen048.svg-->
                                            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        opacity="0.3"
                                                        d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                        fill="currentColor"
                                                    ></path>
                                                    <path
                                                        d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                            <!--end::Icon-->
                                            <!--begin::Wrapper-->
                                            <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                <!--begin::Content-->
                                                <div class="mb-3 mb-md-0 fw-semibold">
                                                    <h4 class="text-gray-900 fw-bold">
                                                        {{ 'EnableAuthenticatorApp' | localize }}
                                                    </h4>
                                                    <div class="fs-6 text-gray-700 pe-7">
                                                        {{ 'EnableAuthenticatorAppDescription' | localize }}
                                                    </div>
                                                </div>
                                                <!--end::Content-->
                                                <!--begin::Action-->
                                                <button
                                                    type="button"
                                                    (click)="enableTwoFactorAuthentication()"
                                                    class="btn btn-primary px-6 align-self-center text-nowrap"
                                                    id="enableTwoFactorAuthenticationButton"
                                                >
                                                    {{ 'Enable' | localize }}
                                                </button>
                                                <!--end::Action-->
                                            </div>
                                            <!--end::Wrapper-->
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light-primary fw-bold" (click)="close()" [disabled]="saving">
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [disabled]="!mySettingsModalForm.form.valid || saving"
                    >
                        <i aria-hidden="true" class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal
    *ngIf="user"
    #smsVerificationModal
    (modalSave)="changePhoneNumberToVerified()"
    [newPhoneNumber]="user.phoneNumber"
></smsVerificationModal>

<enableTwoFactorAuthenticationModal #enableTwoFactor (modalSave)="close()"></enableTwoFactorAuthenticationModal>

<viewRecoveryCodesModal #viewRecoveryCodesModal></viewRecoveryCodesModal>
<verifyCodeModal
    #verifyCodeModal
    (modalSave)="disableTwoFactorAuthentication(verifyCodeModal.verifyCodeInput)"
></verifyCodeModal>
