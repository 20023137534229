import { Component, SecurityContext } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-cell-with-tooltip-renderer',
    templateUrl: './cell-with-tooltip-renderer.component.html',
    styleUrls: ['./cell-with-tooltip-renderer.component.css'],
})
export class CellWithTooltipRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParams;
    securityContext = SecurityContext;

    constructor() { }
    agInit(params: ICellRendererParams<any, any>): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
