import { Component, ElementRef, Injector, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SlideMoreLessAnimation } from '../../animations/animations';

@Component({
    selector: 'app-mazars-desription-with-more',
    templateUrl: './mazars-desription-with-more.component.html',
    styleUrls: ['./mazars-desription-with-more.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [SlideMoreLessAnimation]
})
export class MazarsDesriptionWithMoreComponent extends AppComponentBase {
    @ViewChild('content') contentView: ElementRef;
    @Input({ required: true }) uid: string;
    animationState = 'less';
    isExpanded: boolean;
    isCalculated: boolean;
    canExpand: boolean;

    constructor(_injector: Injector) {
        super(_injector);
    }

    calculateCanExpand() {
        let maxHeight = parseInt(getComputedStyle(this.contentView.nativeElement).maxHeight);
        if (this.contentView.nativeElement.offsetHeight === maxHeight) {
            this.canExpand = true;
        }
        this.isCalculated = true;
    }

    toggle() {
        this.animationState = this.animationState === 'more' ? 'less' : 'more';
        this.isExpanded = !this.isExpanded;
    }
}
