import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ICommunicationDto, ICreateCommunicationInput, IEditCommunicationInput } from '@shared/service-proxies/common-interfaces';
import { Observable } from 'rxjs';
import { IMazarsCreateUpdateFacade } from '../../components/mazars-dialog/mazars-create-update-facade';
import { CommonCommunicationsActions } from '../actions/common-communications.actions';
import { CommonCommunicationsSelectors, CommonCommunicationsSelectorsToken } from '../selectors/common-communication.selectors';
import { CommonCommunicationsState } from '../state/common-communications.state';

@Injectable()
export class CommunicationCreateUpdateFacade
    implements IMazarsCreateUpdateFacade<ICommunicationDto, ICreateCommunicationInput, IEditCommunicationInput>
{
    getEntity$: Observable<ICommunicationDto> = this.store.pipe(select(this.selectors.getCommunication));
    entityUpdatedSuccessfully$: Observable<void> = this.actions$.pipe(ofType(this.commonCommunicationsActions.UpdateCommunicationSuccess));
    entityCreatedSuccessfully$: Observable<void> = this.actions$.pipe(ofType(this.commonCommunicationsActions.CreateCommunicationSuccess));
    createOrUpdateError$: Observable<any> = this.store.pipe(select(this.selectors.getCommunicationError));

    constructor(
        private readonly store: Store<CommonCommunicationsState>,
        private readonly actions$: Actions,
        @Inject(CommonCommunicationsSelectorsToken) private readonly selectors: CommonCommunicationsSelectors,
        private commonCommunicationsActions: CommonCommunicationsActions
    ) {}

    getById(id: number) {
        this.store.dispatch(this.commonCommunicationsActions.GetByIdCommunicationRequest({ input: id }));
    }

    create(input: ICreateCommunicationInput) {
        this.store.dispatch(this.commonCommunicationsActions.CreateCommunicationRequest({ input: input }));
    }

    update(input: IEditCommunicationInput) {
        this.store.dispatch(this.commonCommunicationsActions.UpdateCommunicationRequest({ input: input }));
    }
}
