import { Component, Input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IProcessDto } from '@shared/service-proxies/common-interfaces';
import { ProcessStateType } from './process-state-type';

@Component({
    selector: 'app-mazars-process',
    templateUrl: './mazars-process.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsProcessComponent {
    @Input({ required: true }) uid: string;
    @Input() process: IProcessDto;
    @Input() linkProvider: (description: string, param: any) => string;
    @Input() linkParam: any;
    @Input() localizer: (key: string) => string;

    processStateType = ProcessStateType;
    securityContext = SecurityContext;

    constructor(private router: Router) {}

    l(value: string): string {
        let result = this.localizer ? this.localizer(value) : value;
        if (!result) {
            result = value;
        }
        return result;
    }

    hasLink(description: string): boolean {
        return this.getLink(description) != null;
    }

    onClick(description: string): void {
        let link = this.getLink(description);
        if (link != null) {
            this.router.navigate([link]);
        }
    }

    private getLink(description: string): string {
        if (this.linkProvider) {
            return this.linkProvider(description, this.linkParam);
        }
        return null;
    }
}
