import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICommunicationDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'app-mazars-communication',
    templateUrl: './mazars-communication.component.html',
})
export class MazarsCommunicationComponent {
    @Input({ required: true }) uid: string;
    @Input() communications: ICommunicationDto[];
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Output() editCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Output() deleteCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Output() openCommunicationClicked = new EventEmitter<ICommunicationDto>();

    onEditCommunicationClicked(communication: ICommunicationDto) {
        this.editCommunicationClicked.emit(communication);
    }

    onDeleteCommunicationClicked(communication: ICommunicationDto) {
        this.deleteCommunicationClicked.emit(communication);
    }

    onOpenCommunicationClicked(communication: ICommunicationDto) {
        this.openCommunicationClicked.emit(communication);
    }

}
