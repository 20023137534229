import { ChangeDetectorRef, Component, HostListener, Inject, InjectionToken, Injector, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { CommunicationService } from '@app/modules/mazars-common/services/communication.service';
import { CommonQuillMentionService } from '@app/shared/common/quill/common-quill-mention.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IReferenceCommunicationDto } from '../communication-model';
import { IReferenceCommunicationsFacade } from '../../../state-management/facades/reference-communications.facade';
import { ICreateItemCommentInput } from '@shared/service-proxies/common-interfaces';
import { ScrollPanel } from 'primeng/scrollpanel';
import { timer } from 'rxjs';
import { cloneDeep as _cloneDeep } from 'lodash-es';

export const CommunicationsFacade = new InjectionToken<string>('CommunicationsFacade');

@Component({
    selector: 'app-mazars-reference-communications-modal',
    templateUrl: './mazars-reference-communications-modal.component.html',
    styles: [],
})
export class MazarsReferenceCommunicationsModalComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() commonQuillMentionService: CommonQuillMentionService;
    @Input() communicationMessageIdentifier: string;
    @ViewChild('scrollPanel', { static: true }) scrollPanel: ScrollPanel;

    isVisible: boolean;
    form: FormGroup;
    availableShortcuts: IItem<string>[];

    communications: IReferenceCommunicationDto[] = [];
    communicationType: string;
    title: string;
    hasWritePermission = true;

    private data = {} as ICreateItemCommentInput;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        private communicationService: CommunicationService,
        @Inject(CommunicationsFacade)
        private referenceCommunicationsFacade: IReferenceCommunicationsFacade,
        private cdRef: ChangeDetectorRef
    ) {
        super(injector);
        this.createForm();
        this.registerSubscriptions();
        this.availableShortcuts = this.communicationService.getAvailableEditorShortcuts();
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.cancel();
    }

    cancel() {
        this.isVisible = false;
        this.resetForm();
    }

    create() {
        if (this.form.valid) {
            this.data.content = this.form.value?.content?.toString();
            this.referenceCommunicationsFacade.createCommunication(this.data, this.communicationType);
        }
    }

    private createForm() {
        this.form = this.fb.group({
            content: [null, [Validators.required]],
        });
    }

    private resetForm() {
        this.form.get('content')?.setValue(null);
        this.form.reset();
        this.data = { ...this.data, content: null } as ICreateItemCommentInput;
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.referenceCommunicationsFacade.communicationOpened$.subscribe((data) => {
                this.unsubscribeAllEvents();
                this.data.referenceId = data.referenceId;
                this.data.itemId = data.itemId;
                this.hasWritePermission = data?.hasWritePermission;
                this.communicationType = data.communicationType;
                this.isVisible = true;
                this.referenceCommunicationsFacade.getCommunications(data.referenceId, { communicationType: this.communicationType, itemId: data.itemId });
                this.subscribeToEvent(`app.${this.communicationMessageIdentifier}.messageRecieved`, (message) => {
                    if (abp.session.userId !== message.creatorUserId && this.data.referenceId === message.referenceId) {
                        this.referenceCommunicationsFacade.getCommunications(this.data.referenceId, {
                            communicationType: this.communicationType,
                            itemId: this.data.itemId,
                        });
                    }
                });
            })
        );

        this.subscriptions.push(
            this.referenceCommunicationsFacade.communicationsLoaded$.subscribe((data) => {
                this.title = data.channel.title;
                this.communications = _cloneDeep(data.channel.communications);
                this.cdRef.detectChanges();

                timer(100).subscribe(() => {
                    this.scrollPanel.scrollTop(1000000);
                });
            })
        );

        this.subscriptions.push(
            this.referenceCommunicationsFacade.communicationCreated$.subscribe((_) => {
                this.notify.success('Created');
                this.referenceCommunicationsFacade.getCommunications(this.data.referenceId, {
                    communicationType: this.communicationType,
                    itemId: this.data.itemId,
                });
                this.resetForm();
            })
        );
    }

    private unsubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }
}
