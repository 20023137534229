import { Component, ContentChildren, EventEmitter, Injector, Input, Output, QueryList, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { IActionInfo } from '../mazars-actions-dropdown-menu/action-info';
import { IColumnDefinition } from '../mazars-grid/grid-interfaces';
import { MazarsTemplateDirective } from '../mazars-template/mazars-template.directive';

@Component({
    selector: 'mazars-tree-table',
    templateUrl: './mazars-tree-table.component.html'
})
export class MazarsTreeTableComponent extends AppComponentBase {
    @ContentChildren(MazarsTemplateDirective, { read: MazarsTemplateDirective })
    templateRefs: QueryList<MazarsTemplateDirective>;

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    @Input({ required: true }) uid: string;
    @Input() columnDefinitions: IColumnDefinition[];
    @Input() primengTableHelper: any;
    @Input() dataKey = 'id';

    @Output() actionItemClick: EventEmitter<IActionInfo> = new EventEmitter<IActionInfo>();
    @Output() getAllRecords = new EventEmitter(true);
    @Output() onRowExpand = new EventEmitter();

    selectedItems: any[];
    dynamicHeaderActions: IActionInfo[];
    actionItemsCache: Map<any, IActionInfo[]> = new Map<any, IActionInfo[]>();
    hasDynamicActions: boolean;
    securityContext = SecurityContext;

    constructor(_injector: Injector) {
        super(_injector);
    }

    getRecords(event: any) {
        if (event.sortField === '_defaultSortField') {
            event['sortField'] = undefined;
        }
        this.getAllRecords.emit(event);
    }

    reloadPage() {
        this.paginator.changePage(this.paginator.getPage());
    }

    setCurrentPage(currentPage: number, maxResultCount: number) {
        this.paginator._first = (currentPage - 1) * maxResultCount;
        this.paginator.updatePaginatorState();
    }

    getTemplate(identifier: string): TemplateRef<any> {
        for (let ref of this.templateRefs) {
            if (ref.identifier === identifier) {
                return ref.template;
            }
        }
        return null;
    }

    handleRowExpand(event: any) {
        this.onRowExpand.emit(event);
    }

    getColumnValue(col: IColumnDefinition, record: any): string {
        let value = this.getPropertyByString(record.node, col.field);

        if (col.customRenderer != null) {
            value = col.customRenderer(value);
        }

        return value;
    }

    getPropertyByString(object: any, columnField: string) {
        if (!columnField) {
            return object;
        }

        let property: string;
        let properties = columnField.split('.');

        let index = 0;
        for (let i = 0; i < properties.length - 1; i++) {
            index = i;
            property = properties[i];

            let candidate = object[property];
            if (candidate !== undefined) {
                object = candidate;
            } else {
                break;
            }
        }
        return object[properties[index]];
    }

    getBackgroundColorClass(record) {
        if (!record) {
            return '';
        }
        if (record.style === 'warning') {
            return 'warning-color';
        } else {
            if (record.style === 'error') {
                return 'error-color';
            }
        }
    }
}
