import { Component, Injector, Input, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SurveyComponent } from '../surveyjs/survey.component';

@Component({
    selector: 'app-mazars-survey',
    templateUrl: './mazars-survey.component.html',
})
export class MazarsSurveyComponent extends AppComponentBase implements OnDestroy {
    @ViewChild('survey') survey: SurveyComponent;
    @Input() json: string;
    @Input() answers: string;
    @Input({ required: true }) uid: string;
    @Input() isDisabled = false;
    @Output() saveSurveyAnswers = new EventEmitter<any>();

    constructor(injector: Injector, private elementRef: ElementRef) {
        super(injector);
    }

    onSurveySubmitted(surveyJson) {
        this.saveSurveyAnswers.emit(surveyJson);
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.remove();
    }
}
