import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Dac6ComponentBase } from '@app/modules/dac6/dac6-component-base';
import { ReportReferenceDataService } from '@app/modules/dac6/reports/report-reference-data.service';
import { ExtensionType } from '@app/modules/mazars-common/interfaces/extension-type';

@Component({
    selector: 'app-liability-sub-form',
    templateUrl: './liability-sub-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LiabilitySubFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => LiabilitySubFormComponent),
            multi: true,
        },
    ],
})
export class LiabilitySubFormComponent extends Dac6ComponentBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() submitting: boolean;
    @Input() liabilityType: ExtensionType;

    form: FormGroup;
    subscriptions: Subscription[] = [];
    isDisabled: any;

    constructor(injector: Injector, private fb: FormBuilder, public reportReferenceDataService: ReportReferenceDataService) {
        super(injector);
    }

    get value(): any {
        return this.form.value;
    }

    set value(value: any) {
        if (value) {
            this.form.patchValue(value);
        }
    }

    ngOnInit(): void {
        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    getConnectionToCountries() {
        switch (this.liabilityType) {
            case ExtensionType.TaxPayer:
                return this.reportReferenceDataService.taxpayerConnectionToCountry;
            case ExtensionType.Intermediary:
                return this.reportReferenceDataService.intermediaryConnectionToCountry;
            default:
                return [];
        }
    }

    getLiabilityRoles() {
        switch (this.liabilityType) {
            case ExtensionType.TaxPayer:
                return this.reportReferenceDataService.taxpayerLiabilityRoles;
            case ExtensionType.Intermediary:
                return this.reportReferenceDataService.intermediaryLiabilityRoles;
            default:
                return [];
        }
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { liability: { valid: false } };
    }

    onDestroy(): void {
        // to be implemented if need it
    }

    private createForm(): void {
        this.form = this.fb.group({
            connectionToCountry: [null],
            role: [null],
        });
    }
}
