import { Component, Input, ViewChild, ViewEncapsulation, Output, EventEmitter, ContentChildren, QueryList, TemplateRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Dialog } from 'primeng/dialog';
import { MazarsTemplateDirective } from '../../mazars-template/mazars-template.directive';

@Component({
    templateUrl: './mazars-filters-dialog.component.html',
    selector: 'mazars-filters-dialog',
    styleUrls: ['./mazars-filters-dialog.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsFiltersDialogComponent extends AppComponentBase {
    @ContentChildren(MazarsTemplateDirective, { read: MazarsTemplateDirective })
    templateRefs: QueryList<MazarsTemplateDirective>;

    @ViewChild('filterDialog', { static: true }) reorderDialog: Dialog;

    @Input({ required: true }) uid: string;
    @Input() isVisible: boolean;

    @Output() configChanged = new EventEmitter(true);

    showHide() {
        this.isVisible = !this.isVisible;
    }

    undoChanges() {
    }

    saveConfig() {
    }

    reset() {
    }

    onConfigChanged($event) {
        this.configChanged.emit($event);
    }

    onSavedConfig() {
        this.showHide();
    }

    getTemplate(identifier: string): TemplateRef<any> {
        for (let ref of this.templateRefs) {
            if (ref.identifier === identifier) {
                return ref.template;
            }
        }
        return null;
    }
}
