import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-mazars-input-checkbox',
    templateUrl: './mazars-input-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsInputCheckboxComponent,
        },
    ],
})
export class MazarsInputCheckboxComponent implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Output() onToggleChanged = new EventEmitter<any>();
    @Input() isDisabled: boolean;
    @Input() label: string;
    @Input() binary: boolean;
    @Input() labelStyleClass = 'mb-0';
    @Input() value: any;

    currentValue;
    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.currentValue = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlur() {
        this.onTouched();
    }

    handleOnChange() {
        this.onChange(this.currentValue);
        this.onToggleChanged.emit(this.currentValue);
    }
}
