<div class="d-flex flex-direction-row align-items-center">
    <i #leftArrow class="arrow fas fa-solid fa-chevron-left" (click)="moveItemsToLeft()"></i>
    <div
        #processBarContainer
        (mousemove)="move($event)"
        (mousedown)="startDragging($event)"
        (mouseup)="stopDragging()"
        (mouseleave)="stopDragging()"
        class="flex-grow-1 overflow-hidden"
    >
        <ul #processBar class="d-flex processBar">
            <ng-container *ngFor="let item of model; let i = index">
                <li
                    ngClass="d-flex align-items-center justify-content-center process {{
                        (i == activeIndex ? activeProcessClassName : i < activeIndex ? 'process--completed' : '') + (item.styleClass != null ? ' ' + item.styleClass : '')
                    }}"
                >
                    <i class="fa-check fas"></i>
                    <div class="overflow-hidden process__details_container">
                        <div class="d-flex align-items-center justify-content-center overflow-hidden process__details">
                            <div class="process__details_icon">
                                <i
                                    [mazarsTooltip]="i == activeIndex ? this.activeStepTooltip : ''"
                                    [escape]="false"
                                    aria-hidden="true"
                                    class="{{ item.icon ? item.icon : i + 1 }}"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <span>{{ item.label }}</span>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
    <i #rightArrow class="arrow fas fa-solid fa-chevron-right" (click)="moveItemsToRight()"></i>
</div>
