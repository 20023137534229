import { Component, EventEmitter, Injector, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { YesNoAnswerType } from './mazars-yes-no-answer-type';

@Component({
    selector: 'app-mazars-yes-no-question',
    templateUrl: './mazars-yes-no-question.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsYesNoQuestionComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsYesNoQuestionComponent extends AppComponentBase implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() disabled = false;
    @Output() onValueChange = new EventEmitter<YesNoAnswerType>();

    selectedItem: any;
    isDisabled: boolean;
    selectedItems: string[] = [];
    YesNoAnswerType = YesNoAnswerType;

    constructor(injector: Injector) {
        super(injector);
    }

    writeValue(obj: any): void {
        if (obj !== null || obj !== undefined) {
            this.selectedItem = obj;
            this.selectedItems = [obj];
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onChangeHandler() {
        const latest = this.selectedItems[this.selectedItems.length - 1];
    
        this.selectedItems.length = 0;
        this.selectedItems.push(latest);
        this.selectedItem = latest;
        this.onChange(this.selectedItem);
        this.onValueChange.emit(this.selectedItem);
    }

    private onChange = (_) => {
        // This is intentional
    };

    private onTouched = () => {
        // This is intentional
    };
}
