import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { IActionInfo } from '../../../mazars-actions-dropdown-menu/action-info';
import { DeleteRowCellRendererParams } from '../delete-row-cell-renderer/delete-row-cell-renderer-params';
import { AccountOverviewType } from '@app/modules/account-mapping/account-mapping-proxies';
import { TSActionCellRendererParams } from './ts-actions-cell-renderer-params';

@Component({
    selector: 'ts-actions-cell-renderer',
    templateUrl: './ts-actions-cell-renderer.component.html',
    styleUrls: ['./ts-actions-cell-renderer.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TSActionsCellRendererComponent extends AppComponentBase implements ICellRendererAngularComp {
    isVisible: boolean;
    showDeleteRow: boolean;
    record: any;
    actionItems: IActionInfo[];
    api: GridApi;
    nodeId: any;
    isEditing = false;
    deleteRow: (params: any) => void;
    params: ICellRendererParams;

    canBeEdited = false;
    constructor(injector: Injector) {
        super(injector);
    }

    refresh(): boolean {
        return false;
    }

    onDestroy(): void {
        // to be implemented if needed
    }

    agInit(params: ICellRendererParams & TSActionCellRendererParams & DeleteRowCellRendererParams): void {
        this.showDeleteRow = params?.data?.accountOverviewType === AccountOverviewType.SourceAccount;
        this.deleteRow = params.deleteRow;
        this.isVisible = !params.isHidden && (params?.node?.rowPinned ? false : true);
        this.record = params.data;
        this.params = params;
        this.canBeEdited = this.isVisible && !params?.data?.isDisabled && params?.data?.accountOverviewType !== AccountOverviewType.SourceAccount;

        this.actionItems =
            params?.actionItems?.map(
                (ai) =>
                    ({
                        ...ai,
                        record: params.data,
                    } as IActionInfo)
            ) ?? [];

        this.nodeId = params?.node?.id;
        this.api = params?.api;
        this.api.addEventListener('cellEditingStarted', (event) => {
            this.isEditing = event?.node?.id === this.nodeId;
            this.focusFirstCell(event.api);
        });

        this.api.addEventListener('cellEditingStopped', (_) => {
            this.isEditing = false;
        });
    }

    focusFirstCell(api: any) {
        const instances = api.getCellEditorInstances();
        if (instances.length > 0) {
            const instance = instances[0];
            instance.focusIn();
        }
    }

    stopEditing(cancel: boolean) {
        this.api.stopEditing(cancel);
        if (cancel) {
            abp.event.trigger('app.actions-cell-renderer.stopEditing');
        }
    }

    onDeleteRowClick(event) {
        this.deleteRow(this.params);
    }
}
