import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    Output,
    QueryList,
    SecurityContext,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MazarsTemplateDirective } from '@app/modules/mazars-common/components/mazars-template/mazars-template.directive';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { DropdownChange } from './dropdown-change';
import { IActionInfo } from '../mazars-actions-dropdown-menu/action-info';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-mazars-dropdown',
    templateUrl: './mazars-dropdown.component.html',
    styleUrls: ['./mazars-dropdown.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsDropdownComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsDropdownComponent<TKey> extends AppComponentBase implements OnChanges, AfterContentInit, ControlValueAccessor {
    @ContentChildren(MazarsTemplateDirective) templateRefs: QueryList<MazarsTemplateDirective>;
    @ViewChild('dd', { static: true }) dd: Dropdown;

    @Input() uid!: string;
    @Input() options: IItem<TKey>[];
    @Input() optionFallback: (id) => Observable<IItem<TKey>> = null;
    @Input() hasEmpty: boolean;
    @Input() showClearOption = true;
    @Input() filter: boolean;
    @Input() virtualScrollItemSize = 30;
    @Input() placeholder: string;
    @Input() filterPlaceholder: string;
    @Input() isDisabled: boolean;
    @Input() appendTo = ''; // we should pass input parameter appendTo = 'body', if the dropdown appeared behind another component
    @Input() style: any;
    @Input() handleDeletedEntry = true;
    @Input() removeSelectedValueFromOptions = false;
    @Input() additionalFiltersCallback: Function = null;
    @Input() actionItems: IActionInfo[] | ((record: any) => IActionInfo[]);
    @Output() onSelectedItemChanged = new EventEmitter<DropdownChange<TKey>>();
    @Output() onSelectedItemCleared = new EventEmitter();
    @Output() onDropdownOpened = new EventEmitter();
    @Output() onFilterChanged = new EventEmitter();

    itemTemplate: TemplateRef<any>;
    selectedItemTemplate: TemplateRef<any>;
    selectItems: SelectItem<IItem<TKey>>[] = [];
    selectedItem: IItem<TKey>;
    writtenItemId: TKey;
    securityContext = SecurityContext;
    disabled: boolean;
    areOptionsVisible = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngAfterContentInit(): void {
        this.templateRefs.forEach((ref) => {
            switch (ref.identifier) {
                case 'item':
                    this.itemTemplate = ref.template;
                    break;
                case 'selectedItem':
                    this.selectedItemTemplate = ref.template;
                    break;
                default:
                    this.itemTemplate = ref.template;
                    break;
            }
        });
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: TKey): void {
        if (Number(obj) || String(obj) || obj === null) {
            this.writtenItemId = obj;
        } else if (obj?.hasOwnProperty('value')) {
            this.writtenItemId = obj['value'];
        }

        this.selectedItem = null;
        for (let i of this.selectItems) {
            if (i.value.id === obj) {
                this.selectedItem = i.value;
                return;
            }
        }

        this.includeDeletedEntry();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnChanges(): void {
        this.selectItems = this.convertOptionsToSelectItems(this.options);

        this.includeDeletedEntry();

        // original value still contained in list?
        this.selectedItem = null;
        for (let i of this.selectItems) {
            if (this.writtenItemId === i.value.id) {
                this.selectedItem = i.value;
                break;
            }
        }

        // default to first element
        if (!this.hasEmpty && this.selectedItem == null && this.selectItems.length > 0) {
            this.selectedItem = this.selectItems[0].value;
        }

        // need to notify
        if (this.writtenItemId !== this.selectedItem?.id) {
            this.onInputChange();
        }
    }

    onInputChange() {
        const previousWrittenItemId = this.writtenItemId;
        this.writtenItemId = this.selectedItem?.id;
        if ((this.writtenItemId === undefined && previousWrittenItemId !== null) || this.writtenItemId !== undefined) {
            this.onChange(this.writtenItemId);
            this.onSelectedItemChanged.emit({ oldValue: previousWrittenItemId, newValue: this.writtenItemId });
        }
    }

    onBlur() {
        this.onTouched();
    }

    onClear() {
        this.onSelectedItemCleared.emit();
    }

    onShow() {
        if (this.removeSelectedValueFromOptions) {
            let index = this.selectItems.findIndex((si) => si.value.id === this.selectedItem.id);
            if (index !== -1) {
                this.selectItems.splice(index, 1);
            }
        }
        this.areOptionsVisible = true;
        this.onDropdownOpened.emit();
    }

    onHide() {
        this.areOptionsVisible = false;
    }

    onFilterChange(event: any) {
        if (!event.filter || event.filter === '') {
            this.selectItems = this.convertOptionsToSelectItems(this.options);
        } else if (this.additionalFiltersCallback) {
            let filteredOptions = this.additionalFiltersCallback(event, this.options);
            this.selectItems = this.convertOptionsToSelectItems(filteredOptions);
        } else {
            this.onFilterChanged.emit(event);
        }
    }

    private convertOptionsToSelectItems(options: IItem<TKey>[]): SelectItem[] {
        return options
            ? options.map(
                  (o) =>
                      ({
                          value: o,
                          label: o.text,
                          disabled: o.isDisabled,
                      }) as SelectItem,
              )
            : [];
    }

    private includeDeletedEntry() {
        if (
            this.options &&
            this.writtenItemId &&
            this.options.filter((o) => o.id === this.writtenItemId).length === 0 &&
            this.selectItems.filter((si) => si.value.id === this.writtenItemId).length === 0 &&
            this.handleDeletedEntry
        ) {
            if (this.optionFallback !== null) {
                this.optionFallback(this.writtenItemId).subscribe((item) => {
                    if (item) {
                        const fallbackItem = {
                            value: { id: item.id, text: item.text },
                            label: item.text,
                        }
                        this.selectItems.push(fallbackItem);
                        this.selectedItem = fallbackItem.value;
                    } else {
                        this.addEntryGotDeletedRecord();
                    }
                });
            } else {
                this.addEntryGotDeletedRecord();
            }
        }
    }

    private addEntryGotDeletedRecord() {
        this.selectItems.push({
            value: { id: this.writtenItemId, text: this.l('Entry_Got_Deleted') },
            label: this.l('Entry_Got_Deleted'),
            styleClass: 'mz-red',
        });
    }
}
