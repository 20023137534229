import { DeclarationBucketOverallStatus } from '@app/modules/real-property-tax/real-property-tax-proxies';

export class DeclarationNoticeStatusSettings {

    static Icon_Circle = 'fa fa-circle';
    static Color_Approved = '#198754';
    static Color_Rejected = '#bc363d';
    static Color_ApprovalSuggested = '#a2cd5a';
    static Color_RejectionSuggested = '#dc3545';
    static Color_NotValidated = '#fd7e14';
    static Color_NoNotice = '#CDCDDE';

    static getIcon(): string {
        return DeclarationNoticeStatusSettings.Icon_Circle;
    }

    static getColor(status: DeclarationBucketOverallStatus): string {
        switch (status) {
            case DeclarationBucketOverallStatus.Approved: {
                return DeclarationNoticeStatusSettings.Color_Approved;
            }
            case DeclarationBucketOverallStatus.Rejected: {
                return DeclarationNoticeStatusSettings.Color_Rejected;
            }
            case DeclarationBucketOverallStatus.ApprovalSuggested: {
                return DeclarationNoticeStatusSettings.Color_ApprovalSuggested;
            }
            case DeclarationBucketOverallStatus.RejectionSuggested: {
                return DeclarationNoticeStatusSettings.Color_RejectionSuggested;
            }
            case DeclarationBucketOverallStatus.NotValidated: {
                return DeclarationNoticeStatusSettings.Color_NotValidated;
            }
            case DeclarationBucketOverallStatus.NoNotice: {
                return DeclarationNoticeStatusSettings.Color_NoNotice;
            }
            default: {
                return '';
            }
        }
    }

    static getIndicatorStatusIcon(status: DeclarationBucketOverallStatus, large: boolean = false): string {
        switch (status) {
            case DeclarationBucketOverallStatus.Approved: {
                return `fas fa-check-circle ${(large ? 'fa-xl' : '')}`;
            }
            case DeclarationBucketOverallStatus.Rejected: {
                return `fas fa-exclamation-circle ${(large ? 'fa-xl' : '')}`;
            }
            case DeclarationBucketOverallStatus.ApprovalSuggested:
            case DeclarationBucketOverallStatus.RejectionSuggested:
            case DeclarationBucketOverallStatus.NotValidated:
            case DeclarationBucketOverallStatus.NoNotice: {
                return `fas fa-question-circle ${(large ? 'fa-xl' : '')}`;
            }
            default: {
                return '';
            }
        }
    }

    static getIndicatorStatusColor(status: DeclarationBucketOverallStatus): string {
        switch (status) {
            case DeclarationBucketOverallStatus.Approved: {
                return DeclarationNoticeStatusSettings.Color_Approved;
            }
            case DeclarationBucketOverallStatus.Rejected: {
                return DeclarationNoticeStatusSettings.Color_Rejected;
            }
            case DeclarationBucketOverallStatus.ApprovalSuggested:
            case DeclarationBucketOverallStatus.RejectionSuggested:
            case DeclarationBucketOverallStatus.NotValidated:
            case DeclarationBucketOverallStatus.NoNotice: {
                return DeclarationNoticeStatusSettings.Color_NotValidated;
            }
            default: {
                return '';
            }
        }
    }

    static getStatusLocalizationKey(status: DeclarationBucketOverallStatus): string {
        switch (status) {
            case DeclarationBucketOverallStatus.Approved: {
                return 'DeclarationNoticeStatus_Approved';
            }
            case DeclarationBucketOverallStatus.Rejected: {
                return 'DeclarationNoticeStatus_Rejected';
            }
            case DeclarationBucketOverallStatus.ApprovalSuggested: {
                return 'DeclarationNoticeStatus_ApprovalSuggested';
            }
            case DeclarationBucketOverallStatus.RejectionSuggested: {
                return 'DeclarationNoticeStatus_RejectionSuggested';
            }
            case DeclarationBucketOverallStatus.NotValidated: {
                return 'DeclarationNoticeStatus_NotValidated';
            }
            case DeclarationBucketOverallStatus.NoNotice: {
                return 'DeclarationNoticeStatus_NoNotice';
            }
            default: {
                return 'DeclarationNoticeStatus_Unknown';
            }
        }
    }

}
