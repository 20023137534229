<div #container [ngClass]="'p-splitbutton p-component'" [ngStyle]="style" [class]="styleClass">
    <ng-container *ngIf="contentTemplate; else defaultButton">
        <button
            class="p-splitbutton-defaultbutton"
            type="button"
            pButton
            [icon]="icon"
            [iconPos]="iconPos"
            (click)="onDefaultButtonClick($event)"
            [disabled]="disabled"
            [attr.tabindex]="tabindex"
            [attr.data-testid]="'split_button_' + uid"
        >
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </button>
    </ng-container>
    <ng-template #defaultButton>
        <button
            #defaultbtn
            *ngIf="!isActionMenuButton"
            class="p-splitbutton-defaultbutton"
            type="button"
            pButton
            [icon]="icon"
            [iconPos]="iconPos"
            [label]="label"
            (click)="onDefaultButtonClick($event)"
            [disabled]="disabled"
            [attr.tabindex]="tabindex"
            [attr.data-testid]="'split_button_' + uid + '-' + label"
        ></button>
        <button
            #defaultbtn
            *ngIf="isActionMenuButton"
            type="button"
            pButton
            (click)="onDefaultButtonClick($event)"
            class="btn btn-secondary dropdown-toggle"
            class="btn btn-hover-brand btn-elevate-hover btn-icon btn-icon-md btn-action"
            [ngClass]="btnSize ? btnSize : 'btn-sm'"
            [attr.data-testid]="'split_button_' + uid"
        >
            <i
                class="mazarsActionsButtonIcon"
                aria-hidden="true"
                [ngStyle]="{ 'font-size': fontSize ? fontSize : 'initial' }"
                [className]="icon"
                [ngClass]="{ vertical: vertical }"
            ></i>
        </button>
    </ng-template>
    <button
        type="button"
        pButton
        class="p-splitbutton-menubutton"
        icon="pi pi-chevron-down"
        (click)="onDropdownButtonClick($event)"
        [disabled]="disabled"
        [attr.aria-label]="expandAriaLabel"
        [attr.data-testid]="'split_button_' + uid + '-' + expandAriaLabel"
    ></button>
    <p-tieredMenu
        #menu
        [popup]="true"
        [model]="model"
        [style]="menuStyle"
        [styleClass]="menuStyles"
        [appendTo]="appendTo"
        [showTransitionOptions]="showTransitionOptions"
        [hideTransitionOptions]="hideTransitionOptions"
        [attr.data-testid]="uid"
    ></p-tieredMenu>
</div>
