import {
    Component,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    TemplateRef,
    ContentChildren,
    QueryList,
    AfterContentInit,
} from '@angular/core';
import { MenuItem, PrimeTemplate } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';

type SplitButtonIconPosition = 'left' | 'right';

@Component({
    selector: 'mazars-splitt-button',
    templateUrl: './mazars-splitt-button.component.html',
    styleUrls: ['./mazars-splitt-button.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'p-element',
    },
})
export class MazarsSplittButtonComponent implements AfterContentInit {
    @Input() uid!: string;
    @Input() model: MenuItem[];
    @Input() icon: string;
    @Input() iconPos: SplitButtonIconPosition = 'left';
    @Input() label: string;
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @Output() onDropdownClick: EventEmitter<any> = new EventEmitter();
    @Output() onDropdownHide: EventEmitter<any> = new EventEmitter();
    @Output() onDropdownShow: EventEmitter<any> = new EventEmitter();
    @Input() style: any;
    @Input() styleClass: string;
    @Input() menuStyle: any;
    @Input() menuStyleClass: string;
    @Input() disabled: boolean;
    @Input() tabindex: number;
    @Input() appendTo: any;
    @Input() dir: string;
    @Input() expandAriaLabel: string;
    @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
    @Input() hideTransitionOptions = '.1s linear';
    @Input() isActionMenuButton: boolean;
    @Input() fontSize: string;
    @Input() btnSize: string;
    @Input() vertical: boolean;
    @ViewChild('container') containerViewChild: ElementRef;
    @ViewChild('defaultbtn') buttonViewChild: ElementRef;
    @ViewChild('menu') menu: TieredMenu;
    @ContentChildren(PrimeTemplate) templates: QueryList<any>;
    contentTemplate: TemplateRef<any>;

    get menuStyles() {
        return this.menuStyleClass + (this.model?.length ? '' : ' d-none');
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'content':
                    this.contentTemplate = item.template;
                    break;

                default:
                    this.contentTemplate = item.template;
                    break;
            }
        });
    }

    onDefaultButtonClick(event: Event) {
        this.onClick.emit(event);
    }

    onDropdownButtonClick(event: Event) {
        this.onDropdownClick.emit(event);
        this.menu.toggle({ currentTarget: this.containerViewChild.nativeElement, relativeAlign: this.appendTo == null });
        if (this.menu.visible) {
            this.onDropdownShow.emit();
        } else {
            this.onDropdownHide.emit();
        }
    }

    handleOnDropdownShow($event) {
        this.onDropdownShow.emit();
    }

    handlOnDropdownHide($event) {
        this.onDropdownHide.emit();
    }
}
