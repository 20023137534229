<p-autoComplete
    id="{{ uid }}"
    name="{{ uid }}"
    [attr.data-testid]="uid"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    field="name"
    [suggestions]="suggestedTags"
    (completeMethod)="filterTags($event)"
    placeholder="{{ placeholder }}"
    [minLength]="0"
    [multiple]="true"
    inputStyleClass="form-control"
    styleClass="width-percent-100"
    (onBlur)="onBlur()"
    [disabled]="isDisabled"
>
</p-autoComplete>
