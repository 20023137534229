<div class="d-flex" [attr.data-testid]="uid">
    <div class="d-flex align-items-center">
        {{ dueDate }}
    </div>
    <div class="ml-3 d-flex align-items-center">
        <i
            aria-hidden="true"
            *ngIf="icon"
            class="{{ icon }}"
            style="font-size: large !important"
            [style.color]="iconColor"
            aria-hidden="true"
            [mazarsTooltip]="iconTooltip"
            [escape]="false"
        ></i>
    </div>
</div>
