import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export abstract class SurveyComponentBase extends AppComponentBase implements OnDestroy {
    subscriptions: Subscription[] = [];

    protected constructor(injector: Injector) {
        super(injector);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.onDestroy();
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    abstract onDestroy(): void;
}
