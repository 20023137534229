import { NgModule } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReferenceDataResolver } from '@app/modules/dac6/resolvers/reference-data.resolver';
import { ReportReferenceDataResolver } from '@app/modules/dac6/resolvers/report-reference-data.resolver';
import { MappingReferenceDataResolver } from './modules/account-mapping/resolvers/mapping-reference-data.resolver';
import { EconomicUnitReferenceDataResolver } from './modules/real-property-tax/resolvers/economic-unit-reference-data.resolver';
import { FormReferenceDataResolver } from './modules/iro/resolvers/form-reference-data.resolver';
import { ProjectReferenceDataResolver } from './modules/pillar2/resolvers/project-reference-data.resolver';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'details',
                loadChildren: () => import('./details/details.module').then((m) => m.DetailsModule), //Lazy load details module
                data: { preload: true },
            },
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'master-data-management',
                        loadChildren: () => import('./modules/master-data-management/master-data-management.module').then((m) => m.MasterDataManagementModule),
                        resolve: { referenceDataLoaded: ReferenceDataResolver },
                    },
                    {
                        path: 'dac6',
                        loadChildren: () => import('./modules/dac6/dac6.module').then((m) => m.Dac6Module),
                        resolve: {
                            referenceDataLoaded: ReferenceDataResolver,
                            reportReferenceDataLoaded: ReportReferenceDataResolver,
                        },
                    },
                    {
                        path: 'real-property-tax',
                        loadChildren: () => import('./modules/real-property-tax/real-property-tax.module').then((m) => m.RealPropertyTaxModule),
                        resolve: { economicUnitReferenceDataLoaded: EconomicUnitReferenceDataResolver },
                    },
                    {
                        path: 'tax-analytics',
                        loadChildren: () => import('./modules/tax-analytics/tax-analytics.module').then((m) => m.TaxAnalyticsModule),
                    },
                    {
                        path: 'account-mapping',
                        loadChildren: () => import('./modules/account-mapping/account-mapping.module').then((m) => m.AccountMappingModule),
                        resolve: { mappingReferenceDataLoaded: MappingReferenceDataResolver },
                    },
                    {
                        path: 'iro',
                        loadChildren: () => import('./modules/iro/iro.module').then((m) => m.IroModule),
                        resolve: {
                            formReferenceDataLoaded: FormReferenceDataResolver,
                        },
                    },
                    {
                        path: 'taskit',
                        loadChildren: () => import('./modules/checklists/checklists.module').then((m) => m.ChecklistsModule),
                    },
                    {
                        path: 'pillar2',
                        loadChildren: () => import('./modules/pillar2/pillar2.module').then((m) => m.Pillar2Module),
                        resolve: { projectReferenceDataLoaded: ProjectReferenceDataResolver },
                    },
                    {
                        path: '**',
                        redirectTo: 'notifications',
                    },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }

            if (event instanceof NavigationCancel) {
                spinnerService.hide();
            }
        });
    }
}
