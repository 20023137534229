import { Component, Injector, Input, OnInit } from '@angular/core';
import { IFeedbackDto, IListResultDtoOfSubmissionOverviewDto, ISubmissionOverviewDto } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';
import { finalize, take } from 'rxjs/operators';
import { ISubmissionItem, ISubmissionsServiceProxy, IFeedbacksServiceProxy } from './submission-interfaces';

@Component({
    selector: 'mazars-submission',
    templateUrl: './mazars-submission.component.html',
})
export class MazarsSubmissionComponent extends AppComponentBase implements OnInit {
    @Input({ required: true }) uid: string;
    @Input() submissionsServiceProxy: ISubmissionsServiceProxy;
    @Input() feedbackServiceProxy: IFeedbacksServiceProxy;
    @Input() referenceId: number;
    @Input() submittedTo: string;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    submissions: ISubmissionItem[] = [];
    isSingle: boolean;
    submission: ISubmissionItem;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.getSubmissionData();
    }

    loadFeedbackData(submission: ISubmissionItem) {
        if (submission) {
            this.showMainSpinner();
            this.feedbackServiceProxy
                .getFinalFeedback(submission.id, this.referenceId)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.hideMainSpinner();
                    })
                )
                .subscribe((feedback: IFeedbackDto) => {
                    submission.feedback = feedback;
                });
        }
    }

    preventAccordionClickOnDropdown(event: any): void {
        event.stopPropagation();
    }

    private getSubmissionData() {
        this.submissionsServiceProxy
            .getAll(this.referenceId)
            .pipe(take(1))
            .subscribe((resultList: IListResultDtoOfSubmissionOverviewDto) => {
                if (resultList && resultList.items && resultList.items.length > 0) {
                    if (resultList.items.length === 1) {
                        this.isSingle = true;
                        let submission = resultList.items[0];
                        this.submission = this.initSubmission(submission);
                        this.loadFeedbackData(this.submission);
                    } else {
                        resultList.items.forEach((submission) => {
                            this.submissions.push(this.initSubmission(submission));
                        });
                        this.submissions[this.submissions.length - 1].isSelected = true;
                    }
                }
            });
    }

    private initSubmission(submission: ISubmissionOverviewDto): ISubmissionItem {
        return this.submissionOverviewToSubmissionItem(submission.id, submission.transactionId, submission.submissionDate, '', false, false);
    }

    private submissionOverviewToSubmissionItem(
        id: number,
        transactionId: string,
        submissionDate: DateTime,
        header: string,
        isSelected: boolean,
        disabled?: boolean
    ): ISubmissionItem {
        return {
            id: id,
            referenceId: this.referenceId,
            transactionId: transactionId,
            submissionDate: submissionDate,
            header: header,
            isSelected: isSelected,
            submittedTo: this.submittedTo,
            disabled: disabled,
        } as ISubmissionItem;
    }
}
