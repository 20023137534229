import { Injector, Component, Input, ViewChild, ViewEncapsulation, OnInit, EventEmitter, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Dialog } from 'primeng/dialog';
import { MazarsReorderComponent } from '../mazars-reorder/mazars-reorder.component';
import { CommonGridConfigurationFacade } from '../../state-management/facades/common-grid-configuration.facade';
import { IItem } from '../../interfaces/item.interface';
import { TableStateService } from '@shared/utils/table-state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    templateUrl: './mazars-export-overview-dialog.component.html',
    selector: 'mazars-export-overview-dialog',
    styleUrls: ['./mazars-export-overview-dialog.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsExportOverviewDialogComponent extends AppComponentBase implements OnInit {
    @ViewChild('exportDialog', { static: true }) exportDialog: Dialog;
    @ViewChild('mazarsReorder', { static: true }) mazarsReorder: MazarsReorderComponent;

    @Input({ required: true }) uid: string;
    @Input() isVisible: boolean;
    @Input() tableId: string;
    @Input() gridConfigurationFacade: CommonGridConfigurationFacade;
    @Input() hasArchivableElements = false;
    @Output() onClicked = new EventEmitter();

    filters: any;
    radioItems: IItem<boolean>[];
    form: FormGroup;
    sorting: string;

    constructor(
        injector: Injector,
        private tableStateService: TableStateService,
        private fb: FormBuilder) {
        super(injector);
    }

    getRadioItems(isDisabled): IItem<boolean>[] {
        return [
            {
                id: true,
                text: this.l('ExportOverviewSetting_Radio_AllEntries'),
            },
            {
                id: false,
                text: this.l('ExportOverviewSetting_Radio_ApplyFilter'),
                isDisabled: isDisabled,
            },
        ];
    }

    ngOnInit(): void {
        this.subscriptions.push(this.gridConfigurationFacade?.sorting$?.subscribe((sorting) => {
            this.sorting = sorting;
        }));

        this.radioItems = this.getRadioItems(true);
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            exportAllEntries: [true, [Validators.required]],
            showArchived: [false],
        });
    }

    showHide() {
        this.isVisible = !this.isVisible;

        if (this.isVisible) {
            this.filters = this.tableStateService.getFilters(this.tableId);
            const hasAppliedFilters = this.tableStateService.hasAppliedFilters(this.filters);
            this.radioItems = this.getRadioItems(!hasAppliedFilters);
            this.form.patchValue({ exportAllEntries: true, showArchived: false });
        }
    }

    onClick(){
        let input = { 
                    exportAllEntries: this.form.controls.exportAllEntries.value,
                    columns: this.mazarsReorder.getSelectedColumnNames(),
                    settings: JSON.stringify({ ...this.filters, sorting: this.sorting, showArchived: this.form.controls.showArchived.value}),
                    type: this.tableId,
                };

        this.onClicked.emit(input);
        this.showHide();

        this.message.info(this.l('BackgroundJobStarted'));
    }
}
