<p-dialog #reorderDialog position="right" [(visible)]="isVisible" styleClass="mazars-reorder-dialog" (onHide)="undoChanges()" [attr.data-testid]="uid">
    <ng-template pTemplate="header">{{ l('UserGridSetting_Heading') }}</ng-template>
    <p>{{ l('UserGridSetting_Description') }}</p>
    <mazars-reorder
        #mazarsReorder
        [commonGridConfigurationFacade]="this.gridConfigurationFacade"
        [tableId]="tableId"
        (configChanged)="onConfigChanged($event)"
        (onSavedConfig)="onSavedConfig()"
        [isVisible]="isVisible"
    ></mazars-reorder>
    <ng-template pTemplate="footer">
        <div class="row justify-content-between flex-wrap">
            <div class="col-12 col-lg-6 d-flex justify-content-end justify-content-lg-start align-items-end flex-wrap">
                <app-mazars-button
                    [uid]="uid + '_reset'"
                    [inverted]="true"
                    [label]="l('UserGridSetting_Reset')"
                    (onClick)="reset()"
                ></app-mazars-button>
            </div>
            <div class="col-12 col-lg-6 d-block d-md-flex justify-content-end flex-wrap">
                <app-mazars-button styleClass="mt-1" [uid]="uid + '_cancel'" [inverted]="true" [label]="l('UserGridSetting_Undo')" (onClick)="undoChanges()"></app-mazars-button>
                <app-mazars-button styleClass="mt-1" [uid]="uid + '_ok'" [label]="l('UserGridSetting_Save')" (onClick)="saveConfig()" [disabled]="!canSave"></app-mazars-button>
            </div>
        </div>

    </ng-template>
</p-dialog>
