<mz-dialog
    #mazarsBulkDeleteModalComponent
    [uid]="uid"
    [title]="l('DeleteAll')"
    [canSubmit]="true"
    [submitButtonLabel]="l('Yes')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <p class="font-size-h6">{{ description }}</p>
</mz-dialog>
