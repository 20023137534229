import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ReferenceDataService } from '@app/modules/mazars-common/services/reference-data.service';

@Injectable()
export class ReferenceDataResolver implements Resolve<any> {
    constructor(private referenceDataService: ReferenceDataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.referenceDataService.load();
        return true;
    }
}
