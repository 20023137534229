import { Injectable } from '@angular/core';
import { MazarsMessageService } from '@app/shared/layout/toast/mazars-message.service';
@Injectable({
    providedIn: 'root',
})
export class MazarsNotifyService {
    constructor(private messageService: MazarsMessageService) {}

    info(message: string, title?: string, options?: any) {
        this.messageService.add({ key: 'toast', severity: 'info', summary: title, detail: message, options });
    }
    success(message: string, title?: string, options?: any) {
        this.messageService.add({ key: 'toast', severity: 'success', summary: title, detail: message, options });
    }
    warn(message: string, title?: string, options?: any) {
        this.messageService.add({ key: 'toast', severity: 'warn', summary: title, detail: message, options });
    }
    error(message: string, title?: string, options?: any) {
        this.messageService.add({ key: 'toast', severity: 'error', summary: title, detail: message, options });
    }
}
