import { Component, Injector, Input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { IResultMessageDto, IValidateResultDto } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'mazars-validation-result',
    templateUrl: './mazars-validation-result.component.html',
    styleUrls: ['./mazars-validation-result.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsValidationResultComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() isVisible = true;
    @Input() showSuccessMessage = true;
    isValid = true;
    hasErrors: boolean;
    hasInfos: boolean;
    hasWarnings: boolean;
    description: string;
    securityContext = SecurityContext;

    validationMessageSuccess: IResultMessageDto = {} as IResultMessageDto;
    validationMessageErrors: IResultMessageDto[] = [];
    validationMessageInfos: IResultMessageDto[] = [];
    validationMessageWarnings: IResultMessageDto[] = [];

    constructor(injector: Injector) {
        super(injector);
    }

    setValidationResult(result: IValidateResultDto) {
        this.clean();
        result?.messages?.forEach((message) => {
            if (message.type === 'error') {
                this.validationMessageErrors.push(message);
                if (!this.hasErrors) {
                    this.hasErrors = true;
                }
            }

            if (message.type === 'info') {
                this.validationMessageInfos.push(message);
                if (!this.hasInfos) {
                    this.hasInfos = true;
                }
            }

            if (message.type === 'warning') {
                this.validationMessageWarnings.push(message);
                if (!this.hasWarnings) {
                    this.hasWarnings = true;
                }
            }

            if (message.type === 'success') {
                this.validationMessageSuccess = message;
            }
        });

        this.isValid = result?.isValid && !this.hasErrors;
        this.description = result?.description;
    }

    private clean() {
        this.validationMessageSuccess = {} as IResultMessageDto;
        this.validationMessageErrors = [];
        this.validationMessageInfos = [];
        this.validationMessageWarnings = [];
        this.hasErrors = false;
        this.hasInfos = false;
        this.hasWarnings = false;
    }
}
