<form [formGroup]="form">
    <ng-container formArrayName="values">
        <ng-container *ngFor="let organisationName of getControls(); let i = index">
            <div class="row" [formGroup]="organisationName">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="{{ uid }}-Name-{{ i }}">{{ l('Label_OrganisationName') }} *</label>
                        <app-mazars-input-text [uid]="uid + 'Name' + i" formControlName="name"> </app-mazars-input-text>
                    </div>
                </div>
                <div class="col-lg-5" *ngIf="!partyConfig || partyConfig?.showLanguage">
                    <div class="form-group">
                        <label for="{{ uid }}-Language-{{ i }}">{{ l('Language') }} *</label>
                        <app-mazars-dropdown
                            [uid]="uid + 'Language' + i"
                            [options]="languages"
                            [filter]="true"
                            [hasEmpty]="true"
                            formControlName="language"
                        >
                        </app-mazars-dropdown>
                    </div>
                </div>
                <div class="col-1 align-self-center">
                    <i
                        role="button"
                        *ngIf="organisationNames.length > 1 && !isDisabled"
                        class="flaticon-delete"
                        (click)="removeOrganisationName(i)"
                        aria-hidden="true"
                    ></i>
                </div>
            </div>
        </ng-container>
        <button *ngIf="!isDisabled" type="button" class="btn btn-primary blue mb-4" (click)="addOrganisationName()">
            {{ l('Button_AddName') }}
        </button>
    </ng-container>
</form>
