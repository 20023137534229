import { Component, Input, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Dialog } from 'primeng/dialog';
import { MazarsReorderComponent } from '../mazars-reorder/mazars-reorder.component';
import { CommonGridConfigurationFacade } from '../../state-management/facades/common-grid-configuration.facade';

@Component({
    templateUrl: './mazars-reorder-dialog.component.html',
    selector: 'mazars-reorder-dialog',
    styleUrls: ['./mazars-reorder-dialog.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsReorderDialogComponent extends AppComponentBase {
    @ViewChild('reorderDialog', { static: true }) reorderDialog: Dialog;
    @ViewChild('mazarsReorder', { static: false }) mazarsReorder: MazarsReorderComponent;

    @Input({ required: true }) uid: string;
    @Input() isVisible: boolean;
    @Input() tableId: string;
    @Input() gridConfigurationFacade: CommonGridConfigurationFacade;

    canSave = false;

    showHide() {
        this.isVisible = !this.isVisible;
        this.canSave = false;
    }

    undoChanges() {
        this.mazarsReorder.getData();
    }

    saveConfig() {
        this.mazarsReorder.saveConfig();
    }

    reset() {
        this.mazarsReorder.resetConfig();
    }

    onConfigChanged($event) {
        if (this.isVisible && $event?.source !== 'api') {
            this.canSave = true;
        }
    }

    onSavedConfig() {
        this.showHide();
    }
}
