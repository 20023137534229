import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-mazars-task-status-dropdown',
    templateUrl: './mazars-task-status-dropdown.component.html',
    styleUrls: ['mazars-task-status-dropdown.component.css'],
})
export class MazarsTaskStatusDropdownComponent extends AppComponentBase implements OnInit {
    @Input({ required: true }) uid: string;
    @Input() status: string;
    @Input() possibleStatuses: string[];
    @Output() updateStatus = new EventEmitter<string>();
    form: FormGroup;
    allStatuses: IItem<string>[] = [];
    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.possibleStatuses && this.possibleStatuses.length > 0) {
            this.allStatuses = [];
            this.possibleStatuses.forEach((ps) => {
                let newStatus = { id: ps, text: this.l(ps), iconColor: ps.toLocaleLowerCase() + '-icon' };
                this.allStatuses.push(newStatus);
            });
        }
        this.form = this.fb.group({
            taskStatus: [this.status],
        });
    }

    onStatusChange() {
        if (this.status !== this.form.get('taskStatus').value) {
            this.updateStatus.emit(this.form.get('taskStatus').value);
        }
    }
}
