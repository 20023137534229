import { Component, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-mazars-yearpicker',
    templateUrl: './mazars-yearpicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsYearpickerComponent,
        },
    ],
})
export class MazarsYearpickerComponent implements ControlValueAccessor, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() placeholder: string;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() minYear?: number;
    @Input() showClear = false;
    @Input() valueAsNumber: boolean = false;

    value: Date;
    isDisabled: boolean;
    yearRange: string;
    private minYearRange = 1900;
    private yearThreshold = 100;

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    ngOnChanges() {
        const today = new Date();
        const maxYearRange = this.maxDate instanceof Date ? this.maxDate.getFullYear() : today.getFullYear() + this.yearThreshold;
        const minYearRange = this.minDate instanceof Date ? this.minDate.getFullYear() : this.minYearRange;
        this.minYearRange = this.minYear ? this.minYear : minYearRange
        this.yearRange = `${this.minYearRange}:${maxYearRange}`;
    }

    writeValue(obj: any): void {
        if(obj) {
            if (obj instanceof DateTime) {
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const localDate = DateTime.fromISO(obj.toString(), {zone: timezone});
                this.value = localDate.toJSDate();
            } else if(obj instanceof Date) {
                this.value = obj;
            } else if(Number.isInteger(obj)) {
                this.value = new Date(obj, 1, 1);
            } else {
                this.value = obj;
            }
        } else {
            this.value = null;
        }

        this.onValueChange();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onValueChange() {
        if (this.value == null) {
            this.onChange(null);
        } else {
            if(this.valueAsNumber) {
                this.onChange(this.value.getFullYear());
            } else {
                const utcDateString = new Date(this.value.getTime() - this.value.getTimezoneOffset() * 60000).toISOString();
                this.onChange(utcDateString);
            }
        }
    }

    onClearClick() {
        this.onChange(null);
        this.onTouched();
    }

    onBlur() {
        this.onTouched();
    }
}
