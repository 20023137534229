import { RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';
import { MazarsAbpHttpInterceptor } from './mazarsAbpHttpInterceptor';
import {
    DatevImportServiceProxy,
    ImportPartiesServiceProxy,
    LegalEntitiesServiceProxy,
    NaturalPeopleServiceProxy,
    PartiesServiceProxy,
    ReferenceDataManagementServiceProxy,
    SyncEnvironmentProfilesServiceProxy,
} from '@app/modules/master-data-management/master-data-management-proxies';
import {
    RealPropertyTaxChancelleryServiceProxy,
    DebugDeclarationsServiceProxy,
    DeclarationCalculationsServiceProxy,
    DeclarationFeedbacksServiceProxy,
    DeclarationFileValidationServiceProxy,
    DeclarationNoticesServiceProxy,
    DeclarationReferenceDataServiceProxy,
    DeclarationsBulkServiceProxy,
    DeclarationsExcelImporterServiceProxy,
    DeclarationsProcessStatesServiceProxy,
    DeclarationsServiceProxy,
    DeclarationSubmissionsServiceProxy,
    DeclarationTaxNumberConverterServiceProxy,
    EconomicUnitAttachmentsServiceProxy,
    EconomicUnitsBulkServiceProxy,
    EconomicUnitsCustomGrantsServiceProxy,
    EconomicUnitsServiceProxy,
    ImportEconomicUnitsServiceProxy,
    RealPropertyTaxAdminSettingsServiceProxy,
    RealPropertyTaxTaxOfficeProfilesServiceProxy,
    RealPropertyTaxTaxOfficeReferenceDataServiceProxy,
    RealPropertyTaxUserManualsServiceProxy,
    SupportFilesServiceProxy,
    DeclarationExcelExportServiceProxy,
    DeadlineReferenceDataServiceProxy,
    DeadlineServiceProxy,
} from '@app/modules/real-property-tax/real-property-tax-proxies';
import {
    AccountServiceProxy,
    AuditLogServiceProxy,
    CachingServiceProxy,
    ChatServiceProxy,
    CommonLookupServiceProxy,
    DashboardCustomizationServiceProxy,
    DemoUiComponentsServiceProxy,
    DynamicEntityPropertyDefinitionServiceProxy,
    DynamicEntityPropertyServiceProxy,
    DynamicEntityPropertyValueServiceProxy,
    DynamicPropertyServiceProxy,
    DynamicPropertyValueServiceProxy,
    EditionServiceProxy,
    FriendshipServiceProxy,
    HostDashboardServiceProxy,
    HostSettingsServiceProxy,
    InstallServiceProxy,
    InvoiceServiceProxy,
    LanguageServiceProxy,
    LicensesServiceProxy,
    MazarsLoginServiceProxy,
    MazarsNotificaionServiceProxy,
    MazarsTenantServiceProxy,
    NotificationServiceProxy,
    OrganizationUnitServiceProxy,
    PaymentServiceProxy,
    PayPalPaymentServiceProxy,
    PermissionServiceProxy,
    ProfileServiceProxy,
    RoleServiceProxy,
    SessionServiceProxy,
    StripePaymentServiceProxy,
    SubscriptionServiceProxy,
    TenantDashboardServiceProxy,
    TenantRegistrationServiceProxy,
    TenantServiceProxy,
    TenantSettingsServiceProxy,
    TimingServiceProxy,
    TokenAuthServiceProxy,
    TwitterServiceProxy,
    UiCustomizationSettingsServiceProxy,
    UserDelegationServiceProxy,
    UserLinkServiceProxy,
    UserLoginServiceProxy,
    UserServiceProxy,
    WebhookEventServiceProxy,
    WebhookSendAttemptServiceProxy,
    WebhookSubscriptionServiceProxy,
    WebLogServiceProxy,
} from './service-proxies';
import {
    AccountMappingAdminSettingsServiceProxy,
    MappingCustomGrantsServiceProxy,
    MappingAttachmentsServiceProxy,
    MappingReferenceDataServiceProxy,
    TargetStructureAccountsServiceProxy,
    MappingTargetStructureAccountsServiceProxy,
    MappingRulesServiceProxy,
    MappingsBulkServiceProxy,
    TargetStructuresBulkServiceProxy,
    AccountMappingUserManualsServiceProxy,
} from '@app/modules/account-mapping/account-mapping-proxies';
import {
    ChecklistUserGridConfigurationServiceProxy,
    WorkflowsServiceProxy,
    WorkflowCustomGrantsServiceProxy,
    ChecklistAdminSettingsServiceProxy,
    WorkflowProcessStateServiceProxy,
    WorkflowTasksServiceProxy,
    WorkflowLookupServiceProxy,
    WorkflowReferenceDataServiceProxy,
    WorkflowsBulkServiceProxy,
    QuestionnairesBulkServiceProxy,
    WorkflowTemplatesBulkServiceProxy,
    ChecklistsChancelleryServiceProxy,
} from '@app/modules/checklists/checklists-proxies';
import {
    ReportReferenceDataServiceProxy,
    ReportAttachmentsServiceProxy,
    DisclosingPartyServiceProxy,
    RelevantTaxpayersServiceProxy,
    IntermediariesServiceProxy,
    AffectedPersonsServiceProxy,
    UnspecifiedPartyServiceProxy,
    AssociatedEnterprisesServiceProxy,
    ReportSubmissionsServiceProxy,
    ReportFeedbacksServiceProxy,
    ReportSurveysServiceProxy,
    OrganizationChartServiceProxy,
    Dac6AdminSettingsServiceProxy,
    Dac6TaxOfficeProfilesServiceProxy,
    Dac6TaxOfficeReferenceDataServiceProxy,
    ReportCustomGrantsServiceProxy,
    ReportFileGeneratorServiceProxy,
    ReportDataNLServiceProxy,
    ReportDataSKServiceProxy,
    ReportDataFRServiceProxy,
    ReportDataSEServiceProxy,
    ReportDataLUServiceProxy,
    Dac6UserManualsServiceProxy,
    ReportsBulkServiceProxy,
    SurveysBulkServiceProxy,
} from '@app/modules/dac6/dac6-proxies';
import {
    BillingServiceProxy,
    HostBillingServiceProxy,
    UserManualsServiceProxy,
    GlobalActionsServiceProxy,
    NewsServiceProxy,
    ReferenceDataServiceProxy,
    UserInterfaceServiceProxy,
    NotificationSettingsServiceProxy,
} from './general-proxies';
import {
    DynamicTemplateReferenceDataServiceProxy,
    DynamicTemplatesServiceProxy,
    FormsServiceProxy,
    FormsCustomGrantsServiceProxy,
    FormReferenceDataServiceProxy,
    FormProcessStatesServiceProxy,
    IroAdminSettingsServiceProxy,
    FormsBulkServiceProxy,
} from '@app/modules/iro/iro-proxies';
import {
    Pillar2WidgetServiceProxy,
    ProjectLookupServiceProxy,
    Pillar2UserManualsServiceProxy,
    ProjectsReferenceDataServiceProxy,
    ProjectCustomGrantsServiceProxy,
    ProjectsBulkServiceProxy
} from '@app/modules/pillar2/pillar2-proxies';

@NgModule({
    providers: [
        RealPropertyTaxChancelleryServiceProxy,
        LegalEntitiesServiceProxy,
        EconomicUnitsServiceProxy,
        EconomicUnitsBulkServiceProxy,
        EconomicUnitAttachmentsServiceProxy,
        AuditLogServiceProxy,
        CachingServiceProxy,
        ChatServiceProxy,
        CommonLookupServiceProxy,
        EditionServiceProxy,
        FriendshipServiceProxy,
        HostSettingsServiceProxy,
        InstallServiceProxy,
        LanguageServiceProxy,
        NotificationServiceProxy,
        NotificationSettingsServiceProxy,
        MazarsNotificaionServiceProxy,
        OrganizationUnitServiceProxy,
        PermissionServiceProxy,
        ProfileServiceProxy,
        RoleServiceProxy,
        SessionServiceProxy,
        TenantServiceProxy,
        TenantDashboardServiceProxy,
        TenantSettingsServiceProxy,
        TimingServiceProxy,
        UserServiceProxy,
        UserLinkServiceProxy,
        UserLoginServiceProxy,
        WebLogServiceProxy,
        AccountServiceProxy,
        TokenAuthServiceProxy,
        TenantRegistrationServiceProxy,
        HostDashboardServiceProxy,
        PaymentServiceProxy,
        DemoUiComponentsServiceProxy,
        InvoiceServiceProxy,
        SubscriptionServiceProxy,
        InstallServiceProxy,
        UiCustomizationSettingsServiceProxy,
        PayPalPaymentServiceProxy,
        StripePaymentServiceProxy,
        DashboardCustomizationServiceProxy,
        WebhookEventServiceProxy,
        WebhookSubscriptionServiceProxy,
        WebhookSendAttemptServiceProxy,
        UserDelegationServiceProxy,
        DynamicPropertyServiceProxy,
        DynamicEntityPropertyDefinitionServiceProxy,
        DynamicEntityPropertyServiceProxy,
        DynamicPropertyValueServiceProxy,
        DynamicEntityPropertyValueServiceProxy,
        TwitterServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: MazarsAbpHttpInterceptor, multi: true },
        MazarsTenantServiceProxy,
        MazarsLoginServiceProxy,
        ReferenceDataManagementServiceProxy,
        ReportReferenceDataServiceProxy,
        ReportAttachmentsServiceProxy,
        NaturalPeopleServiceProxy,
        PartiesServiceProxy,
        DisclosingPartyServiceProxy,
        RelevantTaxpayersServiceProxy,
        IntermediariesServiceProxy,
        AffectedPersonsServiceProxy,
        UnspecifiedPartyServiceProxy,
        AssociatedEnterprisesServiceProxy,
        ReportSubmissionsServiceProxy,
        ReportFeedbacksServiceProxy,
        ReportSurveysServiceProxy,
        OrganizationChartServiceProxy,
        Dac6AdminSettingsServiceProxy,
        Dac6TaxOfficeProfilesServiceProxy,
        Dac6TaxOfficeReferenceDataServiceProxy,
        ReportCustomGrantsServiceProxy,
        DeclarationsServiceProxy,
        DebugDeclarationsServiceProxy,
        ReportFileGeneratorServiceProxy,
        ReportDataNLServiceProxy,
        ReportDataSKServiceProxy,
        ReportDataFRServiceProxy,
        ReportDataSEServiceProxy,
        ReportDataLUServiceProxy,
        AccountMappingAdminSettingsServiceProxy,
        MappingCustomGrantsServiceProxy,
        ImportPartiesServiceProxy,
        MappingAttachmentsServiceProxy,
        MappingReferenceDataServiceProxy,
        RealPropertyTaxAdminSettingsServiceProxy,
        EconomicUnitsCustomGrantsServiceProxy,
        TargetStructureAccountsServiceProxy,
        MappingTargetStructureAccountsServiceProxy,
        SyncEnvironmentProfilesServiceProxy,
        RealPropertyTaxTaxOfficeProfilesServiceProxy,
        RealPropertyTaxTaxOfficeReferenceDataServiceProxy,
        DeclarationReferenceDataServiceProxy,
        ImportEconomicUnitsServiceProxy,
        DeclarationsProcessStatesServiceProxy,
        DatevImportServiceProxy,
        SupportFilesServiceProxy,
        DeclarationCalculationsServiceProxy,
        DeclarationTaxNumberConverterServiceProxy,
        DeclarationsExcelImporterServiceProxy,
        DeclarationFileValidationServiceProxy,
        DeclarationNoticesServiceProxy,
        BillingServiceProxy,
        DeclarationSubmissionsServiceProxy,
        DeclarationFeedbacksServiceProxy,
        HostBillingServiceProxy,
        LicensesServiceProxy,
        UserManualsServiceProxy,
        RealPropertyTaxUserManualsServiceProxy,
        AccountMappingUserManualsServiceProxy,
        Pillar2UserManualsServiceProxy,
        Dac6UserManualsServiceProxy,
        DeclarationsBulkServiceProxy,
        DynamicTemplateReferenceDataServiceProxy,
        DynamicTemplatesServiceProxy,
        FormsServiceProxy,
        FormsCustomGrantsServiceProxy,
        FormReferenceDataServiceProxy,
        FormProcessStatesServiceProxy,
        FormsBulkServiceProxy,
        IroAdminSettingsServiceProxy,
        MappingRulesServiceProxy,
        MappingsBulkServiceProxy,
        TargetStructuresBulkServiceProxy,
        ReportsBulkServiceProxy,
        SurveysBulkServiceProxy,
        ChecklistUserGridConfigurationServiceProxy,
        WorkflowsServiceProxy,
        WorkflowCustomGrantsServiceProxy,
        ChecklistAdminSettingsServiceProxy,
        WorkflowProcessStateServiceProxy,
        WorkflowTasksServiceProxy,
        WorkflowLookupServiceProxy,
        GlobalActionsServiceProxy,
        WorkflowReferenceDataServiceProxy,
        WorkflowsBulkServiceProxy,
        WorkflowTemplatesBulkServiceProxy,
        QuestionnairesBulkServiceProxy,
        NewsServiceProxy,
        ReferenceDataServiceProxy,
        UserInterfaceServiceProxy,
        Pillar2WidgetServiceProxy,
        ProjectLookupServiceProxy,
        ProjectsReferenceDataServiceProxy,
        ProjectCustomGrantsServiceProxy,
        ProjectsBulkServiceProxy,
        DeclarationExcelExportServiceProxy,
        DeadlineReferenceDataServiceProxy,
        DeadlineServiceProxy,
        ChecklistsChancelleryServiceProxy,
    ],
})
export class ServiceProxyModule {}
