import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'luxonLocalFormat' })
export class LuxonLocalFormatPipe implements PipeTransform {
    transform(value: DateTime, format: string) {
        if (!value) {
            return '';
        }

        return value.toLocal().toFormat(format);
    }
}
