import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProcessStatePermission } from '@app/modules/mazars-common/components/mazars-process-state/process-state-permissions';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    IAdminSettingsDto,
    ICustomGrantsForPermissionInput,
    ICustomGrantsForPermissionWithAccessRestrictionDataInput,
    ICustomGrantsWithAccessRestrictionInput,
    IEntityPermissionDto,
    IUserOrOrganizationUnitInput,
} from '@shared/service-proxies/common-interfaces';
import { IAdminSettingsService, IAdminSettingsServiceToken } from '@shared/service-proxies/interfaces/IAdminSettingsService';
import { ICustomGrantsServiceProxy } from '@shared/service-proxies/interfaces/ICustomGrantsServiceProxy';
import { Dialog } from 'primeng/dialog';
import { Observable, of } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FourEyesPrinciplePermissionsBaseService } from './four-eyes-principle-permissions-base.service';
import { MazarsCustomPermissionModel } from './mazars-custom-permission-model';

@Component({
    selector: 'app-mazars-custom-permissions',
    templateUrl: './mazars-custom-permissions.component.html',
    styleUrls: ['./mazars-custom-permissions.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsCustomPermissionsComponent extends AppComponentBase implements OnInit, OnChanges {
    @ViewChild('possibleUsersDialog', { static: true }) possibleUsersDialog: Dialog;
    @Input() isCreate: boolean;
    @Input() entityId: number;
    @Input() entityName: string;
    @Input() createOutOfTemplate: boolean;
    @Input() viewOnlyMode = false;
    @Input() isAccessRestrictionActive: boolean;
    @Input() moduleLocalizationSourceName: string;
    @Input() customGrantsServiceProxy: ICustomGrantsServiceProxy;
    @Input() fourEyesPrinciplePermissionsService: FourEyesPrinciplePermissionsBaseService;
    @Output() onEntityPermissionPossibleUsersChange: EventEmitter<any> = new EventEmitter<any>();

    possibleUsers: string[] = [];
    displayPossibleUsersDialog = false;
    isPermissionFormLoading = false;
    form: FormGroup;
    entityPermissions: MazarsCustomPermissionModel[];
    accessRestrictionProvisionalData: IUserOrOrganizationUnitInput[];
    isCurrentUserIncluded = false;
    adminSettingsDto: IAdminSettingsDto;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        @Inject(IAdminSettingsServiceToken)
        private adminSettingsServiceProxy: IAdminSettingsService
    ) {
        super(injector);
        this.createForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.isCreate && changes.customGrantsServiceProxy?.firstChange) {
            this.loadPermissions(this.accessRestrictionProvisionalData);
        }
    }

    ngOnInit(): void {
        this.loadAdminSettings();
        this.localizationSourceName = this.moduleLocalizationSourceName;
    }

    onDestroy(): void {
        // to be implemented
    }

    loadPermissions(provisionalData: IUserOrOrganizationUnitInput[] = null) {
        this.isPermissionFormLoading = true;
        this.accessRestrictionProvisionalData = provisionalData;
        this.getAllPermissions(provisionalData)
            .pipe(
                finalize(() => {
                    this.isPermissionFormLoading = false;
                })
            )
            .subscribe((result) => {
                this.entityPermissions = result.map(
                    (r) =>
                        ({
                            referenceId: r.referenceId,
                            fullPermissionName: r.permissionName,
                            permissionName: r.permissionName?.split('.')?.pop(),
                            isActive: r.isActive,
                            usersOrOrganizationUnits: r.usersOrOrganizationUnits.items.map((u) => `${u.userOrOrganizationId}_${u.type}`),
                            possibleUsers: r.possibleUsers.items.map((p) => p.displayName),
                            possibleUsersOriginalListResultDto: r.possibleUsers,
                            availableUsersOrOrganizationUnits: r.availableUsersOrOrganizationUnits.items.map(
                                (u) =>
                                    ({
                                        id: `${u.userOrOrganizationId}_${u.type}`,
                                        text: u.displayName,
                                        source: u.type,
                                    } as IItem<string>)
                            ),
                        } as MazarsCustomPermissionModel)
                );

                for (let entityPermission of this.entityPermissions) {
                    this.onEntityPermissionPossibleUsersChange.emit({
                        possibleUsers: entityPermission.possibleUsersOriginalListResultDto,
                        permissionName: entityPermission.fullPermissionName,
                        isLoading: this.isPermissionFormLoading,
                    });
                    if (entityPermission.isActive) {
                        this.form.addControl(entityPermission.fullPermissionName, this.fb.control([]));
                        this.form.patchValue({
                            [entityPermission.fullPermissionName]: entityPermission.usersOrOrganizationUnits,
                        });

                        if (
                            this.isCreate &&
                            this.isAccessRestrictionActive &&
                            !this.isCurrentUserIncluded &&
                            entityPermission.fullPermissionName === ProcessStatePermission.ReportEditEvaluation
                        ) {
                            const currentUser = `${this.appSession.userId}_User`;
                            this.onEntityPermissionChange([currentUser], entityPermission);
                            this.isCurrentUserIncluded = true;
                            this.loadPermissions(this.accessRestrictionProvisionalData);
                        }
                    }
                }

                this.form.markAsPristine();

                if (this.createOutOfTemplate) {
                    this.entityId = 0;
                }
            });
    }

    showPossibleUsers(users: string[]) {
        if (users && users.length > 0) {
            this.possibleUsers = users;
            this.displayPossibleUsersDialog = true;
        }
    }

    hasReviewWarning(reviewPermission: MazarsCustomPermissionModel): boolean {
        return this.fourEyesPrinciplePermissionsService.hasReviewWarning(this.entityPermissions, reviewPermission);
    }

    saveAllInput(): ICustomGrantsForPermissionInput[] {
        if (this.viewOnlyMode) {
            return null;
        }

        let formValues = this.form.value;
        let entityPermissionInputs: ICustomGrantsForPermissionInput[] = [];
        for (let propertyName in formValues) {
            if (propertyName) {
                let input = formValues[propertyName].map(
                    (i) => ({ type: i.split('_')[1], userOrOrganizationId: i.split('_')[0] } as IUserOrOrganizationUnitInput)
                );
                entityPermissionInputs.push({
                    permissionName: propertyName,
                    entityId: this.entityId,
                    usersAndOrganizationUnits: input,
                } as ICustomGrantsForPermissionInput);
            }
        }
        return entityPermissionInputs;
    }

    onEntityPermissionChange(newValues, permissionObj) {
        let provisionalData = newValues.map((i) => ({ type: i.split('_')[1], userOrOrganizationId: i.split('_')[0] } as IUserOrOrganizationUnitInput));
        let customGrantsForPermissionInput = {
            entityId: this.entityId,
            permissionName: permissionObj.fullPermissionName,
            usersAndOrganizationUnits: provisionalData,
            accessRestrictionProvisionalData: this.isAccessRestrictionActive ? this.accessRestrictionProvisionalData : undefined,
        } as ICustomGrantsForPermissionWithAccessRestrictionDataInput;

        this.customGrantsServiceProxy.getAllPossibleUsersWithProvisionalData(customGrantsForPermissionInput).subscribe((result) => {
            permissionObj.possibleUsers = result.items.map((p) => p.displayName);
            this.onEntityPermissionPossibleUsersChange.emit({
                possibleUsers: result,
                permissionName: permissionObj.fullPermissionName,
                isLoading: this.isPermissionFormLoading,
            });
        });
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    private createForm() {
        this.form = this.fb.group({});
    }

    private loadAdminSettings() {
        this.adminSettingsServiceProxy
            .getAll()
            .pipe(take(1))
            .subscribe((settings) => {
                this.adminSettingsDto = settings as IAdminSettingsDto;
            });
    }

    private getAllPermissions(provisionalData: IUserOrOrganizationUnitInput[] = null): Observable<IEntityPermissionDto[]> {
        if (!this.customGrantsServiceProxy) {
            return of([]);
        }

        if (provisionalData) {
            return this.customGrantsServiceProxy.getAllWithProvisionalData({
                entityId: this.entityId,
                accessRestrictionProvisionalData: provisionalData,
                customGrantsForPermissions: this.saveAllInput(),
            } as ICustomGrantsWithAccessRestrictionInput);
        }
        return this.customGrantsServiceProxy.getAll(this.entityId);
    }
}
