import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, SecurityContext, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';

@Component({
    selector: 'app-mazars-input-text',
    templateUrl: './mazars-input-text.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsInputTextComponent,
        },
    ],
})
export class MazarsInputTextComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('textInput') input: ElementRef;
    @Input({ required: true }) uid: string;
    @Input() placeholder?: string;
    @Input() isDisabled: boolean;
    @Input() padding: string;
    @Input() tooltipText: string;
    @Input() inlineStyle: string;
    @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
    @Output() onInput:  EventEmitter<void> = new EventEmitter<void>();
    @Output() onInputDebounced:  EventEmitter<string> = new EventEmitter<string>();

    disabled: boolean;
    value: string;
    lastDebouncedInput: string;
    securityContext = SecurityContext;
    
    ngAfterViewInit(): void {
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(500),
                distinctUntilChanged(),
                tap((text) => {
                    this.onInputChangeDebounced();
                })
            )
            .subscribe();
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onInputChange() {
        this.onChange(this.value);
        this.onInput.emit();
    }

    onInputChangeDebounced() {
        if (this.lastDebouncedInput !== this.input.nativeElement.value) {
            this.lastDebouncedInput = this.input.nativeElement.value;
            this.onInputDebounced.emit(this.lastDebouncedInput);
        }
    }

    onBlurChange() {
        this.onTouched();
        this.onBlur.emit();
    }
}
