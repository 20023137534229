import {Pipe, PipeTransform} from '@angular/core';
import {KeyValuePipe} from '@angular/common';

const keepOrder = (a, b) => a;

// This pipe uses the angular keyvalue pipe. but doesn't change order.
@Pipe({
  name: 'defaultOrderKeyValue'
})
export class DefaultOrderKeyValuePipe extends KeyValuePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return super.transform(value, keepOrder);
  }

}