import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SecurityContext,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mazars-steps',
    templateUrl: './mazars-steps.component.html',
    styleUrls: ['./mazars-steps.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsStepsComponent extends AppComponentBase implements OnInit, AfterViewChecked, OnDestroy {
    @Input({ required: true }) uid: string;
    @Input() activeIndex = 0;
    @Input() model: MenuItem[];
    @Input() activeStepTooltip: string;
    @Input() readonly = true;
    @Input() style: any;
    @Input() styleClass: string;
    @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('processBarContainer', { read: ElementRef })
    processBarContainer: ElementRef<HTMLElement>;
    @ViewChild('processBar', { read: ElementRef })
    processBar: ElementRef<HTMLElement>;
    @ViewChild('leftArrow', { read: ElementRef })
    leftArrow: ElementRef<HTMLElement>;
    @ViewChild('rightArrow', { read: ElementRef })
    rightArrow: ElementRef<HTMLElement>;

    private stepMinWidth: number = 230;
    private isMouseDown: boolean = false;
    private leftOffset: number;
    private scrollLeft: number;
    private isActiveProcessAssigned: boolean = false;

    activeProcessClassName: string = 'active';
    items: any[];
    subscription: Subscription;
    securityContext = SecurityContext;

    constructor(_injector: Injector, private router: Router, private route: ActivatedRoute, private cd: ChangeDetectorRef) {
        super(_injector);
    }

    ngOnInit() {
        this.subscription = this.router.events.subscribe(() => this.cd.markForCheck());
    }

    ngAfterViewChecked(): void {
        let isProcessBarOverflown = this.isOverflownOnXAxis(this.processBar.nativeElement);
        this.showOrHideProcessBarArrows(isProcessBarOverflown);

        if (this.isActiveProcessAssigned || !isProcessBarOverflown) {
            return;
        }

        //scroll to active process only once.
        this.scrollToAciveProcess();
    }

    itemClick(event: Event, item: MenuItem, i: number) {
        if (this.readonly || item.disabled) {
            event.preventDefault();
            return;
        }

        this.activeIndexChange.emit(i);

        if (!item.url) {
            event.preventDefault();
        }

        if (item.command) {
            item.command({
                originalEvent: event,
                item: item,
                index: i,
            });
        }
    }

    isClickableRouterLink(item: MenuItem) {
        return item.routerLink && !this.readonly && !item.disabled;
    }

    isActive(item: MenuItem, index: number) {
        if (item.routerLink) {
            return (
                this.router.isActive(item.routerLink, { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }) ||
                this.router.isActive(this.router.createUrlTree([item.routerLink], { relativeTo: this.route }).toString(), {
                    paths: 'subset',
                    queryParams: 'subset',
                    fragment: 'ignored',
                    matrixParams: 'ignored',
                })
            );
        } else {
            return index === this.activeIndex;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    move($event) {
        if (!this.isMouseDown) {
            return;
        }

        const positionX = $event.pageX - this.processBarContainer.nativeElement.offsetLeft;
        const scrollOnXValue = positionX - this.leftOffset;
        this.processBarContainer.nativeElement.scrollLeft = this.scrollLeft - scrollOnXValue;
    }

    startDragging($event) {
        this.isMouseDown = true;
        this.leftOffset = $event.pageX - this.processBarContainer.nativeElement.offsetLeft;
        this.scrollLeft = this.processBarContainer.nativeElement.scrollLeft;
    }

    stopDragging() {
        this.isMouseDown = false;
    }

    moveItemsToLeft() {
        this.scrollToLeft(this.processBarContainer.nativeElement.scrollLeft - this.stepMinWidth);
    }

    moveItemsToRight() {
        this.scrollToLeft(this.processBarContainer.nativeElement.scrollLeft + this.stepMinWidth);
    }

    private showOrHideProcessBarArrows(isProcessBarOverflown: boolean): void {
        if (isProcessBarOverflown) {
            this.leftArrow.nativeElement.classList.remove('d-none');
            this.rightArrow.nativeElement.classList.remove('d-none');
            return;
        }

        this.leftArrow.nativeElement.classList.add('d-none');
        this.rightArrow.nativeElement.classList.add('d-none');
    }

    private isOverflownOnXAxis(element) {
        return element.scrollWidth > element.clientWidth;
    }

    private scrollToLeft(scrollValue: number): void {
        this.processBarContainer.nativeElement.scrollTo({
            left: scrollValue,
            behavior: 'smooth',
        });
    }

    private scrollToAciveProcess(): void {
        for (let i = 0; i < this.processBar.nativeElement.children.length; i++) {
            let child = this.processBar.nativeElement.children[i];
            let isChildActive = child.classList.contains(this.activeProcessClassName);
            if (isChildActive) {
                this.scrollToLeft(i * this.stepMinWidth);
                this.isActiveProcessAssigned = true;
                return;
            }
        }
    }
}
