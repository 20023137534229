import { Injectable, InjectionToken } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonAttachmentsState } from '../state/common-attachments.state';

export const CommonAttachmentsSelectorsToken = new InjectionToken<string>('CommonAttachmentsSelectors');

@Injectable()
export class CommonAttachmentsSelectors {
    public readonly attachmentsState = createFeatureSelector<CommonAttachmentsState>(this.attachmentFeatureKey);
    public readonly getAttachments = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.attachments);

    public readonly getAllowedFileTypes = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.allowedFileTypes);
    public readonly getUploadedFile = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.uploadedFile);
    public readonly getDownloadedFile = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.downloadedFile);
    public readonly getPreviewedFile = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.previewedFile);

    public readonly loaded = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.loaded);
    public readonly getAttachmentsError = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.error);
    public readonly getTagsAttachments = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.tags);
    public readonly getAttachment = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.attachment);
    public readonly getAttachmentError = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.errorUpdatingAttachment);

    public readonly getCreatedAttachmentId = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.createdAttachment);
    public readonly getCreatedByUsers = createSelector(this.attachmentsState, (state: CommonAttachmentsState) => state.createdByUsers);
    constructor(private attachmentFeatureKey: string) {}
}
