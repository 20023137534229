<div class="row">
    <div class="col-sm-6">
        <div class="form-group">
            <label>{{ l('WorkItemStatus') }}</label>
            <div
                class="alert border border-secondary"
                (click)="showStatusEditModal()"
                [ngStyle]="{ cursor: 'pointer' }"
            >
                <app-mazars-status
                    [uid]="uid"
                    [statusValue]="statusValue"
                    [title]="l('WorkItemStatusReason') + ': ' + statusReason"
                    [ngStyle]="{
                        cursor: permission.isGranted('RealPropertyTax.Edit') ? 'pointer' : 'default'
                    }"
                    [isEditable]="false"
                >
                </app-mazars-status>
                <br />
                <a [routerLink]="" class="text-nowrap">{{ l('ChangeWorkItemStatus') }}</a>
            </div>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="form-group">
            <label>{{ l('WorkItemStatusReason') }}</label>
            <div class="alert alert-secondary" role="alert">
                {{ statusReason }}
            </div>
        </div>
    </div>
</div>

<app-mazars-status-edit-modal #mazarsStatusEditModal></app-mazars-status-edit-modal>
