<div appBsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #editForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{ 'NotificationSettings' | localize }}</span>
                    </h4>
                    <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="l('Close')"></button>
                </div>
                <div class="modal-body" *ngIf="tabs">
                    <div>
                        <p-tabView>
                            <p-tabPanel *ngFor="let tab of tabs" header="{{ tab.name | localize }}">
                                <div class="p-4">
                                    <div class="row">
                                        <div class="col-xs-12 col-md-8">
                                            <h5>{{ tab.name | localize }}</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-md-8"></div>
                                        <div class="col-xs-6 col-md-2">{{ 'Bell' | localize }}</div>
                                        <div class="col-xs-6 col-md-2">{{ 'Email' | localize }}</div>
                                    </div>
                                    <div class="row" *ngFor="let notification of tab.notificationSettings">
                                        <div class="col-xs-12 col-md-8 pt-3 word-break">{{ notification.displayName }}</div>
                                        <div class="col-xs-6 col-md-2">
                                            <label class="form-check form-check-custom form-check-solid form-switch py-1">
                                                <input
                                                    type="checkbox"
                                                    name="{{ notification.name }}.IsSubscribedBell"
                                                    [(ngModel)]="notification.isSubscribedBell"
                                                    class="form-check-input"
                                                    value="true"
                                                    [disabled]="!notification.canChangeBell"
                                                />
                                            </label>
                                        </div>
                                        <div class="col-xs-6 col-md-2">
                                            <label *ngIf="notification.isSubscribedBell" class="form-check form-check-custom form-check-solid form-switch py-1">
                                                <input
                                                    type="checkbox"
                                                    name="{{ notification.name }}.IsSubscribedEmail"
                                                    [(ngModel)]="notification.isSubscribedEmail"
                                                    class="form-check-input"
                                                    value="true"
                                                    [disabled]="!notification.canChangeEmail"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-light-primary fw-bold" (click)="close()">
                        {{ 'Cancel' | localize }}
                    </button>
                    <button type="submit" class="btn btn-primary fw-bold" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                        <i aria-hidden="true" class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
