<div class="mb-2">
    <label class="form-label mb-2">{{ header }}</label>

    <p-table #table [value]="clonedItems" dataKey="id" editMode="row" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 45%">{{ keyPlaceHolder }}</th>
                <th style="width: 45%">{{ valuePlaceHolder }}</th>
                <th style="width: 10%">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <button pButton pRipple type="button" icon="pi pi-plus" (click)="addNew()" class="p-button-rounded p-button-text"></button>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="item">
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText class="form-control" type="text" [(ngModel)]="item.key" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ item.key }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText class="form-control" type="text" [(ngModel)]="item.value" required />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ item.value }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <div class="flex align-items-center justify-content-center gap-2">
                        <button
                            *ngIf="!editing"
                            pButton
                            pRipple
                            type="button"
                            pInitEditableRow
                            icon="pi pi-pencil"
                            (click)="onRowEditInit(item)"
                            class="p-button-rounded p-button-text"
                        ></button>
                        <button
                            *ngIf="!editing"
                            pButton
                            pRipple
                            type="button"
                            pCancelEditableRow
                            icon="pi pi-trash"
                            (click)="onRowDelete(item)"
                            class="p-button-rounded p-button-text p-button-danger"
                        ></button>
                        <button
                            *ngIf="editing"
                            pButton
                            pRipple
                            type="button"
                            pSaveEditableRow
                            icon="pi pi-check"
                            (click)="onRowEditSave(item)"
                            class="p-button-rounded p-button-text p-button-success mr-2"
                        ></button>
                        <button
                            *ngIf="editing"
                            pButton
                            pRipple
                            type="button"
                            pCancelEditableRow
                            icon="pi pi-times"
                            (click)="onRowEditCancel(item, ri)"
                            class="p-button-rounded p-button-text p-button-danger"
                        ></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
