import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { QuillEditorComponent } from 'ngx-quill';
import { Quill } from 'quill';

@Component({
    selector: 'app-mazars-display-quill',
    templateUrl: './mazars-display-quill.component.html',
    styleUrls: ['./mazars-display-quill.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsDisplayQuillComponent extends AppComponentBase implements OnChanges {
    @Input({ required: true }) uid: string;
    @Input() description: string;
    @Output() onHtmlLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('view') private view: QuillEditorComponent;

    descriptionHtml: string;
    htmlLoaded = false;

    constructor(_injector: Injector, private cdRef: ChangeDetectorRef) {
        super(_injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const descriptionChanges = changes['description'];
        if (descriptionChanges && !descriptionChanges.firstChange && descriptionChanges.previousValue !== descriptionChanges.currentValue) {
            this.htmlLoaded = false;
        }
    }

    onEditorCreated(editor: Quill) {
        this.view.quillEditor = editor;

        let contentObj: any = this.description;
        try {
            contentObj = JSON.parse(this.description);
            editor.setContents(contentObj);
        } catch (e) {
            editor.setText(contentObj);
        }

        this.descriptionHtml = this.view.quillEditor.root.innerHTML;
        this.htmlLoaded = true;
        this.onHtmlLoaded.emit(true);
        this.cdRef.detectChanges();

    }
}
