import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IItem } from '../../interfaces/item.interface';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';
import { IChancelleryViewReferenceDataFacade } from '@app/shared/common/facades/chancellery-view-reference-data.facade';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mazars-chancellery',
    templateUrl: './mazars-chancellery-view.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsChancelleryViewComponent,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsChancelleryViewComponent),
            multi: true,
        },
    ],
})
export class MazarsChancelleryViewComponent extends AppComponentBase implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    @Input() uid!: string;
    @Input() adminSettings: any;
    @Input() referenceDataFacade: IChancelleryViewReferenceDataFacade;
    @Input() dataLoading: boolean;

    form: FormGroup;
    areClientsBeingLoaded: boolean;
    areProjectsBeingLoaded: boolean;
    showWarnings: boolean;
    allClients: IItem<string>[] = [];
    allProjects: IItem<string>[] = [];
    subscriptions: Subscription[] = [];

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder,
    ) {
        super(injector);
    }

    get value(): any {
        return this.form?.value;
    }

    set value(chancelleryDetails: any) {
        this.form.patchValue(chancelleryDetails);
        this.form.updateValueAndValidity();

        this.onChange(chancelleryDetails);
        this.onTouched();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.adminSettings = changes.adminSettings?.currentValue;
        if (this.adminSettings?.isChancelleryViewActive) {
            this.areClientsBeingLoaded = true;
            this.referenceDataFacade.getAllClients();
            this.setRequiredValidators(this.adminSettings);
        }
    }

    ngOnInit(): void {
        this.allClients = [];
        this.allProjects = [];

        this.createForm();

        this.registerFormValueChangesAndShowWarningsFlag();
        this.registerClientsAndProjectsSubscriptions();
    }

    ngOnDestroy(): void {
        this.referenceDataFacade.clearClients();
        this.referenceDataFacade.clearProjects();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { chancelleryDetails: { valid: false } };
    }

    private registerFormValueChangesAndShowWarningsFlag() {
        this.form?.controls.clientNumber?.valueChanges.subscribe((clientNumber) => {
            let selectedClient = this.allClients.find((c) => c.id == clientNumber);
            this.form.controls.clientName.setValue(selectedClient?.source?.clientName);
            this.resetProjects();
            if (clientNumber) {
                this.areProjectsBeingLoaded = true;
                this.referenceDataFacade.getAllProjects(clientNumber);
            }
        });

        this.form?.controls.projectNumber?.valueChanges.subscribe((projectNumber) => {
            let selectedProject = this.allProjects.find((c) => c.id == projectNumber);
            if (selectedProject) {
                this.form.controls.projectName.setValue(selectedProject?.source?.projectName);
            }
        });

        this.subscriptions.push(
            this.form?.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            }),
        );

        this.subscriptions.push(
            this.referenceDataFacade.showWarnings$.subscribe((result) => {
                if (result) {
                    this.showWarnings = result;
                }
            }),
        );
    }

    private resetProjects() {
        this.form.controls.projectNumber.reset();
        this.form.controls.projectName.reset();
        this.allProjects = [];
    }

    private createForm() {
        this.form = this.formBuilder.group({
            clientNumber: [],
            clientName: [],
            projectNumber: [],
            projectName: [],
        });
    }

    private setRequiredValidators(settings: any) {
        if (settings?.chancelleryViewIsClientRequired) {
            this.form?.controls.clientNumber.setValidators(Validators.required);
            this.form?.controls.clientName.setValidators(Validators.required);
        }

        if (settings?.chancelleryViewIsProjectRequired) {
            this.form?.controls.projectNumber.setValidators(Validators.required);
            this.form?.controls.projectName.setValidators(Validators.required);
        }
    }

    private registerClientsAndProjectsSubscriptions() {
        this.subscriptions.push(
            this.referenceDataFacade.allClients$.subscribe((clients) => {
                if (clients) {
                    this.allClients = clients.map((client: any) => <IItem<string>>{ id: client.clientNumber, text: client.clientName, source: client });
                    this.areClientsBeingLoaded = false;
                }
            }),
        );

        this.subscriptions.push(
            this.referenceDataFacade.allProjects$.subscribe((projects) => {
                if (projects) {
                    this.allProjects = projects.map(
                        (project: any) =>
                            <IItem<string>>{ id: project.projectNumber, text: `${project.projectNumber} | ${project.projectName}`, source: project },
                    );
                    this.areProjectsBeingLoaded = false;
                }
            }),
        );
    }
}
