<div *ngIf="availableShortcuts" class="d-flex justify-content-between">
    <label for="Content">{{ l('Content') }}</label>
    <div>
        <span>{{ l('UsefulShortcuts') }}</span>
        <span class="p-2" *ngFor="let shortcut of availableShortcuts">
            <i [class]="shortcut.icon" [mazarsTooltip]="shortcut.tooltip" [escape]="false"></i>
        </span>
    </div>
</div>

<quill-editor
    [attr.data-testid]="uid"
    [placeholder]="''"
    #editor
    id="{{ uid }}"
    (onEditorCreated)="editorCreated($event)"
    (onContentChanged)="contentChanged($event)"
></quill-editor>
