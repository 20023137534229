import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentsModule } from '@app/modules/mazars-common/components/components.module';
import { SubFormsModule } from '@app/modules/mazars-common/sub-forms/sub-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';

import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppRouteGuard } from '@app/shared/common/auth/auth-route-guard';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { CommonCommunicationsFacade } from './state-management/facades/common-communications.facade';
import { ChannelCreateUpdateFacade } from './state-management/facades/channel-create-update.facade';
import { CommunicationCreateUpdateFacade } from './state-management/facades/communication-create-update.facade';
import { CommonGridConfigurationFacade } from './state-management/facades/common-grid-configuration.facade';
import { MazarsWizardFacade } from './state-management/facades/mazars-wizard.facade';
import { CommonAttachmentsFacade } from './state-management/facades/common-attachments.facade';
import { ColumnDefinitionService } from './services/column-definition.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ComponentsModule,
        SubFormsModule,
    ],
    providers: [
        DatePipe,
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        CommonCommunicationsFacade,
        ChannelCreateUpdateFacade,
        CommunicationCreateUpdateFacade,
        CommonGridConfigurationFacade,
        MazarsWizardFacade,
        CommonAttachmentsFacade,
        ColumnDefinitionService,
    ],
})
export class MazarsCommonModule {
    static forRoot(): ModuleWithProviders<MazarsCommonModule> {
        return {
            ngModule: MazarsCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
