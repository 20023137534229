<div class="row justify-content-center" *ngFor="let communication of communications">
    <div class="col-12 col-md-9 py-0">
        <app-mazars-fieldset
            [communication]="communication"
            [hasEditPermission]="hasEditPermission"
            [hasDeletePermission]="hasDeletePermission"
            class="w-100"
            (editCommunicationClicked)="onEditCommunicationClicked($event)"
            (deleteCommunicationClicked)="onDeleteCommunicationClicked($event)"
            (openCommunicationClicked)="onOpenCommunicationClicked($event)"
            [uid]="'commnication-fieldset-' + communication?.id"
        ></app-mazars-fieldset>
    </div>
</div>
