import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { IActionInfo } from '../../../mazars-actions-dropdown-menu/action-info';
import { ActionCellRendererParams } from './actions-cell-renderer-params';

@Component({
    selector: 'actions-cell-renderer',
    templateUrl: './actions-cell-renderer.component.html',
    styleUrls: ['./actions-cell-renderer.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ActionsCellRendererComponent extends AppComponentBase implements ICellRendererAngularComp {
    isVisible: boolean;
    record: any;
    actionItems: IActionInfo[];
    api: GridApi;
    nodeId: any;
    isEditing = false;

    constructor(injector: Injector) {
        super(injector);
    }

    refresh(): boolean {
        return false;
    }

    onDestroy(): void {
        // to be implemented if needed
    }

    agInit(params: ICellRendererParams & ActionCellRendererParams): void {
        this.isVisible = params.value && !params.isHidden;
        this.record = params.data;
        this.actionItems =
            params.actionItems.map(
                (ai) =>
                    ({
                        ...ai,
                        record: params.data,
                    } as IActionInfo)
            ) ?? [];

        this.nodeId = params.node.id;
        this.api = params.api;
        this.api.addEventListener('cellEditingStarted', (event) => {
            this.isEditing = event.node.id === this.nodeId;
            this.focusFirstCell(event.api);
        });

        this.api.addEventListener('cellEditingStopped', (_) => {
            this.isEditing = false;
        });
    }

    focusFirstCell(api: any) {
        const instances = api.getCellEditorInstances();
        if (instances.length > 0) {
            const instance = instances[0];
            instance.focusIn();
        }
    }

    stopEditing(cancel: boolean) {
        this.api.stopEditing(cancel);
        if (cancel) {
            abp.event.trigger('app.actions-cell-renderer.stopEditing');
        }
    }
}
