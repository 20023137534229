import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    IChannelOverviewDto,
    ISearchCommunicationDto,
    IWhiteListOverviewDto,
    IAuthorDto,
    IInitiateEmailConversationInput,
    ICommunicationsFilterRequest,
    ISaveAllWhiteListInput,
    ICreateCommunicationInput,
    ICommunicationDto,
} from '@shared/service-proxies/common-interfaces';
import { Observable } from 'rxjs';
import { CommonCommunicationsActions } from '../actions/common-communications.actions';
import { CommonCommunicationsSelectors, CommonCommunicationsSelectorsToken } from '../selectors/common-communication.selectors';
import { CommonCommunicationsState } from '../state/common-communications.state';

@Injectable()
export class CommonCommunicationsFacade {
    public readonly allChannels$: Observable<IChannelOverviewDto[] | ISearchCommunicationDto[]> = this.store.pipe(select(this.selectors.getChannels));

    public readonly currentChannel$: Observable<IChannelOverviewDto> = this.store.pipe(select(this.selectors.getCurrentChannel));

    public readonly allWhitelist$: Observable<IWhiteListOverviewDto[]> = this.store.pipe(select(this.selectors.getAllWhitelist));

    public readonly saveAllWhitelistSuccessful$ = this.actions$.pipe(ofType(this.commonCommunicationsActions.SaveAllWhitelistSuccess));

    public readonly saveAllWhitelistError$: Observable<IWhiteListOverviewDto[]> = this.store.pipe(select(this.selectors.getWhitelistError));

    public readonly allCommunicationsAuthors$: Observable<IAuthorDto[]> = this.store.pipe(select(this.selectors.getAuthorsCommunications));

    public readonly loaded$: Observable<boolean> = this.store.pipe(select(this.selectors.loaded));

    public readonly initiateEamilCommunicationSuccessful$ = this.actions$.pipe(ofType(this.commonCommunicationsActions.InitiateEmailCommunicationSuccess));

    public readonly deleteChannelSuccessful$ = this.actions$.pipe(ofType(this.commonCommunicationsActions.DeleteChannelSuccess));

    public readonly deleteCommunicationSuccessful$ = this.actions$.pipe(ofType(this.commonCommunicationsActions.DeleteCommunicationSuccess));

    public readonly numberOfUnreadChannels$: Observable<number> = this.store.pipe(select(this.selectors.getNumberOfUnreadChannels));

    constructor(
        private readonly store: Store<CommonCommunicationsState>,
        private readonly actions$: Actions,
        @Inject(CommonCommunicationsSelectorsToken) private readonly selectors: CommonCommunicationsSelectors,
        private commonCommunicationsActions: CommonCommunicationsActions
    ) {}

    searchCommunications(input: ICommunicationsFilterRequest) {
        this.store.dispatch(this.commonCommunicationsActions.SearchCommunicationsRequest({ input: input }));
    }

    getAllChannelCommunications(input: number) {
        this.store.dispatch(this.commonCommunicationsActions.GetAllChannelCommunicationsRequest({ input: input }));
    }

    getAllChannels(input: number) {
        this.store.dispatch(this.commonCommunicationsActions.GetAllChannelsRequest({ input: input }));
    }

    getAllWhitelist(input: number) {
        this.store.dispatch(this.commonCommunicationsActions.GetAllWhitelistRequest({ input: input }));
    }

    saveAllWhitelist(input: ISaveAllWhiteListInput) {
        this.store.dispatch(this.commonCommunicationsActions.SaveAllWhitelistRequest({ input: input }));
    }

    getAllCommunicationAuthors() {
        this.store.dispatch(this.commonCommunicationsActions.GetAllAuthorsRequest());
    }

    initiateEmailCommunication(input: IInitiateEmailConversationInput) {
        this.store.dispatch(this.commonCommunicationsActions.InitiateEmailCommunicationRequest({ input: input }));
    }

    deleteChannel(id: number, referenceId: number) {
        this.store.dispatch(this.commonCommunicationsActions.DeleteChannelRequest({ id: id, referenceId: referenceId }));
    }

    deleteCommunication(id: number) {
        this.store.dispatch(this.commonCommunicationsActions.DeleteCommunicationRequest({ id: id }));
    }

    selectChannel(id: number) {
        this.store.dispatch(this.commonCommunicationsActions.CurrentChannelChange({ input: id }));
    }

    clearChannel() {
        this.store.dispatch(this.commonCommunicationsActions.ClearCurrentChannel());
    }

    sendCommunicationMessage(message: ICreateCommunicationInput) {
        this.store.dispatch(this.commonCommunicationsActions.CreateCommunicationRequest({ input: message }));
    }

    communicationMessageRecieved(mesage: ICommunicationDto) {
        this.store.dispatch(this.commonCommunicationsActions.CommunicationMessageRecieved({ input: mesage }));
    }

    getNumberOfUnreadChannels(id: number) {
        this.store.dispatch(this.commonCommunicationsActions.GetNumberOfUnreadChannelsRequest({ input: id }));
    }
}
