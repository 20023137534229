import {
    Component,
    Input,
    Output,
    EventEmitter,
    Injector,
    ContentChildren,
    QueryList,
    ViewEncapsulation,
    ViewChild,
    OnInit,
    ElementRef,
} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe, KeyValue } from '@angular/common';
import { MazarsTemplateDirective } from '../mazars-template/mazars-template.directive';
import { MazarsReorderDialogComponent } from '../mazars-reorder-dialog/mazars-reorder-dialog.component';
import { IUserGridConfigurationServiceProxy } from '@shared/service-proxies/interfaces/IUserGridConfigurationServiceProxy';
import { CommonGridConfigurationFacade } from '../../state-management/facades/common-grid-configuration.facade';
import { MazarsExportOverviewDialogComponent } from '../mazars-export-overview-dialog/mazars-export-overview-dialog.component';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';
import { ContextMenuActionType, IContextMenuActionInfo } from '../mazars-archive-menu/context-menu-action-info';
import { MazarsContextMenuComponent } from '../mazars-archive-menu/mazars-context-menu.component';
import { ISearchBarFilter } from './search-bar-filter.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TableStateService } from '@shared/utils/table-state.service';
import { FilterType } from './filter-type';
import { DateTime } from 'luxon';
import { MazarsFiltersDialogComponent } from './mazars-filters-dialog/mazars-filters-dialog.component';
@Component({
    selector: 'app-mazars-searchbar',
    templateUrl: './mazars-searchbar.component.html',
    styleUrls: ['./app-mazars-searchbar.css'],
    providers: [DatePipe],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsSearchbarComponent extends AppComponentBase implements OnInit {
    @ContentChildren(MazarsTemplateDirective, { read: MazarsTemplateDirective })
    templateRefs: QueryList<MazarsTemplateDirective>;
    @ViewChild('mazarsReorderDialog', { static: true }) mazarsReorderDialog: MazarsReorderDialogComponent;
    @ViewChild('mazarsFiltersDialog', { static: true }) mazarsFiltersDialog: MazarsFiltersDialogComponent;
    @ViewChild('mazarsExportOverviewDialog', { static: true }) mazarsExportOverviewDialog: MazarsExportOverviewDialogComponent;
    @ViewChild('contextMenu', { static: false }) mazarsContextMenu: MazarsContextMenuComponent;
    @ViewChild('contextMenuContainer', { static: false }) contextMenuContainer: any;
    @ViewChild('searchInput') input: ElementRef;
    
    @Input() userGridConfigurationServiceProxy: IUserGridConfigurationServiceProxy;

    @Input({ required: true }) uid: string;
    @Input() showCreate = false;
    @Input() showExportButton = false;
    @Input() showSwitchSection = false;
    @Input() switchSectionTooltip?: string;
    @Input() switchIconClass: string;
    @Input() showFiltersSection = true;
    @Input() createButtonTitle?: string = null;
    @Input() hasSelectAll: boolean;
    @Input() showConfigSection: boolean;
    @Input() tableId: string;
    @Input() hasAppliedFilters: boolean;
    @Input() gridConfigurationFacade: CommonGridConfigurationFacade;
    @Input() disableClearFilter = false;
    @Input() contextMenuItems: IContextMenuActionInfo[] = [];
    @Input() contextMenuItemsTooltip: string;
    @Input() hasArchivableElements: boolean;
    @Input() filters: { [key: string]: ISearchBarFilter };
    @Input() currentFilters: { [key: string]: any };
    @Output() onChange = new EventEmitter();
    @Output() onClear = new EventEmitter();
    @Output() onSwitch = new EventEmitter();
    @Output() onCreate = new EventEmitter();
    @Output() onSelectAllChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClicked = new EventEmitter();

    search = '';
    showFilters = false;
    lastEmitted: string;
    isSwitched: boolean;
    filtersForm: FormGroup;
    FilterType = FilterType;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        private tableStateService: TableStateService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.filtersForm = this.fb.group({ filter: '' });
        if (this.filters) {
            this.createFiltersForm();
            if (this.tableId) {
                this.setFilters();
            }
        }
        if (this.hasAppliedFilters) {
            this.showFilters = true;
        }
        if (this.showExportButton) {
            this.contextMenuItems.push({
                label: this.l('ExportOverview_Tooltip'),
                type: ContextMenuActionType.Button,
                icon: 'fas fa-download',
                action: (event) => {
                    this.showExport();
                },
            });
        }

        this.filtersForm.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((event) => {
            if (this.filters) {
                let appliedFilters = { ...event };
                if (appliedFilters.hasOwnProperty('filter')) {
                    delete appliedFilters.filter;
                }
                if (this.tableId) {
                    this.hasAppliedFilters = this.tableStateService.hasAppliedFilters(appliedFilters);
                    this.tableStateService.setFilters(this.tableId, appliedFilters);
                }

                this.currentFilters = this.filtersForm.value;
                this.onChange.emit(event);
            } else {
                this.onChange.emit(event?.filter || '');
            }
        });
    }

    createFiltersForm() {
        Object.entries(this.filters).forEach(([key, filter]) => {
            const filterControl = this.fb.control([]);
            this.filtersForm.addControl(key, filterControl);
        });
    }

    setFilters() {
        const filters = this.tableStateService.getFilters(this.tableId) || [];
        this.hasAppliedFilters = this.tableStateService.hasAppliedFilters(filters);
        Object.entries(this.filters).forEach(([key, filter]) => {
            if (this.filtersForm.controls[key]) {
                if (filters[key]) {
                    if (filter.type === FilterType.Multiselect) {
                        this.filtersForm.controls[key].patchValue(filters[key]);
                    } else if (filter.type === FilterType.DateRangePicker) {
                        const data = filters[key].map((date) => DateTime.fromISO(date));
                        this.filtersForm.controls[key].patchValue(data);
                    }
                }
            }
        });
    }

    resetFilters() {
        if (this.filters) {
            Object.entries(this.filters).forEach(([key, filter]) => {
                if (this.filtersForm.controls[key]) {
                    this.filtersForm.controls[key].patchValue([]);
                }
            });
        } else {
            this.filtersForm.reset();
        }
    }

    onInputChange() {
        if (this.lastEmitted !== this.input.nativeElement.value) {
            this.lastEmitted = this.input.nativeElement.value;
            this.onChange.emit(this.input.nativeElement.value);
        }
    }

    switchSectionClicked() {
        this.onSwitch.emit(this.isSwitched);
    }

    contextMenuClicked() {
        if (this.mazarsContextMenu && this.contextMenuContainer) {
            this.mazarsContextMenu.toggle({ currentTarget: this.contextMenuContainer.nativeElement, relativeAlign: false });
        }
    }

    onCreateClick() {
        this.onCreate.emit();
    }

    onExportClicked($event){
       this.onClicked.emit($event) 
    }

    clearFilter() {
        this.resetFilters();
        this.onClear.emit();
    }

    showConfig() {
        this.mazarsReorderDialog.showHide();
    }

    showExport() {
        this.mazarsExportOverviewDialog.showHide();
    }

    onSelectAllClick(event) {
        this.onSelectAllChange.emit(event);
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    showFiltersToggle() {
        this.mazarsFiltersDialog.showHide();
    }
}
