import { Injector, Component, Input, Inject } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IUserDetailsDto } from '@shared/service-proxies/common-interfaces';
import { ICommunicationsService, ICommunicationsServiceToken } from '@shared/service-proxies/interfaces/ICommunicationsService';

@Component({
    templateUrl: './mazars-participants-dialog.component.html',
    selector: 'mazars-participants-dialog',
    styleUrls: ['./mazars-participants-dialog.component.css'],
})
export class MazarsParticipantsDialogComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() isVisible: boolean;
    userDatails: IUserDetailsDto[] = [];

    constructor(
        injector: Injector,
        @Inject(ICommunicationsServiceToken)
        private communicationsService: ICommunicationsService
    ) {
        super(injector);
    }

    hide() {
        this.isVisible = !this.isVisible;
        this.userDatails = [];
    }

    getUserDetails(channelId: number) {
        if (isNaN(Number(channelId)) || isNaN(channelId - 0)) {
            return;
        }
        this.communicationsService.getAllChannelUserDetail(channelId).subscribe((data) => {
            this.userDatails = data;
            this.isVisible = !this.isVisible;
        });
    }
}
