import { Injectable, Injector } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, switchMap } from 'rxjs/operators';
import { AbpHttpConfigurationService, AbpHttpInterceptor, LocalizationService, LogService, MessageService, TokenService, UtilsService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { NavigationError, Router, RoutesRecognized } from '@angular/router';
declare const abp: any;

@Injectable()
export class MazarsAbpHttpInterceptor extends AbpHttpInterceptor {
    protected configuration: AbpHttpConfigurationService;
    private _messageService: MessageService;
    private __tokenService: TokenService = new TokenService();
    private __utilsService: UtilsService = new UtilsService();
    private __logService: LogService = new LogService();
    private _router: Router;
    private _als: LocalizationService;

    constructor(configuration: AbpHttpConfigurationService, _injector: Injector, _messageService: MessageService) {
        super(configuration, _injector);
        this.configuration = configuration;
        this._messageService = _messageService;
        this._router = _injector.get(Router);
        this._als = _injector.get(LocalizationService);
    }

    protected normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (!request.url.includes(AppConsts.remoteServiceBaseUrl)) {
            return request;
        }

        let modifiedHeaders = new HttpHeaders();
        modifiedHeaders = request.headers.set('Pragma', 'no-cache').set('Cache-Control', 'no-cache').set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

        modifiedHeaders = this.addXRequestedWithHeader(modifiedHeaders);
        modifiedHeaders = this.addAuthorizationHeaders(modifiedHeaders);
        modifiedHeaders = this.addAspNetCoreCultureHeader(modifiedHeaders);
        modifiedHeaders = this.addAcceptLanguageHeader(modifiedHeaders);
        modifiedHeaders = this.addTenantIdHeader(modifiedHeaders);

        return request.clone({
            headers: modifiedHeaders,
        });
    }

    protected handleErrorResponse(error: any): Observable<never> {
        if (!(error.error instanceof Blob)) {
            return throwError(error);
        }
        if (error?.status === 504) {
            this._messageService.error(error.statusText);
            return throwError(error);
        }
        return this.configuration.blobToText(error.error).pipe(
            switchMap((json) => {
                const errorBody = !!json ? JSON.parse(json) : {};
                const errorResponse = new HttpResponse({
                    headers: error.headers,
                    status: error.status,
                    body: errorBody,
                });

                let ajaxResponse = this.configuration.getAbpAjaxResponseOrNull(errorResponse);

                if (ajaxResponse != null) {
                    if (ajaxResponse.error?.code !== 400) {
                        if (ajaxResponse.error?.message === 'Content_Not_Found') {
                            this._router.events.pipe(filter((evt: any) => evt instanceof NavigationError)).subscribe((events: RoutesRecognized[]) => {
                                ajaxResponse.error.message = this._als.localize(ajaxResponse.error.message, AppConsts.localization.defaultLocalizationSourceName);
                                this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
                                timer(1000).subscribe((_) => {
                                    history.back();
                                });
                            });
                        } else {
                            this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
                        }
                    }
                } else {
                    this.configuration.handleNonAbpErrorResponse(errorResponse);
                }

                return throwError(error);
            })
        );
    }
}
