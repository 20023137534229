import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IAssignableUserDto } from '@shared/service-proxies/common-interfaces';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MazarsAssignableUserSelectEditModalComponent } from './mazars-assignable-user-select-edit-modal.component';

@Component({
    selector: 'app-mazars-assignable-user-select',
    templateUrl: './mazars-assignable-user-select.component.html',
})
export class MazarsAssignableUserSelectComponent extends AppComponentBase {
    @ViewChild('mazarsAssignableEditModal')
    mazarsAssignableUserEditModal: MazarsAssignableUserSelectEditModalComponent;

    @Input({ required: true }) uid: string;
    @Input() mode: string;
    @Input() assignedUser: IAssignableUserDto;
    @Input() assignableUsersProvider: (filter: string) => Observable<IAssignableUserDto[]>;
    @Input() isEditable = false;

    @Output() onAssignedUserChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() onShowModal: EventEmitter<void> = new EventEmitter();
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    showAssignableUserEditModal(): void {
        if (!this.isEditable) {
            return;
        }
        this.onShowModal.emit();
        this.assignableUsersProvider('').pipe(take(1)).subscribe(assignableUsers => {
            this.mazarsAssignableUserEditModal.show(this.assignedUser?.id, assignableUsers).then((assignedUserId) => {
                this.onAssignedUserChange.emit(assignedUserId);
            });
        });
    }
}
