import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-mazars-pils',
    templateUrl: './mazars-pils.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsPilsComponent {
    @Input({ required: true }) uid: string;
    @Input() pils: string[];
    @Input() icon?: string;

    constructor() {}
}
