import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'luxonLocalFromNow' })
export class LuxonLocalFromNowPipe implements PipeTransform {
    transform(value: DateTime) {
        if (!value) {
            return '';
        }

        return value.toLocal().toRelative();
    }
}
