import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICommunicationDto, IUserDetailsDto } from '@shared/service-proxies/common-interfaces';
import { IActionInfo } from '../mazars-actions-dropdown-menu/action-info';
import { MazarsCommunicationModalComponent } from '../mazars-communication/mazars-communication-modal.component';
import { MazarsDesriptionWithMoreComponent } from '../mazars-desription-with-more/mazars-desription-with-more.component';

@Component({
    selector: 'app-mazars-fieldset',
    templateUrl: './mazars-fieldset.component.html',
    styleUrls: ['./mazars-fieldset.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsFieldsetComponent extends AppComponentBase implements OnInit, OnChanges {
    @ViewChild('mazarsCommunicationModal', { static: true })
    mazarsCommunicationModal: MazarsCommunicationModalComponent;
    @ViewChild('descWithMore', { static: false })
    descWithMore: MazarsDesriptionWithMoreComponent;
    @Input({ required: true }) uid: string;
    @Input() communication: ICommunicationDto;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Input() isFullScreen = false;
    @Output() editCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Output() deleteCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Output() openCommunicationClicked = new EventEmitter<ICommunicationDto>();
    @Output() closeCommunicationClicked = new EventEmitter<ICommunicationDto>();

    author: IUserDetailsDto;

    actionItemsCache: Map<any, IActionInfo[]> = new Map<any, IActionInfo[]>();
    isCollapsed = true;

    constructor(injector: Injector, public datePipe: DatePipe) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.communication) {
            const comminicationAuthor = this.communication.userDetails.find((userDetails) => userDetails.id === this.communication.creatorUserId);
            if (comminicationAuthor) {
                this.author = comminicationAuthor;
            } else {
                const sender = this.communication.userDetails[0];
                if (sender) {
                    this.author = sender;
                }
            }
        }
    }

    ngOnInit(): void {
    }

    onContentHtmlLoaded(isLoaded: boolean) {
        if (this.descWithMore) {
            this.descWithMore.calculateCanExpand();
        }
    }

    onToggle(event) {
        this.isCollapsed = event.collapsed;
    }

    editCommunication(communication: ICommunicationDto): void {
        this.editCommunicationClicked.emit(communication);
    }

    deleteCommunication(communication: ICommunicationDto): void {
        this.deleteCommunicationClicked.emit(communication);
    }

    openCommunication(communication: ICommunicationDto): void {
        this.openCommunicationClicked.emit(communication);
    }

    closeCommunication(communication: ICommunicationDto): void {
        this.closeCommunicationClicked.emit(communication);
    }
}
