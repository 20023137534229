import {
    Component,
    Input,
    Output,
    OnInit,
    AfterViewInit,
    AfterContentInit,
    OnDestroy,
    ElementRef,
    ViewChild,
    EventEmitter,
    ContentChildren,
    QueryList,
    TemplateRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { PrimeTemplate } from 'primeng/api';
import { ObjectUtils, UniqueComponentId } from 'primeng/utils';
import { Subscription } from 'rxjs';
import { trigger, transition, query, animateChild, AnimationEvent } from '@angular/animations';
import { ZIndexUtils } from 'primeng/utils';
import { MazarsMessageService } from '@app/shared/layout/toast/mazars-message.service';
import { MazarsMessage } from '@app/shared/layout/toast/mazars-message';

@Component({
    selector: 'p-toast',
    template: `
        <div #container [ngClass]="'p-toast p-component p-toast-' + position" [ngStyle]="style" [class]="styleClass">
            <mazars-toast-item
                *ngFor="let msg of messages; let i = index"
                [message]="msg"
                [index]="i"
                (onClose)="onMessageClose($event)"
                [template]="template"
                @toastAnimation
                (@toastAnimation.start)="onAnimationStart($event)"
                (@toastAnimation.done)="onAnimationEnd($event)"
                [showTransformOptions]="showTransformOptions"
                [hideTransformOptions]="hideTransformOptions"
                [showTransitionOptions]="showTransitionOptions"
                [hideTransitionOptions]="hideTransitionOptions"
            ></mazars-toast-item>
        </div>
    `,
    animations: [trigger('toastAnimation', [transition(':enter, :leave', [query('@*', animateChild())])])],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./mazars-toast.component.css'],
    host: {
        class: 'p-element',
    },
})
export class MazarsToastComponent implements OnInit, AfterContentInit, OnDestroy, AfterViewInit {
    @Input() key: string;

    @Input() autoZIndex = true;

    @Input() baseZIndex = 0;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() position = 'top-right';

    @Input() preventOpenDuplicates = false;

    @Input() preventDuplicates = false;

    @Input() showTransformOptions = 'translateY(100%)';

    @Input() hideTransformOptions = 'translateY(-100%)';

    @Input() showTransitionOptions = '300ms ease-out';

    @Input() hideTransitionOptions = '250ms ease-in';

    @Input() breakpoints: any;

    @Output() onClose: EventEmitter<any> = new EventEmitter();

    @ViewChild('container') containerViewChild: ElementRef;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    messageSubscription: Subscription;

    clearSubscription: Subscription;

    messages: MazarsMessage[];

    messagesArchieve: MazarsMessage[];

    template: TemplateRef<any>;

    styleElement: any;

    id: string = UniqueComponentId();

    constructor(public messageService: MazarsMessageService, private cd: ChangeDetectorRef, public config: PrimeNGConfig) {}

    ngOnInit() {
        this.messageSubscription = this.messageService.messageObserver.subscribe((messages) => {
            if (messages) {
                if (Array.isArray(messages)) {
                    const filteredMessages = messages.filter((m) => this.canAdd(m));
                    this.add(filteredMessages);
                } else if (this.canAdd(messages)) {
                    this.add([messages]);
                }
            }
        });

        this.clearSubscription = this.messageService.clearObserver.subscribe((key) => {
            if (key) {
                if (this.key === key) {
                    this.messages = null;
                }
            } else {
                this.messages = null;
            }

            this.cd.markForCheck();
        });
    }

    ngAfterViewInit() {
        if (this.breakpoints) {
            this.createStyle();
        }
    }

    add(messages: MazarsMessage[]): void {
        this.messages = this.messages ? [...this.messages, ...messages] : [...messages];

        if (this.preventDuplicates) {
            this.messagesArchieve = this.messagesArchieve ? [...this.messagesArchieve, ...messages] : [...messages];
        }

        this.cd.markForCheck();
    }

    canAdd(message: MazarsMessage): boolean {
        let allow = this.key === message.key;

        if (allow && this.preventOpenDuplicates) {
            allow = !this.containsMessage(this.messages, message);
        }

        if (allow && this.preventDuplicates) {
            allow = !this.containsMessage(this.messagesArchieve, message);
        }

        return allow;
    }

    containsMessage(collection: MazarsMessage[], message: MazarsMessage): boolean {
        if (!collection) {
            return false;
        }

        return (
            collection.find((m) => {
                return m.summary === message.summary && m.detail == message.detail && m.severity === message.severity;
            }) != null
        );
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'message':
                    this.template = item.template;
                    break;

                default:
                    this.template = item.template;
                    break;
            }
        });
    }

    onMessageClose(event) {
        this.messages.splice(event.index, 1);

        this.onClose.emit({
            message: event.message,
        });

        this.cd.detectChanges();
    }

    onAnimationStart(event: AnimationEvent) {
        if (event.fromState === 'void') {
            this.containerViewChild.nativeElement.setAttribute(this.id, '');
            if (this.autoZIndex && this.containerViewChild.nativeElement.style.zIndex === '') {
                ZIndexUtils.set('modal', this.containerViewChild.nativeElement, this.baseZIndex || this.config.zIndex.modal);
            }
        }
    }

    onAnimationEnd(event: AnimationEvent) {
        if (event.toState === 'void') {
            if (this.autoZIndex && ObjectUtils.isEmpty(this.messages)) {
                ZIndexUtils.clear(this.containerViewChild.nativeElement);
            }
        }
    }

    createStyle() {
        if (!this.styleElement) {
            this.styleElement = document.createElement('style');
            this.styleElement.type = 'text/css';
            document.head.appendChild(this.styleElement);
            let innerHTML = '';
            for (let breakpoint in this.breakpoints) {
                let breakpointStyle = '';
                for (let styleProp in this.breakpoints[breakpoint]) {
                    breakpointStyle += styleProp + ':' + this.breakpoints[breakpoint][styleProp] + ' !important;';
                }
                innerHTML += `
                    @media screen and (max-width: ${breakpoint}) {
                        .p-toast[${this.id}] {
                           ${breakpointStyle}
                        }
                    }
                `;
            }

            this.styleElement.innerHTML = innerHTML;
        }
    }

    destroyStyle() {
        if (this.styleElement) {
            document.head.removeChild(this.styleElement);
            this.styleElement = null;
        }
    }

    ngOnDestroy() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }

        if (this.containerViewChild && this.autoZIndex) {
            ZIndexUtils.clear(this.containerViewChild.nativeElement);
        }

        if (this.clearSubscription) {
            this.clearSubscription.unsubscribe();
        }

        this.destroyStyle();
    }
}
