import { Component, Input, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ITagDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'mazars-tags-list',
    templateUrl: './mazars-tags-list.component.html',
    styleUrls: ['./mazars-tags-list.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsTagsListComponent extends AppComponentBase implements OnInit {
    @Input({ required: true }) uid: string;
    @Input() tagModel: ITagDto[];
    securityContext = SecurityContext;

    tooltipText: string;
    tags: ITagDto[] = [];

    ngOnInit(): void {
        this.tooltipText = this.getTooltipText();
        this.tags = this.getTags();
    }

    getTooltipText(): string {
        let text = '';
        if (this.tagModel && this.tagModel.length > 0) {
            text = this.tagModel.map((t) => t.name).join(', ');
        }

        return text;
    }

    getTags(): ITagDto[] {
        const tagToShow = 2;
        let items = this.tagModel.slice(0, tagToShow);
        if (this.tagModel.length > tagToShow) {
            items.push({ name: '...' } as ITagDto);
        }

        return items;
    }
}
