import { Component, Injector, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IActionInfo } from '../../mazars-actions-dropdown-menu/action-info';
import { CommonCommunicationsFacade } from '@app/modules/mazars-common/state-management/facades/common-communications.facade';
import { MazarsWhitelistModalComponent } from '../../mazars-whitelist-modal/mazars-whitelist-modal.component';
import { MazarsChannelModalComponent } from '../../mazars-channel/mazars-channel-modal.component';
import { IChannelOverviewDto, IInitiateEmailConversationInput } from '@shared/service-proxies/common-interfaces';
import { ActionIcon } from '../../mazars-actions-dropdown-menu/action-icon';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { MazarsParticipantsDialogComponent } from './mazars-participants-dialog/mazars-participants-dialog.component';

@Component({
    selector: 'mazars-chat-channels',
    templateUrl: './mazars-chat-channels.component.html',
    styleUrls: ['./mazars-chat-channels.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsChatChannelsComponent extends AppComponentBase implements OnInit {
    @ViewChild('mazarsChannelModal', { static: true }) mazarsChannelModal: MazarsChannelModalComponent;
    @ViewChild('mazarsWhitelistModal', { static: true }) mazarsWhitelistModal: MazarsWhitelistModalComponent;
    @ViewChild('mazarsParticipantsDialog', { static: true }) mazarsParticipantsDialog: MazarsParticipantsDialogComponent;

    @Input({ required: true }) uid: string;
    @Input() communicationMessageIdentifier: string;
    @Input() referenceId: number;
    @Input() hasCreatePermission: boolean;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Input() hasViewPermission: boolean;
    actionItems: IActionInfo[];

    contextChannelId: any;

    channels: any;
    actionItemsCache: Map<any, IActionInfo[]> = new Map<any, IActionInfo[]>();
    currentChannel: IChannelOverviewDto;
    constructor(injector: Injector, private communicationsFacade: CommonCommunicationsFacade) {
        super(injector);
    }

    ngOnInit(): void {
        this.getAllChannels();

        this.actionItems = [
            {
                visible: this.hasEditPermission,
                label: this.l('EditChannel'),
                icon: ActionIcon.Edit,
            },
            {
                visible: this.hasDeletePermission,
                label: this.l('DeleteChannel'),
                icon: ActionIcon.Delete,
                requireConfirmation: false,
            },
            {
                visible: this.hasCreatePermission,
                label: this.l('btn_InitiateEmail'),
                icon: ActionIcon.Mail,
            },
            {
                visible: this.hasViewPermission,
                label: this.l('ViewWhitelist'),
                icon: ActionIcon.Edit,
            },
            {
                visible: this.hasViewPermission,
                label: this.l('ViewParticipants'),
                icon: ActionIcon.Participants,
            },
        ];

        this.registerSubscriptions();
    }

    getActionItems(record: any): IActionInfo[] {
        if (this.actionItemsCache.has(record)) {
            return this.actionItemsCache.get(record);
        }
        let items = this.getChannelActionItems(record);
        this.actionItemsCache.set(record, items);
        return items;
    }

    getChannelActionItems = (channel: IChannelOverviewDto) =>
        <IActionInfo[]>[
            {
                visible: this.hasEditPermission,
                label: this.l('EditChannel'),
                icon: ActionIcon.Edit,
                action: () => this.showCreateUpdateModal(channel),
            },
            {
                visible: this.hasDeletePermission,
                label: this.l('DeleteChannel'),
                icon: ActionIcon.Delete,
                requireConfirmation: false,
                action: () => this.deleteChannel(channel.id),
            },
            {
                visible: this.hasCreatePermission,
                label: this.l('btn_InitiateEmail'),
                icon: ActionIcon.Mail,
                action: () => this.initiateEmail(channel.id),
            },
            {
                visible: this.hasViewPermission,
                label: this.l('ViewWhitelist'),
                icon: ActionIcon.Edit,
                action: () => this.showWhitelistModal(channel),
            },
            {
                visible: this.hasViewPermission,
                label: this.l('ViewParticipants'),
                icon: ActionIcon.Participants,
                action: () => this.showParticipantsDialog(channel),
            },
        ];

    handleActionItemClick(event: any, record: any): void {
        switch (event.label) {
            case this.actionItems[0].label:
                this.showCreateUpdateModal(record);
                break;
            case this.actionItems[1].label:
                this.deleteChannel(record.id);
                break;
            case this.actionItems[2].label:
                this.initiateEmail(record.id);
                break;
            case this.actionItems[3].label:
                this.showWhitelistModal(record);
                break;
            case this.actionItems[4].label:
                this.showParticipantsDialog(record);
                break;
        }
    }
    showParticipantsDialog(record: IChannelOverviewDto) {
        this.mazarsParticipantsDialog.getUserDetails(record.id);
    }

    onSelectChannel(channel: any) {
        if (channel) {
            this.communicationsFacade.selectChannel(channel.id);
            this.communicationsFacade.getAllChannelCommunications(channel.id);
        }
    }

    public showCreateModal() {
        this.showCreateUpdateModal(null);
    }

    private getAllChannels(): void {
        this.communicationsFacade.getAllChannels(this.referenceId);
    }

    private initiateEmail(channelId) {
        this.communicationsFacade.initiateEmailCommunication({ channelId: channelId, referenceId: this.referenceId } as IInitiateEmailConversationInput);
    }

    private showCreateUpdateModal(channel: IChannelOverviewDto): void {
        this.mazarsChannelModal.show(this.referenceId, channel).then(() => {
            this.notify.success(channel == null ? this.l('SuccessfullyCreated') : this.l('SuccessfullySaved'));
            this.getAllChannels();
            if (channel) {
                this.communicationsFacade.getAllChannelCommunications(channel.id);
            }
        });
    }

    private showWhitelistModal(channel: IChannelOverviewDto): void {
        this.mazarsWhitelistModal.show(channel.id, this.hasDeletePermission, this.hasEditPermission).then(() => {
            this.notify.success(this.l('SuccessfullySaved'));
        });
    }

    private deleteChannel(channelId: number): void {
        this.message.confirm(this.l('DeleteChannelDialog_Content'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.communicationsFacade.deleteChannel(channelId, this.referenceId);
            }
        });
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.communicationsFacade.allChannels$.subscribe((channels) => {
                if (channels) {
                    if (this.channels) {
                        this.communicationsFacade.getNumberOfUnreadChannels(this.referenceId);
                    }
                    let channelsClone = _cloneDeep(channels);

                    if (this.channels) {
                        channelsClone = channelsClone.map((d) => ({ ...d, isSelected: this.channels?.find((x) => x.id === d.id)?.isSelected || d.isSelected }));
                    }

                    this.channels = channelsClone;
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.loaded$.subscribe((loaded) => {
                if (loaded) {
                    this.hideMainSpinner();
                } else {
                    this.showMainSpinner();
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.initiateEamilCommunicationSuccessful$.subscribe(() => {
                this.notify.info(this.l('EmailInitiatedInfo'));
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.deleteChannelSuccessful$.subscribe(() => {
                this.getAllChannels();
                this.notify.success(this.l('SuccessfullyDeleted'));
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.currentChannel$.subscribe((channel) => {
                this.currentChannel = channel;
            })
        );

        this.subscribeToEvent(`app.${this.communicationMessageIdentifier}.messageRecieved`, (message) => {
            const isChannelLoaded = this.channels.find((c) => c.id === message.referenceId);
            if (!isChannelLoaded) {
                this.getAllChannels();
            }
        });
    }
}
