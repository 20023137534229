import { Component, Injector, Input } from '@angular/core';
import { MazarsDownloadService } from '@app/modules/mazars-common/services/mazars-download.service';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';
import { ISubmissionItem, ISubmissionsServiceProxy } from '../../submission-interfaces';

@Component({
    selector: 'mazars-submission-info-section',
    templateUrl: './mazars-submission-info-section.component.html',
})
export class MazarsSubmissionInfoSectionComponent extends AppComponentBase {
    @Input() submissionsServiceProxy: ISubmissionsServiceProxy;
    @Input() submission: ISubmissionItem;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    constructor(
        injector: Injector,
        private mazarsDownloadService: MazarsDownloadService,
        private dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    downloadPdf(): void {
        this.submissionsServiceProxy
            .downloadSubmissionPdf(this.submission?.id, this.submission?.referenceId)
            .subscribe((res) => {
                this.mazarsDownloadService.triggerBrowserDownload(res);
            });
    }

    getDate(date: DateTime): string {
        return date ? this.dateTimeService.formatDate(date, 'dd.MM.yyyy') : '';
    }
}
