import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsTemplateDirective } from '../mazars-template/mazars-template.directive';
import { cloneDeep as _cloneDeep } from 'lodash-es';
@Component({
    selector: 'app-mazars-list-picker',
    styleUrls: ['mazars-list-picker.component.css'],
    templateUrl: './mazars-list-picker.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsListPickerComponent extends AppComponentBase implements OnInit, AfterContentInit, OnChanges {
    @ContentChildren(MazarsTemplateDirective) templateRefs: QueryList<MazarsTemplateDirective>;
    @Input({ required: true }) uid: string;
    @Input() items: any[];
    @Input() targetItems: any[] = [];
    @Input() disabled = false;
    @Output() onMoveToSource: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTargetReorder: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTargetSelect: EventEmitter<any> = new EventEmitter<any>();

    itemTemplate: TemplateRef<any>;
    sourceHeaderTemplate: TemplateRef<any>;
    targetHeaderTemplate: TemplateRef<any>;

    sourceItems: any[] = [];

    ngOnInit(): void {
        this.reloadSourceItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.reloadSourceItems();
    }

    ngAfterContentInit(): void {
        this.templateRefs.forEach((ref) => {
            switch (ref.identifier) {
                case 'itemTemplate':
                    this.itemTemplate = ref.template;
                    break;
                case 'sourceHeaderTemplate':
                    this.sourceHeaderTemplate = ref.template;
                    break;
                case 'targetHeaderTemplate':
                    this.targetHeaderTemplate = ref.template;
                    break;
                default:
                    break;
            }
        });
    }

    onToggle(event?) {
        this.reloadSourceItems();
    }

    handleOnMoveToSource(event?) {
        this.reloadSourceItems();
        this.onMoveToSource.emit(event);
    }

    handleOnMoveToTarget(event?) {
        this.reloadSourceItems();
        this.onMoveToTarget.emit(event);
    }

    handleOnTargetReorder(event?) {
        this.onTargetReorder.emit(event);
    }

    handleOnTargetSelect(event?) {
        this.onTargetSelect.emit(event);
    }

    handleOnSourceReorder(event?) {
        this.reloadSourceItems();
    }

    private reloadSourceItems() {
        const items = _cloneDeep(this.items);
        this.sourceItems = items?.map((item) => ({ ...item, key: crypto.randomUUID() }));
    }
}
