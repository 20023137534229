<mz-dialog
    #mazarsBulkArchiveModalComponent
    [uid]="uid"
    [title]="l('ArchiveAll')"
    [canSubmit]="true"
    [submitButtonLabel]="l('Yes')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <p class="font-size-h6">{{ description }}</p>
    <form [formGroup]="form">
        <div class="form-group">
            <app-mazars-radio-group
                [uid]="'archive_eu_elements'"
                [items]="archiveRadioItems"
                [isVertical]="true" 
                formControlName="archiveElementsOption">
                
            </app-mazars-radio-group>
        </div>
    </form>
</mz-dialog>
