<mz-dialog
    #modal
    [uid]="'whitelist_dialog'"
    [title]="l('AddWhitelistDialog_Title')"
    [canSubmit]="isDataValid()"
    (beforeSubmit)="beforeSubmit($event)"
    [attr.data-testid]="uid"
>
    <p>{{ l('AddWhitelistDialog_Content') }}</p>
    <p-table
        #table
        [value]="whitelist"
        dataKey="id"
        responsiveLayout="scroll"
        (onEditComplete)="onEditComplete($event)"
        [attr.data-testid]="uid + '-table'"
    >
        <ng-template pTemplate="header">
            <tr>
                <th scope="col"id="SourceColumnHeader" pSortableColumn="source">
                    {{ l('Source') }}
                    <p-sortIcon field="source"></p-sortIcon>
                </th>
                <th scope="col"id="EmailColumnHeader" pSortableColumn="email">
                    {{ l('Domain_Or_Email') }}
                    <p-sortIcon field="email"></p-sortIcon>
                </th>
                <th scope="col"*ngIf="hasDeletePermission" id="ActionColumnHeader">
                    {{ l('Action') }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-whitelistEntry let-ri="rowIndex">
            <tr>
                <td>
                    <span class="p-column-title"></span>
                    <span>{{ l('AddWhitelistDialog_Title') }}</span>
                </td>
                <td id="domain-or-email{{ ri }}" pEditableColumn>
                    <p-cellEditor *ngIf="hasEditPermission">
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [placeholder]="l('EmailOrDomainPlaceholder')"
                                [(ngModel)]="whitelistEntry.email"
                                class="w-100"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ whitelistEntry.email }}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf="!hasEditPermission">{{ whitelistEntry.email }}</span>
                </td>
                <td *ngIf="hasDeletePermission">
                    <i aria-hidden="true" role="button" class="flaticon-delete" (click)="deleteWhitelistEntry(ri)" aria-hidden="true"></i>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="emailTouched && !isDataValid()" class="text-danger text-center">
        <small>{{ l('Whitelist_Invalid_Entry') }}</small>
    </div>
    <button type="button" class="btn btn-outline-primary blue m-3" (click)="addWhitelistEntry()">
        <i aria-hidden="true" class="fa fa-times"></i>
        <span>{{ l('AddWhitelistEntry') }}</span>
    </button>
</mz-dialog>
