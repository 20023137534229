import { Component, Injector, SecurityContext, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogInfo } from '../UserNotificationHelper';

@Component({
    selector: 'notification-detail-modal',
    templateUrl: './notification-detail-modal.component.html',
})
export class NotificationDetailModalComponent extends AppComponentBase {
    @ViewChild('messageDetailModal', { static: true }) modal: ModalDirective;

    dialogInfo = {} as DialogInfo;
    securityContext = SecurityContext;

    constructor(injector: Injector) {
        super(injector);
    }

    get severityIcon() {
        switch (this.dialogInfo?.severity) {
            case abp.notifications.severity.SUCCESS:
                return 'fa-8x fas fa-check-circle text-success';
            case abp.notifications.severity.WARN:
                return 'fa-8x fas fa-exclamation-triangle text-warning';
            case abp.notifications.severity.ERROR:
                return 'fa-8x fas fa-exclamation-circle text-danger';
            case abp.notifications.severity.FATAL:
                return 'fa-8x fas fa-bomb text-danger';
            case abp.notifications.severity.INFO:
            default:
                return 'fa-8x fas fa-info-circle text-info';
        }
    }

    show(dialogInfo: DialogInfo) {
        this.dialogInfo = dialogInfo;
        this.modal.show();
    }

    close() {
        this.dialogInfo = undefined;
        this.modal.hide();
    }


}
