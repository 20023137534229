<p-dialog
    #filtersDialog
    position="right"
    [(visible)]="isVisible"
    styleClass="mazars-filters-dialog"
    [contentStyle]="{ overflow: 'visible' }"
    (onHide)="undoChanges()"
    [attr.data-testid]="uid"
>
    <ng-template pTemplate="header">{{ l('Filter') }}</ng-template>
    
        <ng-container [ngTemplateOutlet]="getTemplate('content')"></ng-container>
    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <ng-container [ngTemplateOutlet]="getTemplate('footer')"></ng-container>
            </div>
        </div>
    </ng-template>
</p-dialog>
