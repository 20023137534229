<div class="mz-container" *ngIf="isSingle" [attr.data-testid]="uid">
    <div class="mz-card mz-card-insets">
        <mazars-submission-item
            [submission]="submission"
            [feedback]="submission.feedback"
            [submissionsServiceProxy]="submissionsServiceProxy"
            [feedbackServiceProxy]="feedbackServiceProxy"
        ></mazars-submission-item>
    </div>
</div>

<div class="d-flex flex-column flex-column-fluid" *ngIf="!isSingle">
    <div class="containerClass" *ngFor="let submission of submissions">
        <div class="row">
            <div class="col">
                <p-accordion [multiple]="true" (onOpen)="loadFeedbackData(submission)" [attr.data-testid]="uid + '-item-' + submission?.id"                >
                    <div class="mz-container">
                        <p-accordionTab [(selected)]="submission.isSelected" [(disabled)]="submission.disabled">
                            <ng-template pTemplate="header">
                                <div class="d-flex justify-content-between w-100">
                                    <span class="h4 py-2 m-0 align-middle">{{ l('Submission_Header') }}</span>
                                </div>
                                <div
                                    class="col-1 align-self-center"
                                    (click)="preventAccordionClickOnDropdown($event)"
                                    [hidden]="!hasEditPermission"
                                ></div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="row">
                                    <div class="col">
                                        <mazars-submission-item
                                            [submission]="submission"
                                            [submissionsServiceProxy]="submissionsServiceProxy"
                                            [feedbackServiceProxy]="feedbackServiceProxy"
                                        ></mazars-submission-item>
                                    </div>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </div>
                </p-accordion>
            </div>
        </div>
    </div>
</div>
