import { Injectable, InjectionToken } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonCommunicationsState } from '../state/common-communications.state';

export const CommonCommunicationsSelectorsToken = new InjectionToken<string>('CommonCommunicationsSelectors');

@Injectable()
export class CommonCommunicationsSelectors {
    public readonly communicationsState = createFeatureSelector<CommonCommunicationsState>(this.communicationFeatureKey);
    public readonly getChannels = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.channels);
    public readonly getCurrentChannel = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.currentChannel);
    public readonly getAllWhitelist = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.whitelist);
    public readonly loaded = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.loaded);
    public readonly getCommunicationsError = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.error);
    public readonly getTagsCommunications = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.tags);
    public readonly getTypesCommunications = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.types);
    public readonly getAuthorsCommunications = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.authors);
    public readonly getCommunication = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.communication);
    public readonly getCommunicationError = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.errorUpdatingCommunication);
    public readonly getChannelError = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.errorUpdatingChannel);
    public readonly getWhitelistError = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.errorUpdatingWhitelist);
    public readonly getNumberOfUnreadChannels = createSelector(this.communicationsState, (state: CommonCommunicationsState) => state.numberOfUnreadChannels);


    constructor(private communicationFeatureKey: string) {
    }
}