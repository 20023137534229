import { Component, forwardRef, Injector, Input, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IInvolvedPartyConfig } from '../../interfaces/involved-party-config';

@Component({
    selector: 'app-individual-identification-sub-form',
    templateUrl: './individual-identification-sub-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IndividualIdentificationSubFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IndividualIdentificationSubFormComponent),
            multi: true,
        },
    ],
})
export class IndividualIdentificationSubFormComponent extends AppComponentBase implements ControlValueAccessor, OnDestroy {
    @Input() submitting: boolean;
    @Input() partyConfig: IInvolvedPartyConfig;
    public yesterdayDate: Date;
    isDisabled: any;
    form: FormGroup;
    subscriptions: Subscription[] = [];

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
        this.createForm();
        const todaysDate = new Date();
        this.yesterdayDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() - 1);
        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    get value(): any {
        return this.form.value;
    }

    set value(value: any) {
        this.form.patchValue(value);
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    createForm() {
        this.form = this.fb.group({
            title: [null, [Validators.maxLength(200)]],
            firstName: [null, [Validators.required, Validators.maxLength(200)]],
            middleName: [null, [Validators.maxLength(200)]],
            lastName: [null, [Validators.required, Validators.maxLength(200)]],
            suffix: [null, [Validators.maxLength(200)]],
            dateOfBirth: [null],
            birthPlace: [null, [Validators.maxLength(200)]],
        });
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { address: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
