import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Acceptor } from './acceptor';
import { DialogResult } from './dialog-result';
import { IMazarsCreateUpdateFacade } from './mazars-create-update-facade';
import { MazarsDialogComponent } from './mazars-dialog.component';

@Component({
    selector: 'mz-create-update-dialog',
    templateUrl: './mazars-create-update-dialog.component.html',
})
export class MazarsCreateUpdateDialogComponent<TDto, TCreate, TUpdate> extends AppComponentBase implements OnInit {
    @ViewChild('dialog') dialog: MazarsDialogComponent;

    @Input({ required: true }) uid: string;
    @Input() titleForCreate: string;
    @Input() titleForUpdate: string;
    @Input() canSubmit: boolean;
    @Input() bodyStyleClass = 'modal-body';
    @Input() createUpdateFacade: IMazarsCreateUpdateFacade<TDto, TCreate, TUpdate>;
    @Input() createFormCallback: () => void;
    @Input() model2FormCallback: (model: TDto) => void;
    @Input() form2CreateModelCallback: () => TCreate;
    @Input() form2UpdateModelCallback: () => TUpdate;

    isCreate: boolean;
    _submit;
    _cancel;
    acceptor: Acceptor;

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.registerSubscriptions();
    }

    show(entity?: TDto | number): DialogResult {
        this.isCreate = !entity;
        this.createFormCallback();

        if (this.isCreate) {
            return this.dialog.show();
        }

        if (typeof entity === 'number') {
            let dialogResult: DialogResult = new DialogResult((submit: (result?: any) => void, cancel: () => void) => {
                this._submit = submit;
                this._cancel = cancel;
            });
            this.createUpdateFacade.getById(entity);

            return dialogResult;
        } else {
            this.model2FormCallback(entity);
            return this.dialog.show();
        }
    }

    submit(): void {
        this.dialog.submit();
    }

    beforeSubmit(acceptor: Acceptor): void {
        this.acceptor = acceptor;

        if (this.isCreate) {
            this.createUpdateFacade.create(this.form2CreateModelCallback());
        } else {
            this.createUpdateFacade.update(this.form2UpdateModelCallback());
        }
    }

    private registerSubscriptions() {
        this.subscriptions.push(this.createUpdateFacade.getEntity$.subscribe((result) => {
            if (result) {
                this.model2FormCallback(result);
                this.dialog.show().then(() => this._submit(), () => this._cancel());
            }
        }));

        this.subscriptions.push(this.createUpdateFacade.entityCreatedSuccessfully$.subscribe(() => {
            if (this.acceptor) {
                this.acceptor.accept();
                this.acceptor = null;
            }
        }));

        this.subscriptions.push(this.createUpdateFacade.entityUpdatedSuccessfully$.subscribe(() => {
            if (this.acceptor) {
                this.acceptor.accept();
                this.acceptor = null;
            }
        }));

        this.subscriptions.push(this.createUpdateFacade.createOrUpdateError$.subscribe((error) => {
            if (error && this.acceptor) {
                this.acceptor.reject();
                this.acceptor = null;
            }
        }));
    }
}
