import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[mazarsTemplate]',
})
export class MazarsTemplateDirective {
    @Input('mazarsTemplate') identifier: string;

    constructor(public template: TemplateRef<any>) {}
}
