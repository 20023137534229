import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { PartyType } from '@app/modules/master-data-management/master-data-management-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IInvolvedPartyItem } from '../../interfaces/item.interface';
import { AddressService } from '../../services/address-service';

@Component({
    selector: 'app-mazars-dropdown-masterdataparty',
    templateUrl: './mazars-dropdown-masterdataparty.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MazarsDropdownMasterDataPartyComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsDropdownMasterDataPartyComponent),
            multi: true,
        },
    ],
})
export class MazarsDropdownMasterDataPartyComponent extends AppComponentBase implements ControlValueAccessor, OnInit {
    @Input({ required: true }) uid: string;
    @Input() dropdownLabelLocalization: string = this.l('Client');
    @Input() submitting: boolean;
    @Input() isRequired = false;
    @Input() includeLabel = true;
    @Input() multiselect = false;
    @Input() placeholder?: string;
    @Input() allClients: IInvolvedPartyItem<number>[];
    PartyType: typeof PartyType = PartyType;
    isDisabled: any;
    formControl: FormControl;

    constructor(injector: Injector, private fb: FormBuilder, public addressService: AddressService) {
        super(injector);
    }

    get value(): any {
        return this.formControl.value;
    }

    set value(value: any) {
        this.formControl.patchValue(value);
    }

    ngOnInit(): void {
        this.createFormControl();
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.formControl.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    onDestroy(): void {
        // to be implemented if need it
    }

    getClientType(clientId: number) {
        return this.allClients.find((p) => p.id === clientId)?.type;
    }

    hasAddressInfo(item: IInvolvedPartyItem<any>): boolean {
        return this.addressService.hasAddressInfo(item);
    }

    getFormattedAddress(item: IInvolvedPartyItem<any>): string {
        return this.addressService.getFormattedAddress(item);
    }

    validate(_: FormControl) {
        return this.formControl.valid ? null : { client: { valid: false } };
    }

    onSelectedItemChanged(change) {
        this.value = this.multiselect ? change : change.newValue;
        if (this.value !== undefined) {
            this.onChange(this.value);
        }
    }

    private createFormControl(): void {
        this.formControl = this.fb.control(
            {
                value: null,
                isDisabled: this.isDisabled,
            },
            this.isRequired ? Validators.required : null
        );
    }
}
