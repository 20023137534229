import { Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Criticality, Priority } from '@app/modules/dac6/dac6-proxies';
import { EntityCustomPermissionsFacade } from '@app/shared/common/facades/entity-custom-permissions.facade';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    ICustomGrantsForPermissionWithAccessRestrictionDataInput,
    IUserOrOrganizationUnitDto,
    IUserOrOrganizationUnitInput,
} from '@shared/service-proxies/common-interfaces';
import { IReferenceDataFacade } from '@shared/service-proxies/interfaces/IReferenceDataFacade';
import { IItem } from '../../interfaces/item.interface';
import { MazarsCustomPermissionModel } from '../mazars-custom-permissions/mazars-custom-permission-model';
@Component({
    selector: 'app-mazars-form-project-management-card',
    templateUrl: './mazars-form-project-management-card.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsFormProjectManagementCardComponent,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsFormProjectManagementCardComponent),
            multi: true,
        },
    ],
})
export class MazarsFormProjectManagementCardComponent extends AppComponentBase implements ControlValueAccessor, OnInit, OnChanges {
    @Input() isDisabled: boolean;
    @Input() referenceDataFacade: IReferenceDataFacade;
    @Input() permissionsFacade: EntityCustomPermissionsFacade;
    @Input() permissionName: string;
    @Input() entityId: number;
    @Input({ required: true }) uid: string;
    @Input() dataLoading: boolean;
    referenceDataLoading: boolean;

    showWarnings: boolean;
    isAccessRestrictionActive: any;
    selectedAccessRestrictionProvisionalData: IUserOrOrganizationUnitDto[];

    possibleUsers: IItem<number>[];
    entityPermissions: MazarsCustomPermissionModel[];

    possiblePriority: IItem<number>[];
    possibleCriticality: IItem<number>[];
    form!: FormGroup;

    private priority = [Priority.One, Priority.Two, Priority.Three, Priority.Four];
    private criticality = [
        {
            text: 'VeryLow',
            id: Criticality.VeryLow,
        },
        {
            text: 'Low',
            id: Criticality.Low,
        },
        {
            text: 'Medium',
            id: Criticality.Medium,
        },
        {
            text: 'High',
            id: Criticality.High,
        },
    ];

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
        this.possiblePriority = this.priority.map<IItem<number>>((it) => <IItem<number>>{ id: it, text: this.l(`${it}`) });
        this.possibleCriticality = this.criticality.map<IItem<number>>((it) => <IItem<number>>{ id: it.id, text: this.l(`${it.text}`) });
    }

    get value(): any {
        return this.form?.value;
    }

    set value(projectManagementDto: any) {
        if (projectManagementDto.hasOwnProperty('assignedUser')) {
            const assignedUserGroup = this.form.get('assignedUser') as FormGroup;
            if (assignedUserGroup) {
                assignedUserGroup.controls.id.setValue(projectManagementDto.assignedUser?.id);
            } else {
                const assignedUserGroup = this.fb.group({
                    id: [{ value: projectManagementDto.assignedUser?.id, disabled: this.isDisabled }],
                });

                this.form.addControl('assignedUser', assignedUserGroup);
            }
        }
        if (projectManagementDto.hasOwnProperty('plannedStart')) {
            if (this.form.controls.plannedStart) {
                this.form.controls.plannedStart.setValue(projectManagementDto.plannedStart);
            } else {
                const plannedStartControl = this.fb.control({ value: projectManagementDto.plannedStart, disabled: this.isDisabled });
                this.form.addControl('plannedStart', plannedStartControl);
            }
        }
        if (projectManagementDto.hasOwnProperty('plannedEnd')) {
            if (this.form.controls.plannedEnd) {
                this.form.controls.plannedEnd.setValue(projectManagementDto.plannedEnd);
            } else {
                const plannedEndControl = this.fb.control({ value: projectManagementDto.plannedEnd, disabled: this.isDisabled });
                this.form.addControl('plannedEnd', plannedEndControl);
            }
        }
        if (projectManagementDto.hasOwnProperty('priority')) {
            if (this.form.controls.priority) {
                this.form.controls.priority.setValue(projectManagementDto.priority || null);
            } else {
                const priorityControl = this.fb.control({ value: projectManagementDto.priority || null, disabled: this.isDisabled });
                this.form.addControl('priority', priorityControl);
            }
        }
        if (projectManagementDto.hasOwnProperty('criticality')) {
            if (this.form.controls.criticality) {
                this.form.controls.criticality.setValue(projectManagementDto.criticality || null);
            } else {
                const criticalityControl = this.fb.control({ value: projectManagementDto.criticality || null, disabled: this.isDisabled });
                this.form.addControl('criticality', criticalityControl);
            }
        }
        if (projectManagementDto.hasOwnProperty('effort')) {
            if (this.form.controls.effort) {
                this.form.controls.effort.setValue(projectManagementDto.effort || null);
            } else {
                const effortControl = this.fb.control({ value: projectManagementDto.effort || null, disabled: this.isDisabled });
                this.form.addControl('effort', effortControl);
            }
        }

        this.form.updateValueAndValidity();
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isNotFirstChange = Object.values(changes).some((c) => !c.isFirstChange());
        if (isNotFirstChange) {
            this.updatePossibleUsers();
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({});
        this.referenceDataLoading = true;
        this.registerSubscriptions();
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { projectManagement: { valid: false } };
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );

        this.subscriptions.push(
            this.referenceDataFacade.showWarnings$.subscribe((result) => {
                if (result) {
                    this.showWarnings = result;
                }
            })
        );

        this.subscriptions.push(
            this.permissionsFacade.isAccessRestrictionActive$.subscribe((isAccessRestrictionActive) => {
                this.isAccessRestrictionActive = isAccessRestrictionActive;
            })
        );

        this.subscriptions.push(
            this.permissionsFacade.entityPermissions$.subscribe((result) => {
                this.entityPermissions = result;
                this.referenceDataLoading = false;
                this.updatePossibleUsers();
            })
        );

        this.subscriptions.push(
            this.permissionsFacade.selectedAccessRestrictionProvisionalData$.subscribe((selectedAccessRestrictionProvisionalData) => {
                this.selectedAccessRestrictionProvisionalData = selectedAccessRestrictionProvisionalData;
            })
        );

        this.subscriptions.push(
            this.permissionsFacade.assignedPossibleUsers$.subscribe((assignedPossibleUsers) => {
                if (assignedPossibleUsers) {
                    this.possibleUsers = assignedPossibleUsers;
                }
            })
        );
    }

    private updatePossibleUsers() {
        if (this.entityPermissions) {
            const permission = this.entityPermissions.find((e) => e.fullPermissionName === this.permissionName);

            if (permission) {
                this.possibleUsers = permission.possibleUsersOriginalListResultDto.items.map(
                    (user) =>
                        ({
                            id: user.userOrOrganizationId,
                            text: user.displayName,
                        } as IItem<number>)
                );
            } else {
                this.getAssignedPossibleUsers();
            }
        }
    }

    private getAssignedPossibleUsers() {
        if (this.isAccessRestrictionActive) {
            const customGrantsForPermissionInput = {
                entityId: isNaN(this.entityId) ? 0 : this.entityId,
                permissionName: this.permissionName,
                usersAndOrganizationUnits: [],
                accessRestrictionProvisionalData: this.selectedAccessRestrictionProvisionalData as IUserOrOrganizationUnitInput[],
            } as ICustomGrantsForPermissionWithAccessRestrictionDataInput;
            this.permissionsFacade.getAssignedPossibleUsersWithProvisionalData(customGrantsForPermissionInput);
        } else {
            this.permissionsFacade.getAssignedPossibleUsers(isNaN(this.entityId) ? 0 : this.entityId, this.permissionName, true);
        }
    }
}
