import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-mazars-pdf-options',
    templateUrl: './mazars-pdf-options.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsPdfOptionsComponent,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsPdfOptionsComponent),
            multi: true,
        },
    ],
})
export class MazarsPdfOptionsComponent extends AppComponentBase implements ControlValueAccessor, OnInit {
    @Input() isDisabled: boolean;
    @Input({ required: true }) uid: string;
    form!: FormGroup;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    get value(): any {
        return this.form?.value;
    }

    set value(pdfOptions: any) {
        this.form.controls.addSignatureField.setValue(pdfOptions.addSignatureField);
        this.form.controls.includeReadOnlyValues.setValue(pdfOptions.includeReadOnlyValues);

        this.form.updateValueAndValidity();
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            addSignatureField: [false, Validators.required],
            includeReadOnlyValues: [false, Validators.required],
        });
        
        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { pdfGenerationOptions: { valid: false } };
    }
}
