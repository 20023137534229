import { Component, Input, SecurityContext } from '@angular/core';
import { ITabMenuItem } from './tab-menu-interfaces';

@Component({
    selector: 'app-mazars-tab-menu',
    templateUrl: './mazars-tab-menu.component.html',
    styleUrls: ['./mazars-tab-menu.component.css'],
})
export class MazarsTabMenuComponent {
    @Input({ required: true }) uid: string;
    @Input() items: ITabMenuItem[];
    @Input() iconTabs: ITabMenuItem[];
    securityContext = SecurityContext;
}
