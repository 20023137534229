import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IAdditionalButton, MazarsSubmitButtonComponent } from '../mazars-submit-button/mazars-submit-button.component';

@Component({
    selector: 'app-mazars-submit-draft-button',
    templateUrl: './mazars-submit-draft-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsSubmitDraftButtonComponent extends MazarsSubmitButtonComponent {
    public readonly endDraftButtonId = 'end-draft';

    //disable the additional button props and use the `draft` ones instead
    readonly additionalButtons: IAdditionalButton[] = [];
    readonly onAdditionalButtonClick = new EventEmitter<string>();
    @Input({ required: true }) uid: string;
    @Input() isDraftBusy = false;
    @Input() draftLabel = this.l('EndDraftMode');
    @Output() onDraftButtonClick = new EventEmitter<void>();
}
