import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import * as SurveyKo from 'survey-knockout';
import * as SurveyCreator from 'survey-creator';
import * as widgets from 'surveyjs-widgets';
import { init as initCustomWidget } from './customwidget';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsDisplayDialogComponent } from '../mazars-display-dialog/mazars-display-dialog.component';

widgets.icheck(SurveyKo);
widgets.select2(SurveyKo);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo);
widgets.jqueryuidatepicker(SurveyKo);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo);
//widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo);
widgets.bootstrapslider(SurveyKo);
//widgets.emotionsratings(SurveyKo);
initCustomWidget(SurveyKo);

SurveyCreator.StylesManager.applyTheme('darkblue');

SurveyKo.Serializer.addProperty('question', {
    name: 'popupdescription:text',
    category: 'general',
    visibleIndex: 3,
    isLocalizable: true,
});
SurveyKo.Serializer.addProperty('page', {
    name: 'popupdescription:text',
    category: 'general',
    visibleIndex: 3,
    isLocalizable: true,
});
SurveyKo.surveyLocalization.supportedLocales = ['en', 'de', 'fi', 'bg', 'fr', 'gr', 'hu', 'it', 'pl', 'pt', 'es', 'da', 'sv', 'cs'];

// var CkEditor_ModalEditor = {
//   afterRender: function(modalEditor, htmlElement) {
//     var editor = window["CKEDITOR"].replace(htmlElement);
//     editor.on("change", function() {
//       modalEditor.editingValue = editor.getData();
//     });
//     editor.setData(modalEditor.editingValue);
//   },
//   destroy: function(modalEditor, htmlElement) {
//     var instance = window["CKEDITOR"].instances[htmlElement.id];
//     if (instance) {
//       instance.removeAllListeners();
//       window["CKEDITOR"].remove(instance);
//     }
//   }
// };
// SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget(
//   "html",
//   CkEditor_ModalEditor
// );
// CKEditor is the third party library
/* Steps to use CKEditor in the project:
    1. run 'npm i ckeditor'
    2. update the "build" section of the angular.json: add ckeditor.js script in the scripts section.
*/

@Component({
    selector: 'survey-creator',
    template: `
        <div id="surveyCreatorContainer"></div>
        <app-mazars-display-dialog #moreInfoDisplayDialog [uid]="'survey-create-dialog'" [okBtnText]="l('Cancel')" [title]="l('Information')">
            <div id="questionDescriptionText"></div>
        </app-mazars-display-dialog>
    `,
})
export class SurveyCreatorComponent extends AppComponentBase implements OnInit {
    @Input() json: any;
    @Output() surveySaved: EventEmitter<Object> = new EventEmitter();
    @ViewChild('moreInfoDisplayDialog', { static: false }) moreInfoDisplayDialog: MazarsDisplayDialogComponent;
    surveyCreator: SurveyCreator.SurveyCreator;
    moreInfoText: string;

    ngOnInit() {
        SurveyKo.JsonObject.metaData.addProperty('questionbase', 'popupdescription:text');
        SurveyKo.JsonObject.metaData.addProperty('page', 'popupdescription:text');

        let that = this;
        let scOptions = {
            showEmbededSurveyTab: true,
            generateValidJSON: true,
            haveCommercialLicense: true,
            isAutoSave: true,
        };

        SurveyCreator.editorLocalization.currentLocale = this.localization.currentLanguage.name;
        this.surveyCreator = new SurveyCreator.SurveyCreator('surveyCreatorContainer', scOptions);
        this.surveyCreator.text = JSON.stringify(this.json);
        this.surveyCreator.saveSurveyFunc = this.saveMySurvey;

        this.surveyCreator.onSurveyInstanceCreated.add(function (_sender, intlOptions) {
            if (intlOptions.reason === 'designer' || intlOptions.reason === 'test') {
                intlOptions.survey.onAfterRenderQuestion.add((_survey, options) => {
                    if (!options.question.popupdescription) {
                        return;
                    }
                    // Add a button;

                    const btn = document.createElement('button');
                    btn.type = 'button';
                    btn.className = 'btn btn-primary';
                    btn.style.marginLeft = '8px';
                    btn.style.height = '24px';
                    btn.style.width = '92px';
                    btn.style.padding = '0px 4px 0px 4px';
                    btn.style.lineHeight = '24px';
                    btn.style.fontSize = '12px';
                    btn.innerHTML = that.l('btn_MoreInfo');
                    btn.onclick = () => {
                        that.showMoreInfoDialog(options.question);
                    };
                    let header = options.htmlElement.querySelector('h5');
                    if (!header) {
                        header = options.htmlElement;
                    }
                    let headerDiv = header.children ? header.children[header.children.length - 1] : header;
                    headerDiv.appendChild(btn);
                });
            }
        });
    }

    showMoreInfoDialog(question: any): void {
        let code = this.localization.currentLanguage.name;
        this.moreInfoText =
            typeof question.popupdescription === 'object'
                ? question.popupdescription[code]
                    ? question.popupdescription[code]
                    : question.popupdescription['en']
                : question.popupdescription;

        if (this.moreInfoDisplayDialog) {
            document.getElementById('questionDescriptionText').innerHTML = this.moreInfoText;
            this.moreInfoDisplayDialog.show();
        }
    }

    saveMySurvey = () => {
        this.surveySaved.emit(JSON.parse(this.surveyCreator.text));
    };
}
