import { Component, ElementRef, HostListener, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ChangeUserLanguageDto, ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { filter as _filter } from 'lodash-es';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    templateUrl: './mazars-language-dropdown.component.html',
    selector: 'mazars-language-dropdown',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsLanguageDropdownComponent extends ThemesLayoutBaseComponent implements OnInit {
    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    handleOutsideClick(event) {
        // deactive languages submenu when clicking somewhere else
        if (!this._elementRef.nativeElement.contains(event.target)) {
            this.isSubMenuActive = false;
        }
    }

    isSubMenuActive: boolean;
    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;

    public constructor(
        injector: Injector,
        dateTimeService: DateTimeService,
        private _sessionService: AppSessionService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _router: Router,
        private _elementRef: ElementRef
    ) {
        super(injector, dateTimeService);
    }

    ngOnInit(): void {
        this.languages = _filter(this.localization.languages, (l) => l.isDisabled === false);
        this.currentLanguage = this.localization.currentLanguage;

        //deactive languages submenu on navigation start event
        this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
            this.isSubMenuActive = false;
        });
    }

    changeLanguage(languageName: string): void {
        if (this._sessionService.user) {
            const input = <ChangeUserLanguageDto>{
                languageName: languageName,
            };

            this._profileServiceProxy.changeLanguage(input).subscribe(() => {
                this.setCookie(languageName);
                window.location.reload();
            });

            return;
        }

        this.setCookie(languageName);
        window.location.reload();
    }

    toggleLanguagesMenu() {
        this.isSubMenuActive = !this.isSubMenuActive;
    }

    private setCookie(languageName: string) {
        abp.utils.setCookieValue(
            'Abp.Localization.CultureName',
            languageName,
            new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath
        );
    }
}
