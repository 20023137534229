import { Component, Injector, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CloseableDialogResult } from '../mazars-dialog/closeable-dialog-result';
import { MazarsCloseableDialogComponent } from '../mazars-dialog/mazars-closeable-dialog.component';

@Component({
    selector: 'app-mazars-display-dialog',
    templateUrl: './mazars-display-dialog.component.html',
})
export class MazarsDisplayDialogComponent extends AppComponentBase {
    @ViewChild('modal') modal: MazarsCloseableDialogComponent;
    @Input({ required: true }) uid: string;
    @Input() title: string;
    @Input() okBtnText: string = this.l('Ok');
    @Input() hideOkBtn: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    show(): CloseableDialogResult {
        return this.modal.show(this.title, null, this.okBtnText);
    }

    close(): void {
        this.modal.close();
    }
}
