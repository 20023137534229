import { Injectable } from '@angular/core';
import * as SurveyPDF from 'survey-pdf';
import * as Survey from 'survey-angular';
import { IFileDownloadDto } from '@shared/service-proxies/common-interfaces';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

@Injectable({
    providedIn: 'root',
})
export class MazarsDownloadService {
    public triggerBrowserDownload(dataObject: IFileDownloadDto) {
        let fileName = dataObject.fileName;
        let data = dataObject.content;
        let mimeType = dataObject.mimeType;

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimeType || 'application/octet-stream' });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE doesn't allow using a blob object directly as link href.
            // Workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, fileName);
            return;
        }
        // Other browsers
        // Create a link pointing to the ObjectURL containing the blob
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', fileName);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        tempLink.click();
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    }

    public downloadSurveyPdf(survey) {
        let options = {
            fontSize: 14,
            margins: {
                left: 10,
                right: 10,
                top: 10,
                bot: 10,
            },
        };
        let surveyModel = new Survey.SurveyModel(survey.questionnaire);
        if (typeof survey.answers === 'string') {
            survey.answers = JSON.parse(survey.answers);
        }
        surveyModel.data = survey.answers;
        const surveyPDF = new SurveyPDF.SurveyPDF(surveyModel.toJSON(), options);
        surveyPDF.data = surveyModel.data;
        surveyPDF.mode = 'display';
        surveyPDF.haveCommercialLicense = true;
        surveyPDF.save(survey.name);
    }
}
