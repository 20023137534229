import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IUserDetailsDto } from '@shared/service-proxies/common-interfaces';

@Component({
  selector: 'app-mazars-avatar-group',
  templateUrl: './mazars-avatar-group.component.html',
  styleUrls: ['./mazars-avatar-group.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MazarsAvatarGroupComponent {
  @Input({ required: true }) uid: string;
  @Input() userDetails: IUserDetailsDto[];
  @Input() maxShown = 6;
}
