import { Component, ViewChild, Injector, Input } from '@angular/core';
import { IValidateResultDto } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { MazarsValidationResultComponent } from '../mazars-validation-result/mazars-validation-result.component';

@Component({
    selector: 'mazars-validation-result-modal',
    templateUrl: './mazars-validation-result-modal.component.html',
})
export class MazarsValidationResultModalComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() showSuccessMessage = true;
    @ViewChild('validationResultDialog', { static: true }) validationResultDialog: MazarsDialogComponent;
    @ViewChild('validationResult', { static: true }) validationResult: MazarsValidationResultComponent;
    title = this.l('Validation_Result');

    constructor(injector: Injector) {
        super(injector);
    }

    show(result: IValidateResultDto): DialogResult {
        this.validationResult.setValidationResult(result);
        return this.validationResultDialog.show();
    }
}
