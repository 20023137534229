import { Component, Input, EventEmitter, Output, ViewChild, ViewEncapsulation, OnChanges, OnInit, SimpleChanges, Injector } from '@angular/core';
import { SplitButton } from 'primeng/splitbutton';
import { IActionInfo, ICommandInfo } from '../mazars-actions-dropdown-menu/action-info';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ActionIcon } from '../mazars-actions-dropdown-menu/action-icon';

@Component({
    selector: 'app-mazars-actions-menu',
    templateUrl: './mazars-actions-menu.component.html',
    styleUrls: ['./mazars-actions-menu.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsActionsMenuComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() actionItems: IActionInfo[];
    @Input() fontSize: string;
    @Input() btnSize: string;
    @Input() vertical: boolean;
    @Input() isDynamic: boolean;
    @Input() hasMainAction: boolean;
    @Input() icon = 'flaticon-more-1';
    @Output() itemClick: EventEmitter<IActionInfo> = new EventEmitter<IActionInfo>();
    @Output() onDropdownHide: EventEmitter<any> = new EventEmitter();
    @Output() onDropdownShow: EventEmitter<any> = new EventEmitter();
    @ViewChild('splittButton', { static: false }) splittButton: SplitButton;

    constructor(_injector: Injector) {
        super(_injector);
    }

    get actionMenuStyles(): string {
        let styles = '';
        if (this.isDynamic) {
            styles += 'mazars-dynamic-actions-menu';
        }
        if (this.hasMainAction) {
            styles += ' mz-main-action';
        }
        return styles;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.actionItems && this.reInitNeeded(changes.actionItems.currentValue, changes.actionItems.previousValue)) {
            this.initActionItems(changes.actionItems.currentValue);
        }
    }

    ngOnInit(): void {
        if (this.actionItems) {
            this.initActionItems(this.actionItems);
        }
    }

    onClick() {
        this.splittButton.onDropdownButtonClick(null);
    }

    handleDropdownHide() {
        this.onDropdownHide.emit();
    }

    handleDropdownShow() {
        this.onDropdownShow.emit();
    }

    onItemClick(item: IActionInfo): any {
        this.itemClick.emit(item);
        if (item.action && (item.requireConfirmation === true || (item.requireConfirmation !== false && item.icon === ActionIcon.Delete))) {
            this.message.confirm('', item.confirmationMessage ? item.confirmationMessage : this.l('AreYouSure'), (isConfirmed) => {
                if (isConfirmed) {
                    item.action(item.record);
                }
            });
        } else if (item.action) {
            item.action(item.record);
        }
    }

    private reInitNeeded(currentActionItems: IActionInfo[], previousActionItems: IActionInfo[]) {
        if (currentActionItems && !previousActionItems) {
            return true;
        }
        if (!currentActionItems || !previousActionItems) {
            return false;
        }

        const isDynamic = currentActionItems.find((item) => item.operation !== undefined);

        if (isDynamic && currentActionItems.length !== previousActionItems.length) {
            return true;
        }

        if (currentActionItems.length !== previousActionItems.length) {
            return false;
        }

        const changedObjects = currentActionItems.filter(
            (ai) => previousActionItems.find((x) => x.visible !== ai.visible) || previousActionItems.find((x) => x.action !== ai.action),
        );

        if (changedObjects && changedObjects.length > 0) {
            return true;
        }
    }

    private initActionItems(actionItems: ICommandInfo[]) {
        actionItems.forEach((ai) => {
            if (!ai.separator) {
                ai.command = () => this.onItemClick(ai);
            }
        });
    }
}
