import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FileSelectEvent } from 'primeng/fileupload';

@Component({
    selector: 'app-mazars-upload-button',
    templateUrl: './mazars-upload-button.component.html',
    styleUrls: ['./mazars-upload-button.component.css'],
})
export class MazarsUploadButtonComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input({ required: true }) uid: string;
    @Input() isDisabled: boolean;
    @Input() accept: string;
    @Input() maxFileSize: string;
    @Input() name = 'File[]';
    @Input() title: string;
    @Input() isLoading = false;
    @Input() validationTemplate;
    @Output() onUpload: EventEmitter<File> = new EventEmitter<File>();
    @Output() onSelect: EventEmitter<FileSelectEvent> = new EventEmitter<FileSelectEvent>();

    files: File[] = [];
    selectedFile: File;
    form!: FormGroup;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
    }

    ngOnDestroy(): void {
        this.clearForm();
    }

    createForm() {
        this.form = this.fb.group({
            fileName: [null, [Validators.required]],
            file: [null, [Validators.required]],
            fileToken: [null],
        });
    }

    clearForm() {
        this.files = [];
        this.selectedFile = null;
        this.form.patchValue({ fileToken: null, fileName: null, file: null });
        this.onUpload.emit(null);
    }

    handleOnSelectFile(fileInput: any): void {
        this.selectedFile = <File>fileInput.files[0];
        this.files = [];
        if (!this.selectedFile) {
            return;
        }
        this.form.patchValue({ fileName: this.selectedFile.name, file: this.selectedFile });
        this.onUpload.emit(this.selectedFile);
    }

    removeFile(): void {
        this.form.patchValue({ fileName: null, file: null });
        this.onUpload.emit(null);
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }
}
