import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    Output,
    QueryList,
    SecurityContext,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MazarsTemplateDirective } from '@app/modules/mazars-common/components/mazars-template/mazars-template.directive';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ScrollerOptions } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';
import { IMazarsMultiselectItem } from '../mazars-multiselect/mazars-multiselect-item';
import { MultiselectOptions } from './muliselect-options';

@Component({
    selector: 'app-mazars-lazy-multiselect',
    templateUrl: './mazars-lazy-multiselect.component.html',
    styleUrls: ['./mazars-lazy-multiselect.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsLazyMultiselectComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsLazyMultiselectComponent<TKey> extends AppComponentBase implements OnChanges, AfterContentInit, ControlValueAccessor {
    @ContentChildren(MazarsTemplateDirective) templateRefs: QueryList<MazarsTemplateDirective>;
    @ViewChild('dd', { static: true }) dd: Dropdown;
    @ViewChild('searchInput') input: ElementRef;

    @Input() uid!: string;
    @Input() options: MultiselectOptions;
    @Input() hasEmpty: boolean;
    @Input() showClearOption = true;
    @Input() filter: boolean;
    @Input() virtualScrollItemSize = 38;
    @Input() placeholder: string;
    @Input() filterPlaceholder: string;
    @Input() isDisabled: boolean;
    @Input() appendTo = ''; // we should pass input parameter appendTo = 'body', if the dropdown appeared behind another component
    @Input() style: any;
    @Input() handleDeletedEntry = true;
    @Input() removeSelectedValueFromOptions = false;
    @Input() additionalFiltersCallback: Function = null;
    @Input() fillIsMissing: (item: IMazarsMultiselectItem[]) => any[];
    @Input() isLoadingForEmptySearch = true;
    @Input() optionValue: string;
    @Input() optionLabel: string;
    @Output() onSelectedItemChanged = new EventEmitter<IMazarsMultiselectItem[]>();
    @Output() onDropdownOpened = new EventEmitter();
    @Output() onFilterChanged = new EventEmitter();
    @Output() onLazyLoad = new EventEmitter();

    scrollerOptions: ScrollerOptions = {
        delay: 0,
        lazy: false,
        onScrollIndexChange: this.handleOnLazyLoad.bind(this),
    };
    itemTemplate: TemplateRef<any>;
    selectedItemTemplate: TemplateRef<any>;
    selectItems: IMazarsMultiselectItem[] = [];
    selectedItems: any[] = [];
    writtenItemId: TKey;
    securityContext = SecurityContext;
    disabled: boolean;
    loading = false;
    loadLazyTimeout = null;
    isMoreDataAvailable = false;
    filterValue: string;

    currentSkip = 0;
    currentmaxResultCount = 0;
    loadingStep = 100;

    constructor(injector: Injector) {
        super(injector);
    }

    ngAfterContentInit(): void {
        this.templateRefs.forEach((ref) => {
            switch (ref.identifier) {
                case 'item':
                    this.itemTemplate = ref.template;
                    break;
                case 'selectedItem':
                    this.selectedItemTemplate = ref.template;
                    break;
                default:
                    this.itemTemplate = ref.template;
                    break;
            }
        });
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(objArr: TKey[]): void {
        if (objArr?.length > 0) {
            objArr?.forEach((obj) => {
                if (obj?.hasOwnProperty('id') && obj?.hasOwnProperty('text')) {
                    this.writtenItemId = obj['id'];
                    const item = this.selectItems?.find((e) => e?.value?.id === this.writtenItemId);
                    if (item) {
                        this.selectedItems.push(item.value);
                    } else {
                        const newItem = {
                            value: { id: this.writtenItemId, text: obj['text'] },
                            label: obj['text'],
                            styleClass: obj?.hasOwnProperty('isMissing') && obj['isMissing'] && 'mz-red',
                        };
                        this.selectItems.push(newItem);
                        this.selectedItems.push({ id: this.writtenItemId, text: obj['text'] });
                    }
                    this.checkIsMissing();
                }
            });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnChanges(changes): void {
        if (changes.options && !changes.options.firstChange) {
            const items = this.options.options
                ? (this.options.options as any[]).map(
                      (item) =>
                          ({
                              value: item,
                              label: item.text,
                          }) as IMazarsMultiselectItem,
                  )
                : [];
            this.selectItems = [...this.selectItems, ...items];
            this.loading = false;
            if (this.options.count > this.currentmaxResultCount) {
                this.isMoreDataAvailable = true;
            } else {
                this.isMoreDataAvailable = false;
            }
        }
    }

    onFilter($event) {
        this.loading = true;
        this.currentSkip = 0;
        this.currentmaxResultCount = this.loadingStep;
        this.selectItems = [];
        this.onLazyLoad.emit({ filter: this.filterValue, skipCount: this.currentSkip, maxResultCount: this.currentmaxResultCount });
    }

    onBlur() {
        this.onTouched();
    }

    onShow() {
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(500),
                distinctUntilChanged(),
                tap((text) => {
                    this.onFilter(text);
                }),
            )
            .subscribe();

        this.selectItems = [];
        if (this.isLoadingForEmptySearch === true) {
            this.loading = true;
            this.currentSkip = 0;
            this.currentmaxResultCount = this.loadingStep;
            this.onLazyLoad.emit({ filter: this.filterValue, skipCount: this.currentSkip, maxResultCount: this.currentmaxResultCount });
        }
        this.onDropdownOpened.emit();
    }

    handleOnLazyLoad(event) {
        if (event.last === this.currentmaxResultCount) {
            this.loading = true;
            this.currentSkip = this.currentmaxResultCount;
            this.currentmaxResultCount = this.currentmaxResultCount + this.loadingStep;
            this.onLazyLoad.emit({ filter: this.filterValue, skipCount: this.currentSkip, maxResultCount: this.currentmaxResultCount });
        }
    }

    onChangeHandler(event: any) {
        this.onSelectedItemChanged.emit(this.selectedItems);
        this.onChange(this.selectedItems);
    }

    getSelectedItemsValue() {
        return this.getSelectedItems(this.selectedItems).join(', ');
    }

    getSelectItem(id: any) {
        let foundItems: any[] = this.selectItems ? this.selectItems.filter((i) => i.value === id) : null;
        let isItemFound = foundItems && foundItems.length === 1;

        return {
            value: isItemFound ? foundItems[0].value : undefined,
            label: isItemFound ? foundItems[0].label : undefined,
            icon: isItemFound ? foundItems[0].icon : undefined,
            iconColor: isItemFound ? foundItems[0].iconColor : undefined,
            styleClass: isItemFound ? foundItems[0].styleClass : undefined,
        };
    }

    getSelectedItems(selectedItems: any[]) {
        if (this.selectItems && this.selectItems.length > 0 && selectedItems && selectedItems.length > 0) {
            try {
                return selectedItems.map((si) => this.selectItems.filter((i) => i.value === si)[0].label);
            } catch (error) {
                return [];
            }
        }

        return [];
    }

    private checkIsMissing() {
        if (this.fillIsMissing && this.selectedItems) {
            let selectedItems = this.fillIsMissing({ ...this.selectedItems });
            selectedItems.forEach((si) => {
                this.selectItems.find((item) => item.value.id === si.id).styleClass = si.isMissing ? 'mz-red' : '';
            });
        }
    }
}
