import { Component, Input, Output, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
    selector: 'app-mazars-autocomplete',
    templateUrl: './mazars-autocomplete.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsAutocompleteComponent,
        },
    ],
    styleUrls: ['./mazars-autocomplete.component.css'],
})
export class MazarsAutocompleteComponent implements ControlValueAccessor {
    @ViewChild('autocomplete') autocomplete: AutoComplete;
    @Input({ required: true }) uid: string;
    @Input() placeholder: string;
    @Input() availableData: string[];
    @Input() multiple: boolean = false;
    @Input() dropdownIcon: string;
    @Input() isDropDown: boolean = false;
    @Output() onSelected = new EventEmitter();
    @Output() onKeyUped = new EventEmitter();
    @Output() onCleared = new EventEmitter();

    suggestions: string[];
    currentValue: string;
    value: string;
    isDisabled: boolean;

    onValueChange(event) {
        this.value = event;

        this.onChange(this.value);
    }

    clearItems(autocomplete: any) {
        autocomplete.value = "";
        autocomplete.handleDropdownClick();

        this.onCleared.emit();
    }

    filterData(event) {
        this.suggestions = [];
        let searchText = event.query.trim().toLowerCase();
        let modelLower = !this.value ? "" : this.value.toLowerCase();
        let searchTextLower = searchText.toLowerCase();
        this.currentValue = searchText;

        if (this.availableData != null) {
            this.availableData.forEach((t) => {
                let tLower = t.toLowerCase();
                if (modelLower.indexOf(tLower) === -1) {
                    if (tLower.indexOf(searchTextLower) !== -1) {
                        this.suggestions.push(t);
                    }
                }
            });
        }

        // Add current input, if not an existing
        if (searchText !== '' &&
            modelLower.indexOf(searchTextLower) === -1 &&
            this.suggestions.map((v) => v.toLowerCase()).indexOf(searchTextLower) === -1
        ) {
            this.suggestions.unshift(searchText);
        }
    }

    onKeyUp(event) {
        if (this.currentValue !== '' && event.key === 'Enter') {
            this.currentValue = '';

            this.autocomplete.hide();
            this.onChange(this.value);
        }

        this.onKeyUped.emit(event);
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj == null) {
            this.value = "";
        } else {
            this.value = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlur() {
        this.onTouched();        
    }

    onSelect(event) {
        this.onSelected.emit(event);
    }
}
