import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-mazars-input-toggle',
    templateUrl: './mazars-input-toggle.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsInputToggleComponent
        }
    ]
})
export class MazarsInputToggleComponent implements ControlValueAccessor {

    @Input({ required: true }) uid: string;
    @Input() placeholder?: string;
    isDisabled: boolean;

    value: boolean;

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onInputChange() {
        this.onChange(this.value);
    }

    onBlur() {
        this.onTouched();
    }
}
