<p>{{ l(entityName + '_Process_Flow_Dropdown_Header') }}</p>
<div class="row">
    <div class="col-6">
        <form [formGroup]="form">
            <i aria-hidden="true" *ngIf="isPermissionFormLoading" class="pi pi-spin pi-spinner mz-permission-spinner-center" aria-hidden="true"></i>
            <div [ngClass]="{ opacity: isPermissionFormLoading }" *ngFor="let entityPermission of entityPermissions">
                <div class="form-group">
                    <label [for]="entityPermission.isActive ? entityPermission.fullPermissionName : null">
                        {{ l('Permission' + entityPermission.permissionName + '_DropdownLabel') }}
                        <span class="ml-3" (click)="showPossibleUsers(entityPermission.possibleUsers)" style="cursor: pointer">
                            <i aria-hidden="true" class="fas fa-user"></i>
                            {{ entityPermission.possibleUsers.length }}
                        </span>
                        <app-mazars-information-icon
                            [uid]="'Permission' + entityPermission.permissionName + '_DropdownDescription'"
                            [message]="l('Permission' + entityPermission.permissionName + '_DropdownDescription')"
                        ></app-mazars-information-icon>
                        <span *ngIf="hasReviewWarning(entityPermission)" class="ml-3">
                            <app-mazars-information-icon
                                [uid]="entityName + '_Process_Flow_ReviewWarning'"
                                [message]="l(entityName + '_Process_Flow_ReviewWarning')"
                                [iconClass]="'fas fa-exclamation-triangle fa-exclamation-triangle-yellow'"
                            ></app-mazars-information-icon>
                        </span>
                        <span *ngIf="entityPermission.possibleUsers.length === 0" class="ml-3">
                            <i aria-hidden="true" class="fas fa-exclamation-triangle fa-exclamation-triangle-yellow"></i>
                            {{ l(entityName + '_Process_Flow_NoPossibleUser') }}
                        </span>
                    </label>
                    <div *ngIf="entityPermission.isActive">
                        <app-mazars-multiselect
                            [disabled]="isPermissionFormLoading || viewOnlyMode || !entityPermission.isActive"
                            [id]="entityPermission.fullPermissionName"
                            [items]="entityPermission.isActive ? entityPermission.availableUsersOrOrganizationUnits : []"
                            [isUserOrOrganization]="true"
                            formControlName="{{ entityPermission.fullPermissionName }}"
                            [placeholder]="entityPermission.isActive ? ld('Select') : ld('Disabled')"
                            (onValueChange)="onEntityPermissionChange($event, entityPermission)"
                            [uid]="'mazars_custom_permissions_' + entityPermission.fullPermissionName"
                        ></app-mazars-multiselect>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-6"></div>
</div>

<p-dialog #possibleUsersDialog [(visible)]="displayPossibleUsersDialog">
    <p *ngFor="let possibleUser of possibleUsers" class="text-center" style="min-width: 150px">
        {{ possibleUser }}
    </p>
</p-dialog>
