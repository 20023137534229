import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EconomicUnitReferenceDataService } from '../economic-units/economic-unit-reference-data.service';

@Injectable()
export class EconomicUnitReferenceDataResolver implements Resolve<any> {
    constructor(private economicUnitReferenceDataService: EconomicUnitReferenceDataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.economicUnitReferenceDataService.load();

        return this.economicUnitReferenceDataService.loadRequiredReferenceData();
    }
}
