export enum FormPermission {
    ViewForm = 'Iro.Form.View',
    CreateForm = 'Iro.Form.Create',
    EditForm = 'Iro.Form.Edit',
    EditDevForm = 'Iro.Form.Edit.Dev',
    DeleteForm = 'Iro.Form.Delete',
    ReviewForm = 'Iro.Form.Review',
    RevokeReviewForm = 'Iro.Form.RevokeReview',
    SubmitForm = 'Iro.Form.Submit',
    RevokeSubmitForm = 'Iro.Form.RevokeSubmit',
}
