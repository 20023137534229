<mz-dialog
    #editModal
    [uid]="uid"
    [title]="l('ChangeAssignedUser')"
    [canSubmit]="form.valid"
    [submitButtonLabel]="l('Adopt')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <p>{{ l('BulkAssignedUserJobDetails') }}</p>
    <form [formGroup]="form" (ngSubmit)="modal.submit()" autocomplete="off">
        <div class="form-group">
            <label for="assignedUserId">{{ l('AssignedUser') }} *</label>
            <app-mazars-dropdown
                [uid]="uid + '_assignedUserId'"
                [options]="assignableUsers"
                [hasEmpty]="true"
                [filter]="true"
                formControlName="assignedUserId"
                [placeholder]="l('Unassigned')"
            >
                <ng-template mazarsTemplate let-item>
                    <app-mazars-assignable-user-select
                        [uid]="uid + '_item_' + item.id"
                        [mode]="'icontext'"
                        [assignedUser]="item.source"
                        [isEditable]="false"
                    ></app-mazars-assignable-user-select>
                </ng-template>
            </app-mazars-dropdown>
        </div>
    </form>
</mz-dialog>
