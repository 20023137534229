import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs';
import { IColumnDefinition } from '../mazars-common/components/mazars-grid/grid-interfaces';
import { ITabMenuItem } from '../mazars-common/components/mazars-tab-menu/tab-menu-interfaces';
import { ActionIcon } from '@app/modules/mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { Pillar2Permissions } from './pillar2-permissions';

@Injectable()
export abstract class Pillar2ComponentBase extends AppComponentBase implements OnDestroy {
    subscriptions: Subscription[] = [];
    pillar2TabMenuItems: ITabMenuItem[];
    projectTabMenuItems: ITabMenuItem[];
    projectAdditionalTabMenuItems: ITabMenuItem[] = [];
    public activatedRoute: ActivatedRoute;

    public absoluteModuleRootPath = '/app/pillar2';
    public absoluteModuleRootPathProjects: string = this.absoluteModuleRootPath + '/projects';
    public absoluteModuleRootPathProjectsCreate: string = this.absoluteModuleRootPathProjects + '/create';
    public absoluteModuleRootPathAdminSettings: string = this.absoluteModuleRootPath + '/admin-settings';

    protected fb: FormBuilder;

    constructor(injector: Injector) {
        super(injector);
        this.localizationSourceName = AppConsts.localization.pillar2SourceName;
        this.activatedRoute = injector.get(ActivatedRoute);
        this.fb = injector.get(FormBuilder);
        this.pillar2TabMenuItems = [
            {
                id: 'mi-projects',
                label: this.l('Projects'),
                routerLink: this.absoluteModuleRootPathProjects,
            },
            {
                id: 'pillar2-settings',
                label: '<i aria-hidden="true" class="fas fa-cog"></i>',
                labelAsHtml: true,
                routerLink: this.absoluteModuleRootPathAdminSettings,
                hidden: !this.permission.isGranted(Pillar2Permissions.Admin),
            },
        ];
        this.projectTabMenuItems = [
            {
                id: 'p-general',
                label: this.l('General'),
                icon: ActionIcon.CheckCircle,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/general`,
                disabled: false,
            },
            {
                id: 'p-import-export',
                label: this.l('ImportAndExport'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/import-export`,
                disabled: true,
            },
            {
                id: 'p-scoping',
                label: this.l('Scoping'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/scoping`,
                disabled: true,
            },
            {
                id: 'p-currencies',
                label: this.l('Currencies'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/currencies`,
                disabled: true,
            },
            {
                id: 'p-access-management',
                label: this.l('AccessManagementName'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/access-management`,
                disabled: true,
                hidden: true,
            },
            {
                id: 'p-checks',
                label: this.l('SafeHarbourChecks'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/checks`,
                disabled: true,
            },
            {
                id: 'p-entity-mapping',
                label: this.l('EntityMapping'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/entity-mapping`,
                disabled: true,
                hidden: false,
            },
            {
                id: 'p-full-scope-calc',
                label: this.l('FullScopeCalculation'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/full-scope-calculation`,
                ignoreQueryParams: true,
                disabled: true,
                hidden: false,
            },
            {
                id: 'p-report',
                label: this.l('Reports'),
                icon: ActionIcon.Lock,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/report`,
                disabled: true,
            },
            {
                id: 'p-insights',
                label: this.l('Insights'),
                icon: ActionIcon.CheckCircle,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/insights`,
                disabled: true,
            },
            {
                id: 'p-attachments',
                label: this.l('Attachments'),
                icon: ActionIcon.CheckCircle,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/attachments`,
                disabled: true,
            },
        ];
        this.projectAdditionalTabMenuItems = [
            {
                id: 'p-communications',
                icon: ActionIcon.Comment,
                routerLink: `${this.absoluteModuleRootPathProjects}/${this.activatedRoute.snapshot.params.id}/communications`,
                disabled: true,
                showIconCounter: true,
                tooltip: this.ld('Channels_Tooltip'),
            },
        ];
    }

    setTabIcon(tabName: string, canEditData: boolean) {
        let menuItem = this.projectTabMenuItems.find((mi) => mi.id === tabName);
        if (menuItem) {
            menuItem.icon = canEditData ? ActionIcon.CheckCircle : ActionIcon.Lock;
        }
    }

    setIsVisible(tabName: string, isVisible: boolean) {
        let menuItem = this.projectTabMenuItems.find((mi) => mi.id === tabName);
        if (menuItem) {
            menuItem.hidden = !isVisible;
        }
    }

    localizer: (key: string) => string = (key: string) => this.l(key);

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.onDestroy();
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    abstract onDestroy(): void;
}
