<div class="btn-group" dropdown #dropdown="bs-dropdown">
    <button
        [attr.data-testid]="uid"
        dropdownToggle
        type="button"
        class="btn btn-secondary dropdown-toggle"
        class="btn btn-hover-brand btn-elevate-hover btn-icon btn-icon-md btn-action"
        [ngClass]="btnSize ? btnSize : 'btn-sm'"
        [attr.aria-controls]="'ul_' + uid"
    >
        <i
            class="mazarsActionsButtonIcon"
            aria-hidden="true"
            [ngStyle]="{ 'font-size': fontSize ? fontSize : 'initial' }"
            [className]="icon"
            [ngClass]="{ vertical: vertical }"
        ></i>
    </button>
    <ul role="menu" [attr.id]="'ul_' + uid" class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <ng-container *ngFor="let item of items">
            <li *ngIf="item.visible && !item.separator" role="menuitem" class="cursor-pointer">
                <a [attr.data-testid]="uid + '_' + item.label + '_btn'" class="dropdown-item" [routerLink]="" (click)="onClick($event, item)">
                    <span>
                        <i aria-hidden="true" class="fa-fw mr-2{{ item.icon ? ' ' + item.icon : '' }}" aria-hidden="true"></i>
                        {{ item.label }}
                    </span>
                </a>
            </li>
            <li *ngIf="item.visible && item.separator">
                <hr class="mt-0 mb-1" />
                <span *ngIf="item.label" class="mt-0 pl-4 primary-color">{{ item.label }}</span>
            </li>
        </ng-container>
    </ul>
</div>
