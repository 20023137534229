import { Component, Input, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DueDateStatus } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { DueDateSettings } from '../mazars-deadline-dates/due-date-settings';

@Component({
    selector: 'mazars-due-date',
    templateUrl: './mazars-due-date.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsDueDateComponent implements OnInit {
    @Input({ required: true }) uid: string;
    @Input() status: DueDateStatus;
    @Input() dueDate: string;

    icon: string;
    iconColor: string;
    iconTooltip: string;
    securityContext = SecurityContext;

    ngOnInit(): void {
        this.icon = DueDateSettings.getIcon(this.status);
        this.iconColor = DueDateSettings.getIconColor(this.status);
        this.iconTooltip = DueDateSettings.getIconTooltip(this.status);
    }
}
