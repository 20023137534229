<form [formGroup]='form'>
    <div class='row'>
        <div class='col-lg-6 col-md-6'>
            <div class='form-group'>
                <label for='ConnectionToCountry'>{{ l('Label_ConnectionToCountry') }}</label>
                <app-mazars-dropdown [options]='getConnectionToCountries()' [hasEmpty]='true'
                                     [uid]="'ConnectionToCountry'"
                                     formControlName='connectionToCountry'>
                </app-mazars-dropdown>
            </div>
        </div>
        <div class='col-lg-6 col-md-6'>
            <div class='form-group'>
                <label for='LiabilityRole'>{{ l('Label_LiabilityRole') }}</label>
                <app-mazars-dropdown [options]='getLiabilityRoles()' [hasEmpty]='true'
                                     [uid]="'LiabilityRole'"
                                     formControlName='role'>
                </app-mazars-dropdown>
            </div>
        </div>
    </div>
</form>
