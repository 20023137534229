<div *ngIf="currentChannel">
    <div class="py-2 px-4 border-bottom d-block channel-name">
        <div class="d-flex align-items-center h-100">
            <h5 class="flex-grow-1 pl-3 m-0">{{ currentChannel?.title }}</h5>
        </div>
    </div>
    <div class="position-relative">
        <div class="chat-messages p-4">
            <div class="chat-message" *ngFor="let communication of currentChannel.communications">
                <div
                    [ngClass]="communication.creatorUserId === currentUserId ? 'chat-message-title-right' : 'chat-message-title-left'"
                    class="font-weight-bold pb-1"
                >
                    <div *ngIf="communication.hasOriginalMailSourceCode">{{ l('Email') }}</div>
                    <div *ngIf="!communication.hasOriginalMailSourceCode">
                        <div *ngIf="communication.creatorUserId === currentUserId">{{ l('You') }}</div>
                        <div *ngIf="communication.creatorUserId && communication.creatorUserId !== currentUserId">
                            {{ communication?.userDetails[0]?.fullName }}
                        </div>
                    </div>
                </div>
                <div [ngClass]="communication.creatorUserId === currentUserId ? 'chat-message-right' : 'chat-message-left'" class="pb-4">
                    <app-mazars-avatar
                        *ngIf="communication.creatorUserId"
                        [userDetail]="communication.userDetails[0]"
                        size="large"
                        [uid]="'communications_user_details-' + communication.creatorUserId"
                    ></app-mazars-avatar>
                    <div *ngIf="!communication.creatorUserId" class="symbol symbol-circle symbol-lg-75">
                        <img class="symbol-label mz-rounded" [src]="defaultProfilePicture | safe : SecurityContext.URL" alt="..." />
                    </div>
                    <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3 text-break">
                        <div *ngIf="communication.hasOriginalMailSourceCode" [innerHtml]="communication.content | safe : SecurityContext.HTML"></div>
                        <app-mazars-display-quill
                            *ngIf="!communication.hasOriginalMailSourceCode"
                            text
                            [description]="communication.content"
                            [uid]="'display-quill-editor-' + communication?.id"
                        ></app-mazars-display-quill>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-grow-0 py-3 px-4 border-top">
        <div class="form-group">
            <mazars-editor
                #meditor
                [uid]="'Content'"
                [commonQuillMentionService]="commonQuillMentionService"
                [availableShortcuts]="availableShortcuts"
                [formControl]="formControl"
            ></mazars-editor>
        </div>
        <button class="btn btn-primary" (click)="sendMessage()">Send</button>
    </div>
</div>
