import { EconomicUnitStatus } from '@app/modules/real-property-tax/real-property-tax-proxies';

export class EconomicUnitStatusSettings {
    static getIcon(status: string): string {
        switch (EconomicUnitStatus[status]) {
            case 1: {
                return 'fa fa-exclamation-triangle';
            }
            case 2:
            case 3:
            case 4:
            case 5: {
                return 'fa fa-circle';
            }
            default: {
                return '';
            }
        }
    }

    static getIconColor(status: string): string {
        switch (EconomicUnitStatus[status]) {
            case 1: {
                return 'var(--bs-yellow)';
            }
            case 2: {
                return '#a2cd5a';
            }
            case 3: {
                return '#2C78CA';
            }
            case 4: {
                return '#ff7f24';
            }
            case 5: {
                return '#BC363D';
            }
            default: {
                return '';
            }
        }
    }
}
