export class CloseableDialogResult {
    private callbacks: ((result?: any) => void)[] = [];

    private isFinished = false;

    public constructor(executor: (finish: () => void) => void) {
        executor(this.finish);
    }

    public then(finished: () => void): void {
        if (this.isFinished) {
            finished();
            return;
        }
        this.callbacks.push(finished);
    }

    private finish = (): void => {
        if (this.isFinished) {
throw new Error('Dialog result is already sent');
}
        this.isFinished = true;
        this.invokeAllSafely(this.callbacks, (cb) => cb());
        this.callbacks = [];
    };

    private invokeAllSafely(callbacks: ((result?: any) => void)[], callback: (cb: (result?: any) => void) => void): void {
        for (let cbFkn of callbacks) {
            try {
                callback(cbFkn);
            } catch (error) {
                console.error(error);
            }
        }
    }
}
