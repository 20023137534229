<mz-dialog
    #modal
    [uid]="uid"
    [title]="attachmentId ? l('Edit') : l('Create')"
    [canSubmit]="form.valid && !isFileUploading"
    [submitButtonLabel]="attachmentId ? l('Save') : l('Create')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <form [formGroup]="form" (ngSubmit)="modal.submit()" autocomplete="off">
        <ng-container *ngIf="attachmentId == null">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="Type">{{ l('Type') }}</label>
                        <app-mazars-dropdown
                            [uid]="'Type'"
                            [options]="typeItems"
                            [hasEmpty]="false"
                            placeholder="{{ l('Type') }}"
                            formControlName="type"
                        ></app-mazars-dropdown>
                    </div>
                </div>

                <div class="col-6">
                    <div *ngIf="form.value.type == availableTypes.Url" class="form-group">
                        <label for="Url">{{ l('Url') }}</label>
                        <app-mazars-input-text [uid]="'Url'" [placeholder]="l('Url')" formControlName="urlOrFiletoken"></app-mazars-input-text>
                        <app-mazars-validation-message
                            [uid]="'Url'"
                            [key]="'Url'"
                            [control]="form.controls.urlOrFiletoken"
                            [submitting]="validation"
                        ></app-mazars-validation-message>
                    </div>

                    <div *ngIf="form.value.type == availableTypes.File" class="form-group">
                        <app-mazars-upload-button
                            #uploadFile
                            uid="ImportReportButton"
                            name="File[]"
                            [accept]="fileAcceptedTypes"
                            (onUpload)="onSelectFile($event)"
                            [validationTemplate]="validationTemplate"
                            title="{{ l('File') }} *"
                            [isLoading]="isFileUploading"
                        ></app-mazars-upload-button>
                        <ng-template #validationTemplate>
                            <app-mazars-validation-message
                                [uid]="'File'"
                                [key]="'File'"
                                [control]="form.controls.urlOrFiletoken"
                                [submitting]="validation"
                            ></app-mazars-validation-message>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div *ngIf="form.value.fileName" class="form-group" style="background-color: #e5e5e5; padding: 5px">
                <div class="alert alert-custom alert-white alert-bold m-1" role="alert">
                    <span class="alert-text">
                        {{ form.value.fileName }}
                    </span>
                    <button type="button" class="btn p-0" style="vertical-align: top">
                        <span (click)="removeFile()">
                            <i aria-hidden="true" class="la la-close"></i>
                        </span>
                    </button>
                </div>
            </div>
        </ng-container>

        <div class="form-group">
            <label for="Note">{{ l('Note') }}</label>
            <app-mazars-input-text [uid]="'Note'" [placeholder]="l('Note')" formControlName="note"></app-mazars-input-text>
            <app-mazars-validation-message
                [uid]="'Note'"
                [key]="'Note'"
                [control]="form.controls.note"
                [submitting]="validation"
            ></app-mazars-validation-message>
        </div>

        <div class="form-group">
            <label for="DateOfAttachment">{{ l('DateOfAttachment') }}</label>
            <app-mazars-datepicker [uid]="'DateOfAttachment'" [placeholder]="l('DateOfAttachment')" formControlName="dateOfAttachment"></app-mazars-datepicker>
            <app-mazars-validation-message
                [uid]="'DateOfAttachment'"
                [key]="'DateOfAttachment'"
                [control]="form.controls.dateOfAttachment"
                [submitting]="validation"
            ></app-mazars-validation-message>
        </div>

        <div class="form-group">
            <label for="Tags">{{ l('Tags') }}</label>
            <mazars-tags uid="Tags" placeholder="{{ l('Tags') }}" [availableTags]="availableTags" formControlName="tags"></mazars-tags>
        </div>
    </form>
</mz-dialog>
