<div class="row justify-content-between text-center a">
    <p-selectButton [options]="options" [(ngModel)]="selectedItem" (onOptionClick)="onTouched()" (onChange)="onInputChange()" optionLabel="Type" [attr.data-testid]="uid">
        <ng-template let-item pTemplate>
            <div class="flex-row">
                <div class="col">
                    <div><i aria-hidden="true" [class]="item.icon"></i></div>
                    <div>{{ item.text }}</div>
                </div>
            </div>
        </ng-template>
    </p-selectButton>
</div>
