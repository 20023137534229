export enum DeclarationProcessStateNames {
    EditDeclarationPhase = 'EditDeclarationPhase',
    ReviewDeclarationPhase = 'ReviewDeclarationPhase',
    ReadyForSubmissionPhase = 'ReadyForSubmissionPhase',
    MarkedForSubmission = 'MarkedForSubmission',
    WaitingForFeedbackPhase = 'WaitingForFeedbackPhase',
    ErrorFeedback = 'ErrorFeedback',
    WaitingForNotices = 'WaitingForNotices',
    Completed = 'Completed',
}
