import { Component, ViewChild, Injector, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IItem } from '../../interfaces/item.interface';
import { CommunicationType } from './communication-type';
import { CommonQuillMentionService } from '@app/shared/common/quill/common-quill-mention.service';
import { MazarsEditorComponent } from '../mazars-editor/mazars-editor.component';
import { CommunicationModel } from './communication-model';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { MazarsCreateUpdateDialogComponent } from '../mazars-dialog/mazars-create-update-dialog.component';
import { CommunicationService } from '../../services/communication.service';
import { CommonCommunicationsFacade } from '../../state-management/facades/common-communications.facade';
import { CommunicationCreateUpdateFacade } from '../../state-management/facades/communication-create-update.facade';
import { ICommunicationDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'mazars-communication-modal',
    templateUrl: './mazars-communication-modal.component.html',
    styleUrls: ['./mazars-communication-modal.component.css'],
})
export class MazarsCommunicationModalComponent extends AppComponentBase {
    @Input() commonQuillMentionService: CommonQuillMentionService;
    @ViewChild('dialog', { static: true }) modal: MazarsCreateUpdateDialogComponent<ICommunicationDto, CommunicationModel, CommunicationModel>;
    @Input({ required: true }) uid: string;

    typeItems: IItem<string>[] = Object.keys(CommunicationType).map(
        (t) => <IItem<string>>{ id: t, text: this.l(t) }
    );
    availableTags: string[];
    availableShortcuts: IItem<string>[];
    form: FormGroup = null;
    saving = false;
    validation = false;

    referenceId: number;
    communicationId?: number;
    limitEdit = false;
    mEditor: MazarsEditorComponent;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        private communicationService: CommunicationService,
        public communicationsFacade: CommonCommunicationsFacade,
        public communicationCreateUpdateFacade: CommunicationCreateUpdateFacade
    ) {
        super(injector);
    }

    @ViewChild('meditor', { static: false }) set content(content: MazarsEditorComponent) {
        if (content) {
            // initially setter gets called with undefined
            this.mEditor = content;
        }
    }

    show(referenceId: number, communication: ICommunicationDto): DialogResult {
        this.referenceId = referenceId;
        this.validation = false;
        this.communicationId = communication?.id ?? null;
        this.availableShortcuts = this.communicationService.getAvailableEditorShortcuts();
        return this.modal.show(communication);
    }

    canSubmit(): boolean {
        return this.form?.valid ?? false;
    }

    createFormCallback = (): void => {
        this.form = this.fb.group({
            subject: [null, [Validators.maxLength(400)]],
            participants: [null, [Validators.maxLength(2000)]],
            date: [null, [Validators.required]],
            tags: [null],
            content: [null],
        });
    };

    model2FormCallback = (model: ICommunicationDto): void => {
        this.form.reset();
        this.form.setValue({
            content: model?.content ?? null,
        });

        if (model?.hasOriginalMailSourceCode) {
            this.limitEdit = true;
        } else {
            this.limitEdit = false;
        }
    };

    form2CreateModelCallback = (): CommunicationModel => ({
        referenceId: this.referenceId,
        content: this.form.value.content,
    });

    form2UpdateModelCallback = (): CommunicationModel => ({
        id: this.communicationId,
        referenceId: this.referenceId,
        content: this.form.value.content,
    });
}
