<p-dialog #exportDialog position="center" [(visible)]="isVisible" styleClass="mazars-reorder-dialog">
    <ng-template pTemplate="header">{{ l('ExportOverviewSetting_Heading') }}</ng-template>
    <div class="mb-10">
        <p>{{ l('ExportOverviewSetting_Description') }}</p>
        <form [formGroup]="form">
            <app-mazars-radio-group
                [uid]="uid + '_is_filter_radio_group'"
                [items]="radioItems"
                [isVertical]="true"
                formControlName="exportAllEntries"
            ></app-mazars-radio-group>
            <div class="form-group p-field-checkbox mb-0 mt-1">
                <app-mazars-input-checkbox 
                *ngIf="hasArchivableElements"
                uid="ExportOverview_IncludeArchived_checkbox"
                [binary]="true" 
                [labelStyleClass]="'mb-0'"                
                [label]="l('ExportOverview_IncludeArchived')"
                formControlName="showArchived">            
                </app-mazars-input-checkbox>
            </div>
        </form>
    </div>
    <mazars-reorder
        #mazarsReorder
        [commonGridConfigurationFacade]="this.gridConfigurationFacade"
        [tableId]="tableId"
        [showSearch]="true"
        [livePreview]="false"
        [isVisible]="isVisible"
    ></mazars-reorder>
    <ng-template pTemplate="footer">
        <div class="row justify-content-between flex-wrap">
            <div class="d-block d-md-flex justify-content-end flex-wrap">
                <app-mazars-button styleClass="mt-1" [uid]="uid + '_cancel'" [inverted]="true" [label]="l('Cancel')" (onClick)="showHide()"></app-mazars-button>
                <app-mazars-button styleClass="mt-1" [uid]="uid + '_create'" [label]="l('Create')" (onClick)="onClick()"></app-mazars-button>
            </div>
        </div>

    </ng-template>
</p-dialog>
