import { AppConsts } from '@shared/AppConsts';

export class UrlHelper {
    /**
     * The URL requested, before initial routing.
     */
    static readonly initialUrl = location.href;
    static ignoreReturnUrl = false; //Added by mazars
    static noTenancyNameQParam = false; //Added by mazars

    static getQueryParameters(): any {
        return UrlHelper.getQueryParametersUsingParameters(document.location.search);
    }

    static getQueryParametersUsingParameters(search: string): any {
        return search
            .replace(/(^\?)/, '')
            .split('&')
            .map(
                function (n) {
                    return (n = n.split('=')), (this[n[0]] = n[1]), this;
                }.bind({})
            )[0];
    }

    static getQueryParametersUsingHash(): any {
        return document.location.hash
            .substr(1, document.location.hash.length - 1)
            .replace(/(^\?)/, '')
            .split('&')
            .map(
                function (n) {
                    return (n = n.split('=')), (this[n[0]] = n[1]), this;
                }.bind({})
            )[0];
    }

    static getInitialUrlParameters(startingChar: string = '?'): any {
        //Added by mazars - startingChar param
        let questionMarkIndex = UrlHelper.initialUrl.indexOf(startingChar);
        if (questionMarkIndex >= 0) {
            return UrlHelper.initialUrl.substr(questionMarkIndex, UrlHelper.initialUrl.length - questionMarkIndex);
        }

        return '';
    }

    static getReturnUrl(): string {
        const queryStringObj = UrlHelper.getQueryParametersUsingParameters(UrlHelper.getInitialUrlParameters());
        if (this.ignoreReturnUrl) {
            //Added by mazars
            return AppConsts.appBaseUrl;
        }

        if (queryStringObj.returnUrl) {
            return decodeURIComponent(queryStringObj.returnUrl);
        }

        return null;
    }

    static getSingleSignIn(): boolean {
        const queryStringObj = UrlHelper.getQueryParametersUsingParameters(UrlHelper.getInitialUrlParameters());
        if (queryStringObj.ss) {
            return queryStringObj.ss;
        }

        return false;
    }

    static isInstallUrl(url): boolean {
        return url && url.indexOf('app/admin/install') >= 0;
    }

    //Added by mazars
    static getUrlForRedirect() {
        return this.ignoreReturnUrl && !this.noTenancyNameQParam ? AppConsts.appBaseUrl : this.initialUrl;
    }

    //Added by mazars
    static getTenancyName(): string {
        const queryStringObj = UrlHelper.getQueryParametersUsingParameters(UrlHelper.getInitialUrlParameters());
        if (queryStringObj.tn === undefined) {
            this.noTenancyNameQParam = true;
        }

        if (queryStringObj.tn) {
            return decodeURIComponent(queryStringObj.tn);
        }

        return null;
    }

    //Added by mazars
    static getOpenIdConnectRedirectUrl() {
        let redirectUrl = AppConsts.appBaseUrl;
        try {
            let queryRedirectUrl = this.getInitialQueryParameters('#').redirectUrl;
            if (!queryRedirectUrl) {
                queryRedirectUrl = this.getInitialQueryParameters().redirectUrl;
            }

            redirectUrl = queryRedirectUrl ? atob(queryRedirectUrl) : queryRedirectUrl;
        } catch {
            abp.log.warn({ message: 'Redirect url for openid cannot be parsed', url: this.initialUrl });
        }

        return redirectUrl;
    }

    //Added by mazars
    static getInitialQueryParameters(startingChar: string = '?'): any {
        const initialUrlParameters = this.getInitialUrlParameters(startingChar);
        const params = initialUrlParameters
            .substr(1, initialUrlParameters.length - 1)
            .split('&')
            .map(
                function (n) {
                    return (n = n.split('=')), (this[n[0]] = n[1]), this;
                }.bind({})
            )[0];

        const additionalParamsStr = decodeURIComponent(decodeURIComponent(params.state).split(';')[1]);
        return additionalParamsStr.split(';').map(
            function (n) {
                return (n = n.split('=')), (this[n[0]] = n[1]), this;
            }.bind({})
        )[0];
    }
}
