import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mazars-zoom',
    styleUrls: ['./mazars-zoom.component.css'],
    templateUrl: './mazars-zoom.component.html',
})
export class MazarsZoomComponent {
    @Input({ required: true }) uid: string;
    @Input() value: number;
    @Output() onZoomLevelChanged = new EventEmitter<number>();
    zoomStep: number = 5;

    zoomOut() {
        if (this.value > 1) {
            this.value -= this.zoomStep;
            if (this.value < 1) {
                this.value = 1;
            }
        }
        else {
            this.value = 1;
        }
        this.onStepChanged();
    }

    zoomIn() {
        if (this.value < 91) {
            this.value += this.zoomStep;
            if (this.value > 100) {
                this.value = 100;
            }
        }
        else {
            this.value = 100;
        }
        this.onStepChanged();
    }

    onStepChanged() {
        this.onZoomLevelChanged.emit(this.value);
    }
}
