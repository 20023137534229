import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'unsafe',
})
export class UnsafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: SecurityContext): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case SecurityContext.HTML:
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case SecurityContext.STYLE:
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case SecurityContext.SCRIPT:
                return this.sanitizer.bypassSecurityTrustScript(value);
            case SecurityContext.URL:
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case SecurityContext.RESOURCE_URL:
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid unsafe type specified: ${type}`);
        }
    }
}
