import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

export interface IAdditionalButton {
    id: string;
    label: string;
    isBusy: boolean;
    iconClass?: string;
    bordered?: boolean;
    inverted?: boolean;
    isHidden?: boolean;
}

@Component({
    selector: 'app-mazars-submit-button',
    templateUrl: './mazars-submit-button.component.html',
    styleUrls: ['./mazars-submit-button.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsSubmitButtonComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() isButtonBusy = false;
    @Input() submitLabel: string = this.l('Save');
    @Input() resetLabel: string = this.l('Reset');
    @Output() onSubmit = new EventEmitter<void>();

    @Input() showReset = false;
    @Output() onReset = new EventEmitter<void>();
    @Input() isDirty = false;
    @Input() leftAdditionalButton: IAdditionalButton;
    @Input() additionalButtons: IAdditionalButton[] = [];
    // this output emits the id of the clicked additional button
    @Output() onLeftAdditionalButtonClick = new EventEmitter<string>();
    @Output() onAdditionalButtonClick = new EventEmitter<string>();

    isDisabled(): boolean {
        return this.isAdditionalDisabled() || !this.isDirty;
    }

    isAdditionalDisabled(): boolean {
        return this.isButtonBusy || this.isOneAdditionalBusy();        
    }

    protected isOneAdditionalBusy(): boolean {
        return this.additionalButtons.some(b => b.isBusy);
    }
}
