<form [formGroup]="form">
    <div class="row">
        <div
            [ngClass]="
                !partyConfig?.showHasNationalExemption &&
                !partyConfig?.showImplementationDate &&
                !partyConfig?.showIntermediaryLiabilityRoleInOtherDetails &&
                !partyConfig?.showIsAffectedPerson
                    ? 'col-lg-6 col-md-6'
                    : 'col-lg-4 col-md-4'
            "
        >
            <div class="form-group">
                <label for="ResidenceCountry">{{ l('ResidenceCountry') }}</label>
                <app-mazars-multiselect
                    [id]="'ResidenceCountry'"
                    [isCountries]="true"
                    [items]="referenceDataService.allCountries"
                    formControlName="residenceCountryCodes"
                    [uid]="'other_details_form_ResidenceCountry'"
                ></app-mazars-multiselect>
            </div>
        </div>
        <div
            [ngClass]="
                !partyConfig?.showHasNationalExemption &&
                !partyConfig?.showImplementationDate &&
                !partyConfig?.showIntermediaryLiabilityRoleInOtherDetails &&
                !partyConfig?.showIsAffectedPerson
                    ? 'col-lg-6 col-md-6'
                    : 'col-lg-4 col-md-4'
            "
        >
            <div class="form-group">
                <label for="Email">{{ l('Email') }}</label>
                <app-mazars-input-text [uid]="'Email'" formControlName="email"></app-mazars-input-text>
                <app-mazars-validation-message  [uid]="'Email'"  [key]="'Email'" [control]="form.controls.email" [submitting]="submitting"></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-4 col-md-4" *ngIf="partyConfig?.showImplementationDate">
            <div class="form-group">
                <label for="ImplementationDate">{{ l('TaxPayer_ImplementationDate') }}</label>
                <app-mazars-datepicker [uid]="'TaxPayer_ImplementationDate'" formControlName="implementationDate"></app-mazars-datepicker>
            </div>
        </div>
        <div class="col-lg-4 col-md-4" *ngIf="partyConfig?.showIntermediaryLiabilityRoleInOtherDetails">
            <div class="form-group">
                <label for="Role">{{ l('Label_LiabilityRole') }}</label>
                <app-mazars-dropdown
                    [options]="reportReferenceDataService.intermediaryLiabilityRoles"
                    [hasEmpty]="true"
                    [uid]="'LiabilityRole'"
                    formControlName="role"
                ></app-mazars-dropdown>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 align-self-center" *ngIf="partyConfig?.showIsAffectedPerson">
            <div class="form-group p-field-checkbox mt-5">
                <app-mazars-input-checkbox uid="IsAffectedPerson" formControlName="isAffectedPerson" [binary]="true"></app-mazars-input-checkbox>
                <label for="IsAffectedPerson" class="mb-0">{{ l('Label_IsAffectedPerson') }}</label>
                <app-mazars-information-icon uid="IsAffectedPerson_Tooltip" [message]="l('IsAffectedPerson_Tooltip')"></app-mazars-information-icon>
            </div>
        </div>
    </div>
    <div *ngIf="partyConfig?.showHasNationalExemption" class="row">
        <div class="col">
            <div class="form-group p-field-checkbox">
                <app-mazars-input-checkbox
                    uid="NationalExemption"
                    formControlName="hasNationalExemption"
                    [binary]="true"
                    (onToggleChanged)="onHasNationalExemptionChange($event)"
                ></app-mazars-input-checkbox>
                <label for="NationalExemption" class="mb-0 ml-2">{{ l('Label_NationalExemption') }}</label>
                <app-mazars-information-icon uid="NationalExemption_Tooltip" [message]="l('NationalExemption_Tooltip')"></app-mazars-information-icon>
            </div>
        </div>
    </div>
    <div *ngIf="partyConfig?.showHasNationalExemption && nationalExemptionFormContol" class="row">
        <div class="col">
            <div class="form-group">
                <label for="NationalExemptionCountries">{{ l('Label_NationalExemptionCountries') }}</label>
                <app-mazars-multiselect
                    [uid]="'other_details_form_NationalExemptionCountries'"
                    [isCountries]="true"
                    [id]="'NationalExemptionCountries'"
                    [items]="referenceDataService.memberStateCountries"
                    formControlName="nationalExemptionCountries"
                ></app-mazars-multiselect>
            </div>
        </div>
    </div>
    <div *ngIf="partyConfig?.showNotifiedByIntermediary" class="row">
        <div class="col">
            <div class="form-group p-field-checkbox">
                <app-mazars-input-checkbox
                    uid="NotifiedByIntermediary"
                    formControlName="notifiedByIntermediary"
                    [binary]="true"
                    (onToggleChanged)="onNotifiedByIntermediaryChange($event)"
                ></app-mazars-input-checkbox>
                <label for="NotifiedByIntermediary" class="mb-0 ml-2">{{ l('NotifiedByAnIntermediary') }}</label>
                <app-mazars-information-icon
                    uid="NotifiedByAnIntermediary_Tooltip"
                    [message]="l('NotifiedByAnIntermediary_Tooltip')"
                ></app-mazars-information-icon>
            </div>
        </div>
    </div>
    <div *ngIf="partyConfig?.showNotifiedByIntermediary && notificationDateFormControl" class="row">
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="NotificationDate">{{ l('NotificationDate') }}</label>
                <app-mazars-information-icon uid="NotificationDate_Tooltip" [message]="l('NotificationDate_Tooltip')"></app-mazars-information-icon>
                <app-mazars-datepicker [uid]="'NotificationDate'" formControlName="notificationDate"></app-mazars-datepicker>
            </div>
        </div>
    </div>
</form>
