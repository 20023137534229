import { ProcessStateGroupedDto } from '@app/modules/iro/iro-proxies';
import { FormPermission } from '../form-permissions/form-permission';
import { FormProcessState } from './form-process-state';

export class FormProcessStateSettings {

    static activeStatePhaseClass(phaseName: string) {
        return (phaseName === FormProcessState.Completed) ? 'mz-process-item-success' : '';
    }

    static activeStatePhaseIcon(phaseName: string) {
        switch (phaseName) {
            case FormProcessState.EditFormPhase:
                return 'fas fa-tasks';
            case FormProcessState.ReviewFormPhase:
                return 'fas fa-search';
            case FormProcessState.ReadyForSubmissionPhase:
                return 'fas fa-paper-plane';
            case FormProcessState.ManualSubmissionPhase:
                return 'fas fa-download';
            case FormProcessState.WaitingForAuthorityFeedbackPhase:
                return 'fas fa-comments';
            case FormProcessState.Completed:
                return 'fas fa-check-double';
            default:
                return 'fas fa-angle-right';
        }
    }

    static stateLabel(state: ProcessStateGroupedDto) {
        return 'Form_Process_Flow_Step_' + state.processGroup;
    }

    static setProcessState(phaseName: string): FormProcessState {
        return phaseName in FormProcessState ? FormProcessState[phaseName] : null;
    }

    static getProcessStateHeadline(phaseName: string) {
        switch (phaseName) {
            case FormProcessState.EditFormPhase:
                return 'Form_Process_Flow_Headline_Edit';
            case FormProcessState.ReviewFormPhase:
                return 'Form_Process_Flow_Headline_Review';
            case FormProcessState.ReadyForSubmissionPhase:
                return 'Form_Process_Flow_Headline_ReadyForSubmission';
            case FormProcessState.ManualSubmissionPhase:
                return 'Form_Process_Flow_Headline_ManualSubmission';
            case FormProcessState.WaitingForAuthorityFeedbackPhase:
                return 'Form_Process_Flow_Headline_WaitingForAuthorityFeedback';
            case FormProcessState.Completed:
                return 'Form_Process_Flow_Headline_Completed';
            default:
                return 'Form_Process_Flow_Button_Unknown';
        }
    }

    static getProcessButtonText(phaseName: string) {
        switch (phaseName) {
            case FormProcessState.EditFormPhase:
            case FormProcessState.WaitingForAuthorityFeedbackPhase:
            case FormProcessState.ManualSubmissionPhase:
                return 'Form_Process_Flow_Button_Finish';
            case FormProcessState.ReviewFormPhase:
                return 'Form_Process_Flow_Button_ReviewDecision';
            case FormProcessState.ReadyForSubmissionPhase:
                return 'Form_Process_Flow_Button_Submit';
            default:
                return 'Form_Process_Flow_Button_Finish';
        }
    }

    static getProcessPermission(phaseName: string) {
        switch (phaseName) {
            case FormProcessState.EditFormPhase:
                return FormPermission.EditForm;
            case FormProcessState.ReviewFormPhase:
                return FormPermission.ReviewForm;
            case FormProcessState.ReadyForSubmissionPhase:
            case FormProcessState.ManualSubmissionPhase:
            case FormProcessState.WaitingForAuthorityFeedbackPhase:
                return FormPermission.SubmitForm;
            default:
                return FormPermission.EditForm;
        }
    }

    static getProcessRevokePermission(phaseName: string) {
        switch (phaseName) {
            case FormProcessState.ReviewFormPhase:
                return FormPermission.RevokeReviewForm;
            case FormProcessState.ReadyForSubmissionPhase:
                return FormPermission.RevokeSubmitForm;
            default:
                return null;
        }
    }

    static getProcessStateWorkItemName(processState: FormProcessState){
        switch (processState){
            case FormProcessState.EditFormPhase:
                return 'FormProcessState_EditFormPhase';
            case FormProcessState.ReviewFormPhase:
                return 'FormProcessState_ReviewFormPhase';
            case FormProcessState.ReadyForSubmissionPhase:
                return 'FormProcessState_ReadyForSubmissionPhase';
            case FormProcessState.ManualSubmissionPhase:
                return 'FormProcessState_ManualSubmissionPhase';
            case FormProcessState.WaitingForAuthorityFeedbackPhase:
                return 'FormProcessState_WaitingForAuthorityFeedbackPhase';
            case FormProcessState.Completed:
                return 'FormProcessState_Completed';
            default:
                return 'FormProcessState_Unknown';
        }
    }
}
