import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IAdminSettingsServiceToken = new InjectionToken<string>('IAdminSettingsService');

export interface IAdminSettingsDto {
    isAccessRestriction: boolean;
}

export interface IAdminSettingsInput {
    isAccessRestriction: boolean;
}

export interface IAdminSettingsService {
    getAll(): Observable<IAdminSettingsDto>;
    saveAll(body: IAdminSettingsInput): Observable<void | IAdminSettingsDto>;
}
