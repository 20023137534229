import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    Output,
    QueryList,
    SecurityContext,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MazarsTemplateDirective } from '@app/modules/mazars-common/components/mazars-template/mazars-template.directive';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ScrollerOptions, SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { DropdownChange } from './dropdown-change';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';
import { DropdownOptions } from './dropdown-options';

@Component({
    selector: 'app-mazars-lazy-dropdown',
    templateUrl: './mazars-lazy-dropdown.component.html',
    styleUrls: ['./mazars-lazy-dropdown.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsLazyDropdownComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsLazyDropdownComponent<TKey> extends AppComponentBase implements OnChanges, AfterContentInit, ControlValueAccessor {
    @ContentChildren(MazarsTemplateDirective) templateRefs: QueryList<MazarsTemplateDirective>;
    @ViewChild('dd', { static: true }) dd: Dropdown;
    @ViewChild('searchInput') input: ElementRef;

    @Input() uid!: string;
    @Input() options: DropdownOptions;
    @Input() hasEmpty: boolean;
    @Input() showClearOption = true;
    @Input() filter: boolean;
    @Input() virtualScrollItemSize = 38;
    @Input() placeholder: string;
    @Input() filterPlaceholder: string;
    @Input() isDisabled: boolean;
    @Input() appendTo = ''; // we should pass input parameter appendTo = 'body', if the dropdown appeared behind another component
    @Input() style: any;
    @Input() handleDeletedEntry = true;
    @Input() removeSelectedValueFromOptions = false;
    @Input() additionalFiltersCallback: Function = null;
    @Input() fillIsMissing: (item: IItem<TKey>) => IItem<TKey>;
    @Input() isLoadingForEmptySearch = true;
    @Output() onSelectedItemChanged = new EventEmitter<DropdownChange<TKey>>();
    @Output() onDropdownOpened = new EventEmitter();
    @Output() onFilterChanged = new EventEmitter();
    @Output() onLazyLoad = new EventEmitter();

    scrollerOptions: ScrollerOptions = {
        delay: 0,
        lazy: false,
        onScrollIndexChange: this.handleOnLazyLoad.bind(this),
    };
    itemTemplate: TemplateRef<any>;
    selectedItemTemplate: TemplateRef<any>;
    selectItems: SelectItem<IItem<TKey>>[] = [];
    selectedItem: IItem<TKey>;
    writtenItemId: TKey;
    securityContext = SecurityContext;
    disabled: boolean;
    loading = false;
    loadLazyTimeout = null;
    isMoreDataAvailable = false;
    filterValue: string;

    currentSkip = 0;
    currentmaxResultCount = 0;
    loadingStep = 100;

    constructor(injector: Injector) {
        super(injector);
    }

    ngAfterContentInit(): void {
        this.templateRefs.forEach((ref) => {
            switch (ref.identifier) {
                case 'item':
                    this.itemTemplate = ref.template;
                    break;
                case 'selectedItem':
                    this.selectedItemTemplate = ref.template;
                    break;
                default:
                    this.itemTemplate = ref.template;
                    break;
            }
        });
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: TKey): void {
        if (obj?.hasOwnProperty('id') && obj?.hasOwnProperty('text')) {
            this.writtenItemId = obj['id'];
            const item = this.selectItems.find((e) => e?.value?.id === this.writtenItemId);
            if (item) {
                this.selectedItem = item.value;
            } else {
                const newItem = {
                    value: { id: this.writtenItemId, text: obj['text'] },
                    label: obj['text'],
                    styleClass: obj?.hasOwnProperty('isMissing') && obj['isMissing'] && 'mz-red',
                };
                this.selectItems.push(newItem);
                this.selectedItem = newItem.value;
            }
            this.checkIsMissing();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnChanges(changes): void {
        if (changes.options && !changes.options.firstChange) {
            const items = this.convertOptionsToSelectItems(this.options.options);
            this.selectItems = [...this.selectItems, ...items]
            this.loading = false;
            if(this.options.count > this.currentmaxResultCount) {
                this.isMoreDataAvailable = true;
            } else {
                this.isMoreDataAvailable = false;
            }
        }
        // need to notify
        if (this.writtenItemId !== this.selectedItem?.id) {
            this.onInputChange();
        }
    }

    onInputChange() {
        const previousWrittenItemId = this.writtenItemId;
        this.writtenItemId = this.selectedItem?.id;
        if ((this.writtenItemId === undefined && previousWrittenItemId !== null) || this.writtenItemId !== undefined) {
            this.onChange(this.writtenItemId);
            this.onSelectedItemChanged.emit({ oldValue: previousWrittenItemId, newValue: this.writtenItemId });
        }
    }

    onFilter($event) {
        this.loading = true;
        this.currentSkip = 0;
        this.currentmaxResultCount = this.loadingStep;
        this.selectItems = [];
        this.onLazyLoad.emit({ filter: this.filterValue, skipCount: this.currentSkip, maxResultCount: this.currentmaxResultCount });
    }

    onBlur() {
        this.onTouched();
    }

    onShow() {
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(500),
                distinctUntilChanged(),
                tap((text) => {
                    this.onFilter(text);
                }),
            )
            .subscribe();
        if (this.removeSelectedValueFromOptions) {
            let index = this.selectItems.findIndex((si) => si.value.id === this.selectedItem.id);
            if (index !== -1) {
                this.selectItems.splice(index, 1);
            }
        }
        this.selectItems = [];
        if (this.isLoadingForEmptySearch === true) {
            this.loading = true;
            this.currentSkip = 0;
            this.currentmaxResultCount = this.loadingStep;
            this.onLazyLoad.emit({ filter: this.filterValue, skipCount: this.currentSkip, maxResultCount: this.currentmaxResultCount });
        }
        this.onDropdownOpened.emit();
    }

    onFilterChange(event: any) {
        if (!event.filter || event.filter === '') {
            this.selectItems = this.convertOptionsToSelectItems(this.options.options);
        } else if (this.additionalFiltersCallback) {
            let filteredOptions = this.additionalFiltersCallback(event, this.options);
            this.selectItems = this.convertOptionsToSelectItems(filteredOptions);
        } else {
            this.onFilterChanged.emit(event);
        }
    }

    handleOnLazyLoad(event) {
        if(event.last === this.currentmaxResultCount) {
            this.loading = true;
            this.currentSkip = this.currentmaxResultCount;
            this.currentmaxResultCount = this.currentmaxResultCount + this.loadingStep;
            this.onLazyLoad.emit({ filter: this.filterValue, skipCount: this.currentSkip, maxResultCount: this.currentmaxResultCount });
        }
    }

    private convertOptionsToSelectItems(options: IItem<TKey>[]): SelectItem[] {
        return options
            ? options.map(
                  (o) =>
                      ({
                          value: o,
                          label: o.text,
                      }) as SelectItem,
              )
            : [];
    }

    private checkIsMissing() {
        if (this.fillIsMissing && this.selectedItem) {
            this.selectedItem = this.fillIsMissing(this.selectedItem);
            this.selectItems.find((item) => item.value.id === this.selectedItem.id).styleClass = this.selectedItem.isMissing ? 'mz-red' : '';
        }
    }
}
