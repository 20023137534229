import { Component, ElementRef, ViewChild } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { DropdownEditorParams } from './dropdown-editor-params';

@Component({
    selector: 'text-editor',
    template: `
        <app-mazars-dropdown
            #input
            [(ngModel)]="value"
            [uid]="'General_Mapping_ImportType'"
            [options]="options"
            [hasEmpty]="true"
            [showClearOption]="false"
            (onSelectedItemChanged)="onSelectedItemChanged($event)"
            [appendTo]="'body'"
            [style]="{ height: '42px' }"
        ></app-mazars-dropdown>
    `,
})
export class DropdownEditorComponent<Key> implements ICellEditorAngularComp {
    @ViewChild('input', { read: ElementRef }) input: ElementRef;

    public options: IItem<Key>[];
    public value!: Key;

    focusIn() {
        setTimeout(() => this.input.nativeElement.querySelector('input').focus());
    }

    agInit(params: ICellEditorParams & DropdownEditorParams<Key>): void {
        this.options = params.options;
        //fix for when endpoint is returning string and then expecting enum int as input
        if (this.options.filter((o) => o.id === params.value).length > 0) {
            this.value = params.value;
        } else {
            this.value = this.options.find((o) => o.text === params.value)?.id;
        }
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    onSelectedItemChanged(event) {
        this.value = event.newValue;
    }
}
