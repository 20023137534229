<p-skeleton
    [attr.data-testid]="'skeleton_' + uid"
    [shape]="pShape"
    [style]="pStyle"
    [styleClass]="pStyleClass"
    [borderRadius]="pBorderRadius"
    [size]="pSize"
    [width]="pWidth"
    [height]="pHeight"
></p-skeleton>
