<div *ngIf="isVisible" [attr.data-testid]="uid">
    <p class="xLarge font-weight-bolder successGreen" *ngIf="isValid && showSuccessMessage">
        {{ l('Validation_Success') }}
    </p>
    <p *ngIf="!isValid && description" [innerHtml]="description | safe: securityContext.HTML">
    </p>

    <p class="font-weight-bolder" *ngIf="hasErrors" class="errorRed">
        {{ l('Validation_Errors') }}
    </p>
    <p class="font-italic word-break" *ngFor="let error of validationMessageErrors">
        <mazars-validation-result-message
            [labels]="error?.labels"
            [code]="error?.code"
            [reference]="error?.reference"
        ></mazars-validation-result-message>
    </p>

    <p class="font-weight-bolder" *ngIf="hasInfos" class="warningBlue">
        {{ l('Validation_Information') }}
    </p>
    <p class="font-italic word-break" *ngFor="let info of validationMessageInfos">
        <mazars-validation-result-message
            [labels]="info?.labels"
            [code]="info?.code"
            [reference]="info?.reference"
        ></mazars-validation-result-message>
    </p>

    <p class="font-weight-bolder" *ngIf="hasWarnings">
        {{ l('Validation_Warnings') }}
    </p>
    <p class="font-italic" *ngFor="let warning of validationMessageWarnings">
        <mazars-validation-result-message
            [labels]="warning?.labels"
            [code]="warning?.code"
            [reference]="warning?.reference"
        ></mazars-validation-result-message>
    </p>
</div>
