import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { MazarsNotifyService } from '@app/shared/common/toast/mazars-notify.service';
import { fromEvent, switchMap } from 'rxjs';

@Directive({
    selector: '[copy]',
})
export class CopyDirective implements OnInit {
    @Input() copy: string;

    constructor(private host: ElementRef<HTMLElement>, private zone: NgZone, private notify: MazarsNotifyService) {}

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            fromEvent(this.host.nativeElement, 'click')
                .pipe(switchMap(() => navigator.clipboard.writeText(this.copy)))
                .subscribe(() => this.notify.success('Copied!'));
        });
    }
}
