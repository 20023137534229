<div [formGroup]="form">
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label for="Chancellery_Clients">{{ ld('ChancelleryClient') + (adminSettings?.chancelleryViewIsClientRequired ? ' *' : '') }}</label>
                <app-mazars-skeleton *ngIf="dataLoading || areClientsBeingLoaded" uid="clientNumber" shape="input"></app-mazars-skeleton>
                <app-mazars-dropdown
                    *ngIf="!dataLoading && !areClientsBeingLoaded"
                    [uid]="'Chancellery_Clients'"
                    [options]="allClients"
                    [filter]="true"
                    [virtualScrollItemSize]="30"
                    formControlName="clientNumber"
                    hasEmpty="true"
                ></app-mazars-dropdown>
                <app-mazars-validation-message
                    [uid]="'Chancellery_Clients'"
                    [name]="ld('ChancelleryClient')"
                    [control]="form.controls.clientNumber"
                    [submitting]="showWarnings"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label for="Chancellery_Projects">{{ ld('ChancelleryProject') + (adminSettings?.chancelleryViewIsProjectRequired ? ' *' : '') }}</label>
                <app-mazars-skeleton *ngIf="dataLoading || areProjectsBeingLoaded" uid="Chancellery_Projects" shape="input"></app-mazars-skeleton>
                <app-mazars-dropdown
                    *ngIf="!dataLoading && !areProjectsBeingLoaded"
                    [uid]="'Chancellery_Projects'"
                    [options]="allProjects"
                    [filter]="true"
                    [virtualScrollItemSize]="30"
                    formControlName="projectNumber"
                    hasEmpty="true"
                ></app-mazars-dropdown>
                <app-mazars-validation-message
                    [uid]="'Chancellery_Projects'"
                    [name]="ld('ChancelleryProject')"
                    [control]="form.controls.projectNumber"
                    [submitting]="showWarnings"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
</div>
