<div #chart [class]="showDragCursor ? 'grabbable' : 'cursor-default'">
    <drag-scroll style="width: 100%" (dragStart)="dragStart()" (dragEnd)="dragEnd()">
        <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            style="width: 100%; height: 80vh; display: block"
            [callbackFunction]="chartCallback"
            [(update)]="updateFlag"
        ></highcharts-chart>
    </drag-scroll>
</div>
