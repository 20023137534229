<form *ngIf="isAccessRestrictionActive" [formGroup]="form">
    <div class="form-group">
        <label>
            {{ l('PermissionAccessRestriction_DropdownLabel') }} *
            <app-mazars-information-icon
                uid="PermissionAccessRestriction_DropdownDescription"
                message="{{ l('PermissionAccessRestriction_DropdownDescription') }}"
            ></app-mazars-information-icon>
        </label>
        <div>
            <app-mazars-skeleton *ngIf="dataLoading || referenceDataLoading" uid="PermissionAccessRestriction" shape="input"></app-mazars-skeleton>
            <app-mazars-multiselect
                *ngIf="!dataLoading && !referenceDataLoading"
                [id]="'PermissionAccessRestriction'"
                [items]="isAccessRestrictionActive ? items : []"
                [isUserOrOrganization]="true"
                formControlName="accessPermission"
                [placeholder]="isAccessRestrictionActive ? l('Select') : l('Disabled')"
                (onValueChange)="onAccessRestrictionChange($event)"
                [uid]="'mazars_access_restiction_accessPermission'"
            ></app-mazars-multiselect>
        </div>
        <app-mazars-validation-message
            [id]="'PermissionAccessRestriction'"
            [key]="l('PermissionAccessRestriction_DropdownLabel')"
            [control]="form.controls.accessPermission"
            [submitting]="submitting"
            [uid]="'mazars_access_restiction_accessPermission'"
        ></app-mazars-validation-message>
    </div>
</form>
