import { Component, EventEmitter, Injector, Input, Output, SecurityContext, ViewChild } from '@angular/core';
import { MazarsGridComponent } from '@app/modules/mazars-common/components/mazars-grid/mazars-grid.component';
import { IColumnDefinition } from '../mazars-grid/grid-interfaces';
import { ActionIcon } from '@app/modules/mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { SurveyComponentBase } from './survey.component-base';
import { DatePipe } from '@angular/common';
import { IBulkOperationServiceProxy } from '@shared/service-proxies/interfaces/IBulkOperationServiceProxy';

@Component({
    selector: 'app-surveys',
    templateUrl: './surveys.component.html',
})
export class SurveysComponent extends SurveyComponentBase {
    @ViewChild('mazarsTable', { static: true }) overviewTable: MazarsGridComponent;
    @Input() redirectPath: string;
    @Input() actionItems: ActionIcon[];
    @Input() headerActionItems: ActionIcon[];
    @Input() bulkOperationService: IBulkOperationServiceProxy;
    @Output() getAllSurveys = new EventEmitter(true);
    securityContext = SecurityContext;

    columnDefinitions: IColumnDefinition[] = [
        {
            label: this.ld('SurveyName'),
            sortableName: 'name',
            field: 'name',
            template: 'nameTemplate'
        },
        {
            label: this.ld('Status'),
            sortableName: 'isDisabled',
            field: 'isDisabled',
            template: 'statusTemplate'
        },
        {
            label: this.ld('LastUpdate'),
            sortableName: 'lastModificationTime',
            field: 'lastModificationTime',
            customRenderer: (v) => this.datePipe.transform(v),
        },
        {
            label: this.ld('LastUpdateBy'),
            sortableName: 'lastModifierUserId',
            field: 'lastModifier',
        },
        {
            label: this.ld('Tags'),
            field: 'tags',
            template: 'taglistTemplate',
        },
    ];

    constructor(injector: Injector, private datePipe: DatePipe, ) {
        super(injector);
    }

    getSurveys(event: any) {
        this.getAllSurveys.emit(event);
    }

    onDestroy() {
        // abstract method called ngOnDestroy() from the base class
    }
}
