import { Component, Input } from '@angular/core';
import { IUserDetailsDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'app-mazars-avatar',
    templateUrl: './mazars-avatar.component.html',
})
export class MazarsAvatarComponent {
    @Input({ required: true }) uid: string;
    @Input() userDetail: IUserDetailsDto;
    @Input() size: 'large' | 'xlarge';
}
