<mz-dialog
    #dialog
    [uid]="uid"
    [title]="title"
    [size]="size"
    [canSubmit]="false"
    [showSubmitButton]="false"
    [cancelButtonLabel]="closeButtonLabel != null ? closeButtonLabel : l('Close')"
    [showCancelButtonIcon]="false"
>
    <ng-content></ng-content>
</mz-dialog>
