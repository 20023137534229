import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatLongNumber',
})
export class FormatLongNumberPipe implements PipeTransform {

    transform(number: string, nullSymbol = ''): string {
        if (number != null) {
            return this.addTrailingZeros(this.formatFinancialValue(number)) || nullSymbol;
        }
        return nullSymbol;
    }

    private formatFinancialValue(value: string) {
        if (value != null) {
            const number = value ?? '';
            const replaced = number.replace('.', getLocaleNumberSymbol(abp.localization.currentLanguage.name, NumberSymbol.CurrencyDecimal));
            return replaced === 'NaN' ? null : this.formatBigNumber(replaced);
        }
        return null;
    }

    private formatBigNumber(number: string) {
        const parts = number.toString().split(getLocaleNumberSymbol(abp.localization.currentLanguage.name, NumberSymbol.CurrencyDecimal));
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, getLocaleNumberSymbol(abp.localization.currentLanguage.name, NumberSymbol.CurrencyGroup));
        if (parts.length > 1 && parts[1].length < 2) {
            parts[1] = parts[1] + '0';
        }
        return parts.join(getLocaleNumberSymbol(abp.localization.currentLanguage.name, NumberSymbol.CurrencyDecimal));
    }

    private addTrailingZeros(number: string) {
        const numberSymbol = getLocaleNumberSymbol(abp.localization.currentLanguage.name, NumberSymbol.CurrencyDecimal);
        if (number && number !== '-' && number.indexOf(numberSymbol) === -1) {
            return number + numberSymbol + '00';
        } else {
            return number;
        }
    }
}
