<p-overlayPanel #menu [appendTo]="'body'" [attr.data-testid]="uid">
    <div *ngFor="let item of items">
        <div *ngIf="item.type == ContextMenuActionType.Switch" class="d-flex align-items-center py-2">
            <app-mazars-switch [uid]="'Switch_' + uid" [(ngModel)]="item.value" (click)="item.action(item.value)"></app-mazars-switch>
            <label for="'Switch_' + uid" class="ms-2 pe-1" style="display: inline">
                {{ item.label }}
            </label>
        </div>
        <div *ngIf="item.type == ContextMenuActionType.Button" class="d-flex align-items-center context-menu-button-item">
            <div class="py-2">
                <a href="javascript:;" (click)="item.action(item.value); menu.hide();">
                    <span class="me-1">
                        <i aria-hidden="true" [class]="item.icon"></i>
                    </span>
                    {{ item.label }}
                </a>
            </div>
        </div>
    </div>
</p-overlayPanel>
