<p-dialog [header]="title" position="right" [(visible)]="isVisible" [style]="{ width: '40vw' }">
    <p-scrollPanel #scrollPanel [style]="{ width: '100%', height: '40vh' }">
        <div *ngFor="let communication of communications">
            <div class="mb-3 p-3" style="background-color: #f8f9fa">
                <div class="row">
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex">
                            <app-mazars-avatar
                                *ngIf="communication.userDetails"
                                [userDetail]="communication.userDetails[0]"
                                size="large"
                                [uid]="'communications_user_details-' + communication?.userDetails[0]?.id"
                            ></app-mazars-avatar>
                            <p class="my-auto ms-2">{{ communication.userDetails[0].fullName }}</p>
                        </div>
                        <div
                            class="cursorPointer float-right"
                            mazarsTooltip="{{ communication.creationTime.toString() | date : 'EEEE, d. MMMM y, HH:mm:ss' }}"
                            [escape]="false"
                            tooltipPosition="bottom"
                        >
                            {{ communication.creationTime.toString() | date : 'd. MMMM y' }}
                        </div>
                    </div>
                </div>

                <div class="row mt-2 pl-2">
                    <app-mazars-display-quill text [description]="communication.content" [uid]="'quill-editor-' + communication?.id"></app-mazars-display-quill>
                </div>
            </div>
        </div>
    </p-scrollPanel>

    <ng-template pTemplate="footer">
        <div class="mt-2">
            <mazars-editor
                *ngIf="commonQuillMentionService && this.hasWritePermission"
                #meditor
                [uid]="'Comment'"
                [commonQuillMentionService]="commonQuillMentionService"
                [availableShortcuts]="availableShortcuts"
                [formControl]="form.controls.content"
            ></mazars-editor>
            <div>
                <span>
                    <app-mazars-button [uid]="uid + '_cancel'" [inverted]="true" [label]="l('Cancel')" (onClick)="cancel()"></app-mazars-button>
                    <app-mazars-button *ngIf="this.hasWritePermission" [uid]="uid + '_create'" [label]="l('Create')" [disabled]="!form.valid" (onClick)="create()"></app-mazars-button>
                </span>
            </div>
        </div>
    </ng-template>
</p-dialog>
