import { Injectable } from '@angular/core';


@Injectable()
export class TableStateService {
    private filtersPrefix = 'MazarsPortal/TableFilters/';
    private recordsCountPrefix = 'MazarsPortal/RecordsCount/';

    private storage = sessionStorage;

    public getFilters(key: string) {
        return JSON.parse(this.storage.getItem(this.filtersPrefix + key));
    }

    public setFilters(key: string, data: any): void {
        this.storage.setItem(this.filtersPrefix + key, JSON.stringify(data));
    }

    public getRecordsCount(key: string) {
        return JSON.parse(this.storage.getItem(this.recordsCountPrefix + key));
    }

    public setRecordsCount(key: string, data: any): void {
        this.storage.setItem(this.recordsCountPrefix + key, JSON.stringify(data));
    }

    public clearAllTableState() {
        this.storage.clear(); // Update this if storage is not session
    }

    public hasAppliedFilters(filters) {
        return filters ? Object.keys(filters).some((k) => filters[k]?.length > 0) : false;
    }
}
