import { Component, Injector, Input } from '@angular/core';
import { IResultMessageLabelDto } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'mazars-validation-result-message',
    templateUrl: './mazars-validation-result-message.component.html',
})
export class MazarsValidationResultMessageComponent extends AppComponentBase {
    @Input() labels: IResultMessageLabelDto;
    @Input() code: string;
    @Input() reference: string;

    constructor(injector: Injector) {
        super(injector);
    }

    getTooltip(): string {
        return (
            this.l('Validation_Code') +
            ': ' +
            (this.code ? this.code : '') +
            '\n' +
            this.l('Validation_FieldRef') +
            ': ' +
            (this.reference ? this.reference : '')
        );
    }

    getText(): string {
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        switch (cookieLangValue) {
            case 'en':
                return this.labels.en ? this.labels.en : this.labels.de;
            case 'de':
                return this.labels.de ? this.labels.de : this.labels.en;
            default:
                return this.labels.de;
        }
    }
}
