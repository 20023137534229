import { Component } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppConsts } from '@shared/AppConsts';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'mazars-reorder-cell-renderer',
    templateUrl: './mazars-reorder-cell-renderer.component.html',
})
export class MazarsReorderCellRendererComponent implements ICellRendererAngularComp {
    private readonly DefaultLocalizationSourceName: string = AppConsts.localization.defaultLocalizationSourceName;
    params: ICellRendererParams;

    constructor(public als: AppLocalizationService) {}

    refresh(): boolean {
        return false;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    l(key: string, ...args: any[]): string {
        return this.als.ls(this.DefaultLocalizationSourceName, key, ...args);
    }
}
