import { Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IInvolvedPartyItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { take } from 'rxjs/operators';
import { PartiesFilterRequest, PartiesServiceProxy, PartyDataItemDto, PartyType } from '@app/modules/master-data-management/master-data-management-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReportService } from '@app/modules/dac6/reports/report.service';
import { AddressService } from '../../services/address-service';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-mazars-party-selector',
    templateUrl: './mazars-party-selector.component.html',
    styleUrls: ['./mazars-party-selector.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MazarsPartySelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsPartySelectorComponent),
            multi: true,
        },
    ],
})
export class MazarsPartySelectorComponent extends AppComponentBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input({ required: true }) uid: string;
    @Input() hasEmpty: boolean;
    @Input() isDisabled: boolean;
    @Output() onPartySelect: EventEmitter<void> = new EventEmitter<void>();

    form: FormGroup;
    subscriptions: Subscription[] = [];

    allColumnTypes = PartyType;
    selectedPartyItem: PartyType;

    involvedParties: IInvolvedPartyItem<number>[] = [];
    selectedParty: IInvolvedPartyItem<number>;

    partiesRequest: PartiesFilterRequest = {
        filter: '',
        skipCount: 0,
        maxResultCount: 10000,
    } as PartiesFilterRequest;
    virtualScrollItemSize = 30;

    constructor(
        injector: Injector,
        private reportService: ReportService,
        private _partiesServiceProxy: PartiesServiceProxy,
        public addressService: AddressService,
        private fb: FormBuilder
    ) {
        super(injector);
    }

    get value(): any {
        return this.form.value;
    }

    set value(value: any) {
        this.form.patchValue(value);
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ngOnInit(): void {
        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                const party = this.involvedParties.filter((ip) => ip.id === value.id)[0];
                if (party) {
                    value.type = party.type;
                    value.id = party.id;
                } else {
                    value.isDeleted = true;
                }

                this.onChange(value);
                this.onTouched();
            })
        );

        this.getParties();
    }

    getParties(): void {
        this._partiesServiceProxy
            .getParties(this.partiesRequest)
            .pipe(take(1))
            .subscribe((parties: PartyDataItemDto[]) => {
                let involvedParties = parties.map(
                    (party) =>
                        ({
                            text: party.name,
                            id: party.id,
                            type: party.type,
                            address: party.address,
                        } as IInvolvedPartyItem<number>)
                );
                if (this.value && this.value.id) {
                    this.selectedParty = involvedParties.find((p) => p.id === this.value.id);
                }
                this.involvedParties = this.excludeSelectedPartiesFromInvolvedPartyList(this.reportService.selectedInvolvedParties, involvedParties);

                if (this.isDisabled) {
                    this.form.disable();
                } else {
                    this.form.enable();
                }
            });
    }

    handleDropdownOpened() {
        this.getParties();
    }

    handleSelectedItemChanged(previousId: any): void {
        if (this.selectedParty) {
            this.reportService.removeInvolvedParty(this.selectedParty);
        } else if (previousId) {
            let previousParty = this.involvedParties.find((p) => p.id === previousId);
            this.reportService.removeInvolvedParty(previousParty);
        }

        this.selectedParty = this.involvedParties.find((p) => p.id === this.value.id);
        if (this.selectedParty) {
            this.reportService.addInvolvedParty(this.selectedParty);
        }

        this.onPartySelect.emit();
    }

    handleFilterChanged(event: any): void {
        if (event.filter) {
            //load data when there are more than 1 characters typed
            if (event.filter.length > 1) {
                this.filterParties(event.filter);
            }
        } else {
            //load default data
            this.filterParties('');
        }
    }

    filterParties(filter: string): void {
        this.partiesRequest.filter = filter;
        this.getParties();
    }

    onInputChange() {
        this.onChange(this.selectedParty);
    }

    onBlur() {
        this.onTouched();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { selectedParty: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    hasAddressInfo(item: SelectItem): boolean {
        return this.addressService.hasAddressInfo(item?.value);
    }

    getFormattedAddress(item: SelectItem): string {
        return this.addressService.getFormattedAddress(item?.value);
    }

    private createForm(): void {
        this.form = this.fb.group({
            id: [null],
            type: [null],
        });
    }

    private excludeSelectedPartiesFromInvolvedPartyList(
        partiesToExclude: IInvolvedPartyItem<number>[],
        involvedParties: IInvolvedPartyItem<number>[]
    ): IInvolvedPartyItem<number>[] {
        if (partiesToExclude.length > 0) {
            partiesToExclude.forEach((partyToExclude) => {
                involvedParties.forEach((involvedParty, index) => {
                    if (involvedParty.id === partyToExclude.id && (this.value.id || !this.isDisabled) && this.value.id !== partyToExclude.id) {
                        involvedParties.splice(index, 1);
                    }
                });
            });
        }
        return involvedParties;
    }
}
