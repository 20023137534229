import { trigger, state, style, transition, animate, group } from '@angular/animations';

export const SlideMoreLessAnimation = [
    trigger('slideMoreLess', [
        state(
            'more',
            style({
                'max-height': 'auto',
                overflow: 'visible',
            })
        ),
        state(
            'less',
            style({
                'max-height': '10rem',
                overflow: 'hidden',
            })
        ),
        transition('less => more', [
            group([
                animate('50ms ease-in-out', style({})),
                animate(
                    '400ms ease-in-out',
                    style({
                        'max-height': '30rem',
                    })
                ),
            ]),
        ]),
    ]),
];
