import { Component, Input, ViewChild, ViewEncapsulation, SimpleChanges, OnChanges, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import 'ag-grid-enterprise';
import { MazarsAngularGridComponent } from '../mazars-ag-grid/mazars-ag-grid.component';
import { ColDef, CheckboxSelectionCallbackParams, GetRowIdFunc, GetRowIdParams, RowDragEndEvent, RowSelectedEvent } from 'ag-grid-community';
import { MazarsReorderCellRendererComponent } from './mazars-reorder-cell-renderer/mazars-reorder-cell-renderer.component';
import { CommonGridConfigurationFacade } from '../../state-management/facades/common-grid-configuration.facade';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import {
    IMazarsColumnDefinitionDto,
    IMazarsColumnDefinitionInput,
    IMazarsGridDefinitionDto,
    IMazarsGridDefinitionInput,
} from '@shared/service-proxies/common-interfaces';

@Component({
    templateUrl: './mazars-reorder.component.html',
    selector: 'mazars-reorder',
    styleUrls: ['./mazars-reorder.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsReorderComponent extends AppComponentBase implements OnInit, OnChanges, OnDestroy {
    @ViewChild('reorderTable', { static: false }) reorderTable: MazarsAngularGridComponent;
    @Input() isEditable = true;
    @Input() tableId = '';
    @Input() commonGridConfigurationFacade: CommonGridConfigurationFacade;
    @Input() isVisible: boolean;
    @Input() showSearch = true;
    @Input() livePreview = true;
    @Output() onSavedConfig = new EventEmitter();
    @Output() configChanged = new EventEmitter();

    reorderColumnDefs: ColDef[] = [];
    reorderAutoGroupColumnDef: ColDef;
    defaultColDef: ColDef = {
        sortable: false,
        filter: false,
    };
    gridDefinitionDto: IMazarsGridDefinitionDto;
    search = '';

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy(): void {
        this.commonGridConfigurationFacade?.clearData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isVisible'] && this.isVisible) {
            this.getData();
        }
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.field;

    init() {
        this.reorderColumnDefs = [
            {
                rowDrag: true,
                field: 'localizedField',
                minWidth: 300,
                checkboxSelection: (params: CheckboxSelectionCallbackParams<IMazarsColumnDefinitionDto>) => !!params.data && params.data.canBeChanged,
                showDisabledCheckboxes: true,
                cellRenderer: MazarsReorderCellRendererComponent,
            },
        ];

        if (this.commonGridConfigurationFacade) {
            this.subscriptions.push(
                this.commonGridConfigurationFacade.gridColumnSettings$.subscribe((definition: IMazarsGridDefinitionDto) => {
                    if (definition) {
                        this.gridDefinitionDto = _cloneDeep(definition);
                        if (this.isVisible) {
                            this.refreshSelected();
                            this.reorderTable?.agGrid?.api?.hideOverlay();
                        } else {
                            this.setColumnsOnLoad();
                        }
                    }
                }),
            );

            this.subscriptions.push(
                this.commonGridConfigurationFacade.resetGridColumnSettings$.subscribe(() => {
                    this.getData();
                    if (this.isVisible) {
                        this.reorderTable?.agGrid?.api?.hideOverlay();
                    }
                }),
            );

            this.subscriptions.push(
                this.commonGridConfigurationFacade.setGridColumnSettings$.subscribe(() => {
                    this.getData();
                    if (this.isVisible) {
                        this.reorderTable?.agGrid?.api?.hideOverlay();
                    }
                }),
            );
        }
    }

    onDefinitionsFilterChange() {
        this.reorderTable.agGrid.api.setQuickFilter(this.search);
        if (this.gridDefinitionDto) {
            this.gridDefinitionDto = _cloneDeep(this.gridDefinitionDto);
        }
    }

    selectDeselectAll(event) {
        if (event) {
            this.selectAll();
        } else {
            this.deselectAll();
        }
    }

    getData() {
        this.gridShowLoadingOverlay();
        if (this.tableId && this.commonGridConfigurationFacade && this.isVisible) {
            this.commonGridConfigurationFacade.getGridColumnSettings(this.tableId);
        }
    }

    saveConfig() {
        this.gridShowLoadingOverlay();
        let input = {
            tableId: this.tableId,
            configuration: this.dataToConfig(),
        } as IMazarsGridDefinitionInput;
        this.commonGridConfigurationFacade.setGridColumnSettings(input);
        this.onSavedConfig.emit();
    }

    onRowSelected(event: RowSelectedEvent) {
        if (event?.node && event?.node?.rowIndex !== null) {
            let dataItem: IMazarsColumnDefinitionDto = this.gridDefinitionDto.configuration.find((d) => d.field === event.node.id);
            if (dataItem) {
                dataItem.visible = event.node.isSelected();
                if (dataItem?.width === 0) {
                    dataItem.width = 10;
                }
            } else {
                this.gridDefinitionDto.configuration[event.node.rowIndex].visible = event.node.isSelected();
            }
        }
        this.configChanged.emit(event)
        this.setColumns();
    }

    setColumnsOnLoad() {
        this.gridDefinitionDto.configuration.forEach((dataItem) => {
            if (dataItem) {
                if (dataItem?.width === 0) {
                    dataItem.width = 10;
                }
            }
        });

        this.setColumns();
    }

    setColumns() {
        const visibleCols = this.gridDefinitionDto.configuration?.filter((d) => d.visible === true);
        if (visibleCols.length > 1) {
            const widthSum = visibleCols?.map((d) => d.width)?.reduce((a, b) => a + b);
            if (widthSum < 99) {
                const widthToAdd = Math.round((100 - widthSum) / visibleCols.length);
                visibleCols.forEach((c) => (c.width = c.width + widthToAdd));
            } else if (widthSum > 102) {
                const widthToRemove = Math.round(Math.abs(100 - widthSum) / visibleCols.length);
                visibleCols.forEach((c) => (c.width = c.width - widthToRemove));
            }
        } else if (visibleCols.length === 1) {
            visibleCols[0].width = 100;
        }

        if (this.livePreview) {
            this.commonGridConfigurationFacade.changeGridColumnConfig(_cloneDeep(this.gridDefinitionDto));
        }
    }

    resetConfig() {
        this.gridShowLoadingOverlay();
        this.commonGridConfigurationFacade.resetGridColumnSettings(this.tableId);
    }

    onRowDragEnd(event: RowDragEndEvent) {
        if (event?.node) {
            const orderedNodes = [];
            this.reorderTable.agGrid.api.forEachNodeAfterFilterAndSort((node) => orderedNodes.push(node));

            orderedNodes.forEach((on, i) => {
                let dataItemIndex = this.gridDefinitionDto.configuration.findIndex((d) => d.localizedField === on.data.localizedField);
                if (dataItemIndex > 0 && i != dataItemIndex) {
                    this.moveNodeElement(this.gridDefinitionDto.configuration, dataItemIndex, i);
                }
            });

            if (this.livePreview) {
                this.configChanged.emit(event)
                this.commonGridConfigurationFacade.changeGridColumnConfig(_cloneDeep(this.gridDefinitionDto));
            }
        }
    }

    getSelectedColumnNames(): string[] {
        return this.dataToConfig()
            .filter((n) => n.visible)
            .map((n) => n.field);
    }

    private gridShowLoadingOverlay() {
        if (this.isVisible) {
            this.reorderTable.agGrid.api.showLoadingOverlay();
        }
    }

    private moveNodeElement(array: IMazarsColumnDefinitionDto[], fromIndex: number, toIndex: number) {
        const element = array.splice(fromIndex, 1)[0];
        array.splice(toIndex, 0, element);
        return array;
    }

    private selectAll() {
        if (this.search === '') {
            this.reorderTable.agGrid.api.selectAll();
        } else {
            this.reorderTable.agGrid.api.selectAllFiltered();
        }
    }

    private deselectAll() {
        if (this.search === '') {
            this.reorderTable.agGrid.api.deselectAll();
        } else {
            this.reorderTable.agGrid.api.deselectAllFiltered();
        }
        this.gridDefinitionDto.configuration.forEach((d) => {
            if (!d.canBeChanged) {
                let node = this.reorderTable.agGrid.api.getRowNode(d.field);
                node?.setSelected(true);
            }
        });
    }

    private refreshSelected() {
        setTimeout(() => {
            this.reorderTable.agGrid.api.forEachNode((node) => node.setSelected(!!node.data && node.data.visible));
        });
    }

    private dataToConfig(): IMazarsColumnDefinitionInput[] {
        const orderedNodes = [];
        this.reorderTable.agGrid.api.forEachNode((node) => orderedNodes.push(node));
        if (orderedNodes && orderedNodes.length > 0) {
            let input: IMazarsColumnDefinitionInput[] = orderedNodes.map(
                (on) =>
                    ({
                        field: on?.data?.field,
                        isDefault: on?.data?.isDefault,
                        visible: on?.data?.visible,
                        canBeChanged: on?.data?.canBeChanged,
                        localizationKey: on?.data?.localizationKey,
                        localizationSource: on?.data?.localizationSource,
                        width: on?.data?.width,
                    }) as IMazarsColumnDefinitionInput,
            );
            return input;
        }
        return [];
    }
}
