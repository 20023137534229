import { Injectable } from '@angular/core';
import { IMazarsMultiselectItem } from '../components/mazars-multiselect/mazars-multiselect-item';
import { IInvolvedPartyItem } from '../interfaces/item.interface';

@Injectable({
    providedIn: 'root',
})
export class AddressService {
    hasAddressInfo(item: IMazarsMultiselectItem | IInvolvedPartyItem<any>): boolean {
        return (
            item && item.address &&
            ((item.address.street !== '' && item.address.street !== null) ||
                (item.address.buildingIdentifier !== '' && item.address.buildingIdentifier !== null) ||
                (item.address.postalCode !== '' && item.address.postalCode !== null) ||
                (item.address.city !== '' && item.address.city !== null))
        );
    }

    getFormattedAddress(item: IMazarsMultiselectItem | IInvolvedPartyItem<any>) {
        let result = '';
        if (item.address) {
            if (item.address.street) {
                result += item.address.street + ' ';
            }
            if (item.address.buildingIdentifier) {
                result += item.address.buildingIdentifier;
            }

            if (item.address.postalCode || item.address.city) {
                if (item.address.street || item.address.buildingIdentifier) {
                    result += ', ';
                }
                if (item.address.postalCode) {
                    result += item.address.postalCode + ' ';
                }
                if (item.address.city) {
                    result += item.address.city;
                }
            }
        }
        return result;
    }

    additionalFiltersCallback = (event: any, options: any) =>
        options.filter(
            (o) =>
                o.text?.toLowerCase().includes(event.filter?.toLowerCase()) ||
                o.address?.buildingIdentifier?.toLowerCase().includes(event.filter) ||
                o.address?.city?.toLowerCase().includes(event.filter) ||
                o.address?.street?.toLowerCase().includes(event.filter) ||
                o.address?.postalCode?.toLowerCase().includes(event.filter)
        );
}
