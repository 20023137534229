<mz-dialog
    #addModal
    [uid]="uid"
    [title]="l('Title_AddSurvey')"
    [canSubmit]="form.valid"
    [submitButtonLabel]="l('btn_AddSurvey')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="modal.submit()" autocomplete="off">
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="Survey">{{ l('Survey') }}</label>
                        <app-mazars-dropdown
                            [uid]="'Survey'"
                            [options]="surveys"
                            [hasEmpty]="false"
                            placeholder="{{ l('Survey') }}"
                            formControlName="surveyId"
                        >
                            <ng-template mazarsTemplate let-option>
                                {{ option.text }}
                            </ng-template>
                        </app-mazars-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mz-dialog>
