<div class="form-group">
    <label *ngIf="includeLabel" for="{{ uid }}">{{ dropdownLabelLocalization }} {{ isRequired ? '*' : '' }}</label>
    <app-mazars-dropdown
        *ngIf="!multiselect"
        [uid]="uid"
        [options]="allClients"
        [additionalFiltersCallback]="addressService.additionalFiltersCallback"
        [formControl]="formControl"
        [filter]="true"
        hasEmpty="true"
        (onSelectedItemChanged)="onSelectedItemChanged($event)"
    >
        <ng-template mazarsTemplate="selectedItem" let-item let-styleClass="styleClass">
            <div>
                <i *ngIf="getClientType(item.id) === PartyType.LegalEntity" class="pi pi-sitemap mr-2" aria-hidden="true"></i>
                <i *ngIf="getClientType(item.id) === PartyType.NaturalPerson" class="pi pi-user mr-2" aria-hidden="true"></i>
                <span [attr.data-testid]="uid + '-' + item.id" [ngClass]="styleClass">{{ item.text }}</span>
            </div>
        </ng-template>
        <ng-template mazarsTemplate="item" let-item let-styleClass="styleClass">
            <div>
                <i *ngIf="getClientType(item.id) === PartyType.LegalEntity" class="pi pi-sitemap mr-2" aria-hidden="true"></i>
                <i *ngIf="getClientType(item.id) === PartyType.NaturalPerson" class="pi pi-user mr-2" aria-hidden="true"></i>
                <span [attr.data-testid]="uid + '-' + item.id" [ngClass]="styleClass">{{ item.text }}</span>
                <div>
                    <small *ngIf="hasAddressInfo(item)" class="ml-4 mz-address-info" [ngClass]="{ 'ml-4': item?.icon && item?.icon !== '' }">
                        {{ getFormattedAddress(item) }}
                    </small>
                </div>
            </div>
        </ng-template>
    </app-mazars-dropdown>
    <app-mazars-multiselect
        *ngIf="multiselect"
        [uid]="uid + '-multiselect'"
        [items]="allClients"
        [formControl]="formControl"
        [filter]="true"
        [hasAddressDetails]="true"
        (onValueChange)="onSelectedItemChanged($event)"
    ></app-mazars-multiselect>
    <app-mazars-validation-message
        [uid]="uid + '-multiselect'"
        [key]="dropdownLabelLocalization"
        [control]="formControl"
        [submitting]="submitting"
    ></app-mazars-validation-message>
</div>
