import {
    Component,
    Input,
    Output,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    ViewChild,
    EventEmitter,
    TemplateRef,
    ChangeDetectionStrategy,
    NgZone,
    ViewEncapsulation,
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MazarsMessage } from '@app/shared/layout/toast/mazars-message';

@Component({
    selector: 'mazars-toast-item',
    template: `
        <div
            #container
            [attr.id]="message.id"
            [class]="message.styleClass"
            [ngClass]="['p-toast-message-' + message.severity, 'p-toast-message']"
            [@messageState]="{
                value: 'visible',
                params: {
                    showTransformParams: showTransformOptions,
                    hideTransformParams: hideTransformOptions,
                    showTransitionParams: showTransitionOptions,
                    hideTransitionParams: hideTransitionOptions
                }
            }"
            (mouseenter)="onMouseEnter()"
            (mouseleave)="onMouseLeave()"
            (click)="handleOnClick()"
        >
            <div class="p-toast-message-content" role="alert" aria-live="assertive" aria-atomic="true" [ngClass]="message.contentStyleClass">
                <ng-container *ngIf="!template">
                    <span
                        [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')"
                        [ngClass]="{
                            'pi-info-circle': message.severity == 'info',
                            'pi-exclamation-triangle': message.severity == 'warn',
                            'pi-times-circle': message.severity == 'error',
                            'pi-check': message.severity == 'success'
                        }"
                    ></span>
                    <div class="p-toast-message-text">
                        <div class="p-toast-summary">{{ message.summary }}</div>
                        <div class="p-toast-detail">{{ message.detail }}</div>
                    </div>
                </ng-container>
                <ng-container *ngTemplateOutlet="template; context: { $implicit: message }"></ng-container>
                <button
                    type="button"
                    class="p-toast-icon-close p-link"
                    (click)="onCloseIconClick($event)"
                    (keydown.enter)="onCloseIconClick($event)"
                    *ngIf="message.closable !== false"
                    pRipple
                >
                    <span class="p-toast-icon-close-icon pi pi-times"></span>
                </button>
            </div>
        </div>
    `,
    animations: [
        trigger('messageState', [
            state(
                'visible',
                style({
                    transform: 'translateY(0)',
                    opacity: 1,
                })
            ),
            transition('void => *', [style({ transform: '{{showTransformParams}}', opacity: 0 }), animate('{{showTransitionParams}}')]),
            transition('* => void', [
                animate(
                    '{{hideTransitionParams}}',
                    style({
                        height: 0,
                        opacity: 0,
                        transform: '{{hideTransformParams}}',
                    })
                ),
            ]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'p-element',
    },
})
export class MazarsToastItemComponent implements AfterViewInit, OnDestroy {
    @Input() message: MazarsMessage;

    @Input() index: number;

    @Input() template: TemplateRef<any>;

    @Input() showTransformOptions: string;

    @Input() hideTransformOptions: string;

    @Input() showTransitionOptions: string;

    @Input() hideTransitionOptions: string;

    @Output() onClose: EventEmitter<any> = new EventEmitter();

    @ViewChild('container') containerViewChild: ElementRef;

    timeout: any;

    constructor(private zone: NgZone) {}

    ngAfterViewInit() {
        this.initTimeout();
    }

    initTimeout() {
        if (!this.message.sticky) {
            this.zone.runOutsideAngular(() => {
                this.timeout = setTimeout(() => {
                    this.onClose.emit({
                        index: this.index,
                        message: this.message,
                    });
                }, this.message.life || 3000);
            });
        }
    }

    clearTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    onMouseEnter() {
        this.clearTimeout();
    }

    onMouseLeave() {
        this.initTimeout();
    }

    onCloseIconClick(event) {
        this.clearTimeout();

        this.onClose.emit({
            index: this.index,
            message: this.message,
        });

        event.preventDefault();
    }

    ngOnDestroy() {
        this.clearTimeout();
    }

    handleOnClick() {
        if (this.message?.options?.action) {
            this.message.options.action();
        }
    }
}
