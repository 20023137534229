<textarea
    [rows]="rows"
    [cols]="cols"
    pInputTextarea
    autoResize="autoResize"
    id="{{ uid }}"
    name="{{ uid }}"
    class="form-control"
    placeholder="{{ placeholder }}"
    [(ngModel)]="value"
    [disabled]="isDisabled"
    (blur)="onBlur()"
    (input)="onInputChange()"
    style="min-height: 80px"
    [attr.data-testid]="uid"
></textarea>
<label *ngIf="maxLength" class="max-length-label">{{ value?.length ? value?.length : 0 }} /{{ maxLength }}</label>
