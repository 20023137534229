export enum ProcessStatePermission{
    ReportEditEvaluation = 'Dac6.Report.EditEvaluation',
    ReportEditDisclosure = 'Dac6.Report.EditDisclosure',
    ReportDecisionMaking = 'Dac6.Report.DecisionMaking',
    ReportReviewDocumentationDecision = 'Dac6.Report.ReviewDocumentationDecision',
    ReportReviewSubmissionDecision = 'Dac6.Report.ReviewSubmissionDecision',
    ReportReviewDisclosureData = 'Dac6.Report.ReviewDisclosureData',
    ReportSubmission = 'Dac6.Report.Submission',
    RevokeDocumentationExternalReporting = 'Dac6.Report.RevokeDocumentationExternalReporting',
}
