<div [mazarsTooltip]="selectedItem ? selectedItem.text : placeholder ? placeholder : l('Select')" tooltipPosition="bottom" [escape]="false">
    <p-dropdown
        #dd
        id="{{ uid }}"
        name="{{ uid }}"
        [attr.data-testid]="uid"
        [options]="selectItems"
        [disabled]="isDisabled || disabled"
        [(ngModel)]="selectedItem"
        [virtualScroll]="filter"
        [virtualScrollItemSize]="virtualScrollItemSize"
        placeholder="{{ placeholder ? placeholder : l('Select') }}"
        filterPlaceholder="{{ filterPlaceholder != null && filterPlaceholder != '' ? filterPlaceholder : l('Search...') }}"
        (onBlur)="onBlur()"
        (onChange)="onInputChange()"
        (onFilter)="onFilter($event)"
        (onShow)="onShow()"
        styleClass="form-control"
        [filter]="filter"
        [showClear]="hasEmpty && showClearOption"
        [appendTo]="appendTo"
        [style]="style"
        [virtualScrollOptions]="scrollerOptions"
        [resetFilterOnHide]="true"
        scrollHeight="250px"
    >
        <ng-template pTemplate="filter" let-options="options">
            <div class="p-dropdown-filter-container py-2" (click)="$event.stopPropagation()">
                <input #searchInput type="text" class="p-dropdown-filter p-inputtext p-component" pInputText placeholder="Filter" [(ngModel)]="filterValue" />
                <i class="pi pi-search p-dropdown-filter-icon"></i>
            </div>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
            <div *ngIf="!selectedItemTemplate" class="truncate">
                <i *ngIf="item.icon" class="{{ item.icon }} mr-2" style="width: 14px" [style.color]="item.iconColor" aria-hidden="true"></i>
                <span [attr.data-testid]="uid + '-' + item.value.id" [ngClass]="item.styleClass">{{ item.label }}</span>
            </div>
            <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: item.value, styleClass: item.styleClass }"></ng-container>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div *ngIf="!itemTemplate" class="truncate">
                <i *ngIf="item.icon" class="{{ item.icon }} mr-2" style="width: 14px" [style.color]="item.iconColor" aria-hidden="true"></i>
                <span [attr.data-testid]="uid + '-' + item.value.id" [ngClass]="item.styleClass">{{ item.label }}</span>
            </div>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item.value, styleClass: item.styleClass }"></ng-container>
        </ng-template>

        <ng-template let-item pTemplate="empty">
            <div *ngIf="!loading">No records</div>
        </ng-template>

        <ng-template let-item pTemplate="emptyfilter">
            <div *ngIf="!loading">No records</div>
        </ng-template>

        <ng-template let-item pTemplate="footer">
            <div *ngIf="isMoreDataAvailable && !loading && filterValue" class="py-4 px-3">More data available. Use more specific filter.</div>
            <div class="d-flex row justify-content-center w-100 py-5" *ngIf="loading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
    </p-dropdown>
</div>
