<div class="text-nowrap" [attr.data-testid]="uid">
    <span
        *ngFor="let phase of process"
        class="rounded-circle d-inline-block mr-2"
        [ngStyle]="{
            width: '1.2rem',
            height: '1.2rem',
            cursor: hasLink(phase.description) ? 'pointer' : 'inherit',
            backgroundColor:
                phase.stateType == processStateType.Active
                    ? '#86C3EF'
                    : phase.stateType == processStateType.Finished
                    ? '#4170CC'
                    : phase.stateType == processStateType.Error
                    ? '#CD2121'
                    : '#DFDFDF'
        }"
        [mazarsTooltip]="l('WorkItemProcess_' + phase.description)"
        [escape]="false"
        tooltipEvent="hover"
        (click)="onClick(phase.description)"
    ></span>
</div>
