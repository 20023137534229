<div>
    <quill-editor
        *ngIf="!htmlLoaded"
        #view
        format="json"
        theme="snow"
        [contentEditable]="'false'"
        (onEditorCreated)="onEditorCreated($event)"
        [attr.data-testid]="uid"
    ></quill-editor>
    <div innerHTML="{{ descriptionHtml }}"></div>
</div>
