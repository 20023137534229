<form [formGroup]="form">
    <h5>{{ l('DeadlineDates_Title') }}</h5>
    <section class="mb-4">
        <p class="mb-0">
            {{ l('DeadlineDates_Description') }}
            <app-mazars-information-icon
                uid="DeadlineDates_DescriptionTooltip"
                [message]="this.tooltipMessage ?? l('DeadlineDates_DescriptionTooltip')"
            ></app-mazars-information-icon>
        </p>
    </section>

    <div>
        <div class="due-date-reminder-row">
            <app-mazars-input-number
                class="mr-3"
                [uid]="'DeadlineDates_DueDateFirstReminder'"
                [width]="100"
                [height]="30"
                formControlName="dueDateFirstReminder"
            ></app-mazars-input-number>
            <app-mazars-switch
                [uid]="'DeadlineDates_DueDateFirstReminderActive'"
                [formControl]="form.controls.dueDateFirstReminderActive"
                class="deadline-date-toggle"
            ></app-mazars-switch>
            <span class="ml-3">
                <app-mazars-information-icon
                    uid="deadline-date-icon"
                    [iconClass]="'fas fa-exclamation-triangle fa-exclamation-triangle-yellow deadline-date-icon'"
                ></app-mazars-information-icon>
            </span>
            <span class="ml-4">
                {{ l('DeadlineDates_FirstReminderDescription') }}
            </span>
        </div>
    </div>
    <div>
        <div id="due-date-exceeded-row" class="mt-2 due-date-reminder-row">
            <app-mazars-switch
                [uid]="'DeadlineDates_DueDateHasExceeded'"
                [formControl]="form.controls.dueDateHasExceeded"
                class="deadline-date-toggle"
            ></app-mazars-switch>

            <span class="ml-4">
                <app-mazars-information-icon uid="dueDateHasExceeded-icon" [iconClass]="'fas fa-exclamation fa-exclamation-red deadline-date-icon'"></app-mazars-information-icon>
            </span>

            <span class="ml-3">
                {{ l('DeadlineDates_DueDateDescription') }}
            </span>
        </div>
    </div>
    <div>
        <div class="due-date-reminder-row">
            <span *ngIf="isDueDateFirstReminderInvalid()" class="text-danger">
                {{ l('DeadlineDates_FirstReminderDaysRequired') }}
            </span>
        </div>
    </div>
</form>
