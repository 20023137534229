import { Component, EventEmitter, Injector, Input, OnChanges, Output, SecurityContext, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IInvolvedPartyItem, IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MultiSelect } from 'primeng/multiselect';
import { AddressService } from '../../services/address-service';
import { IMazarsMultiselectItem } from './mazars-multiselect-item';

@Component({
    selector: 'app-mazars-multiselect',
    templateUrl: './mazars-multiselect.component.html',
    styleUrls: ['./mazars-multiselect.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsMultiselectComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsMultiselectComponent<TKey> extends AppComponentBase implements OnChanges, ControlValueAccessor {
    @ViewChild('multiselect', { static: true }) multiselect: MultiSelect;
    @Input({ required: true }) uid: string;
    @Input() items: IItem<TKey>[] | any[];
    @Input() showToggleAll?: boolean;
    @Input() filter = true;
    @Input() displayType = 'comma';
    @Input() isDisabled: boolean;
    @Input() optionValue: string;
    @Input() optionLabel: string;
    @Input() placeholder?: string = this.l('Select');
    @Input() isCountries: boolean;
    @Input() isUserOrOrganization: boolean;
    @Input() haveStatus: boolean;
    @Input() hasAddressDetails: boolean;
    @Input() showHeader?: boolean = true;
    @Input() appendTo?: any;
    @Output() onValueChange: EventEmitter<IMazarsMultiselectItem[]> = new EventEmitter<IMazarsMultiselectItem[]>();

    options: IMazarsMultiselectItem[];

    disabled: boolean;
    selectedItems: IMazarsMultiselectItem[];
    securityContext = SecurityContext;

    constructor(injector: Injector, private addressService: AddressService) {
        super(injector);
    }

    writeValue(obj: any): void {
        if (obj) {
            this.selectedItems = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.options = this.items
            ? (this.items as any[]).map(
                  (item) =>
                      ({
                          value: this.optionValue ? item[this.optionValue] : item.id,
                          label: this.optionLabel ? item[this.optionLabel] : item.text,
                          icon: item?.icon,
                          iconColor: item?.iconColor,
                          address: item?.address,
                      } as IMazarsMultiselectItem)
              )
            : [];

        if (this.selectedItems) {
            const deletedItems = this.selectedItems.filter((si) => !this.items.map((i) => i.id).includes(si));
            if (deletedItems && deletedItems.length > 0) {
                deletedItems.forEach((di) => {
                    this.options.push({
                        value: di,
                        label: this.l('Entry_Got_Deleted'),
                        styleClass: 'mz-red',
                    });
                });
            }
        }
    }

    getSelectItem(id: any) {
        let foundItems: any[] = this.options ? this.options.filter((i) => i.value === id) : null;
        let isItemFound = foundItems && foundItems.length === 1;

        return {
            value: isItemFound ? foundItems[0].value : undefined,
            label: isItemFound ? foundItems[0].label : undefined,
            icon: isItemFound ? foundItems[0].icon : undefined,
            iconColor: isItemFound ? foundItems[0].iconColor : undefined,
            styleClass: isItemFound ? foundItems[0].styleClass : undefined,
        };
    }

    getSelectedItems(selectedItems: any[]) {
        if (this.options && this.options.length > 0 && selectedItems && selectedItems.length > 0) {
            try {
                return selectedItems.map((si) => this.options.filter((i) => i.value === si)[0].label);
            } catch (error) {
                return [];
            }
        }

        return [];
    }

    getSelectedItemsValue() {
        return this.getSelectedItems(this.selectedItems).join(', ');
    }

    hasAddressInfo(item: IInvolvedPartyItem<any>): boolean {
        return this.addressService.hasAddressInfo(item);
    }

    getFormattedAddress(item: IInvolvedPartyItem<any>): string {
        return this.addressService.getFormattedAddress(item);
    }

    onBlurHandler() {
        this.onTouched();
    }

    onChangeHandler(event: any) {
        this.onChange(this.selectedItems);
        this.onValueChange.emit(this.selectedItems);
    }

    onFilterChange(event: any) {
        if (this.hasAddressDetails) {
            this.multiselect._filteredOptions = this.items
                ? this.items
                      .filter(
                          (o) =>
                              o.text?.toLowerCase().includes(event.filter) ||
                              o.address?.buildingIdentifier?.toLowerCase().includes(event.filter) ||
                              o.address?.city?.toLowerCase().includes(event.filter) ||
                              o.address?.street?.toLowerCase().includes(event.filter) ||
                              o.address?.postalCode?.toLowerCase().includes(event.filter)
                      )
                      .map(
                          (item) =>
                              ({
                                  value: this.optionValue ? item[this.optionValue] : item.id,
                                  label: this.optionLabel ? item[this.optionLabel] : item.text,
                                  icon: item?.icon,
                                  iconColor: item?.iconColor,
                                  address: item?.address,
                              } as IMazarsMultiselectItem)
                      )
                : [];
        }
    }

    private onChange = (_) => {
        // This is intentional
    };

    private onTouched = () => {
        // This is intentional
    };
}
