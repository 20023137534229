import { Directive, ElementRef, HostListener } from '@angular/core';
import { MazarsTooltipDirective } from '@app/modules/mazars-common/components/mazars-tooltip/mazars-tooltip.directive';

@Directive({
    selector: '[isEllipsisActive]',
})
export class IsEllipsisActiveDirective {
    constructor(private tooltip: MazarsTooltipDirective, private elementRef: ElementRef) {}

    @HostListener('mouseenter')
    onMouseEnter(): void {
        setTimeout(() => {
            const element = this.elementRef.nativeElement;
            if (element.scrollWidth <= element.clientWidth) {
                this.tooltip.hide();
            } else {
                this.tooltip.show();
            }
        });
    }
}
