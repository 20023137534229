import { ProcessStateNames } from '@app/modules/mazars-common/components/mazars-process-state/process-state-names';
import { ProcessStatePermission } from '@app/modules/mazars-common/components/mazars-process-state/process-state-permissions';
import { DeclarationProcessStateNames } from '@app/modules/real-property-tax/economic-units/economic-unit-tab-declaration/declaration-process-flow/declaration-process-state-names';
import { IProcessStateGroupedDto } from '@shared/service-proxies/common-interfaces';

export class ProcessStateSettings {
    static activeStatePhaseClass(phaseName: string) {
        switch (phaseName) {
            case ProcessStateNames.ErrorFeedback:
                return 'process--error';

            case ProcessStateNames.FinishedWithoutSubmission:
            case ProcessStateNames.SuccessfullyFinished:
            case ProcessStateNames.FinishedExternalReporting:
                return 'process--completed';

            case ProcessStateNames.EditEvaluationPhase:
            case ProcessStateNames.SubmissionDecisionPhase:
            case ProcessStateNames.ReviewDocumentationDecisionPhase:
            case ProcessStateNames.ReviewExternalDecisionPhase:
            case ProcessStateNames.ReviewSubmissionDecisionPhase:
            case ProcessStateNames.EditReportDataPhase:
            case ProcessStateNames.ReviewReportDataPhase:
            case ProcessStateNames.SubmissionPhase:
            case ProcessStateNames.ManualSubmissionPendingPhase:
            case ProcessStateNames.WaitingForFeedbackPhase:
            default:
                return '';
        }
    }

    static activeStatePhaseIcon(phaseName: string) {
        switch (phaseName) {
            case ProcessStateNames.EditEvaluationPhase: {
                return 'fas fa-tasks';
            }
            case ProcessStateNames.SubmissionDecisionPhase: {
                return 'fas fa-gavel';
            }
            case ProcessStateNames.ReviewDocumentationDecisionPhase:
            case ProcessStateNames.ReviewExternalDecisionPhase: {
                return 'fas fa-search';
            }
            case ProcessStateNames.FinishedWithoutSubmission:
            case ProcessStateNames.FinishedExternalReporting: {
                return 'fas fa-check-double';
            }
            case ProcessStateNames.ReviewSubmissionDecisionPhase: {
                return 'fas fa-search';
            }
            case ProcessStateNames.EditReportDataPhase:
            case DeclarationProcessStateNames.EditDeclarationPhase: {
                return 'far fa-edit';
            }
            case ProcessStateNames.ReviewReportDataPhase:
            case DeclarationProcessStateNames.ReviewDeclarationPhase: {
                return 'fas fa-search';
            }
            case ProcessStateNames.SubmissionPhase:
            case DeclarationProcessStateNames.ReadyForSubmissionPhase: {
                return 'far fa-paper-plane';
            }
            case ProcessStateNames.ManualSubmissionPendingPhase: {
                return 'far fa-paper-plane';
            }
            case ProcessStateNames.WaitingForFeedbackPhase: {
                return 'far fa-comments';
            }
            case ProcessStateNames.ErrorFeedback: {
                return 'fas fa-exclamation';
            }
            case ProcessStateNames.SuccessfullyFinished: {
                return 'fas fa-check-double';
            }
            default: {
                return 'fas fa-angle-right';
            }
        }
    }

    static stateLabel(state: IProcessStateGroupedDto, currentState: string) {
        let prefix = 'Report_Process_Flow_Step_';
        switch (state.processStates.includes(currentState) ? currentState : state.processStates[0]) {
            case ProcessStateNames.EditEvaluationPhase:
            case ProcessStateNames.SubmissionDecisionPhase:
            case ProcessStateNames.FinishedWithoutSubmission:
            case ProcessStateNames.EditReportDataPhase:
            case ProcessStateNames.SubmissionPhase:
            case ProcessStateNames.ManualSubmissionPendingPhase:
            case ProcessStateNames.WaitingForFeedbackPhase:
            case ProcessStateNames.ErrorFeedback:
            case ProcessStateNames.SuccessfullyFinished:
            case ProcessStateNames.FinishedExternalReporting: {
                return prefix + state.processGroup;
            }
            case ProcessStateNames.ReviewDocumentationDecisionPhase:
            case ProcessStateNames.ReviewExternalDecisionPhase:
            case ProcessStateNames.ReviewSubmissionDecisionPhase:
            case ProcessStateNames.ReviewReportDataPhase: {
                return prefix + currentState;
            }
            default: {
                return prefix + state.processGroup + currentState;
            }
        }
    }

    static setProcessState(phaseName: string): ProcessStateNames {
        return phaseName in ProcessStateNames ? ProcessStateNames[phaseName] : null;
    }

    static getProcessBtnText(phaseName: string) {
        switch (phaseName) {
            case ProcessStateNames.EditEvaluationPhase: {
                return 'Report_Process_Flow_Button_Finish';
            }
            case ProcessStateNames.SubmissionDecisionPhase: {
                return 'Report_Process_Flow_Button_MakeDecision';
            }
            case ProcessStateNames.ReviewDocumentationDecisionPhase:
            case ProcessStateNames.ReviewExternalDecisionPhase:
            case ProcessStateNames.ReviewSubmissionDecisionPhase: {
                return 'Report_Process_Flow_Button_ReviewDecision';
            }
            case ProcessStateNames.EditReportDataPhase: {
                return 'Report_Process_Flow_Button_Finish';
            }
            case ProcessStateNames.ReviewReportDataPhase: {
                return 'Report_Process_Flow_Button_FinishReview';
            }
            case ProcessStateNames.SubmissionPhase: {
                return 'Report_Process_Flow_Button_Submit';
            }
            case ProcessStateNames.ManualSubmissionPendingPhase: {
                return 'Report_Process_Flow_Button_Confirm_Submit';
            }
            case ProcessStateNames.WaitingForFeedbackPhase: {
                return 'Report_Process_Flow_Button_Provide_Feedback';
            }
            case ProcessStateNames.ErrorFeedback: {
                return 'Report_Process_Flow_Button_Resubmit';
            }
            case ProcessStateNames.FinishedWithoutSubmission: {
                return 'Report_Process_Flow_Button_Revoke';
            }
            case ProcessStateNames.FinishedExternalReporting: {
                return 'Report_Process_Flow_Button_Revoke';
            }
            default: {
                return 'Report_Process_Flow_Button_Next';
            }
        }
    }

    static getProcessPermission(phaseName: string) {
        switch (phaseName) {
            case ProcessStateNames.EditEvaluationPhase: {
                return ProcessStatePermission.ReportEditEvaluation;
            }
            case ProcessStateNames.SubmissionDecisionPhase: {
                return ProcessStatePermission.ReportDecisionMaking;
            }
            case ProcessStateNames.ReviewDocumentationDecisionPhase:
            case ProcessStateNames.ReviewExternalDecisionPhase: {
                return ProcessStatePermission.ReportReviewDocumentationDecision;
            }
            case ProcessStateNames.ReviewSubmissionDecisionPhase: {
                return ProcessStatePermission.ReportReviewSubmissionDecision;
            }
            case ProcessStateNames.EditReportDataPhase: {
                return ProcessStatePermission.ReportEditDisclosure;
            }
            case ProcessStateNames.ReviewReportDataPhase: {
                return ProcessStatePermission.ReportReviewDisclosureData;
            }
            case ProcessStateNames.SubmissionPhase: {
                return ProcessStatePermission.ReportSubmission;
            }
            case ProcessStateNames.ManualSubmissionPendingPhase: {
                return ProcessStatePermission.ReportSubmission;
            }
            case ProcessStateNames.WaitingForFeedbackPhase: {
                return ProcessStatePermission.ReportSubmission;
            }
            case ProcessStateNames.ErrorFeedback: {
                return ProcessStatePermission.ReportSubmission;
            }
            case ProcessStateNames.FinishedExternalReporting: {
                return ProcessStatePermission.RevokeDocumentationExternalReporting;
            }
            case ProcessStateNames.FinishedWithoutSubmission: {
                return ProcessStatePermission.RevokeDocumentationExternalReporting;
            }
            default: {
                return 'Unknown';
            }
        }
    }

    static isReportDataTabReadonly(phaseName: string): boolean {
        switch (phaseName) {
            case ProcessStateNames.EditEvaluationPhase:
            case ProcessStateNames.EditReportDataPhase: {
                return false;
            }
            case ProcessStateNames.SubmissionDecisionPhase:
            case ProcessStateNames.ReviewDocumentationDecisionPhase:
            case ProcessStateNames.ReviewExternalDecisionPhase:
            case ProcessStateNames.FinishedWithoutSubmission:
            case ProcessStateNames.ReviewSubmissionDecisionPhase:
            case ProcessStateNames.ReviewReportDataPhase:
            case ProcessStateNames.SubmissionPhase:
            case ProcessStateNames.ManualSubmissionPendingPhase:
            case ProcessStateNames.WaitingForFeedbackPhase:
            case ProcessStateNames.ErrorFeedback:
            case ProcessStateNames.SuccessfullyFinished:
            case ProcessStateNames.FinishedExternalReporting: {
                return true;
            }
            default: {
                return null;
            }
        }
    }

    static isAssignedUserDisabled(phaseName: string): boolean {
        if (
            phaseName !== ProcessStateNames.SuccessfullyFinished &&
            phaseName !== ProcessStateNames.FinishedWithoutSubmission &&
            phaseName !== ProcessStateNames.FinishedExternalReporting &&
            phaseName !== ProcessStateNames.WaitingForFeedbackPhase
        ) {
            return false;
        }

        return true;
    }
}
