<div class="mz-container">
    <app-mazars-searchbar
        uid="search"
        (onChange)="setFilter($event)"
        (onClear)="clearFilter()"
        [showCreate]="hasCreatePermission"
        [createButtonTitle]="l('CreateChannel')"
        (onCreate)="showCreateModal()"
    >
        <ng-template mazarsTemplate>
            <app-mazars-multiselect
                [uid]="'Type'"
                [items]="typeItems"
                [filter]="true"
                placeholder="{{ l('Type') }}"
                [(ngModel)]="typeFilter"
                (ngModelChange)="reloadPage()"
                [showToggleAll]="true"
            ></app-mazars-multiselect>
        </ng-template>
        <ng-template mazarsTemplate>
            <app-mazars-multiselect
                [uid]="'Tag'"
                [items]="tagsItems"
                [filter]="true"
                placeholder="{{ l('Tags') }}"
                [(ngModel)]="tagsFilter"
                (ngModelChange)="reloadPage()"
                [showToggleAll]="true"
            ></app-mazars-multiselect>
        </ng-template>
        <ng-template mazarsTemplate>
            <app-mazars-multiselect
                [uid]="'Author'"
                [items]="authors"
                [filter]="true"
                placeholder="{{ l('Author') }}"
                [(ngModel)]="authorFilter"
                (ngModelChange)="reloadPage()"
                [showToggleAll]="true"
            ></app-mazars-multiselect>
        </ng-template>
    </app-mazars-searchbar>
</div>

<div class="containerClass">
    <div class="mz-container" *ngFor="let channel of channels">
        <div class="channels-accordion mz-card">
            <p-accordion [multiple]="true" (onOpen)="onTabOpen(channel)">
                <p-accordionTab [(selected)]="channel.isSelected" [(disabled)]="channel.disabled">
                    <ng-template pTemplate="header">
                        <div class="d-flex justify-content-between w-100">
                            <span class="py-2 m-0 align-middle">{{ channel.title }}<i *ngIf="!channel.isRead" class="fa fa-circle fa-xs ms-2 primary-color"></i></span>
                        </div>
                        <div class="col-1 align-self-center">
                            <div class="d-flex justify-content-end">
                                <app-mazars-channel-user-details [channel]="channel"></app-mazars-channel-user-details>
                            </div>
                        </div>
                        <div class="col-1 align-self-center" (click)="preventAccordionClickOnDropdown($event)" [hidden]="!hasEditPermission">
                            <div class="d-flex justify-content-end">
                                <app-mazars-actions-menu
                                    icon="flaticon-more"
                                    [uid]="'Channels_' + channel.id + '_actions_btn'"
                                    [btnSize]="'btn-md'"
                                    [fontSize]="'x-large'"
                                    [hasMainAction]="true"
                                    [actionItems]="getActionItems(channel)"
                                    (itemClick)="handleActionItemClick($event, channel)"
                                ></app-mazars-actions-menu>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="row">
                            <div class="col">
                                <app-mazars-communication
                                    [communications]="channel.communications"
                                    [hasEditPermission]="hasEditPermission"
                                    [hasDeletePermission]="hasDeletePermission"
                                    (editCommunicationClicked)="showUpdateCommunicationModal($event)"
                                    (deleteCommunicationClicked)="deleteCommunication($event)"
                                    (openCommunicationClicked)="showViewCommunicationModal($event)"
                                    [uid]="uid + '-communication'"
                                ></app-mazars-communication>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>

<mazars-channel-modal #mazarsChannelModal [uid]="uid + '-channel-modal'"></mazars-channel-modal>
<mazars-communication-modal #mazarsCommunicationModal [commonQuillMentionService]="commonQuillMentionService" [uid]="uid + '-communication-modal'"></mazars-communication-modal>
<mazars-whitelist-modal #mazarsWhitelistModal [uid]="uid + '-whitelist-modal'"></mazars-whitelist-modal>
<mazars-communication-view-modal
    #mazarsCommunicationViewModal
    (deleteCommunicationClicked)="deleteCommunication($event)"
    (editCommunicationClicked)="showUpdateCommunicationModal($event)"
    [uid]="uid + '-communication-view-modal'"
></mazars-communication-view-modal>
