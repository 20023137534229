import { Component, ViewChild, Injector, Input, OnChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileVariantType } from './profile-variant-type';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { Acceptor } from '../mazars-dialog/acceptor';
import { IProfilesServiceProxy } from './IProfilesServiceProxy';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import {
    ICreateTaxOfficeProfileInput,
    ICredentialInfoDto,
    ITaxOffice,
    ITaxOfficeConfiguration,
    ITaxOfficeProfileDto,
    IUpdateTaxOfficeProfileInput,
} from '@shared/service-proxies/common-interfaces';
import { FileParameter } from '@app/modules/master-data-management/master-data-management-proxies';

@Component({
    selector: 'mazars-profiles-modal',
    templateUrl: './mazars-profiles-modal.component.html',
})
export class MazarsProfilesModalComponent extends AppComponentBase implements OnChanges {
    @ViewChild('modal', { static: true }) modal: MazarsDialogComponent;
    @Input({ required: true }) uid: string;

    profilesServiceProxy: IProfilesServiceProxy;
    form: FormGroup = null;
    saving = false;
    validation = false;

    profileId: number = null;
    taxAuthorityDescription: string;
    taxAuthorityUrl: string;
    expirationDateTooltip: string;

    profileVariantType = ProfileVariantType;
    taxAuthorityTypes: IItem<string>[];
    taxOfficeConfig: ITaxOffice;
    minDate: Date;
    minYear: number;
    files: string[] = [];
    fileAcceptedTypes = '.pfx, .pem';

    constructor(injector: Injector, private fb: FormBuilder, private appLocalizationService: AppLocalizationService) {
        super(injector);
    }

    get isCreate(): boolean {
        return this.profileId == null;
    }

    get selectedTaxAuthority(): ITaxOfficeConfiguration {
        if (this.form.value.taxOfficeId) {
            let taxAuthorityType = this.taxAuthorityTypes.find((tat) => tat.id === this.form.value.taxOfficeId);
            if (taxAuthorityType) {
                return taxAuthorityType.source;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    setMinDates(): void {
        let todaysDate = new Date();
        this.minDate = new Date(
            todaysDate.getFullYear(),
            todaysDate.getMonth(),
            todaysDate.getDate() + (this.taxOfficeConfig ? this.taxOfficeConfig.profileExpirationWarningTreshold : 0),
            todaysDate.getHours(),
            todaysDate.getMinutes(),
            todaysDate.getSeconds()
        );
        this.minYear = todaysDate.getFullYear();
        this.setExpirationDateMessage();
    }

    ngOnChanges(): void {
        this.createForm();
    }

    show(profile: ITaxOfficeProfileDto, configurations: ITaxOfficeConfiguration[], taxOfficeConfig: ITaxOffice, profilesServiceProxy: IProfilesServiceProxy) {
        this.form.reset();
        this.profileId = profile?.id;
        this.validation = false;
        this.taxAuthorityTypes = this.mapConfigurations(configurations);
        this.taxOfficeConfig = taxOfficeConfig;

        this.profilesServiceProxy = profilesServiceProxy;
        this.ModelToForm(profile);
        this.setMinDates();

        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor) {
        this.validation = true;

        if (!this.form.valid) {
            return;
        }

        this.saving = true;
        if (this.profileId == null) {
            this.createProfile(acceptor);
        } else {
            this.updateProfile(acceptor);
        }
    }

    onSelectFile(fileInput: any): void {
        let selectedFile = <File>fileInput.files[0];
        this.files = [];
        if (!selectedFile) {
            return;
        }

        this.form.patchValue({ fileName: selectedFile.name });

        this.profilesServiceProxy
            .uploadFile(<FileParameter>{
                data: selectedFile,
                fileName: selectedFile.name,
            })
            .subscribe(
                (result) => {
                    this.onFileUploaded(result);
                },
                () => {
                    this.onUploadFailed();
                }
            );
    }

    removeFile(): void {
        this.form.patchValue({ fileName: null });
    }

    navigate() {
        window.open(this.selectedTaxAuthority.url, '_blank');
    }

    setExpirationDateMessage() {
        this.expirationDateTooltip = this.appLocalizationService.l('Profiles_ExpirationDateTooltip', this.taxOfficeConfig?.profileExpirationWarningTreshold);
    }

    private createForm(): void {
        this.form = this.fb.group({
            profileName: [null, [Validators.required, Validators.maxLength(50)]],
            taxOfficeId: [null, [Validators.required]],
            fileToken: [null, [Validators.required]],
            fileName: [null],
            expirationDate: [null, [Validators.required]],
            username: [null],
            passphrase: [null, [Validators.required, Validators.maxLength(256)]],
        });
    }

    private ModelToForm(model: ITaxOfficeProfileDto): void {
        this.form.reset();
        this.form.patchValue({
            profileName: model?.profileName ?? null,
            taxOfficeId: model?.taxOfficeId ?? (this.taxAuthorityTypes && this.taxAuthorityTypes.length > 0) ? this.taxAuthorityTypes[0].id : null,
            expirationDate: model?.expirationDate ?? null,
        });

        if (this.isCreate) {
            this.form.controls.fileToken.setValidators(Validators.required);
            this.form.controls.passphrase.setValidators(Validators.required);
        } else {
            this.form.controls.fileToken.clearValidators();
            this.form.controls.passphrase.clearValidators();
        }
        this.form.controls.fileToken.updateValueAndValidity();
        this.form.controls.passphrase.updateValueAndValidity();

        this.form.markAsUntouched();
        this.form.markAsPristine();
    }

    private Form2CreateModel(): ICreateTaxOfficeProfileInput {
        let credentialInfo: ICredentialInfoDto = <ICredentialInfoDto>{
            username: this.form.value.username ?? '',
            passphrase: this.form.value.passphrase,
        };
        return <ICreateTaxOfficeProfileInput>{
            profileName: this.form.value.profileName,
            taxOfficeId: this.form.value.taxOfficeId,
            credentialInfo: credentialInfo,
            fileToken: this.form.value.fileToken,
            expirationDate: this.form.value.expirationDate,
        };
    }

    private Form2UpdateModel(): IUpdateTaxOfficeProfileInput {
        return <IUpdateTaxOfficeProfileInput>{
            id: this.profileId,
            profileName: this.form.value.profileName,
            expirationDate: this.form.value.expirationDate,
        };
    }

    private mapConfigurations(configurations: ITaxOfficeConfiguration[]): IItem<string>[] {
        return configurations.map(
            (configuration) =>
                ({
                    id: configuration.name,
                    text: configuration.fullName,
                    source: configuration,
                } as IItem<string>)
        );
    }

    private createProfile(acceptor: Acceptor): void {
        let profile = this.Form2CreateModel();
        this.profilesServiceProxy
            .create(profile)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(
                (creationId: number) => {
                    acceptor.accept(creationId);
                },
                () => {
                    acceptor.reject();
                }
            );
    }

    private updateProfile(acceptor: Acceptor): void {
        let profile = this.Form2UpdateModel();
        this.profilesServiceProxy
            .update(profile)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(
                () => {
                    acceptor.accept();
                },
                () => {
                    acceptor.reject();
                }
            );
    }

    private onFileUploaded(fileToken: string): void {
        this.form.patchValue({ fileToken: fileToken });
    }

    private onUploadFailed(): void {
        this.form.patchValue({ fileName: null });
        this.message.error(this.l('UploadFailed'));
    }
}
