import { Directive, Host, Self, Optional } from '@angular/core';
import { SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';

@Directive({
    selector: '[withSortDelay]',
})
export class TableSortDelayDirective {
    sortDelay = 300;
    sortTimeout: any;

    constructor(@Host() @Self() @Optional() public pTable: Table) {
        pTable.sortSingle = () => {
            if (this.sortTimeout) {
                clearTimeout(this.sortTimeout);
            }

            let field = pTable.sortField || pTable.groupRowsBy;
            let order = pTable.sortField ? pTable.sortOrder : pTable.groupRowsByOrder;
            if (pTable.groupRowsBy && pTable.sortField && pTable.groupRowsBy !== pTable.sortField) {
                pTable._multiSortMeta = [pTable.getGroupRowsMeta(), { field: pTable.sortField, order: pTable.sortOrder }];
                pTable.sortMultiple();
                return;
            }

            if (field && order) {
                if (pTable.restoringSort) {
                    pTable.restoringSort = false;
                }

                if (pTable.lazy) {
                    this.sortTimeout = setTimeout(() => {
                        pTable.onLazyLoad.emit(pTable.createLazyLoadMetadata());
                        this.sortTimeout = null;
                    }, this.sortDelay);
                } else if (pTable.value) {
                    if (pTable.customSort) {
                        pTable.sortFunction.emit({
                            data: pTable.value,
                            mode: pTable.sortMode,
                            field: field,
                            order: order,
                        });
                    } else {
                        pTable.value.sort((data1, data2) => {
                            let value1 = ObjectUtils.resolveFieldData(data1, field);
                            let value2 = ObjectUtils.resolveFieldData(data2, field);
                            let result = null;

                            if (value1 == null && value2 != null) result = -1;
                            else if (value1 != null && value2 == null) result = 1;
                            else if (value1 == null && value2 == null) result = 0;
                            else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
                            else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

                            return order * result;
                        });

                        pTable._value = [...pTable.value];
                    }

                    if (pTable.hasFilter()) {
                        pTable._filter();
                    }
                }

                let sortMeta: SortMeta = {
                    field: field,
                    order: order,
                };

                pTable.onSort.emit(sortMeta);
                pTable.tableService.onSort(sortMeta);
            }
        };
    }
}
