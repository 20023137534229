import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Table, TableService } from 'primeng/table';
import { Subscription } from 'rxjs';
import { DomHandler } from 'primeng/dom';

@Component({
    selector: 'mazars-tableHeaderCheckbox',
    template: `
        <div class="p-checkbox p-component" (click)="onClick($event)">
            <div class="p-hidden-accessible">
                <input
                    #cb
                    type="checkbox"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [checked]="checked"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    [disabled]="isDisabled()"
                    [attr.aria-label]="ariaLabel"
                />
            </div>
            <div #box [ngClass]="{ 'p-checkbox-box': true, 'p-highlight': checked, 'p-disabled': isDisabled() }" role="checkbox" [attr.aria-checked]="checked">
                <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': checked }"></span>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MazarsTableHeaderCheckboxComponent implements OnInit, OnDestroy {
    @ViewChild('box') boxViewChild: ElementRef;

    @Input() disabled: boolean;

    @Input() inputId: string;

    @Input() name: string;

    @Input() ariaLabel: string;

    checked: boolean;

    selectionChangeSubscription: Subscription;

    valueChangeSubscription: Subscription;

    constructor(public dt: Table, public tableService: TableService, public cd: ChangeDetectorRef) {
        this.valueChangeSubscription = this.dt.tableService.valueSource$.subscribe(() => {
            this.checked = this.updateCheckedState();
        });

        this.selectionChangeSubscription = this.dt.tableService.selectionSource$.subscribe(() => {
            this.checked = this.updateCheckedState();
        });
    }

    ngOnInit() {
        this.checked = this.updateCheckedState();
    }

    onClick(event: Event) {
        if (!this.disabled) {
            if (this.dt.value && this.dt.value.length > 0) {
                this.dt.toggleRowsWithCheckbox(event, !this.checked);
            }
        }

        DomHandler.clearSelection();
    }

    onFocus() {
        DomHandler.addClass(this.boxViewChild.nativeElement, 'p-focus');
    }

    onBlur() {
        DomHandler.removeClass(this.boxViewChild.nativeElement, 'p-focus');
    }

    isDisabled() {
        return this.disabled || !this.dt.value || !this.dt.value.length;
    }

    ngOnDestroy() {
        if (this.selectionChangeSubscription) {
            this.selectionChangeSubscription.unsubscribe();
        }

        if (this.valueChangeSubscription) {
            this.valueChangeSubscription.unsubscribe();
        }
    }

    updateCheckedState() {
        this.cd.markForCheck();
        if (this.dt.filteredValue && !this.dt.lazy) {
            const val = this.dt.filteredValue;
            return val && val.length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.isAllFilteredValuesChecked();
        } else {
            const val = this.dt.value;
            const length = this.dt.lazy ? val?.length : this.dt._totalRecords;
            return val && length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.dt.selection.length === length;
        }
    }

    isAllFilteredValuesChecked() {
        if (!this.dt.filteredValue) {
            return false;
        } else {
            for (let rowData of this.dt.filteredValue) {
                if (!this.dt.isSelected(rowData)) {
                    return false;
                }
            }
            return true;
        }
    }
}
