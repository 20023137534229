import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ICommunicationDto, ISearchCommunicationDto, IUserDetailsDto } from '../common-interfaces';

export const ICommunicationsServiceToken = new InjectionToken<string>('ICommunicationsService');

export interface ICommunicationsService {
    search(input: any | undefined): Observable<ISearchCommunicationDto[]>;

    getAll(input: any | undefined): Observable<ICommunicationDto[]>;

    getById(input: any | undefined): Observable<ICommunicationDto>;

    create(input: any | undefined): Observable<number>;

    update(input: any | undefined): Observable<void>;

    delete(input: any | undefined): Observable<void>;

    getAllChannelUserDetail(channelId: number | undefined): Observable<IUserDetailsDto[]>;
}
