import { Injector, Component, OnInit } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { filter, map } from 'rxjs/operators';
import { AppMenuItem } from '../../nav/app-menu-item';
import { ConfigurationSettingsInput, UserInterfaceServiceProxy } from '@shared/service-proxies/general-proxies';
import { UserInterfaceSettings } from '../../user-interface-settings';

@Component({
    templateUrl: './mazars-layout.component.html',
    selector: 'mazars-layout',
    animations: [appModuleAnimation()],
})
export class MazarsLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    isBurgermenuOpen: boolean;
    selectedAppMenuItem: AppMenuItem;
    isSidebarToggled: boolean;

    constructor(
        injector: Injector,
        _dateTimeService: DateTimeService,
        private router: Router,
        private appNavigationService: AppNavigationService,
        private userInterfaceServiceProxy: UserInterfaceServiceProxy
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.userInterfaceServiceProxy.getSetting(UserInterfaceSettings.AsideMenu).subscribe((res) => {
            this.isSidebarToggled = res === 'True' ? true : false;
        });

        //set selected module icon for first load
        if (!this.selectedAppMenuItem) {
            this.appNavigationService.getMenu().items.forEach((item) => {
                this.setSelecteAppMenuItem(item, this.router.url);
            });
        }

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url)
            )
            .subscribe((url) => {
                this.appNavigationService.getMenu().items.forEach((item) => {
                    this.setSelecteAppMenuItem(item, url);
                });
            });
    }

    toggleMenu(toggled: boolean) {
        this.isBurgermenuOpen = toggled;
    }

    toggleSideBar() {
        const isSidebarToggledSetting = (!this.isSidebarToggled).toString();
        const value = isSidebarToggledSetting.charAt(0).toUpperCase() + isSidebarToggledSetting.toString().slice(1);
        let configurationSetting = new ConfigurationSettingsInput({
            configurationKey: UserInterfaceSettings.AsideMenu,
            value: value
        });

        this.userInterfaceServiceProxy.setSetting(configurationSetting).subscribe((_) => {
            this.isSidebarToggled = !this.isSidebarToggled;
        });
    }

    private setSelecteAppMenuItem(item: AppMenuItem, url: string): void {
        //get app menu item recursively
        if (url === item.route) {
            this.selectedAppMenuItem = item;
        } else {
            if (item.items.length > 0) {
                item.items.forEach((i) => this.setSelecteAppMenuItem(i, url));
            }
        }
    }
}
