import { Injectable, Injector } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import {
    AssignableUserDto,
    FormReferenceDataServiceProxy,
    FormsCustomGrantsServiceProxy,
} from '@app/modules/iro/iro-proxies';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IroComponentBase } from '../iro-component-base';
import { FormProcessState } from './form-process-flow/form-process-state';
import { FormProcessStateSettings } from './form-process-flow/form-process-state-settings';

@Injectable({
    providedIn: 'root',
})
export class FormReferenceDataService extends IroComponentBase {
    formProcessStates: IItem<string>[];

    constructor(
        injector: Injector,
        private formReferenceDataServiceProxy: FormReferenceDataServiceProxy,
        private formCustomGrantsServiceProxy: FormsCustomGrantsServiceProxy,
    ) {
        super(injector);
    }

    public load() {
        this.getFormProcessStates()
            .pipe(take(1))
            .subscribe((formProcessStates) => {
                this.formProcessStates = formProcessStates.filter(item => item.id !== FormProcessState.Unknown);
            });
    }

    getAllPossibleUsersOverview(
        formId: number,
        permissionName: string,
        excludeUsers: boolean = true
    ): Observable<AssignableUserDto[]> {
        return this.getAllPossibleUsers(formId, permissionName, excludeUsers).pipe(
            take(1),
            map((result) => result.map((user) => ({
                        id: user.id,
                        fullName: user.text,
                    } as AssignableUserDto)))
        );
    }

    getAllPossibleUsers(
        formId: number,
        permissionName: string,
        excludeUsers: boolean = true
    ): Observable<IItem<number>[]> {
        return this.formCustomGrantsServiceProxy
            .getAllPossibleUsers(formId, permissionName, excludeUsers)
            .pipe(
                take(1),
                map((result) => result.items.map((user) => ({
                            id: user.userOrOrganizationId,
                            text: user.displayName,
                        } as IItem<number>)))
            );
    }

    private getFormProcessStates(): Observable<IItem<string>[]> {
        return this.formReferenceDataServiceProxy.getFormProcessStates().pipe(
            take(1),
            map(processStates => this.mapFormProcessStates(processStates as FormProcessState[]))
        );
    }

    private mapFormProcessStates(processStates: FormProcessState[]): IItem<string>[] {
        return processStates.map(processState => ({
                id: processState,
                text: this.l(FormProcessStateSettings.getProcessStateWorkItemName(processState)),
            } as IItem<string>));
    }
}
