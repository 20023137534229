import { Component, Injector, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonQuillMentionService } from '@app/shared/common/quill/common-quill-mention.service';
import { AppComponentBase } from '@shared/common/app-component-base';

import { CommonCommunicationsFacade } from '../../state-management/facades/common-communications.facade';

@Component({
    selector: 'app-mazars-chat',
    templateUrl: './mazars-chat.component.html',
    styleUrls: ['./mazars-chat.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsChatComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() commonQuillMentionService: CommonQuillMentionService;
    @Input({ required: true }) uid: string;
    @Input() communicationMessageIdentifier: string;
    @Input() referenceId: number;
    @Input() hasCreatePermission: boolean;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Input() hasViewPermission: boolean;

    constructor(injector: Injector, public communicationsFacade: CommonCommunicationsFacade) {
        super(injector);
    }

    ngOnInit(): void {
        this.registerSubscriptions();
    }

    ngOnDestroy(): void {
        this.communicationsFacade.clearChannel();
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.communicationsFacade.loaded$.subscribe((loaded) => {
                if (loaded) {
                    this.hideMainSpinner();
                } else {
                    this.showMainSpinner();
                }
            })
        );
    }
}
