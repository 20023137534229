import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type: SecurityContext): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        if (!type) {
            throw new Error('Safe pipe has missing security context type.');
        }

        return this.sanitizer.sanitize(type, value);
    }
}
