<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.required" class="text-danger">
    {{ l('Validation_IsRequired', name ?? l(key)) }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.minlength" class="text-danger">
    {{ l('Validation_IsTooShort', name ?? l(key)) }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.maxlength" class="text-danger">
    {{ l('Validation_IsTooLong', name ?? l(key)) }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.min" class="text-danger">
    {{ name ?? l(key) }} {{ l('Validation_BelowMinValue') }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.max" class="text-danger">
    {{ name ?? l(key) }} {{ l('Validation_ExceedMaxValue') }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.email" class="text-danger">
    {{ name ?? l(key) }} {{ l('Validation_MustBeValid') }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.pattern" class="text-danger">
    {{ name ?? l(key) }} {{ l('Validation_MustBeValid') }}
</span>
<span [attr.data-testid]="uid + '-validation-message'" *ngIf="isEnabled && (control?.touched || submitting) && control?.errors?.emailOrDomain" class="text-danger">
    {{ l('InvalidEmailOrDomain') }}
</span>
