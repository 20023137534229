import { Injectable } from '@angular/core';
import { IMazarsGridDefinitionDto } from '@shared/service-proxies/common-interfaces';
import { IColumnDefinition } from '../components/mazars-grid/grid-interfaces';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';

@Injectable()
export class ColumnDefinitionService {
    constructor(
        private datePipe: DatePipe,
    ) {}

    addColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        this.addGeneralInfoColumnDefinitions(gridDefinitionDto, columnDefinitions);
        this.addProjectManagementInfo(gridDefinitionDto, columnDefinitions);
        this.addCreateInfoColumnDefinitions(gridDefinitionDto, columnDefinitions);
        this.addUpdateInfoColumnDefinitions(gridDefinitionDto, columnDefinitions);
        this.addUpdateDateColumnDefinitions(gridDefinitionDto, columnDefinitions);
    }

    addNameColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('Name'));
        if (columnDef == null && (gridDefinitionDto.settings.haveGeneralInfo || gridDefinitionDto.settings.haveName)) {
            columnDefinitions.push(
                {
                    label: this.ld('Name'),
                    sortableName: 'name',
                    field: 'name',
                    maxWidth: 250,
                    template: 'nameTemplate',
                    isColumnHidden: true,
                }
            );
        }
    }

    addTaggableColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('Tags'));
        if (columnDef == null && (gridDefinitionDto.settings.haveGeneralInfo || gridDefinitionDto.settings.isTaggable)) {
            columnDefinitions.push(
                {
                    label: this.ld('Tags'),
                    field: 'tags',
                    template: 'taglistTemplate',
                    isColumnHidden: true,
                }
            );
        }
    }

    addProjectManagementInfo(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        if (gridDefinitionDto.settings.haveFullProjectManagementInfo) {
            this.addAssignedUserColumnDefinitions(gridDefinitionDto, columnDefinitions);
            this.addPlannedDatesColumnDefinitions(gridDefinitionDto, columnDefinitions);
            this.addHaveProjectInfoColumnDefinitions(gridDefinitionDto, columnDefinitions);
        }
        else {
            if (gridDefinitionDto.settings.haveAssignedUser) {
                this.addAssignedUserColumnDefinitions(gridDefinitionDto, columnDefinitions);
            }
            if (gridDefinitionDto.settings.havePlanDates) {
                this.addPlannedDatesColumnDefinitions(gridDefinitionDto, columnDefinitions);
            }
            if (gridDefinitionDto.settings.haveProjectInfo) {
                this.addHaveProjectInfoColumnDefinitions(gridDefinitionDto, columnDefinitions);
            }
        }
    }

    addAssignedUserColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('AssignedUser'));
        if (columnDef == null && (gridDefinitionDto.settings.haveFullProjectManagementInfo || gridDefinitionDto.settings.haveAssignedUser)) {
            columnDefinitions.push(
                {
                    label: this.ld('AssignedUser'),
                    field: 'assignedUser',
                    template: 'assignedUserTemplate',
                    isColumnHidden: true,
                },
            );
        }
    }

    addPlannedDatesColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        if (gridDefinitionDto.settings.haveFullProjectManagementInfo || gridDefinitionDto.settings.havePlanDates) {
            let plannedStartColumnDef = columnDefinitions.find((cd) => cd.label === this.ld('PlannedStart'));
            let plannedEndColumnDef = columnDefinitions.find((cd) => cd.label === this.ld('PlannedEnd'));
            if (plannedStartColumnDef == null) {
                columnDefinitions.push(
                    {
                        label: this.ld('PlannedStart'),
                        field: 'plannedStart',
                        template: 'plannedStartTemplate',
                        isColumnHidden: true,
                    },
                );
            }
            if (plannedEndColumnDef == null) {
                columnDefinitions.push(
                    {
                        label: this.ld('PlannedEnd'),
                        field: 'plannedEnd',
                        template: 'plannedEndTemplate',
                        isColumnHidden: true,
                    },
                );
            }
        }
    }

    addHaveProjectInfoColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        if (gridDefinitionDto.settings.haveFullProjectManagementInfo || gridDefinitionDto.settings.haveProjectInfo) {
            let criticalityColumnDef = columnDefinitions.find((cd) => cd.label === this.ld('Criticality'));
            let priorityColumnDef = columnDefinitions.find((cd) => cd.label === this.ld('Priority'));
            let plannedEffortColumnDef = columnDefinitions.find((cd) => cd.label === this.ld('PlannedEffort'));
            if (criticalityColumnDef == null) {
                columnDefinitions.push(
                    {
                        label: this.ld('Criticality'),
                        field: 'criticality',
                        template: 'criticalityTemplate',
                        isColumnHidden: true,
                    },
                );
            }
            if (priorityColumnDef == null) {
                columnDefinitions.push(
                    {
                        label: this.ld('Priority'),
                        field: 'priority',
                        isColumnHidden: true,
                    },
                );
            }
            if (plannedEffortColumnDef == null) {
                columnDefinitions.push(
                    {
                        label: this.ld('PlannedEffort'),
                        field: 'effort',
                        isColumnHidden: true,
                    },
                );
            }
        }
    }

    addCreateInfoColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        if (gridDefinitionDto.settings.haveGeneralInfo) {
            this.addCreateDateColumnDefinitions(gridDefinitionDto, columnDefinitions);
            this.addCreatedByColumnDefinitions(gridDefinitionDto, columnDefinitions);
        }
    }

    addCreateDateColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('CreationDate'));
        if (columnDef == null && (gridDefinitionDto.settings.haveCreatedInfo)) {
            columnDefinitions.push(
                {
                    label: this.ld('CreationDate'),
                    sortableName: 'creationTime',
                    field: 'creationDate',
                    customRenderer: (v) => this.datePipe.transform(v),
                    isColumnHidden: true,
                }

            );
        }
    }

    addCreatedByColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('CreatedBy'));
        if (columnDef == null && (gridDefinitionDto.settings.haveCreatedInfo)) {
            columnDefinitions.push(
                {
                    label: this.ld('CreatedBy'),
                    sortableName: 'createdBy',
                    field: 'createdBy',
                    customRenderer: (v) => this.datePipe.transform(v),
                    isColumnHidden: true,
                }
            );
        }
    }

    addUpdateInfoColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        if (gridDefinitionDto.settings.haveGeneralInfo) {
            this.addUpdateDateColumnDefinitions(gridDefinitionDto, columnDefinitions);
            this.addUpdatedByColumnDefinitions(gridDefinitionDto, columnDefinitions);
        }
    }

    addUpdateDateColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('LastUpdate'));
        if (columnDef == null && (gridDefinitionDto.settings.haveUpdateInfo)) {
            columnDefinitions.push(
                {
                    label: this.ld('LastUpdate'),
                    sortableName: 'lastModificationTime',
                    field: 'lastModificationTime',
                    customRenderer: (v) => this.datePipe.transform(v),
                },
            );
        }
    }

    addUpdatedByColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        let columnDef = columnDefinitions.find((cd) => cd.label === this.ld('LastUpdateBy'));
        if (columnDef == null && (gridDefinitionDto.settings.haveUpdateInfo)) {
            columnDefinitions.push(
                {
                    label: this.ld('LastUpdateBy'),
                    sortableName: 'lastModifier',
                    field: 'lastModifier',
                },
            );
        }
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    private addColumnDefinition(fieldName: string, gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[], columnDefinition: IColumnDefinition) {
        let columnDef = columnDefinitions.find((cd) => cd.label === fieldName);
        let gridDefinition = gridDefinitionDto?.configuration.find((cd) => cd.localizedField === fieldName);
        if (columnDef == null && gridDefinition != null) {
            columnDefinitions.push(columnDefinition);
        }
    }

    private addGeneralInfoColumnDefinitions(gridDefinitionDto: IMazarsGridDefinitionDto, columnDefinitions: IColumnDefinition[]) {
        this.addColumnDefinition(this.ld('Name'), gridDefinitionDto, columnDefinitions,
            {
                label: this.ld('Name'),
                sortableName: 'name',
                field: 'name',
                maxWidth: 250,
                template: 'nameTemplate',
                isColumnHidden: true,
            }
        );
        this.addColumnDefinition(this.ld('Tags'), gridDefinitionDto, columnDefinitions,
            {
                label: this.ld('Tags'),
                field: 'tags',
                template: 'taglistTemplate',
                isColumnHidden: true,
            }
        );
    }
}
