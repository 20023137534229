import { Injectable, Injector } from '@angular/core';
import { IInvolvedPartyItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { ReportDto, ReportOverviewDto } from '@app/modules/dac6/dac6-proxies';
import { ProcessNames } from '@app/modules/mazars-common/components/mazars-process/process-names';
import { ProcessStateNames } from '@app/modules/mazars-common/components/mazars-process-state/process-state-names';
import { ProcessStatePermission } from '@app/modules/mazars-common/components/mazars-process-state/process-state-permissions';
import { PermissionCheckerService } from 'abp-ng2-module';
import { ProcessStateSettings } from '@app/modules/mazars-common/consts/process-state-settings';

@Injectable({
    providedIn: 'root',
})
export class ReportService {

    permission: PermissionCheckerService;

    selectedInvolvedParties: IInvolvedPartyItem<number>[] = [];

    constructor(injector: Injector) {
        this.permission = injector.get(PermissionCheckerService);
    }

    isReadonly(reportDto: ReportOverviewDto | ReportDto): boolean {
        let isReadonly = false;
        const currentProcess = reportDto?.process;
        if (currentProcess) {
            if (
                currentProcess.name === ProcessNames.WaitingForFeedbackPhase ||
                currentProcess.name === ProcessNames.SuccessfullyFinished ||
                currentProcess.name === ProcessNames.FinishedWithoutSubmission ||
                currentProcess.name === ProcessNames.FinishedExternalReporting
            ) {
                isReadonly = true;
            }
        }

        return isReadonly;
    }

    isTabReadonly(reportDto: ReportOverviewDto | ReportDto, isGeneral?: boolean): boolean {
        return isGeneral ? this.isReadonly(reportDto) : this.isDataTabReadonly(reportDto);
    }

    currentProcessPermissionName(reportDto: ReportOverviewDto | ReportDto): string {
        const currentProcess = reportDto?.process;
        return ProcessStateSettings.getProcessPermission(currentProcess?.name ?? ProcessStateNames.EditEvaluationPhase);
    }

    addInvolvedParty(party: IInvolvedPartyItem<number>): void {
        let foundedParty = this.selectedInvolvedParties.find((p) => p.id === party.id);
        if (!foundedParty) {
            this.selectedInvolvedParties.push(party);
        }
    }

    removeInvolvedParty(partyToRemove: IInvolvedPartyItem<number>): void {
        this.removeInvolvedPartyById(partyToRemove.id);
    }

    removeInvolvedPartyById(id: number): void {
        this.selectedInvolvedParties.forEach((involvedParty, index) => {
            if (involvedParty.id === id) {
                const removeCount = 1;
                this.selectedInvolvedParties.splice(index, removeCount);
            }
        });
    }

    canEditData(reportDto: ReportOverviewDto | ReportDto): boolean {
        if (reportDto?.process.name === ProcessNames.EditEvaluationPhase) {
            return this.isReportPermissionGranted(reportDto, ProcessStatePermission.ReportEditEvaluation);
        } else if (reportDto?.process.name === ProcessNames.EditReportDataPhase) {
            return this.isReportPermissionGranted(reportDto, ProcessStatePermission.ReportEditDisclosure);
        } else {
            return false;
        }
    }

    canEditAttachmentsData(reportDto: ReportOverviewDto | ReportDto): boolean {
        if (reportDto?.process.name) {
            return this.isPermissionForEditedReportGranted(reportDto, ProcessStateSettings.getProcessPermission(reportDto?.process.name));
        } else {
            return false;
        }
    }

    isPermissionForEditedReportGranted(reportDto: ReportOverviewDto | ReportDto, permissionName: string): boolean {
        return this.isReportPermissionGranted(reportDto, permissionName);
    }

    isReportPermissionGranted(reportDto: ReportOverviewDto | ReportDto, permissionName: string): boolean {
        return this.permission.isGranted(permissionName) || (reportDto && reportDto?.permissions ? reportDto?.permissions.includes(permissionName) : false);
    }

    private isDataTabReadonly(reportDto: ReportOverviewDto | ReportDto): boolean {
        let isReadonly = false;
        const currentProcess = reportDto?.process;
        if (currentProcess) {
            isReadonly = ProcessStateSettings.isReportDataTabReadonly(currentProcess.name);
        }
        return isReadonly || !this.canEditData(reportDto);
    }
}
