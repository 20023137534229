<div [mazarsTooltip]="selectedItems ? getSelectedItemsValue() : placeholder" [appendTo]="'body'" [escape]="false" tooltipPosition="bottom">
    <p-multiSelect
        #multiselect
        id="{{ uid }}"
        name="{{ uid }}"
        [attr.data-testid]="uid"
        [options]="options"
        [(ngModel)]="selectedItems"
        [placeholder]="placeholder"
        [disabled]="isDisabled || disabled"
        (onBlur)="onBlurHandler()"
        (onChange)="onChangeHandler($event)"
        [virtualScroll]="false"
        [filter]="filter"
        (onFilter)="onFilterChange($event)"
        [showToggleAll]="showToggleAll"
        styleClass="form-control"
        [display]="displayType"
        [appendTo]="appendTo"
    >
        <ng-template *ngIf="isCountries" let-value pTemplate="selectedItems">
            <span *ngFor="let item of selectedItems; let i = index">
                <app-mazars-flag-icon
                    class="mx-2"
                    [countryCode]="getSelectItem(item).value"
                    [uid]="uid + '-multiselect-item-icon-' + item?.value"
                ></app-mazars-flag-icon>
                <span>{{ getSelectItem(item).label }}</span>
                <span class="multiselect-comma-separator" *ngIf="selectedItems.lastIndexOf(selectedItems[selectedItems.length - 1]) != i">,</span>
            </span>
            <div *ngIf="!selectedItems || selectedItems.length === 0">
                {{ placeholder }}
            </div>
        </ng-template>
        <ng-template *ngIf="haveStatus" let-value pTemplate="selectedItems">
            <span *ngFor="let item of selectedItems; let i = index">
                <app-mazars-status [statusValue]="getSelectItem(item).label" [uid]="uid + '-selected-item-' + item?.id"></app-mazars-status>
            </span>
            <div *ngIf="!selectedItems || selectedItems.length === 0">
                {{ placeholder }}
            </div>
        </ng-template>
        <ng-template let-value pTemplate="selectedItems">
            <span *ngFor="let item of selectedItems; let i = index">
                <i
                    *ngIf="getSelectItem(item).icon"
                    class="{{ getSelectItem(item).icon }} mr-1"
                    [ngClass]="{ 'ml-1': i !== 0 }"
                    [style.color]="getSelectItem(item).iconColor"
                    aria-hidden="true"
                ></i>
                <span [class]="!getSelectItem(item).icon ? 'ml-1' : ''" [ngClass]="getSelectItem(item).styleClass">{{ getSelectItem(item).label }}</span>
                <span *ngIf="selectedItems.lastIndexOf(selectedItems[selectedItems.length - 1]) != i">,</span>
            </span>
            <div *ngIf="!selectedItems || selectedItems.length === 0">
                {{ placeholder }}
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <app-mazars-profile-picture
                *ngIf="isUserOrOrganization"
                [mode]="'icon'"
                [user]="item"
                class="mr-2"
                [uid]="uid + '-profile-picture-' + item?.id"
            ></app-mazars-profile-picture>
            <app-mazars-status *ngIf="haveStatus" [statusValue]="item.label" [uid]="uid + '-multiselect-item-status' + item?.value"></app-mazars-status>
            <app-mazars-flag-icon
                *ngIf="isCountries && item?.value"
                class="mr-2"
                [countryCode]="item.value"
                [uid]="uid + '-multiselect-item-icon-' + item?.value"
            ></app-mazars-flag-icon>
            <div>
                <span *ngIf="!haveStatus" [attr.data-testid]="uid + '-' + item.value">
                    <i *ngIf="item.icon" class="{{ item.icon }} mr-2" style="width: 14px" [style.color]="item.iconColor" aria-hidden="true"></i>
                    <span [ngClass]="item.styleClass">{{ item.label }}</span>
                </span>
            </div>
            <div class="multiselect-line-break"></div>
            <div>
                <small *ngIf="hasAddressInfo(item)" class="ml-4 mz-address-info">
                    {{ getFormattedAddress(item) }}
                </small>
            </div>
        </ng-template>
    </p-multiSelect>
</div>
