<mz-dialog
    #modal
    [uid]="uid"
    [title]="isCreate ? l('Profiles_AddNewProfile') : l('Profiles_UpdateProfile')"
    [canSubmit]="form.valid && form.dirty"
    [submitButtonLabel]="isCreate ? l('Create') : l('Save')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <form [formGroup]="form" (ngSubmit)="modal.submit()" autocomplete="off">
        <div class="form-group">
            <label for="ProfileName">{{ l('Profiles_ProfileName') }} *</label>
            <app-mazars-input-text [uid]="'ProfileName'" [placeholder]="l('Profiles_ProfileName')" formControlName="profileName"></app-mazars-input-text>
            <app-mazars-validation-message
                [uid]="'ProfileName'"
                [key]="'Profiles_ProfileName'"
                [control]="form.controls.profileName"
                [submitting]="validation"
            ></app-mazars-validation-message>
        </div>

        <div class="form-group">
            <label for="ExpirationDate">{{ l('Profiles_ExpirationDate') }} *</label>
            <app-mazars-information-icon [uid]="'Profiles_ExpirationDate_tooltip'" [message]="expirationDateTooltip"></app-mazars-information-icon>
            <app-mazars-datepicker
                [uid]="'ExpirationDate'"
                [placeholder]="l('Profiles_ExpirationDate')"
                [minDate]="minDate"
                [minYear]="minYear"
                formControlName="expirationDate"
            ></app-mazars-datepicker>
            <app-mazars-validation-message
                [uid]="'ExpirationDate'"
                [key]="'Profiles_ExpirationDate'"
                [control]="form.controls.expirationDate"
                [submitting]="validation"
            ></app-mazars-validation-message>
        </div>

        <div class="form-group">
            <label for="TaxAuthorityType">{{ l('Profiles_TaxAuthority') }} *</label>
            <app-mazars-dropdown
                [uid]="'TaxAuthorityType'"
                [options]="taxAuthorityTypes"
                [hasEmpty]="false"
                [isDisabled]="!isCreate"
                placeholder="{{ l('Profiles_TaxAuthority') }}"
                formControlName="taxOfficeId"
            ></app-mazars-dropdown>
        </div>

        <div class="form-group" style="background-color: #e5e5e5; padding: 1px">
            <div class="text-muted alert alert-custom alert-white m-1" role="alert">
                <div class="alert-text">
                    {{ l(selectedTaxAuthority?.description) }}
                </div>
                <div class="alert-text">
                    {{ l('Profiles_Link') }}:
                    <a
                        href="javascript:;"
                        (click)="navigate()"
                        [attr.data-testid]="'profiles-url-a'"
                        target="_self"
                        style="cursor: pointer"
                        class="align-middle font-size-h6 font-weight-bolder"
                    >
                        <u>{{ selectedTaxAuthority?.url }}</u>
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isCreate" class="form-group">
            <label for="CertificateFile">{{ l('Profiles_CertificateFile') }} *</label>
            <p-fileUpload
                mode="basic"
                id="CertificateFile"
                name="CertificateFile[]"
                customUpload="true"
                (uploadHandler)="onSelectFile($event)"
                [auto]="true"
                [files]="files"
                [accept]="fileAcceptedTypes"
                [chooseLabel]="l('Browse')"
                maxFileSize="20000"
            ></p-fileUpload>
            <app-mazars-validation-message [uid]="'File'"  [key]="'File'" [control]="form.controls.fileToken" [submitting]="validation"></app-mazars-validation-message>
        </div>

        <div *ngIf="form.value.fileName && isCreate" class="form-group" style="background-color: #e5e5e5; padding: 5px">
            <div class="alert alert-custom alert-white alert-bold m-1" role="alert">
                <span class="alert-text">
                    {{ form.value.fileName }}
                </span>
                <button type="button" class="btn p-0" style="vertical-align: top; cursor: pointer">
                    <span (click)="removeFile()">
                        <i aria-hidden="true" class="la la-close"></i>
                    </span>
                </button>
            </div>
        </div>

        <div class="form-group" *ngIf="isCreate && selectedTaxAuthority?.profileVariant == profileVariantType.CertAndUsername">
            <label for="Username">{{ l('Profiles_Username') }} *</label>
            <app-mazars-input-text [uid]="'Username'" [placeholder]="l('Profiles_Username')" formControlName="username"></app-mazars-input-text>
            <app-mazars-validation-message [uid]="'Username'" [key]="'Username'" [control]="form.controls.username" [submitting]="validation"></app-mazars-validation-message>
        </div>

        <div class="form-group" *ngIf="isCreate">
            <label for="Passphrase">{{ l('Profiles_Passphrase') }} *</label>
            <app-mazars-input-password [uid]="'Passphrase'" [placeholder]="l('Profiles_Passphrase')" formControlName="passphrase"></app-mazars-input-password>
            <app-mazars-validation-message [uid]="'Passphrase'" [key]="'Passphrase'" [control]="form.controls.passphrase" [submitting]="validation"></app-mazars-validation-message>
        </div>
    </form>
</mz-dialog>
