import { Injectable, Injector } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import {
    AssignableUserDto,
    DeclarationBucketOverallStatus,
    DeclarationCalculationStatus,
    DeclarationReferenceDataServiceProxy,
    DeclarationSubmissionStatus,
    EconomicUnitsCustomGrantsServiceProxy,
    EconomicUnitsServiceProxy,
    EconomicUnitStatus,
    ModelInfoDto,
    RealPropertyTaxTaxOfficeProfilesServiceProxy,
    TaxOfficeDto,
    TaxOfficeProfileDto,
} from '@app/modules/real-property-tax/real-property-tax-proxies';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RealPropertyTaxComponentBase } from '../real-property-tax-component-base';
import { DueDateSettings } from '@app/modules/mazars-common/components/mazars-deadline-dates/due-date-settings';
import { EconomicUnitStatusSettings } from './economic-unit-status-settings';
import { DeclarationNoticeStatusSettings } from '../declarations/declaration-notice-status/declaration-notice-status-settings';

@Injectable({
    providedIn: 'root',
})
export class EconomicUnitReferenceDataService extends RealPropertyTaxComponentBase {
    declarationProcessStates: IItem<string>[];
    declarationSubmissionStatuses: IItem<number>[];
    declarationCalculationStatuses: IItem<number>[];
    declarationNoticeStatuses: IItem<number>[];
    dueDateStatuses: IItem<string>[] = [];
    economicUnitStatuses: IItem<number>[] = [];
    taxAuthorities: IItem<string>[] = [];

    constructor(
        injector: Injector,
        private declarationReferenceDataServiceProxy: DeclarationReferenceDataServiceProxy,
        private economicUnitCustomGrantsServiceProxy: EconomicUnitsCustomGrantsServiceProxy,
        private realPropertyTaxTaxOfficeProfilesServiceProxy: RealPropertyTaxTaxOfficeProfilesServiceProxy,
        private economicUnitsServiceProxy: EconomicUnitsServiceProxy,
    ) {
        super(injector);
    }

    public load() {
        this.getDeclarationProcessStates()
            .pipe(take(1))
            .subscribe((declarationProcessStates) => {
                this.declarationProcessStates = declarationProcessStates;
            });

        this.getDeclarationDueDateStatuses()
            .pipe(take(1))
            .subscribe((duedateTypes) => {
                this.dueDateStatuses = duedateTypes;
            });

        this.getEconomicUnitStatuses()
            .pipe(take(1))
            .subscribe((statuses) => {
                this.economicUnitStatuses = statuses;
            });

        this.getDeclarationSubmissionStatuses()
            .pipe(take(1))
            .subscribe((statuses) => {
                this.declarationSubmissionStatuses = statuses;
            });

        this.getDeclarationCalculationStatuses()
            .pipe(take(1))
            .subscribe((statuses) => {
                this.declarationCalculationStatuses = statuses;
            });

        this.getDeclarationNoticeStatuses()
            .pipe(take(1))
            .subscribe((statuses) => {
                this.declarationNoticeStatuses = statuses;
            });

        this.getTaxAuthorities()
            .pipe(take(1))
            .subscribe((taxAuthorities) => {
                this.taxAuthorities = taxAuthorities;
            })
    }

    public loadRequiredReferenceData(): Observable<ModelInfoDto[]> {
        return this.getAllModelInfo();
    }

    getAllPossibleUsersOverview(
        reportId: number,
        permissionName: string,
        excludeUsers: boolean = true,
        declarationId: number = undefined
    ): Observable<AssignableUserDto[]> {
        return this.getAllPossibleUsers(reportId, permissionName, excludeUsers, declarationId).pipe(
            take(1),
            map((result) =>
                result.map(
                    (user) =>
                    ({
                        id: user.id,
                        fullName: user.text,
                    } as AssignableUserDto)
                )
            )
        );
    }

    getAllPossibleUsers(
        reportId: number,
        permissionName: string,
        excludeUsers: boolean = true,
        declarationId: number = undefined
    ): Observable<IItem<number>[]> {
        return this.economicUnitCustomGrantsServiceProxy.getAllPossibleUsers(reportId, permissionName, excludeUsers, declarationId).pipe(
            take(1),
            map((result) =>
                result.items.map(
                    (user) =>
                    ({
                        id: user.userOrOrganizationId,
                        text: user.displayName,
                    } as IItem<number>)
                )
            )
        );
    }

    getAllPossibleUsersForDeadline(economicUnitId: number, permissionName: string): Observable<AssignableUserDto[]> {
        return this.economicUnitCustomGrantsServiceProxy.getAllPossibleUsersForDeadline(economicUnitId, permissionName).pipe(
            take(1),
            map((result) =>
                result.items.map(
                    (user) =>
                    ({
                        id: user.userOrOrganizationId,
                        fullName: user.displayName,
                    } as AssignableUserDto)
                )
            )
        );
    }

    getActiveTaxAuthorities(): Observable<IItem<number>[]> {
        return this.isGranted('RealPropertyTax.Profiles')
            ? this.realPropertyTaxTaxOfficeProfilesServiceProxy.getActive().pipe(
                take(1),
                map((items: TaxOfficeProfileDto[]) =>
                    items.map(
                        (taxAuthority) =>
                        ({
                            id: taxAuthority.id,
                            text: taxAuthority.profileName,
                        } as IItem<number>)
                    )
                )
            )
            : of([]);
    }

    getTaxAuthorities(): Observable<IItem<string>[]> {
        return this.economicUnitsServiceProxy
            .getTaxOfficeByFederalState(undefined)
            .pipe(take(1),
                map((items: TaxOfficeDto[]) =>
                    items.map(
                        (taxAuthority) =>
                            ({
                                id: taxAuthority.id,
                                text: taxAuthority.name,
                            }) as IItem<string>)));
    }

    getAllModelInfo(): Observable<ModelInfoDto[]> {
        return this.declarationReferenceDataServiceProxy
            .getModelInfo()
            .pipe(take(1));
    }

    private getDeclarationProcessStates(): Observable<IItem<string>[]> {
        return this.declarationReferenceDataServiceProxy.getDeclarationProcessStates().pipe(
            take(1),
            map((declarationProcessStates) => this.mapDeclarationProcessStates(declarationProcessStates))
        );
    }

    private mapDeclarationProcessStates(declarationProcessStates: string[]): IItem<string>[] {
        return declarationProcessStates.map(
            (declarationProcessState) =>
            ({
                id: declarationProcessState,
                text: this.l(`WorkItemProcess_${declarationProcessState}`),
            } as IItem<string>)
        );
    }

    private getDeclarationDueDateStatuses(): Observable<IItem<string>[]> {
        return this.declarationReferenceDataServiceProxy.getDeclarationDueDateStatuses().pipe(
            take(1),
            map((dueDateStatus) => this.mapDeclarationDueDateStatuses(dueDateStatus))
        );
    }

    private getDeclarationSubmissionStatuses(): Observable<IItem<number>[]> {
        return of(
            Object.keys(DeclarationSubmissionStatus)
                .map((key) => DeclarationSubmissionStatus[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (status: string) =>
                    ({
                        id: DeclarationSubmissionStatus[status],
                        text: this.l(
                            DeclarationSubmissionStatus[status] == DeclarationSubmissionStatus.Submitted
                                ? 'DeclarationSubmissionStatus_Submitted'
                                : 'DeclarationSubmissionStatus_NotSubmitted'
                        ),
                        icon: 'fa fa-circle',
                        iconColor: DeclarationSubmissionStatus[status] == DeclarationSubmissionStatus.Submitted ? '#198754' : '#CDCDDE',
                    } as IItem<number>)
                )
        );
    }

    private getDeclarationCalculationStatuses(): Observable<IItem<number>[]> {
        return of(
            Object.keys(DeclarationCalculationStatus)
                .map((key) => DeclarationCalculationStatus[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (status: string) =>
                    ({
                        id: DeclarationCalculationStatus[status],
                        text: this.l(
                            DeclarationCalculationStatus[status] == DeclarationCalculationStatus.Calculated
                                ? 'DeclarationCalculationStatus_Calculated'
                                : 'DeclarationCalculationStatus_NotCalculated'
                        ),
                        icon: 'fa fa-circle',
                        iconColor: DeclarationCalculationStatus[status] == DeclarationCalculationStatus.Calculated ? '#198754' : '#CDCDDE',
                    } as IItem<number>)
                )
        );
    }

    private getDeclarationNoticeStatuses(): Observable<IItem<number>[]> {
        return of(
            Object.keys(DeclarationBucketOverallStatus)
                .map((key) => DeclarationBucketOverallStatus[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (status: string) =>
                    ({
                        id: DeclarationBucketOverallStatus[status],
                        text: this.l('DeclarationNoticeStatus_' + status),
                        icon: DeclarationNoticeStatusSettings.getIcon(),
                        iconColor: DeclarationNoticeStatusSettings.getColor(DeclarationBucketOverallStatus[status]),
                    } as IItem<number>)
                )
        );
    }

    private getEconomicUnitStatuses(): Observable<IItem<number>[]> {
        return of(
            Object.keys(EconomicUnitStatus)
                .map((key) => EconomicUnitStatus[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (status: string) =>
                    ({
                        id: EconomicUnitStatus[status],
                        text: this.l(`EconomicUnitStatus_${status}`),
                        icon: EconomicUnitStatusSettings.getIcon(status),
                        iconColor: EconomicUnitStatusSettings.getIconColor(status),
                    } as IItem<number>)
                )
        );
    }

    private mapDeclarationDueDateStatuses(dueDateStatuses: string[]): IItem<string>[] {
        return dueDateStatuses.map((dueDateStatus) => {
            let status = {
                id: dueDateStatus,
                text: this.ld(`DueDateStatus_${dueDateStatus}`),
            } as IItem<string>;
            status.icon = DueDateSettings.getIcon(dueDateStatus);
            status.iconColor = DueDateSettings.getIconColor(dueDateStatus);
            status.tooltip = DueDateSettings.getIconTooltip(dueDateStatus);
            return status;
        });
    }
}
