import { Injector, Component, ViewEncapsulation, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DomHandler } from 'primeng/dom';

@Component({
    templateUrl: './mazars-chart.component.html',
    selector: 'app-mazars-chart',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsChartComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() chartType: string;
    @Input() data: any;
    @Input() width: string;
    @Input() height: string;
    @Input() maxWidth = 200;
    @Input() maxHeight = 200;
    @Input() showLegend: boolean;
    @Input() showCustomLegend: boolean;
    @Input() showValueInLabel: boolean = true;
    @Input() customTooltip: boolean = false;
    @Input() responsiveLayout: boolean = true;
    @Input() title: string;
    @Input() legendAlign: string;
    options: any;

    constructor(injector: Injector) {
        super(injector);
    }

    private static getValueAtIndexOrDefault(arr: string[], i: number, def: any): string {
        return arr && arr.length ? arr[i] : def;
    }

    ngOnInit(): void {
        this.options = this.getOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.options = this.getOptions();
    }

    getOptions() {
        let options = {
            responsive: this.responsiveLayout,
            plugins: {
                legend: {
                    display: this.showLegend,
                    position: 'bottom',
                    align: this.legendAlign ? this.legendAlign : 'center',
                    generateLabels: {
                        usePointStyle: true,
                    },
                },
            },
        };

        if (this.customTooltip) {
            options.plugins['tooltip'] = this.getOffCanvasTooltip(this.showValueInLabel);
        }

        if (this.responsiveLayout) {
            options['layout'] = {
                padding: 0,
                autoPadding: false,
            };
        }

        if (this.showCustomLegend) {
            options.plugins.legend['labels'] = {
                usePointStyle: true,
                generateLabels: this.generateLabels,
            };
        }

        if (this.title) {
            options.plugins['title'] = {
                display: true,
                text: this.title,
                fontSize: 16,
            };
        }

        return options;
    }

    getOffCanvasTooltip(showValueInLabel: boolean) {
        return {
            // Disable the on-canvas tooltip
            enabled: false,

            callbacks: !showValueInLabel
                ? {
                      label: function (context) {
                          return context.label || '';
                      },
                  }
                : {},

            external: function (context) {
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');
                let tooltipContentEl = document.getElementById('chartjs-tooltip-content');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    document.body.appendChild(tooltipEl);

                    // Create tooltip content element
                    tooltipContentEl = document.createElement('div');
                    tooltipContentEl.id = 'chartjs-tooltip-content';
                    tooltipEl.appendChild(tooltipContentEl);
                }

                // Hide if no tooltip
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0';
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = '';
                    bodyLines.forEach(function (body, i) {
                        const colors = tooltipModel.labelColors[i];
                        let style = 'background: #495057';
                        style += '; color:' + '#ffffff';
                        style += '; border-color:' + '#000000';
                        style += '; border-width: 2px';
                        style += '; padding: 0.5rem';
                        style += '; border-radius: 5px';
                        style += '; word-wrap: break-word';
                        const colorSquare =
                            '<span class="mr-2" style="border: 2px solid #ffffff; display: inline-block; width: 12px; height: 12px; background-color: ' +
                            colors.backgroundColor +
                            '"></span>';
                        const span = '<div style="' + style + '">' + colorSquare + body + '</div>';
                        innerHtml += span;
                    });

                    tooltipContentEl.innerHTML = innerHtml;
                }
                const position = context.chart.canvas.getBoundingClientRect();
                const font = tooltipModel.options.bodyFont;
                const fontStyle = font.style ? font.style + ' ' : '';
                const fontWeight = font.weight ? font.weight + ' ' : '';
                const bodyFont = !font || font.size || font.family ? null : fontStyle + fontWeight + font.size + 'px ' + font.family;

                let left = position.left + window.scrollX + tooltipModel.caretX;
                // Display, position, and set styles for font
                tooltipEl.style.opacity = '1';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = left + 'px';
                tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';
                tooltipEl.style.font = bodyFont;
                tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                tooltipEl.style.pointerEvents = 'none';

                //adjust to right position if no space in left
                if (left + DomHandler.getOuterWidth(tooltipEl) >= DomHandler.getViewport()?.width) {
                    left -= DomHandler.getOuterWidth(tooltipEl);
                }
                tooltipEl.style.left = left + 'px';

                // Check if there's enough space above the chart
                let top = position.top + window.scrollY + tooltipModel.caretY;
                if (top - DomHandler.getOuterHeight(tooltipEl) < 0) {
                    top = position.top + window.scrollY + tooltipModel.caretY + DomHandler.getOuterHeight(tooltipEl);
                }

                // Check if there's enough space below the chart
                if (top + DomHandler.getOuterHeight(tooltipEl) >= DomHandler.getViewport().height) {
                    top = position.top + window.scrollY + tooltipModel.caretY - DomHandler.getOuterHeight(tooltipEl);
                }

                tooltipEl.style.top = top + 'px';
            },
        };
    }

    private generateLabels(chart) {
        const data = chart.data;
        if (data.labels.length && data.datasets.length) {
            return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const ds = data.datasets[0];
                const arc = meta.data[i];
                const custom = (arc && arc.custom) || {};
                const arcOpts = chart.options.elements.arc;
                const fill = custom.backgroundColor
                    ? custom.backgroundColor
                    : MazarsChartComponent.getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                const stroke = custom.borderColor ? custom.borderColor : MazarsChartComponent.getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                const bw = custom.borderWidth ? custom.borderWidth : MazarsChartComponent.getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                let text = label;
                if (chart.config.data.datasets[0].dataPercentage) {
                    const value = chart.config.data.datasets[0].dataPercentage[i];
                    text = `${value}% ${label}`;
                }

                return {
                    text: text,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i,
                };
            });
        }
    }
}
