<mazars-splitt-button
    [uid]="uid"
    #splittButton
    [isActionMenuButton]="true"
    class="mz-actions-menu"
    [ngStyle]="{ 'font-size': fontSize ? fontSize : 'initial' }"
    [icon]="icon"
    [fontSize]="fontSize"
    [btnSize]="btnSize"
    [vertical]="vertical"
    [model]="actionItems"
    [menuStyleClass]="actionMenuStyles"
    [appendTo]="'body'"
    (onClick)="onClick()"
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
    (onDropdownHide)="handleDropdownHide()"
    (onDropdownShow)="handleDropdownShow()"
></mazars-splitt-button>
