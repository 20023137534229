<app-mazars-searchbar
    uid="search"
    (onChange)="setFilter($event)"
    [showCreate]="hasCreatePermission"
    (onCreate)="showCreateModal()"
    [createButtonTitle]="l('Profiles_Add')"
    [filters]="filtersDefinitions"
>
</app-mazars-searchbar>

<app-mazars-grid
    #mazarsTable
    [uid]="tableId"
    [primengTableHelper]="primengTableHelper"
    [actionItems]="getActionItems"
    [isActionColumnHidden]="!hasEditPermission"
    [columnDefinitions]="columnDefinitions"
    (getAllRecords)="getProfiles($event)"
    [hasRowExpansion]="false"
    [hasGridSettings]="false"
>
    <ng-template mazarsTemplate="receiverForCountriesTemplate" let-option>
        <span *ngFor="let country of option.countryCodes" class="ml-2">
            <app-mazars-flag-icon class="mr-2" [countryCode]="country" [uid]="'receiverForCountriesTemplate-' + country"></app-mazars-flag-icon>
        </span>
    </ng-template>

    <ng-template mazarsTemplate="statusTemplate" let-option>
        <div class="text-center">
            <app-mazars-information-icon
                uid="ExpirationTresholdReached_tooltip"
                *ngIf="option.isExpirationTresholdReached"
                [message]="l('Profile_Invalid')"
                [iconClass]="'fa fa-minus-circle text-danger'"
            ></app-mazars-information-icon>
            <app-mazars-information-icon
                *ngIf="option.isExpirationWarningTresholdReached && !option.isExpirationTresholdReached"
                [message]="l('Profile_ExpiringSoon')"
                uid="Profile_ExpiringSoon"
                [iconClass]="'fa fa-exclamation-triangle text-warning'"
            ></app-mazars-information-icon>
            <app-mazars-information-icon
                uid="ExpirationTresholdReached_ExpirationWarningTresholdReached_tooltip"
                *ngIf="!option.isExpirationTresholdReached && !option.isExpirationWarningTresholdReached"
                [iconClass]="'fa fa-check-circle text-success'"
            ></app-mazars-information-icon>
        </div>
    </ng-template>
</app-mazars-grid>

<mazars-profiles-modal #mazarsProfilesModal [uid]="'ma_profile_modal'"></mazars-profiles-modal>
