<div class="d-flex align-items-center" [attr.data-testid]="uid">
    <img
        *ngIf="(mode === 'icon' || mode === 'icontext') && user && profilePicture && user?.id !== -1 && user?.value !== -1"
        [src]="profilePicture | safe: securityContext.URL"
        alt=""
        class="mz-rounded"
        style="width: 1.65rem"
    />
    <span *ngIf="user?.id === -1 || user?.value === -1">
        <i aria-hidden="true" class="fas fa-users-slash mz-rounded" style="font-size: 0.7rem; padding: 8px 6px; color: #ffffff; background-color: #6e7868"></i>
    </span>
    <span *ngIf="isOrganizationUnit">
        <i aria-hidden="true" class="fas fa-users mz-rounded" style="font-size: 0.7rem; padding: 8px 6px; color: #ffffff; background-color: #6e7868"></i>
    </span>
    <span *ngIf="mode === 'text' || mode === 'icontext'" class="ml-2">{{ user.fullName || user.text }}</span>
</div>
