import { Directive, ElementRef, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Directive({
    selector: '[setContentHeight]',
    host: {
        '(window:resize)': 'onResize($event)',
    },
})
export class SetContentHeightDirective implements OnInit, OnDestroy {
    @Output() resize = new EventEmitter<number>(false);
    @Output() outerParams = new EventEmitter<number>(false);
    resizeEvent$: Subject<MouseEvent> = new Subject<MouseEvent>();
    subscription: Subscription = null;
    constructor(private el: ElementRef) {}

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    ngOnInit() {
        this.subscription = this.resizeEvent$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(() => {
            let height = this.el.nativeElement.clientHeight;
            this.resize.emit(height);
        });
    }
    private onResize(event: any) {
        this.resizeEvent$.next(event);
    }
}
