import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IUserDetailsDto } from '@shared/service-proxies/common-interfaces';
import { ICommunicationsService, ICommunicationsServiceToken } from '@shared/service-proxies/interfaces/ICommunicationsService';

@Component({
    selector: 'app-mazars-channel-user-details',
    templateUrl: './mazars-channel-user-details.component.html',
})
export class MazarsChannelUserDetailsComponent implements OnChanges {
    @Input() channel: any;

    userDatails: IUserDetailsDto[];

    constructor(
        @Inject(ICommunicationsServiceToken)
        private communicationsService: ICommunicationsService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.channel) {
            if (this.channel?.id) {
                this.getUserDetails(this.channel.id);
            }
        }
    }

    private getUserDetails(channelId: number) {
        if (isNaN(Number(channelId)) || isNaN(channelId - 0)) {
            return;
        }
        this.communicationsService.getAllChannelUserDetail(channelId).subscribe((data) => {
            this.userDatails = data;
        });
    }
}
