<span
    tabindex="0"
    role="button"
    [ngClass]="iconClass"
    [mazarsTooltip]="message"
    [escape]="escape"
    [tooltipEvent]="'focus'"
    [tooltipPosition]="position"
    [tooltipStyleClass]="tooltipStyleClass"
    [tooltipZIndex]="1100"
    [escape]="false"
    [attr.data-testid]="uid"
    (click)="handleOnClick()"
></span>
