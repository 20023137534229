<p-autoComplete
    #autocomplete
    id="{{ uid }}"
    name="{{ uid }}"
    minLength="3"
    [ngModel]="value"
    [attr.data-testid]="uid"
    [suggestions]="suggestions"
    (completeMethod)="filterData($event)"
    placeholder="{{ placeholder }}"
    [dropdown]="isDropDown"
    [dropdownIcon]="dropdownIcon"
    [minLength]="0"
    inputStyleClass="form-control"
    (onBlur)="onBlur()"
    (onFocus)="autocomplete.handleDropdownClick($event)"
    (onClear)="clearItems(autocomplete)"
    (ngModelChange)="onValueChange($event)"
    (onKeyUp)="onKeyUp($event)"
    (onSelect)="onSelect($event)"
    [disabled]="isDisabled"
    showClear="true"
>
    <ng-template let-item pTemplate="item">
        <span [attr.data-testid]="uid + '-' + item">
            <i aria-hidden="true" class="{{dropdownIcon}} mr-1"></i>
            {{ item }}
        </span>
    </ng-template>
</p-autoComplete>