<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="FirstName">{{ l('FirstName') }} *</label>
                <app-mazars-input-text [uid]="'FirstName'" formControlName="firstName"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="'FirstName'"
                    [key]="'FirstName'"
                    [control]="form.controls.firstName"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="MiddleName">{{ l('MiddleName') }}</label>
                <app-mazars-input-text [uid]="'MiddleName'" formControlName="middleName"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="'MiddleName'"
                    [key]="'MiddleName'"
                    [control]="form.controls.middleName"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="LastName">{{ l('LastName') }} *</label>
                <app-mazars-input-text [uid]="'LastName'" formControlName="lastName"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="'LastName'"
                    [key]="'LastName'"
                    [control]="form.controls.lastName"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6" *ngIf="!partyConfig || partyConfig?.showSuffix">
            <div class="form-group">
                <label for="Suffix">{{ l('Suffix') }}</label>
                <app-mazars-input-text [uid]="'Suffix'" formControlName="suffix"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="'Suffix'"
                    [key]="'Suffix'"
                    [control]="form.controls.suffix"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="DateOfBirth">{{ l('DateOfBirth') }}</label>
                <app-mazars-datepicker
                    [uid]="'DateOfBirth'"
                    [placeholder]="l('Select')"
                    formControlName="dateOfBirth"
                    [maxDate]="yesterdayDate"
                ></app-mazars-datepicker>
                <app-mazars-validation-message
                    [uid]="'DateOfBirth'"
                    [key]="'DateOfBirth'"
                    [control]="form.controls.dateOfBirth"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label for="BirthPlace">{{ l('BirthPlace') }}</label>
                <app-mazars-input-text [uid]="'BirthPlace'" formControlName="birthPlace"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="'BirthPlace'"
                    [key]="'BirthPlace'"
                    [control]="form.controls.birthPlace"
                    [submitting]="submitting"
                ></app-mazars-validation-message>
            </div>
        </div>
    </div>
</form>
