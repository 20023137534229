<button
    [type]="isSubmit ? 'submit' : 'button'"
    [class]="'mz-button' + (large ? ' mz-button-lg' : '') + (bordered ? ' mz-button-bg' : '') + (styleClass != null ? ' ' + styleClass : '') + (inverted ? ' inverted' : '')"
    [class.disabled]="busy || disabled"
    [disabled]="busy || disabled"
    [attr.data-testid]="uid"
    (click)="onClick.emit()"
>
    <i aria-hidden="true" *ngIf="iconClass && !busy" [ngClass]="[iconClass, label ? 'mr-2' : '']" aria-hidden="true"></i>
    <i aria-hidden="true" *ngIf="busy" [ngClass]="['pi pi-spin pi-spinner mr-2']" aria-hidden="true"></i>
    {{ label }}
</button>
