<div class="mz-container">
    <app-mazars-searchbar uid="search"></app-mazars-searchbar>
</div>

<div class="containerClass">
    <div class="card">
        <div class="row g-0">
            <div class="col-12 col-lg-5 col-xl-3 border-end pt-0 mb-3">
                <mazars-chat-channels
                    [referenceId]="referenceId"
                    [communicationMessageIdentifier]="communicationMessageIdentifier"
                    [hasCreatePermission]="hasViewPermission"
                    [hasEditPermission]="hasViewPermission"
                    [hasDeletePermission]="hasViewPermission"
                    [hasViewPermission]="hasViewPermission"
                    [uid]="'chat-channels'"
                ></mazars-chat-channels>
            </div>
            <div class="col-12 col-lg-7 col-xl-9 pt-0">
                <mazars-chat-mazars-chat-discussion
                    [referenceId]="referenceId"
                    [communicationMessageIdentifier]="communicationMessageIdentifier"
                    [commonQuillMentionService]="commonQuillMentionService"
                    [hasCreatePermission]="hasViewPermission"
                    [hasEditPermission]="hasViewPermission"
                    [hasDeletePermission]="hasViewPermission"
                    [hasViewPermission]="hasViewPermission"
                    [uid]="'chat-discussion'"
                ></mazars-chat-mazars-chat-discussion>
            </div>
        </div>
    </div>
</div>
