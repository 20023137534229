<p-calendar
    id="{{ uid }}"
    name="{{ uid }}"
    [attr.data-testid]="uid"
    [inputStyleClass]="'form-control'"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
    [style]="{ width: '100%' }"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [monthNavigator]="true"
    [yearNavigator]="true"
    [yearRange]="yearRange"
    [dateFormat]="dateFormat"
    (onBlur)="onBlur()"
    (onSelect)="onValueChange()"
    (onInput)="onValueChange()"
    (onClearClick)="onClearClick()"
    [showTime]="false"
    [selectionMode]="'single'"
    [showButtonBar]="showClear"
    todayButtonStyleClass="d-none"
    [view]="view"
>
</p-calendar>
