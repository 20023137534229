import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonQuillMentionService } from '@app/shared/common/quill/common-quill-mention.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICommunicationDto, IChannelOverviewDto, IInitiateEmailConversationInput } from '@shared/service-proxies/common-interfaces';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { SelectItem } from 'primeng/api';
import { IItem } from '../../interfaces/item.interface';
import { CommunicationService } from '../../services/communication.service';
import { CommonCommunicationsFacade } from '../../state-management/facades/common-communications.facade';
import { ActionIcon } from '../mazars-actions-dropdown-menu/action-icon';
import { IActionInfo } from '../mazars-actions-dropdown-menu/action-info';
import { MazarsCommunicationModalComponent } from '../mazars-communication/mazars-communication-modal.component';
import { MazarsCommunicationViewModalComponent } from '../mazars-communication/mazars-communication-view-modal/mazars-communication-view-modal.component';
import { MazarsWhitelistModalComponent } from '../mazars-whitelist-modal/mazars-whitelist-modal.component';
import { MazarsChannelModalComponent } from './mazars-channel-modal.component';

@Component({
    selector: 'app-mazars-channel',
    templateUrl: './mazars-channel.component.html',
    styleUrls: ['./mazars-channel.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsChannelComponent extends AppComponentBase implements OnInit {
    @ViewChild('mazarsChannelModal', { static: true }) mazarsChannelModal: MazarsChannelModalComponent;
    @ViewChild('mazarsCommunicationModal', { static: true })
    mazarsCommunicationModal: MazarsCommunicationModalComponent;
    @ViewChild('mazarsCommunicationViewModal', { static: true })
    mazarsCommunicationViewModalComponent: MazarsCommunicationViewModalComponent;
    @ViewChild('mazarsWhitelistModal', { static: true }) mazarsWhitelistModal: MazarsWhitelistModalComponent;
    @Input() commonQuillMentionService: CommonQuillMentionService;
    @Input({ required: true }) uid: string;
    @Input() referenceId: number;
    @Input() hasCreatePermission: boolean;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;
    @Input() hasViewPermission: boolean;
    @Output() actionItemClick: EventEmitter<IActionInfo> = new EventEmitter<IActionInfo>();

    actionItems: IActionInfo[];
    channels: any;

    filterText = '';
    typeFilter: SelectItem[] = null;
    tagsFilter: SelectItem[] = null;
    authorFilter: SelectItem[] = null;

    typeItems: IItem<string>[] = [];
    tagsItems: IItem<string>[] = [];
    authors: IItem<number>[] = [];
    contextChannelId: any;

    actionItemsCache: Map<any, IActionInfo[]> = new Map<any, IActionInfo[]>();
    private communicationService: CommunicationService;

    constructor(injector: Injector, public communicationsFacade: CommonCommunicationsFacade) {
        super(injector);
        this.communicationService = injector.get(CommunicationService);
    }

    get hasFilters(): boolean {
        if (
            this.filterText ||
            (this.typeFilter && this.typeFilter.length > 0) ||
            (this.tagsFilter && this.tagsFilter.length > 0) ||
            (this.authorFilter && this.authorFilter.length > 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    ngOnInit(): void {
        this.reloadPage();
        this.reloadFilters();

        this.actionItems = [
            {
                visible: this.hasCreatePermission,
                label: this.l('CreateCommunicationElement'),
                icon: ActionIcon.Create,
            },
            {
                visible: this.hasEditPermission,
                label: this.l('EditChannel'),
                icon: ActionIcon.Edit,
            },
            {
                visible: this.hasDeletePermission,
                label: this.l('DeleteChannel'),
                icon: ActionIcon.Delete,
                requireConfirmation: false,
            },
            {
                visible: this.hasCreatePermission,
                label: this.l('btn_InitiateEmail'),
                icon: ActionIcon.Mail,
            },
            {
                visible: this.hasViewPermission,
                label: this.l('ViewWhitelist'),
                icon: ActionIcon.Edit,
            },
        ];

        this.registerSubscriptions();
    }

    setFilter(event: any): void {
        this.filterText = event;
        this.reloadPage();
    }

    clearFilter() {
        this.typeFilter = [];
        this.tagsFilter = [];
        this.authorFilter = [];
    }

    onTabOpen(channel: any) {
        if (channel && !this.hasFilters && !channel.communications) {
            this.communicationsFacade.getAllChannelCommunications(channel.id);
        }
    }

    public showCreateModal() {
        this.showCreateUpdateModal(null);
    }

    reloadPage(): void {
        if (this.hasFilters) {
            const filterRequest = <any>{
                referenceId: this.referenceId,
                filter: this.filterText ?? '',
                typeFilter: this.typeFilter ? this.typeFilter : [],
                tagsFilter: this.tagsFilter ? this.tagsFilter : [],
                authorFilter: this.authorFilter ? this.authorFilter : [],
            };

            this.communicationsFacade.searchCommunications(filterRequest);
        } else {
            this.getAllChannels();
        }
    }

    getActionItems(record: any): IActionInfo[] {
        if (this.actionItemsCache.has(record)) {
            return this.actionItemsCache.get(record);
        }
        let items = this.getChannelActionItems(record);
        this.actionItemsCache.set(record, items);
        return items;
    }

    getChannelActionItems = (channel: IChannelOverviewDto) =>
        <IActionInfo[]>[
            {
                visible: this.hasCreatePermission,
                label: this.l('CreateCommunicationElement'),
                icon: ActionIcon.Create,
                record: channel,
            },
            {
                visible: this.hasEditPermission,
                label: this.l('EditChannel'),
                icon: ActionIcon.Edit,
                record: channel,
            },
            {
                visible: this.hasDeletePermission,
                label: this.l('DeleteChannel'),
                icon: ActionIcon.Delete,
                requireConfirmation: false,
                record: channel,
            },
            {
                visible: this.hasCreatePermission,
                label: this.l('btn_InitiateEmail'),
                icon: ActionIcon.Mail,
                record: channel,
            },
            {
                visible: this.hasViewPermission,
                label: this.l('ViewWhitelist'),
                icon: ActionIcon.Edit,
                record: channel,
            },
        ];

    handleActionItemClick(event: any, record: any): void {
        switch (event.label) {
            case this.actionItems[0].label:
                this.showCreateCommunicationModal(record);
                break;
            case this.actionItems[1].label:
                this.showCreateUpdateModal(record);
                break;
            case this.actionItems[2].label:
                this.deleteChannel(record.id);
                break;
            case this.actionItems[3].label:
                this.initiateEmail(record.id);
                break;
            case this.actionItems[4].label:
                this.showWhitelistModal(record);
                break;
        }
    }

    preventAccordionClickOnDropdown(event: any): void {
        event.stopPropagation();
    }

    showUpdateCommunicationModal(communication: ICommunicationDto) {
        this.contextChannelId = communication.referenceId;
        this.mazarsCommunicationModal.show(communication.referenceId, communication).then(() => {
            this.mazarsCommunicationViewModalComponent.close();
            this.notify.success(this.l('SuccessfullySaved'));
            this.reloadPage();
        });
    }

    showViewCommunicationModal(communication: ICommunicationDto) {
        this.contextChannelId = communication.referenceId;
        this.mazarsCommunicationViewModalComponent.show(communication, this.hasEditPermission, this.hasDeletePermission);
    }

    deleteCommunication(communication: ICommunicationDto): void {
        this.contextChannelId = communication.referenceId;
        this.message.confirm('', this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.communicationsFacade.deleteCommunication(communication.id);
            }
        });
    }

    private getAllChannels(): void {
        this.communicationsFacade.getAllChannels(this.referenceId);
    }

    private initiateEmail(channelId) {
        this.communicationsFacade.initiateEmailCommunication({ channelId: channelId, referenceId: this.referenceId } as IInitiateEmailConversationInput);
    }

    private reloadFilters() {
        this.communicationsFacade.getAllCommunicationAuthors();
    }

    private showCreateCommunicationModal(channel: IChannelOverviewDto) {
        this.contextChannelId = channel.id;
        this.mazarsCommunicationModal.show(channel.id, null).then(() => {
            this.notify.success(this.l('SuccessfullyCreated'));
            this.reloadPage();
            this.reloadFilters();
        });
    }

    private showCreateUpdateModal(channel: IChannelOverviewDto): void {
        this.mazarsChannelModal.show(this.referenceId, channel).then(() => {
            this.notify.success(channel == null ? this.l('SuccessfullyCreated') : this.l('SuccessfullySaved'));
            this.reloadPage();
            this.reloadFilters();
        });
    }

    private showWhitelistModal(channel: IChannelOverviewDto): void {
        this.mazarsWhitelistModal.show(channel.id, this.hasDeletePermission, this.hasEditPermission).then(() => {
            this.notify.success(this.l('SuccessfullySaved'));
        });
    }

    private deleteChannel(channelId: number): void {
        this.message.confirm(this.l('DeleteChannelDialog_Content'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.communicationsFacade.deleteChannel(channelId, this.referenceId);
            }
        });
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.communicationsFacade.allChannels$.subscribe((channels) => {
                if (channels) {
                    if (this.channels) {
                        this.communicationsFacade.getNumberOfUnreadChannels(this.referenceId);
                    }
                    let channelsClone = _cloneDeep(channels);

                    if (this.channels) {
                        channelsClone = channelsClone.map((d) => ({ ...d, isSelected: this.channels?.find((x) => x.id === d.id)?.isSelected || d.isSelected }));
                    }

                    this.channels = channelsClone;
                } else {
                    return;
                }

                if (this.contextChannelId) {
                    this.channels.map((d) => {
                        if (d.id === this.contextChannelId) {
                            d.isSelected = true;
                            this.onTabOpen(d);
                            this.contextChannelId = null;
                        }
                    });
                } else {
                    this.channels.map((d) => {
                        if (d.isSelected && !d.communications) {
                            this.onTabOpen(d);
                        }
                    });
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.loaded$.subscribe((loaded) => {
                if (loaded) {
                    this.hideMainSpinner();
                } else {
                    this.showMainSpinner();
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.allCommunicationsAuthors$.subscribe((result) => {
                if (result) {
                    this.authors = result.map((a) => <IItem<number>>{ id: a.id, text: a.text, icon: 'pi pi-user' });
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.initiateEamilCommunicationSuccessful$.subscribe(() => {
                this.notify.info(this.l('EmailInitiatedInfo'));
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.deleteChannelSuccessful$.subscribe(() => {
                this.reloadPage();
                this.notify.success(this.l('SuccessfullyDeleted'));
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.deleteCommunicationSuccessful$.subscribe(() => {
                this.mazarsCommunicationViewModalComponent.close();
                this.reloadPage();
                this.notify.success(this.l('SuccessfullyDeleted'));
            })
        );
    }
}
