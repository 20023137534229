export class DialogResult {
    private submitCallbacks: ((result?: any) => void)[] = [];
    private cancelCallbacks: (() => void)[] = [];

    private isSubmitted = false;
    private isCancelled = false;
    private result?: any = null;

    public constructor(executor: (submit: (result?: any) => void, cancel: () => void) => void) {
        executor(this.submit, this.cancel);
    }

    public then(submitted: (result?: any) => void, cancelled?: () => void): void {
        if (this.isSubmitted) {
            submitted(this.result);
            return;
        }
        if (this.isCancelled) {
            if (cancelled != null) {
                cancelled();
            }
            return;
        }
        this.submitCallbacks.push(submitted);
        if (cancelled != null) {
            this.cancelCallbacks.push(cancelled);
        }
    }

    private submit = (result?: any): void => {
        if (this.isSubmitted || this.isCancelled) {
throw new Error('Dialog result is already sent');
}
        this.isSubmitted = true;
        this.result = result;
        this.invokeAllSafely(this.submitCallbacks, (cb) => cb(this.result));
        this.submitCallbacks = [];
        this.cancelCallbacks = [];
    };

    private cancel = (): void => {
        if (this.isSubmitted || this.isCancelled) {
throw new Error('Dialog result is already sent');
}
        this.isSubmitted = true;
        this.invokeAllSafely(this.cancelCallbacks, (cb) => cb());
        this.submitCallbacks = [];
        this.cancelCallbacks = [];
    };

    private invokeAllSafely(callbacks: ((result?: any) => void)[], callback: (cb: (result?: any) => void) => void): void {
        for (let cbFkn of callbacks) {
            try {
                callback(cbFkn);
            } catch (error) {
                console.error(error);
            }
        }
    }
}
