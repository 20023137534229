import { Injector, Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './mazars-header.component.html',
    selector: 'mazars-header',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsHeaderComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() actionsTemplate;
    @Input() useBurgerMenu: boolean = false;
    @Input() toolHeaderIcon: string;
    @Input() toolHeaderTitle: string;
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

    isBurgermenuOpen: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    toggleMenu(isMenuOpen: boolean) {
        //scroll to top if menu is open
        if (isMenuOpen) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }

        this.toggle.emit(isMenuOpen);
    }
}
