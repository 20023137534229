import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ReportReferenceDataService } from '../reports/report-reference-data.service';

@Injectable()
export class ReportReferenceDataResolver implements Resolve<any> {
    constructor(private reportReferenceDataService: ReportReferenceDataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.reportReferenceDataService.load();
        return true;
    }
}
