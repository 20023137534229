import { Component, forwardRef, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityCustomPermissionsFacade } from '@app/shared/common/facades/entity-custom-permissions.facade';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    ICustomGrantsForPermissionInput,
    ICustomGrantsWithAccessRestrictionInput,
    IUserOrOrganizationUnitInput,
} from '@shared/service-proxies/common-interfaces';
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash-es';
import { Dialog } from 'primeng/dialog';
import { FourEyesPrinciplePermissionsBaseService } from './four-eyes-principle-permissions-base.service';
import { MazarsCustomPermissionModel } from './mazars-custom-permission-model';

@Component({
    selector: 'app-mazars-custom-permissions-v2',
    templateUrl: './mazars-custom-permissions-v2.component.html',
    styleUrls: ['./mazars-custom-permissions.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsCustomPermissionsV2Component,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MazarsCustomPermissionsV2Component),
            multi: true,
        },
    ],
})
export class MazarsCustomPermissionsV2Component extends AppComponentBase implements ControlValueAccessor, OnInit {
    @ViewChild('possibleUsersDialog', { static: true }) possibleUsersDialog: Dialog;
    @Input() isCreate: boolean;
    @Input() entityId: number;
    @Input() entityName: string;
    @Input() viewOnlyMode = false;
    @Input() moduleLocalizationSourceName: string;
    @Input() fourEyesPrinciplePermissionsService: FourEyesPrinciplePermissionsBaseService;
    @Input() entityCustomPermissionsFacade: EntityCustomPermissionsFacade;
    @Input() dataLoading: boolean;
    referenceDataLoading: boolean;
    isDisabled: boolean;
    possibleUsers: string[] = [];
    displayPossibleUsersDialog = false;
    form: FormGroup;
    entityPermissions: MazarsCustomPermissionModel[];
    accessRestrictionProvisionalData: IUserOrOrganizationUnitInput[];
    newValues: string[];
    newValuesPermissionName: string;
    resetActive: boolean;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
    ) {
        super(injector);
        this.createForm();
    }

    get value(): any {
        return this.form?.value;
    }

    set value(entityPermissions: MazarsCustomPermissionModel[]) {
        this.entityPermissions = entityPermissions;
        this.patchForm();
        this.onChange(entityPermissions);
        this.onTouched();
    }

    ngOnInit(): void {
        this.referenceDataLoading = true;
        this.localizationSourceName = this.moduleLocalizationSourceName;

        this.subscriptions.push(
            this.entityCustomPermissionsFacade.entityPermissionsLoading$.subscribe((isLoading) => {
                this.referenceDataLoading = isLoading;
            }),
        );

        this.subscriptions.push(
            this.entityCustomPermissionsFacade.selectedAccessRestrictionProvisionalData$.subscribe((accessRestrictionProvisionalData) => {
                this.accessRestrictionProvisionalData = accessRestrictionProvisionalData;
                if (!this.resetActive) {
                    this.getAllPermissions();
                }
            }),
        );

        this.subscriptions.push(
            this.entityCustomPermissionsFacade.resetForm$.subscribe((resetActive) => {
                this.resetActive = resetActive;
            }),
        );

        this.subscriptions.push(
            this.entityCustomPermissionsFacade.entityPermissions$.subscribe((entityPermissions) => {
                this.updateEntityPermissions(entityPermissions);
                if (this.entityPermissions) {
                    this.patchForm();
                }
            }),
        );
    }

    onDestroy(): void {
        // to be implemented
    }

    showPossibleUsers(users: string[]) {
        if (users && users.length > 0) {
            this.possibleUsers = users;
            this.displayPossibleUsersDialog = true;
        }
    }

    hasReviewWarning(reviewPermission: MazarsCustomPermissionModel): boolean {
        return this.fourEyesPrinciplePermissionsService.hasReviewWarning(this.entityPermissions, reviewPermission);
    }

    onEntityPermissionChange(newValues, permissionObj) {
        this.newValues = newValues;
        this.newValuesPermissionName = permissionObj.fullPermissionName;
        this.getAllPermissions(true);
        this.onChange(this.entityPermissions);
        this.onTouched();
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    validate(_: FormControl) {
        return !this.form.invalid ? null : { customPermissions: { valid: false } };
    }

    private createForm() {
        this.form = this.fb.group({});
    }

    private patchForm() {
        for (let entityPermission of this.entityPermissions) {
            if (entityPermission.isActive) {
                if (!this.form.controls[entityPermission.fullPermissionName]) {
                    this.form.addControl(entityPermission.fullPermissionName, this.fb.control({ value: [], disabled: this.viewOnlyMode }));
                }

                this.form.patchValue({
                    [entityPermission.fullPermissionName]: entityPermission.usersOrOrganizationUnits,
                });
            }
        }
    }

    private getAllPermissions(formChanged: boolean = false) {
        if (this.accessRestrictionProvisionalData || formChanged) {
            this.entityCustomPermissionsFacade.loadAllPermissionsWithProvisionalData({
                entityId: isNaN(this.entityId) ? 0 : this.entityId,
                accessRestrictionProvisionalData: this.accessRestrictionProvisionalData,
                customGrantsForPermissions: this.getFormEntityPermission(),
            } as ICustomGrantsWithAccessRestrictionInput);
        } else {
            this.entityCustomPermissionsFacade.loadAllPermissions(isNaN(this.entityId) ? 0 : this.entityId);
        }
    }

    private getFormEntityPermission(): ICustomGrantsForPermissionInput[] {
        let formValues = this.form.value;
        let entityPermissionInputs: ICustomGrantsForPermissionInput[] = [];
        for (let propertyName in formValues) {
            if (propertyName) {
                let propertyValues = propertyName === this.newValuesPermissionName ? this.newValues : formValues[propertyName];
                let input = propertyValues.map((i) => ({ type: i.split('_')[1], userOrOrganizationId: i.split('_')[0] }) as IUserOrOrganizationUnitInput);
                entityPermissionInputs.push({
                    permissionName: propertyName,
                    entityId: isNaN(this.entityId) ? 0 : this.entityId,
                    usersAndOrganizationUnits: input,
                } as ICustomGrantsForPermissionInput);
            }
        }

        return entityPermissionInputs;
    }

    private updateEntityPermissions(entityPermissions: MazarsCustomPermissionModel[]) {
        if (!this.entityPermissions || this.entityPermissions.length !== entityPermissions.length) {
            this.entityPermissions = _cloneDeep(entityPermissions);
        } else {
            for (let i = 0; i < this.entityPermissions.length; i++) {
                if (this.entityPermissions[i].fullPermissionName !== entityPermissions[i].fullPermissionName) {
                    this.entityPermissions = _cloneDeep(entityPermissions);
                    break;
                }

                for (let entityPermissionPropertyName in this.entityPermissions[i]) {
                    if (!_isEqual(this.entityPermissions[i][entityPermissionPropertyName], entityPermissions[i][entityPermissionPropertyName])) {
                        this.entityPermissions[i][entityPermissionPropertyName] = entityPermissions[i][entityPermissionPropertyName];
                    }
                }
            }
        }
    }
}
