<form [formGroup]="form">
    <ng-container formArrayName="taxIds">
        <ng-container *ngFor="let taxId of getControls(); let i = index">
            <div class="row" [formGroup]="taxId">
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label for="Tin-{{ i }}">{{ l('TaxId') }} *</label>
                        <app-mazars-information-icon uid="TaxId-tooltip" [message]="getTaxIdDescription(taxId)"></app-mazars-information-icon>
                        <app-mazars-input-text [uid]="uid + '-tin-' + i" formControlName="value"></app-mazars-input-text>
                        <app-mazars-validation-message
                            [uid]="uid + '-tin-' + i"
                            [control]="taxId.controls.value"
                            [submitting]="submitting"
                        ></app-mazars-validation-message>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5">
                    <div class="form-group">
                        <label for="IssuedBy-{{ i }}">{{ l('IssuedBy') }} *</label>
                        <app-mazars-information-icon uid="IssuedByDescription" [message]="l('IssuedByDescription')"></app-mazars-information-icon>
                        <app-mazars-dropdown [uid]="uid + '-issued-by-' + i" [filter]="true" [options]="countries" [hasEmpty]="true" formControlName="issuedBy">
                            <ng-template mazarsTemplate let-item let-styleClass="styleClass">
                                <app-mazars-flag-icon class="mr-2" [countryCode]="item.id" [uid]="uid + '-contry-item-' + item?.id"></app-mazars-flag-icon>
                                <span [attr.data-testid]="uid + '-' + item.id" [ngClass]="styleClass">{{ item.text }}</span>
                            </ng-template>
                        </app-mazars-dropdown>
                        <app-mazars-validation-message
                            [uid]="uid + '-issued-by-' + i"
                            [key]="'IssuedBy'"
                            [control]="taxId.controls.issuedBy"
                            [submitting]="submitting"
                        ></app-mazars-validation-message>
                    </div>
                </div>
                <div class="col-1 align-self-center">
                    <i
                        *ngIf="!isDisabled"
                        role="button"
                        class="flaticon-delete"
                        aria-hidden="true"
                        [attr.data-testid]="uid + '_remove_' + i + '_btn'"
                        (click)="removeTaxId(i)"
                    ></i>
                </div>
            </div>
        </ng-container>
        <button *ngIf="!isDisabled" type="button" class="btn btn-primary blue" [attr.data-testid]="uid + '_add_btn'" (click)="addTaxId()">
            {{ l('Add') }} {{ l('TaxId') }}
        </button>
    </ng-container>
</form>
