import { Component, ViewChild, Injector, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Acceptor } from './acceptor';
import { DialogResult } from './dialog-result';

@Component({
    selector: 'mz-dialog',
    templateUrl: './mazars-dialog.component.html',
})
export class MazarsDialogComponent extends AppComponentBase {
    @ViewChild('template') template: TemplateRef<any>;

    @Input({ required: true }) uid: string;
    @Input() title: string;
    @Input() submitButtonLabel: string = this.l('Save');
    @Input() cancelButtonLabel: string = this.l('Cancel');
    @Input() nextButtonLabel: string = this.l('Continue');
    @Input() backButtonLabel: string = this.l('Back');
    @Input() additionalActionButtonLabel: string;
    @Input() canSubmit = true;
    @Input() showSubmitButton = true;
    @Input() submitButtonIcon = 'pi pi-save';
    @Input() canClickNext = false;
    @Input() showAdditionalActionButton = false;
    @Input() showNextButton = false;
    @Input() showBackButton = false;
    @Input() showCancelButton = true;
    @Input() showCancelButtonIcon = true;
    @Input() showConfirm = false;
    @Input() showFooter = true;
    @Input() confirmText: string = this.l('AreYouSure');
    @Input() size: string = null; // sm md lg xl
    @Input() styleClass = '';
    @Input() bodyStyleClass = 'modal-body';
    @Input() isLoading = false;
    @Output() beforeSubmit: EventEmitter<Acceptor> = new EventEmitter<Acceptor>();
    @Output() onNextClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onBackClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onCancelClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onDestroy: EventEmitter<void> = new EventEmitter<void>();
    @Output() onAditionalActionButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    modalRef?: BsModalRef;

    saving = false;

    private promiseResult: { submit: (result?: any) => void; cancel: () => void };

    constructor(
        injector: Injector,
        private modalService: BsModalService,
    ) {
        super(injector);
    }

    show(): DialogResult {
        return new DialogResult((submit, cancel) => {
            this.promiseResult = { submit: submit, cancel: cancel };
            this.modalRef = this.modalService.show(this.template, {
                backdrop: 'static',
                class: this.styleClass
                    ? this.styleClass
                    : 'modal-dialog ' +
                      (this.size == 'sm' ? 'modal-sm' : this.size == 'md' ? '' : this.size == 'lg' ? 'modal-lg' : this.size == 'xl' ? 'modal-xl' : 'modal-lg'),
            });
        });
    }

    submit(): void {
        if (this.showConfirm) {
            this.message.confirm('', this.confirmText, (isConfirmed) => {
                if (isConfirmed) {
                    this.submitConfirmed();
                }
            });
        } else {
            this.submitConfirmed();
        }
    }

    submitConfirmed() {
        this.saving = true;
        this.beforeSubmit.emit(
            new Acceptor(
                (result?: any) => {
                    this.saving = false;
                    this.modalRef?.hide();
                    this.promiseResult?.submit(result);
                    this.onDestroy.emit();
                },
                () => {
                    this.saving = false;
                },
            ),
        );
    }

    cancel(): void {
        this.modalRef?.hide();
        this.promiseResult?.cancel();
        this.onDestroy.emit();
    }

    nextButtonClick(): void {
        this.onNextClick.emit(true);
    }

    backButtonClick(): void {
        this.onBackClick.emit(true);
    }

    additionalActionButtonClick(): void {
        this.onAditionalActionButtonClick.emit(true);
    }

    getDiaglogResult(): DialogResult {
        return new DialogResult((submit, cancel) => {
            this.promiseResult = { submit: submit, cancel: cancel };
        });
    }

    showWithoutDialogResult() {
        this.modalRef = this.modalService.show(this.template, {
            backdrop: 'static',
            class: this.styleClass
                ? this.styleClass
                : 'modal-dialog ' +
                  (this.size == 'sm' ? 'modal-sm' : this.size == 'md' ? '' : this.size == 'lg' ? 'modal-lg' : this.size == 'xl' ? 'modal-xl' : 'modal-lg'),
        });
    }
}
