import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { CountryContext } from '@app/modules/mazars-common/interfaces/country-context';
import {
    CountryReferenceDataDto,
    CurrencyReferenceDataDto,
    Filter,
    LanguageReferenceDataDto,
    ReferenceDataManagementServiceProxy,
} from '@app/modules/master-data-management/master-data-management-proxies';

@Injectable({
    providedIn: 'root',
})
export class ReferenceDataService extends AppComponentBase {
    radioItems: IItem<boolean>[];
    allCountries: IItem<string>[];
    memberStateCountries: IItem<string>[];
    currencies: IItem<string>[];
    languages: IItem<string>[];
    germanDescriptionLanguages: IItem<string>[];
    frenchDescriptionLanguages: IItem<string>[];

    constructor(injector: Injector, private referenceDataManagementServiceProxy: ReferenceDataManagementServiceProxy) {
        super(injector);
    }

    getCountries(countryFilter: Filter): Observable<CountryReferenceDataDto[]> {
        return this.referenceDataManagementServiceProxy.getCountries(countryFilter).pipe(
            take(1),
            map((countries) => this.mapReceivedCountries(countries))
        );
    }

    mapReceivedCountries(countries: CountryReferenceDataDto[]): CountryReferenceDataDto[] {
        return countries.map(
            (country) =>
                ({
                    id: country.id,
                    text: this.l(`Country_${country.text}`),
                } as CountryReferenceDataDto)
        );
    }

    getLanguages(): Observable<LanguageReferenceDataDto[]> {
        return this.referenceDataManagementServiceProxy.getLanguages().pipe(
            take(1),
            map((languages) =>
                languages.map(
                    (language) =>
                        ({
                            id: language.id,
                            text: this.l(`Language_${language.text}`),
                        } as LanguageReferenceDataDto)
                )
            )
        );
    }

    getCurrencies(): Observable<CurrencyReferenceDataDto[]> {
        return this.referenceDataManagementServiceProxy.getCurrencies().pipe(
            take(1),
            map((currencies) =>
                currencies.map(
                    (currency) =>
                        ({
                            id: currency.id,
                            text: currency.text,
                        } as CurrencyReferenceDataDto)
                )
            )
        );
    }

    getRadioItems(): Observable<IItem<boolean>[]> {
        return of([
            {
                id: false,
                text: this.ld('No'),
            },
            {
                id: true,
                text: this.ld('Yes'),
            },
        ]);
    }

    public getCountryName(countryCode: string): string {
        return this.l(`Country_${countryCode}`);
    }

    public load() {
        this.getCountries(Filter.MemberStatesOnly)
            .pipe(take(1))
            .subscribe((countries) => {
                this.memberStateCountries = countries as IItem<string>[];
            });
        this.getCountries(Filter.All)
            .pipe(take(1))
            .subscribe((countries) => {
                this.allCountries = countries as IItem<string>[];
            });
        this.getCurrencies()
            .pipe(take(1))
            .subscribe((currencies) => {
                this.currencies = currencies as IItem<string>[];
            });
        this.getLanguages()
            .pipe(take(1))
            .subscribe((languages) => {
                this.languages = languages as IItem<string>[];
                this.germanDescriptionLanguages = languages.filter((l) => l.id === CountryContext.DE || l.id === CountryContext.EN);
                this.frenchDescriptionLanguages = languages.filter((l) => l.id === CountryContext.FR);
            });
        this.getRadioItems()
            .pipe(take(1))
            .subscribe((radioItems) => {
                this.radioItems = radioItems;
            });
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }
}
