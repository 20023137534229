<mz-dialog
    #dialog
    [uid]="uid"
    [title]="isCreate ? titleForCreate : titleForUpdate"
    [submitButtonLabel]="isCreate ? l('Create') : l('Save')"
    [canSubmit]="canSubmit"
    (beforeSubmit)="beforeSubmit($event)"
    [bodyStyleClass]="bodyStyleClass"
>
    <ng-content></ng-content>
</mz-dialog>
