<form [formGroup]="form">
    <div class="row">
        <div class="col-2">
            <span [ngSwitch]="form.value.type">
                <span *ngSwitchCase="null">
                    <i aria-hidden="true" class="pi pi-search xxLargeSize"></i>
                </span>
                <span *ngSwitchCase="allColumnTypes.LegalEntity">
                    <i aria-hidden="true" class="pi pi-sitemap xxLargeSize"></i>
                </span>
                <span *ngSwitchCase="allColumnTypes.NaturalPerson">
                    <i aria-hidden="true" class="pi pi-user xxLargeSize"></i>
                </span>
            </span>
        </div>
        <div class="col-10 p-0">
            <app-mazars-dropdown
                [uid]="'uid'"
                [options]="involvedParties"
                [filter]="true"
                [virtualScrollItemSize]="virtualScrollItemSize"
                [additionalFiltersCallback]="addressService.additionalFiltersCallback"
                (onFilterChanged)="handleFilterChanged($event)"
                (onDropdownOpened)="handleDropdownOpened()"
                (onSelectedItemChanged)="handleSelectedItemChanged($event.oldValue)"
                formControlName="id"
                [hasEmpty]="hasEmpty"
            >
                <ng-template mazarsTemplate let-item let-styleClass="styleClass">
                    <span [attr.data-testid]="uid + '-' + item.id" [ngClass]="styleClass">{{ item.text }}</span>
                    <div>
                        <small *ngIf="hasAddressInfo(item)" class="ml-4 mz-address-info" [ngClass]="{ 'ml-4': item?.icon && item?.icon !== '' }">
                            {{ getFormattedAddress(item) }}
                        </small>
                    </div>
                </ng-template>
            </app-mazars-dropdown>
        </div>
    </div>
</form>
