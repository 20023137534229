<div class="mz-submit-button-container">
    <app-mazars-button
        *ngIf="leftAdditionalButton && !leftAdditionalButton.isHidden"
        [uid]="uid + '_leftAdditionalButton'"
        [label]="leftAdditionalButton.label"
        iconClass="{{leftAdditionalButton.iconClass}}"
        styleClass="mz-button-additional-left"
        [busy]="leftAdditionalButton.isBusy"
        [busyText]="l('SavingWithThreeDot')"
        large="true"
        (onClick)="onLeftAdditionalButtonClick.emit()"
    ></app-mazars-button>

    <app-mazars-button
        *ngIf="showReset && isDirty"
        [uid]="uid + '_reset'"
        [label]="resetLabel"
        [disabled]="isDisabled()"
        [inverted]="true"
        styleClass="mz-submit-button-reset"
        large="true"
        bordered="true"
        (onClick)="onReset.emit()"
    ></app-mazars-button>

    <app-mazars-button
        [uid]="uid + '_submit'"
        [label]="submitLabel"
        iconClass="pi pi-save"
        [busy]="isButtonBusy"
        [busyText]="l('SavingWithThreeDot')"
        [disabled]="isDisabled()"
        large="true"
        (onClick)="onSubmit.emit()"
    ></app-mazars-button>

    <ng-container *ngFor="let button of additionalButtons;">
        <app-mazars-button
            [uid]="uid + '_additional_' + button.id"
            [label]="button.label"
            iconClass="{{button.iconClass}}"
            [busy]="button.isBusy"
            [disabled]="isAdditionalDisabled()"
            large="true"
            [bordered]="button.bordered ?? false"
            [inverted]="button.inverted ?? false"
            styleClass="mz-button-additional"
            (onClick)="onAdditionalButtonClick.emit(button.id)"
        ></app-mazars-button>
    </ng-container>
</div>
