import { Component, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { Acceptor } from '../mazars-dialog/acceptor';

@Component({
    selector: 'app-mazars-bulk-delete-modal',
    templateUrl: './mazars-bulk-delete-modal.component.html',
})
export class MazarsBulkDeleteModalComponent extends AppComponentBase {
    @ViewChild('mazarsBulkDeleteModalComponent', { static: true }) modal: MazarsDialogComponent;
    @Input('uid') uid: string;
    description: string;

    show(description: string): DialogResult {
        this.description = description;
        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor): void {
        acceptor.accept();
    }
}
