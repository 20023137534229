import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ITagDto } from '@shared/service-proxies/common-interfaces';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
    selector: 'mazars-tags',
    templateUrl: './mazars-tags.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsTagsComponent,
        },
    ],
    styleUrls: ['./mazars-tags.component.css'],
})
export class MazarsTagsComponent extends AppComponentBase implements ControlValueAccessor {
    @ViewChild('tagsAutocomplete') tagsAutocomplete: AutoComplete;
    @Input({ required: true }) uid: string;
    @Input() placeholder: string;
    @Input() availableTags: string[];

    suggestedTags: ITagDto[];
    currentValue: string;
    value: ITagDto[];
    isDisabled: boolean;
    tagsAdded: boolean;

    onValueChange(event) {
        this.value = event;
        this.onChange(this.value);
    }

    fillSuggestedTags(searchTextLowerCase: string, tagModelLowerCase: string[]) {
        if (this.availableTags != null) {
            this.availableTags.forEach((t) => {
                let tLower = t.toLowerCase();
                if (tagModelLowerCase.indexOf(tLower) === -1) {
                    if (tLower.indexOf(searchTextLowerCase) !== -1) {
                        this.suggestedTags.push({ name: t } as ITagDto);
                    }
                }
            });
        }
    }

    addCurrentInput(searchText: string, searchTextLowerCase: string, tagModelLowerCase: string[]) {
        // Add current input, if not an existing tag
        if (
            searchText !== '' &&
            tagModelLowerCase.indexOf(searchTextLowerCase) === -1 &&
            this.suggestedTags.map((v) => v.name.toLowerCase()).indexOf(searchTextLowerCase) === -1
        ) {
            this.suggestedTags.unshift({ name: searchText } as ITagDto);
        }
    }

    search(searchText: string) {
        this.suggestedTags = [];
        let tagModelLower = !this.value ? [] : this.value.map((v) => v.name.toLowerCase());
        let sLower = searchText.toLowerCase();
        this.currentValue = searchText;

        this.fillSuggestedTags(sLower, tagModelLower);
        this.addCurrentInput(searchText, sLower, tagModelLower);
    }

    filterTags(event) {
        this.search(event.query.trim());
    }

    clearItems(autocomplete: any) {
        autocomplete.value = [];
        autocomplete.handleDropdownClick();
    }

    onAddClick() {
        this.tagsAdded = true;
    }

    onKeyUp(event) {
        if (this.currentValue !== '' && this.value.find(p => p.name === this.currentValue) === undefined && event.key === 'Enter') {
            this.value.push({ name: this.currentValue } as ITagDto);
            this.currentValue = '';
            this.tagsAutocomplete.multiInputEl.nativeElement.value = '';
            this.tagsAutocomplete.hide();
            this.onChange(this.value);
        }
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj == null) {
            this.value = [];
        } else {
            this.value = obj;
            this.tagsAdded = this.value.length !== 0;
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlur() {
        this.onTouched();
    }
}
