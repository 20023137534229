import { Component, EventEmitter, Input, OnInit, Output, SecurityContext, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-mazars-input-number',
    templateUrl: './mazars-input-number.component.html',
    styleUrls: ['./mazars-input-number.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsInputNumberComponent,
        },
    ],
})
export class MazarsInputNumberComponent implements ControlValueAccessor, OnInit {
    @Input({ required: true }) uid: string;
    @Input() placeholder?: string;
    @Input() tooltipText?: string;
    @Input() min?: number;
    @Input() max?: number;
    @Input() width?: string;
    @Input() height?: string;
    @Input() mode: string;
    @Input() currency: string;
    @Input() prefix?: string;
    @Input() suffix?: string;
    @Input() minFractionDigits = 0;
    @Input() maxFractionDigits = 0;
    @Input() step = 1;
    @Input() useGrouping = false;
    @Input() showButtons = true;
    @Input() isDisabled: boolean;

    @Output() onInput:  EventEmitter<void> = new EventEmitter<void>();
    @Output() onBlur:  EventEmitter<void> = new EventEmitter<void>();

    disabled: boolean;
    locale: string;
    value: number;
    securityContext = SecurityContext;

    ngOnInit(): void {
        this.locale = abp.localization.currentLanguage.name;
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: number): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    changeValue(value: number) {
        this.onChange(value);
        this.onInput.emit();
    }

    handleOnBlur() {
        this.onChange(this.value);
        this.onTouched();
        this.onBlur.emit();
    }
}
