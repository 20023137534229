export enum Pillar2Permissions{
    Admin = 'Pillar2.Admin',
    ProjectView = 'Pillar2.Project.View',
    ProjectCreate = 'Pillar2.Project.Create',
    ProjectEdit = 'Pillar2.Project.Edit',
    ProjectDelete = 'Pillar2.Project.Delete',
    ProjectDataDelete = 'Pillar2.Project.Data.Delete',
    ProjectProcessFlowSettingsEdit = 'Pillar2.Project.ProcessFlowSettings.Edit',
    ProjectDataEdit = 'Pillar2.Project.Data.Edit',
    ProjectDataEditImport = 'Pillar2.Project.Data.Edit.Import',
    ProjectReview = 'Pillar2.Project.Review',
    ProjectRevokeReview = 'Pillar2.Project.RevokeReview',
    ProjectReportView = 'Pillar2.Project.Report.View',
    ProjectReportCreate = 'Pillar2.Project.Report.Create',
    ProjectReportReview = 'Pillar2.Project.Report.Review',
    ProjectCurrenciesEdit = 'Pillar2.Project.Currencies.Edit',
}
