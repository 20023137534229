<p-dialog #participantsDialog position="center" [(visible)]="isVisible" styleClass="mazars-participants-dialog" [style]="{ width: '30vw' }">
    <ng-template pTemplate="header">
        {{ l('ShowParticipants_Heading') }}
    </ng-template>
    <div>
        <div class="list-group-item list-group-item-action border-0" *ngFor="let userDetail of userDatails">
            <div class="d-flex align-items-start">
                <p-avatar size="xlarge" shape="circle" [image]="'data:image/jpeg;base64,' + userDetail.profilePicture"></p-avatar>
                <div class="ms-3">
                    <div class="mb-3">{{ userDetail.fullName }}</div>
                    <div>{{ userDetail.email }}</div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="row justify-content-between flex-wrap">
            <div class="d-block d-md-flex justify-content-end flex-wrap">
                <app-mazars-button styleClass="mt-1" [uid]="uid + '_create'" [label]="l('Ok')" (onClick)="hide()"></app-mazars-button>
            </div>
        </div>
    </ng-template>
</p-dialog>
