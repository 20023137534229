<mz-create-update-dialog
    #dialog
    [uid]="uid + '-channel'"
    [titleForCreate]="l('CreateChannel')"
    [titleForUpdate]="l('EditChannel')"
    [canSubmit]="canSubmit()"
    [createUpdateFacade]="createUpdateFacade"
    [createFormCallback]="createFormCallback"
    [model2FormCallback]="model2FormCallback"
    [form2CreateModelCallback]="form2CreateModelCallback"
    [form2UpdateModelCallback]="form2UpdateModelCallback"
>
    <form [formGroup]="form" *ngIf="form" (ngSubmit)="dialog.submit()" autocomplete="off">
        <div class="form-group">
            <label for="title">{{ l('ChannelTitle') }}</label>
            <app-mazars-input-text [uid]="'title'" [placeholder]="l('ChannelTitle')" formControlName="title"></app-mazars-input-text>
            <app-mazars-validation-message [uid]="'title'" [key]="l('ChannelTitle')" [control]="form.controls.title"></app-mazars-validation-message>
        </div>
    </form>
</mz-create-update-dialog>
