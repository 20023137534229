import { Component, Injector, Input, OnChanges, SecurityContext } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as flags from '@geobuff/flags';

@Component({
    selector: 'app-mazars-federal-state-icon',
    templateUrl: './mazars-federal-state-icon.component.html',
    styleUrls: ['./mazars-federal-state-icon.component.css'],
})
export class MazarsFederalStateIconComponent extends AppComponentBase implements OnChanges {
    @Input({ required: true }) uid: string;
    @Input() federalState;
    securityContext = SecurityContext;

    icon: string;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnChanges(): void {
        this.icon = flags.getFlagUrl(`de-${this.federalState}`);
    }
}
