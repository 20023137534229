import { Injectable, InjectionToken } from "@angular/core";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MazarsWizardState } from "../state/mazars-wizard.state";

export const MazarsWizardSelectorsToken = new InjectionToken<string>('MazarsWizardSelectors');

@Injectable()
export class MazarsWizardSelectors {
    public readonly mazarsWizardState = createFeatureSelector<MazarsWizardState>(this.mazarsWizardFeatureKey);
    public readonly activeStep = createSelector(this.mazarsWizardState, (state: MazarsWizardState) => state.activeStep);

    constructor(private mazarsWizardFeatureKey: string) {
    }
}