import { Component, ViewChild, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { IItem } from '../../../interfaces/item.interface';
import { DialogResult } from '../../mazars-dialog/dialog-result';
import { MazarsDialogComponent } from '../../mazars-dialog/mazars-dialog.component';
import { Acceptor } from '../../mazars-dialog/acceptor';

@Component({
    selector: 'app-mazars-bulk-assign-user-modal',
    templateUrl: './mazars-bulk-assign-user-modal.component.html',
})
export class MazarsBulkAssignUserModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('editModal', { static: true }) modal: MazarsDialogComponent;
    @Input('uid') uid: string;

    form: FormGroup;
    assignableUsers: IItem<number>[];

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
    }

    show(assignableUsers: IItem<number>[]): DialogResult {
        this.assignableUsers = assignableUsers;
        this.form.reset();

        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor): void {
        let result = this.form.value.assignedUserId;
        acceptor.accept(result);
    }

    private createForm(): void {
        this.form = this.fb.group({
            assignedUserId: [null, [Validators.required]],
        });
    }
}
