import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'mazars-slider',
    templateUrl: './mazars-slider.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsSliderComponent,
        },
    ],
})
export class MazarsSliderComponent implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Input() min: number = 0;
    @Input() max: number = 100;
    @Input() step: number;
    @Input() orientation : string = 'horizontal';
    @Input() isDisabled: boolean;
    @Output() onStepChanged = new EventEmitter<number>();

    value: number;

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onInputChange() {
        this.onChange(this.value);
        this.onStepChanged.emit(this.value);
    }

    onBlur() {
        this.onTouched();
    }
}
