import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { Acceptor } from '../mazars-dialog/acceptor';
import { IItem } from '../../interfaces/item.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArchiveOperationType } from '@app/modules/real-property-tax/real-property-tax-proxies';

@Component({
    selector: 'app-mazars-bulk-archive-modal',
    templateUrl: './mazars-bulk-archive-modal.component.html',
})
export class MazarsBulkArchiveModalComponent extends AppComponentBase implements OnInit{
    @ViewChild('mazarsBulkArchiveModalComponent', { static: true }) modal: MazarsDialogComponent;
    @Input('uid') uid: string;
    description: string;
    archiveRadioItems: IItem<number>[];
    form: FormGroup;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
    }

    show(description: string, archiveRadioItems: IItem<number>[]): DialogResult {
        this.form.reset();

        this.description = description;
        this.archiveRadioItems = archiveRadioItems;
        
        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor): void {
        let result = ArchiveOperationType[this.form.getRawValue().archiveElementsOption];
        acceptor.accept(result);
    }

    createForm() {
        this.form = this.fb.group({
            archiveElementsOption: [null, [Validators.required]],
        });
    }
}
