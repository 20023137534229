import { Component, ViewChild, Injector, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChannelModel } from './channel-model';
import { MazarsCreateUpdateDialogComponent } from '../mazars-dialog/mazars-create-update-dialog.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable } from 'rxjs';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { IChannelService, IChannelsServiceToken } from '@shared/service-proxies/interfaces/IChannelService';
import { ChannelCreateUpdateFacade } from '../../state-management/facades/channel-create-update.facade';
import { IChannelOverviewDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'mazars-channel-modal',
    templateUrl: './mazars-channel-modal.component.html',
})
export class MazarsChannelModalComponent extends AppComponentBase {
    @ViewChild('dialog', { static: true }) dialog: MazarsCreateUpdateDialogComponent<IChannelOverviewDto, ChannelModel, ChannelModel>;
    @Input({ required: true }) uid: string;
    @Input() channelServiceProxy: { create: (entity: ChannelModel) => Observable<number>; update: (entity: ChannelModel) => Observable<void> };

    referenceId: number;
    channelId?: number;
    form: FormGroup = null;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        @Inject(IChannelsServiceToken)
        public channelsService: IChannelService,
        public createUpdateFacade: ChannelCreateUpdateFacade,
    ) {
        super(injector);
    }

    show(referenceId: number, channel?: IChannelOverviewDto): DialogResult {
        this.referenceId = referenceId;
        this.channelId = channel?.id ?? null;
        return this.dialog.show(channel);
    }

    canSubmit(): boolean {
        return (this.form?.valid && this.form?.dirty) ?? false;
    }

    createFormCallback: () => void = () => {
        this.form = this.fb.group({
            title: [null, [Validators.required, Validators.maxLength(200)]],
        });
    };

    model2FormCallback: (model: IChannelOverviewDto) => void = (model: IChannelOverviewDto) => {
        this.form.reset();
        this.form.setValue({
            title: model?.title ?? null,
        });
    };

    form2CreateModelCallback: () => ChannelModel = () => ({
        referenceId: this.referenceId,
        title: this.form.value.title,
    });

    form2UpdateModelCallback: () => ChannelModel = () => ({
        title: this.form.value.title,
        referenceId: this.referenceId,
        id: this.channelId,
    });
}
