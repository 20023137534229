<app-mazars-submit-button
    (onSubmit)="onSubmit.emit()"
    [isButtonBusy]="isButtonBusy"
    [submitLabel]="submitLabel"
    [showReset]="showReset"
    (onReset)="onReset.emit()"
    [isDirty]="isDirty"
    [additionalButtons]="[{
        id: endDraftButtonId,
        label: draftLabel,
        isBusy: isDraftBusy,
        iconClass: 'pi pi-save'
    }]"
    (onAdditionalButtonClick)="onDraftButtonClick.emit()"
    [uid]="'draft-mode-'+ uid"
></app-mazars-submit-button>