<div
    appBsModal
    #modal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    (onShown)="shown()"
    aria-hidden="true"
    [attr.data-testid]="uid"
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <app-mazars-fieldset
                *ngIf="communication"
                [communication]="communication"
                [hasEditPermission]="hasEditPermission"
                [hasDeletePermission]="hasDeletePermission"
                class="w-100"
                [isFullScreen]="true"
                (closeCommunicationClicked)="close()"
                (editCommunicationClicked)="showUpdateCommunicationModal($event)"
                (deleteCommunicationClicked)="deleteCommunication($event)"
                [uid]="uid + '-commnication-view-modal-fieldset-' + communication?.id"
            ></app-mazars-fieldset>
        </div>
    </div>
</div>
