<input
    id="{{ uid }}"
    type="checkbox"
    name="{{ uid }}"
    [disabled]="isDisabled"
    class="form-control form-check-input"
    [checked]="value"
    [(ngModel)]="value"
    (change)="onInputChange()"
    [attr.data-testid]="uid"
/>