import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MazarsDialogComponent } from '@app/modules/mazars-common/components/mazars-dialog/mazars-dialog.component';
import { DialogResult } from '@app/modules/mazars-common/components/mazars-dialog/dialog-result';
import { Acceptor } from '@app/modules/mazars-common/components/mazars-dialog/acceptor';
import { IBulkOperationServiceProxy } from '@shared/service-proxies/interfaces/IBulkOperationServiceProxy';
import { AppComponentBase } from '@shared/common/app-component-base';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IBulkChangeAccessRestrictionInput, IBulkDetailsDto, IUserOrOrganizationUnitInput } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'app-mazars-bulk-change-access-restriction-modal',
    templateUrl: './mazars-bulk-change-access-restriction-modal.component.html',
})
export class MazarsBulkChangeAccessRestrictionModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('submitBulkAccesRestictionsModal', { static: true })
    modal: MazarsDialogComponent;

    @Input() bulkServiceProxy: IBulkOperationServiceProxy;
    @Input() grantsServiceProxy: any;

    form: FormGroup;
    details: IBulkDetailsDto;
    selectedItems: number[];
    selectedUsersAndOrganizationUnits: IUserOrOrganizationUnitInput[];
    private acceptor: Acceptor;
    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }
    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.form = this.fb.group({
            bulkAccessPermission: [],
        });
    }

    show(details: IBulkDetailsDto, selectedItems: number[]): DialogResult {
        this.details = details;
        this.selectedItems = selectedItems;
        this.form.reset();
        return this.modal.show();
    }

    canSubmit(): boolean {
        return this.selectedUsersAndOrganizationUnits?.length > 0;
    }

    beforeSubmit(acceptor: Acceptor): void {
        this.acceptor = acceptor;
        let bulkChangeAccessRestrictionInput = {
            ids: this.selectedItems,
            usersAndOrganizationUnits: this.selectedUsersAndOrganizationUnits,
        } as IBulkChangeAccessRestrictionInput;
        this.bulkServiceProxy
            .bulkCheckAccessRestriction(bulkChangeAccessRestrictionInput)
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    if (result) {
                        acceptor.accept(bulkChangeAccessRestrictionInput);
                    } else {
                        this.message.confirm('', this.l('BulkChangeAccessRestrictions_Losing_Access_Wraning'), (isConfirmed) => {
                            if (isConfirmed) {
                                acceptor.accept(bulkChangeAccessRestrictionInput);
                            } else {
                                acceptor?.reject();
                            }
                        });
                    }
                },
                error: () => {
                    this.acceptor?.reject();
                },
            });
    }

    onBulkAccessRestrictionChange(data) {
        this.selectedUsersAndOrganizationUnits = data?.provisionalData ? data?.provisionalData : [];
    }
}
