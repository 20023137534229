import { Component, Injector, Input } from '@angular/core';
import { MazarsDownloadService } from '@app/modules/mazars-common/services/mazars-download.service';
import { IFeedbackParsedResultDto } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';
import { IFeedbacksServiceProxy, ISubmissionItem } from '../../submission-interfaces';

@Component({
    selector: 'mazars-feedback-info-section',
    templateUrl: './mazars-feedback-info-section.component.html',
    styleUrls: ['./mazars-feedback-info-section.component.css'],
})
export class MazarsFeedbackInfoSectionComponent extends AppComponentBase {
    @Input() feedbackServiceProxy: IFeedbacksServiceProxy;
    @Input() submission: ISubmissionItem;
    @Input() hasEditPermission: boolean;
    @Input() hasDeletePermission: boolean;

    constructor(
        injector: Injector,
        private mazarsDownloadService: MazarsDownloadService,
        private dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    downloadPdf(): void {
        this.feedbackServiceProxy
            .downloadFinalFeedbackPdf(this.submission?.id, this.submission?.referenceId)
            .subscribe((res) => {
                this.mazarsDownloadService.triggerBrowserDownload(res);
            });
    }

    getDate(date: DateTime): string {
        return date ? this.dateTimeService.formatDate(date, 'dd.MM.yyyy') : '';
    }

    feedbackErrors(): IFeedbackParsedResultDto[] {
        return this.submission?.feedback?.parsedResult
            ? this.submission?.feedback?.parsedResult.filter((fd) => fd.type === 'error')
            : [];
    }

    feedbackWarnings(): IFeedbackParsedResultDto[] {
        return this.submission?.feedback?.parsedResult
            ? this.submission?.feedback?.parsedResult.filter((fd) => fd.type === 'info')
            : [];
    }

    feedbackSuccessful(): boolean {
        return this.feedbackErrors().length === 0;
    }
}
