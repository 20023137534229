import { Injectable, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITabMenuItem } from '@app/modules/mazars-common/components/mazars-tab-menu/tab-menu-interfaces';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ArchiveOperationType } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActionIcon } from '../mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { Observable, of } from 'rxjs';
import { IItem } from '../mazars-common/interfaces/item.interface';

@Injectable()
export abstract class RealPropertyTaxComponentBase extends AppComponentBase {
    subscriptions: Subscription[] = [];
    public absoluteModuleRootPath = '/app/real-property-tax';
    public absoluteEconomicUnitsPath: string = this.absoluteModuleRootPath + '/economic-units';
    public absoluteDeclarationsPath: string = this.absoluteModuleRootPath + '/declarations';
    public absoluteDeclarationsCreate: string = this.absoluteDeclarationsPath + '/create';
    public absoluteModuleRootPathProfiles: string = this.absoluteModuleRootPath + '/profiles';
    public absoluteModuleRootPathAdminSettings: string = this.absoluteModuleRootPath + '/admin-settings';
    public absoluteModuleRootPathMetadataConfiguration: string = this.absoluteModuleRootPath + '/metadata-configuration';
    public activatedRoute: ActivatedRoute;
    readonly ArchiveOperationType = ArchiveOperationType;

    rptTabMenuItems: ITabMenuItem[];
    tabMenuItems: ITabMenuItem[];
    additionalTabMenuItems: ITabMenuItem[];
    economicUnitId?: number;
    isInEconomicUnit = false;

    protected fb: FormBuilder;

    constructor(injector: Injector) {
        super(injector);
        this.localizationSourceName = AppConsts.localization.realPropertyTaxSourceName;
        this.fb = injector.get(FormBuilder);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.economicUnitId = this.activatedRoute.snapshot.params.euId;
        if (this.economicUnitId) {
            this.isInEconomicUnit = true;
        }

        this.rptTabMenuItems = [
            {
                id: 'mi-economic-units',
                label: this.l('EconomicUnits'),
                routerLink: this.absoluteEconomicUnitsPath,
                ignoreQueryParams: true,
            },
            {
                id: 'mi-declarations',
                label: this.l('TabDeclaration'),
                routerLink: this.absoluteDeclarationsPath,
                ignoreQueryParams: true,
            },
            {
                id: 'mi-profiles',
                label: this.ld('Profiles_TabName'),
                routerLink: this.absoluteModuleRootPathProfiles,
                hidden: !this.permission.isGranted('RealPropertyTax.Profiles') || !abp.features.isEnabled('RealPropertyTaxReportingServiceFeature'),
            },
            {
                id: 'mi-settings',
                label: '<i aria-hidden="true" class="fas fa-cog"></i>',
                labelAsHtml: true,
                routerLink: this.absoluteModuleRootPathAdminSettings,
                hidden: !this.permission.isGranted('RealPropertyTax.Admin'),
            },
            {
                id: 'mi-configurations',
                label: '<i aria-hidden="true" class="fas fa-wrench"></i>',
                labelAsHtml: true,
                routerLink: this.absoluteModuleRootPathMetadataConfiguration,
                hidden: !this.permission.isGranted('RealPropertyTax.Admin') || !abp.features.isEnabled('App.DocumentRevisionHistory'),
            },
        ];
        this.tabMenuItems = [
            {
                id: 'tab-general',
                label: this.l('TabGeneral'),
                icon: 'pi pi-check-circle',
                routerLink: `${this.absoluteEconomicUnitsPath}/${!this.economicUnitId ? 'create' : this.economicUnitId + '/general'}`,
                disabled: false,
            },
            {
                id: 'tab-declaration',
                label: this.l('TabDeclaration'),
                icon: 'pi pi-check-circle',
                routerLink: `${this.absoluteEconomicUnitsPath}/${this.economicUnitId}/declarations`,
                disabled: this.economicUnitId == null,
                ignoreQueryParams: true,
            },
            {
                id: 'tab-deadlines',
                label: this.l('DeadlinesTab_Name'),
                icon: 'pi pi-check-circle',
                routerLink: `${this.absoluteEconomicUnitsPath}/${this.economicUnitId}/deadlines`,
                disabled: this.economicUnitId == null,
            },
            {
                id: 'tab-attachment',
                label: this.l('TabAttachment'),
                icon: 'pi pi-check-circle',
                routerLink: `${this.absoluteEconomicUnitsPath}/${this.economicUnitId}/attachments`,
                disabled: this.economicUnitId == null,
            },
        ];
        this.additionalTabMenuItems = [
            {
                id: 'tab-communication',
                icon: ActionIcon.Comment,
                routerLink: `${this.absoluteEconomicUnitsPath}/${this.economicUnitId}/communications`,
                disabled: this.economicUnitId == null,
                showIconCounter: this.economicUnitId != null,
                tooltip: this.ld('Channels_Tooltip'),
            },
        ];
    }

    ld(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }

    getRadioArchiveItems(): Observable<IItem<number>[]> {
        return of([
            {
                id: this.ArchiveOperationType.Archive,
                text: this.l('Action_Popup_ArchiveAll_EU_Option'),
            },
            {
                id: this.ArchiveOperationType.Unarchive,
                text: this.l('Action_Popup_UnarchiveAll_EU_Option'),
            },
        ]);
    }
}
