import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MazarsActionsDropdownMenuService {

    onActionMenuOpen: Subject<string> = new Subject<string>();

    constructor() {}
}
