import { CheckResultStatus } from '../../pillar2-proxies';

export class CheckResultSettings {
    static getDataIcon(status: CheckResultStatus | string): string {
        switch (status) {
            case CheckResultStatus.Passed: {
                return 'pi pi-check-circle icon-green';
            }
            case CheckResultStatus.Failed: {
                return 'pi pi-times-circle icon-red';
            }
            case CheckResultStatus.NotComputable: {
                return 'pi pi-question-circle icon-orange';
            }
            default: {
                return 'pi pi-question-circle icon-orange';
            }
        }
    }

    static getIconColor(status: CheckResultStatus | string): string {
        switch (status) {
            case CheckResultStatus.Passed: {
                return 'var(--bs-green)';
            }
            case CheckResultStatus.Failed: {
                return 'var(--bs-red)';
            }
            case CheckResultStatus.NotComputable: {
                return 'var(--bs-orange)';
            }
            default: {
                return '';
            }
        }
    }
}
