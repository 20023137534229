import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AddRowCellRendererParams } from './add-row-cell-renderer-params';

@Component({
    selector: 'add-row-cell-renderer',
    template: `
        <i class="fas fa-plus icon-btn-sm pb-0" aria-hidden="true" (click)="onAddRowClick($event)"></i>
    `,
})
export class AddRowCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParams;
    addRow: (params: any) => void;

    refresh(): boolean {
        return false;
    }

    agInit(params: ICellRendererParams & AddRowCellRendererParams): void {
        this.params = params;
        this.addRow = params.addRow;
    }

    onAddRowClick(event) {
        this.addRow(this.params);
    }
}
