import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notificationDisplayName',
})
export class NotificationDisplayNamePipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): string {
        let name = value.replace('.', '_');
        if (name.endsWith('_News')) {
            name = 'Recieve_Overall_News';
        }
        return name;
    }
}
