import { Injector, Component, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './mazars-burgermenu.component.html',
    selector: 'mazars-burgermenu',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsBurgerMenuComponent extends AppComponentBase {
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
    
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    isBurgermenuOpen: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    toggleNavigation() {
        this.isBurgermenuOpen = !this.isBurgermenuOpen;
        this.toggle.emit(this.isBurgermenuOpen);
    }
}
