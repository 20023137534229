<input
    #textInput
    pInputText
    type="text"
    id="{{ uid }}"
    name="{{ uid }}"
    class="form-control"
    placeholder="{{ placeholder }}"
    [style.padding]="padding"
    [attr.data-testid]="uid"
    [(ngModel)]="value"
    [disabled]="isDisabled || disabled"
    (blur)="onBlurChange()"
    (input)="onInputChange()"
    [mazarsTooltip]="tooltipText"
    [escape]="false"
    tooltipPosition="bottom"
    [style]="inlineStyle"
/>
