import { Inject, Injectable } from '@angular/core';
import { createAction, props } from '@ngrx/store';
import { AppModules, IModule } from '@shared/AppEnums';
import {
    ICommunicationsFilterRequest,
    ISearchCommunicationDto,
    IChannelOverviewDto,
    ICommunicationDto,
    IAuthorDto,
    IInitiateEmailConversationInput,
    ICreateChannelInput,
    IEditChannelInput,
    ICreateCommunicationInput,
    IEditCommunicationInput,
    IWhiteListOverviewDto,
    ISaveAllWhiteListInput,
} from '@shared/service-proxies/common-interfaces';

export enum CommonCommunicationsActionsNames {
    Init = '[Communications] Task Communications Init',
    SearchCommunicationsRequest = '[Communications] Search Communications Request',
    SearchCommunicationsSuccess = '[Communications] Search Communications Success',
    SearchCommunicationsFailure = '[Communications] Search Communications Failure',
    GetAllChannelsRequest = '[Communications] Get All Channels Request',
    GetAllChannelsSuccess = '[Communications] Get All Channels Success',
    GetAllChannelsFailure = '[Communications] Get All Channels Failure',
    GetAllChannelCommunicationsRequest = '[Communications] Get All Channel Communications Request',
    GetAllChannelCommunicationsSuccess = '[Communications] Get All Channel Communications Success',
    GetAllChannelCommunicationsFailure = '[Communications] Get All Channel Communications Failure',
    GetAllAuthorsRequest = '[Communications] Get Authors Request',
    GetAllAuthorsSuccess = '[Communications] Get Authors Success',
    GetAllAuthorsFailure = '[Communications] Get Authors Failure',
    InitiateEmailCommunicationRequest = '[Communications] Initiate Email Communication Request',
    InitiateEmailCommunicationSuccess = '[Communications] Initiate Email Communication Success',
    InitiateEmailCommunicationFailure = '[Communications] Initiate Email Communication Failure',
    CreateChannelRequest = '[Communications] Create Channel Request',
    CreateChannelSuccess = '[Communications] Create Channel Success',
    CreateChannelFailure = '[Communications] Create Channel Failure',
    UpdateChannelRequest = '[Communications] Update Channel Request',
    UpdateChannelSuccess = '[Communications] Update Channel Success',
    UpdateChannelFailure = '[Communications] Update Channel Failure',
    DeleteChannelRequest = '[Communications] Delete Channel Request',
    DeleteChannelSuccess = '[Communications] Delete Channel Success',
    DeleteChannelFailure = '[Communications] Delete Channel Failure',
    GetByIdCommunicationRequest = '[Communications] Get By Id Communication Request',
    GetByIdCommunicationSuccess = '[Communications] Get By Id Communication Success',
    GetByIdCommunicationFailure = '[Communications] Get By Id Communication Failure',
    CreateCommunicationRequest = '[Communications] Create Communication Request',
    CreateCommunicationSuccess = '[Communications] Create Communication Success',
    CreateCommunicationFailure = '[Communications] Create Communication Failure',
    UpdateCommunicationRequest = '[Communications] Update Communication Request',
    UpdateCommunicationSuccess = '[Communications] Update Communication Success',
    UpdateCommunicationFailure = '[Communications] Update Communication Failure',
    DeleteCommunicationRequest = '[Communications] Delete Communication Request',
    DeleteCommunicationSuccess = '[Communications] Delete Communication Success',
    DeleteCommunicationFailure = '[Communications] Delete Communication Failure',
    GetAllWhitelistRequest = '[Communications] Get All Whitelist Request',
    GetAllWhitelistSuccess = '[Communications] Get All Whitelist Success',
    GetAllWhitelistFailure = '[Communications] Get All Whitelist Failure',
    SaveAllWhitelistRequest = '[Communications] Save All Whitelist Request',
    SaveAllWhitelistSuccess = '[Communications] Save All Whitelist Success',
    SaveAllWhitelistFailure = '[Communications] Save All Whitelist Failure',
    GetNumberOfUnreadChannelsRequest = '[Communications] Get Number Of Unread Channels Request',
    GetNumberOfUnreadChannelsSuccess = '[Communications] Get Number Of Unread Channels Success',
    GetNumberOfUnreadChannelsFailure = '[Communications] Get Number Of Unread Channels Failure',

    CurrentChannelChange = '[Communications] Current channel change',
    ClearCurrentChannel = '[Communications] Clear Current channel',
    CommunicationMessageReceived = '[Communications] Message Received',
}

@Injectable()
export class CommonCommunicationsActions {
    readonly Init = createAction(`[${this.module}]${CommonCommunicationsActionsNames.Init}`);

    readonly CurrentChannelChange = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.CurrentChannelChange}`,
        props<{ input: number }>()
    );

    readonly ClearCurrentChannel = createAction(`[${this.module}]${CommonCommunicationsActionsNames.ClearCurrentChannel}`);

    readonly CommunicationMessageRecieved = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.CommunicationMessageReceived}`,
        props<{ input: ICommunicationDto }>()
    );

    readonly SearchCommunicationsRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.SearchCommunicationsRequest}`,
        props<{ input: ICommunicationsFilterRequest }>()
    );
    readonly SearchCommunicationsSuccess = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.SearchCommunicationsSuccess}`,
        props<{ data: ISearchCommunicationDto[] }>()
    );

    readonly SearchCommunicationsFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.SearchCommunicationsFailure}`,
        props<{ error: any }>()
    );

    readonly GetAllChannelsRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetAllChannelsRequest}`,
        props<{ input: number }>()
    );
    readonly GetAllChannelsSuccess = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetAllChannelsSuccess}`,
        props<{ data: IChannelOverviewDto[] }>()
    );

    readonly GetAllChannelsFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetAllChannelsFailure}`, props<{ error: any }>());

    readonly GetAllChannelCommunicationsRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetAllChannelCommunicationsRequest}`,
        props<{ input: number }>()
    );
    readonly GetAllChannelCommunicationsSuccess = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetAllChannelCommunicationsSuccess}`,
        props<{ data: ICommunicationDto[]; input: number }>()
    );

    readonly GetAllChannelCommunicationsFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetAllChannelCommunicationsFailure}`,
        props<{ error: any }>()
    );

    readonly GetAllAuthorsRequest = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetAllAuthorsRequest}`);

    readonly GetAllAuthorsSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetAllAuthorsSuccess}`, props<{ data: IAuthorDto[] }>());

    readonly GetAllAuthorsFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetAllAuthorsFailure}`, props<{ error: any }>());

    readonly InitiateEmailCommunicationRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.InitiateEmailCommunicationRequest}`,
        props<{ input: IInitiateEmailConversationInput }>()
    );

    readonly InitiateEmailCommunicationSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.InitiateEmailCommunicationSuccess}`);

    readonly InitiateEmailCommunicationFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.InitiateEmailCommunicationFailure}`,
        props<{ error: any }>()
    );

    readonly CreateChannelRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.CreateChannelRequest}`,
        props<{ input: ICreateChannelInput }>()
    );

    readonly CreateChannelSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.CreateChannelSuccess}`);

    readonly CreateChannelFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.CreateChannelFailure}`, props<{ error: any }>());

    readonly UpdateChannelRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.UpdateChannelRequest}`,
        props<{ input: IEditChannelInput }>()
    );

    readonly UpdateChannelSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.UpdateChannelSuccess}`);

    readonly UpdateChannelFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.UpdateChannelFailure}`, props<{ error: any }>());

    readonly DeleteChannelRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.DeleteChannelRequest}`,
        props<{ id: number; referenceId: number }>()
    );

    readonly DeleteChannelSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.DeleteChannelSuccess}`);

    readonly DeleteChannelFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.DeleteChannelFailure}`, props<{ error: any }>());

    readonly GetByIdCommunicationRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetByIdCommunicationRequest}`,
        props<{ input: number }>()
    );

    readonly GetByIdCommunicationSuccess = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetByIdCommunicationSuccess}`,
        props<{ data: ICommunicationDto }>()
    );

    readonly GetByIdCommunicationFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetByIdCommunicationFailure}`,
        props<{ error: any }>()
    );

    readonly CreateCommunicationRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.CreateCommunicationRequest}`,
        props<{ input: ICreateCommunicationInput }>()
    );

    readonly CreateCommunicationSuccess = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.CreateCommunicationSuccess}`,
        props<{ data: number }>()
    );

    readonly CreateCommunicationFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.CreateCommunicationFailure}`,
        props<{ error: any }>()
    );

    readonly UpdateCommunicationRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.UpdateCommunicationRequest}`,
        props<{ input: IEditCommunicationInput }>()
    );

    readonly UpdateCommunicationSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.UpdateCommunicationSuccess}`);

    readonly UpdateCommunicationFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.UpdateCommunicationFailure}`,
        props<{ error: any }>()
    );

    readonly DeleteCommunicationRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.DeleteCommunicationRequest}`,
        props<{ id: number }>()
    );

    readonly DeleteCommunicationSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.DeleteCommunicationSuccess}`);

    readonly DeleteCommunicationFailure = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.DeleteCommunicationFailure}`,
        props<{ error: any }>()
    );

    readonly GetAllWhitelistRequest = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetAllWhitelistRequest}`, props<{ input: number }>());
    readonly GetAllWhitelistSuccess = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetAllWhitelistSuccess}`,
        props<{ data: IWhiteListOverviewDto[] }>()
    );

    readonly GetAllWhitelistFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetAllWhitelistFailure}`, props<{ error: any }>());

    readonly SaveAllWhitelistRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.SaveAllWhitelistRequest}`,
        props<{ input: ISaveAllWhiteListInput }>()
    );

    readonly SaveAllWhitelistSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.SaveAllWhitelistSuccess}`);

    readonly SaveAllWhitelistFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.SaveAllWhitelistFailure}`, props<{ error: any }>());


    readonly GetNumberOfUnreadChannelsRequest = createAction(
        `[${this.module}]${CommonCommunicationsActionsNames.GetNumberOfUnreadChannelsRequest}`,
        props<{ input: number | undefined }>()
    );

    readonly GetNumberOfUnreadChannelsSuccess = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetNumberOfUnreadChannelsSuccess}`, props<{ data: number }>());

    readonly GetNumberOfUnreadChannelsFailure = createAction(`[${this.module}]${CommonCommunicationsActionsNames.GetNumberOfUnreadChannelsFailure}`, props<{ error: any }>());

    constructor(@Inject(IModule) private module: AppModules) {}
}
