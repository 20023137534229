import { Inject, Injectable } from '@angular/core';
import { UserReferenceDataDto } from '@app/modules/account-mapping/account-mapping-proxies';
import { FileParameter } from '@app/modules/master-data-management/master-data-management-proxies';
import { createAction, props } from '@ngrx/store';
import { AppModules, IModule } from '@shared/AppEnums';
import {
    IAttachmentDto,
    IAttachmentsFilterRequest,
    ICreateAttachmentInput,
    IFileDownloadDto,
    IPagedResultDtoOfAttachmentOverviewDto,
    IUpdateAttachmentInput,
} from '@shared/service-proxies/common-interfaces';

export enum CommonAttachmentsActionsNames {
    Init = '[Attachments] Task Attachments Init',
    GetAllAttachmentsRequest = '[Attachments] Get All Attachments Request',
    GetAllAttachmentsSuccess = '[Attachments] Get All Attachments Success',
    GetAllAttachmentsFailure = '[Attachments] Get All Attachments Failure',

    GetAllTagsRequest = '[Attachments] Get Tags Request',
    GetAllTagsSuccess = '[Attachments] Get Tags Success',
    GetAllTagsFailure = '[Attachments] Get Tags Failure',

    GetAllowedFileTypesRequest = '[Attachments] Get Allowed File Types Request',
    GetAllowedFileTypesSuccess = '[Attachments] Get Allowed File Types Success',
    GetAllowedFileTypesFailure = '[Attachments] Get Allowed File Types Failure',

    UploadFileRequest = '[Attachments] Upload File Request',
    UploadFileSuccess = '[Attachments] Upload File Success',
    UploadFileFailure = '[Attachments] Upload File Failure',

    DownloadFileRequest = '[Attachments] Download File Request',
    DownloadFileSuccess = '[Attachments] Download File Success',
    DownloadFileFailure = '[Attachments] Download File Failure',

    PreviewFileRequest = '[Attachments] Preview File Request',
    PreviewFileSuccess = '[Attachments] Preview File Success',
    PreviewFileFailure = '[Attachments] Preview File Failure',

    GetByIdAttachmentRequest = '[Attachments] Get By Id Attachment Request',
    GetByIdAttachmentSuccess = '[Attachments] Get By Id Attachment Success',
    GetByIdAttachmentFailure = '[Attachments] Get By Id Attachment Failure',

    CreateAttachmentRequest = '[Attachments] Create Attachment Request',
    CreateAttachmentSuccess = '[Attachments] Create Attachment Success',
    CreateAttachmentFailure = '[Attachments] Create Attachment Failure',

    UpdateAttachmentRequest = '[Attachments] Update Attachment Request',
    UpdateAttachmentSuccess = '[Attachments] Update Attachment Success',
    UpdateAttachmentFailure = '[Attachments] Update Attachment Failure',

    DeleteAttachmentRequest = '[Attachments] Delete Attachment Request',
    DeleteAttachmentSuccess = '[Attachments] Delete Attachment Success',
    DeleteAttachmentFailure = '[Attachments] Delete Attachment Failure',

    GetCreatedByUsersRequest = '[Attachments] Get Created By Users Request',
    GetCreatedByUsersSuccess = '[Attachments] Get Created By Users Success',
    GetCreatedByUsersFailure = '[Attachments] Get Created By Users Failure',
}

@Injectable()
export class CommonAttachmentsActions {
    readonly Init = createAction(`[${this.module}]${CommonAttachmentsActionsNames.Init}`);

    readonly GetAllAttachmentsRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetAllAttachmentsRequest}`,
        props<{ input: IAttachmentsFilterRequest }>()
    );
    readonly GetAllAttachmentsSuccess = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetAllAttachmentsSuccess}`,
        props<{ data: IPagedResultDtoOfAttachmentOverviewDto }>()
    );

    readonly GetAllAttachmentsFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetAllAttachmentsFailure}`, props<{ error: any }>());

    readonly GetAllTagsRequest = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetAllTagsRequest}`, props<{ filter: string }>());

    readonly GetAllTagsSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetAllTagsSuccess}`, props<{ data: string[] }>());

    readonly GetAllTagsFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetAllTagsFailure}`, props<{ error: any }>());

    readonly GetAllowedFileTypesRequest = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetAllowedFileTypesRequest}`);

    readonly GetAllowedFileTypesSuccess = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetAllowedFileTypesSuccess}`,
        props<{ data: string[] }>()
    );

    readonly GetAllowedFileTypesFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetAllowedFileTypesFailure}`, props<{ error: any }>());

    readonly UploadFileRequest = createAction(`[${this.module}]${CommonAttachmentsActionsNames.UploadFileRequest}`, props<{ input: FileParameter }>());

    readonly UploadFileSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.UploadFileSuccess}`, props<{ data: string }>());

    readonly UploadFileFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.UploadFileFailure}`, props<{ error: any }>());

    readonly DownloadFileRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.DownloadFileRequest}`,
        props<{ referenceId: number; id: number }>()
    );

    readonly DownloadFileSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.DownloadFileSuccess}`, props<{ data: IFileDownloadDto }>());

    readonly DownloadFileFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.DownloadFileFailure}`, props<{ error: any }>());

    readonly PreviewFileRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.PreviewFileRequest}`,
        props<{ referenceId: number; id: number }>()
    );

    readonly PreviewFileSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.PreviewFileSuccess}`, props<{ data: IFileDownloadDto }>());

    readonly PreviewFileFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.PreviewFileFailure}`, props<{ error: any }>());


    readonly GetByIdAttachmentRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetByIdAttachmentRequest}`,
        props<{ referenceId: number; id: number }>()
    );

    readonly GetByIdAttachmentSuccess = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetByIdAttachmentSuccess}`,
        props<{ data: IAttachmentDto }>()
    );

    readonly GetByIdAttachmentFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetByIdAttachmentFailure}`, props<{ error: any }>());

    readonly CreateAttachmentRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.CreateAttachmentRequest}`,
        props<{ input: ICreateAttachmentInput }>()
    );

    readonly CreateAttachmentSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.CreateAttachmentSuccess}`, props<{ data: number }>());

    readonly CreateAttachmentFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.CreateAttachmentFailure}`, props<{ error: any }>());

    readonly UpdateAttachmentRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.UpdateAttachmentRequest}`,
        props<{ input: IUpdateAttachmentInput }>()
    );

    readonly UpdateAttachmentSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.UpdateAttachmentSuccess}`);

    readonly UpdateAttachmentFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.UpdateAttachmentFailure}`, props<{ error: any }>());

    readonly DeleteAttachmentRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.DeleteAttachmentRequest}`,
        props<{ referenceId: number; id: number }>()
    );

    readonly DeleteAttachmentSuccess = createAction(`[${this.module}]${CommonAttachmentsActionsNames.DeleteAttachmentSuccess}`);

    readonly DeleteAttachmentFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.DeleteAttachmentFailure}`, props<{ error: any }>());

    readonly GetCreatedByUsersRequest = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetCreatedByUsersRequest}`,
        props<{ referenceId: number}>()
    );

    readonly GetCreatedByUsersSuccess = createAction(
        `[${this.module}]${CommonAttachmentsActionsNames.GetCreatedByUsersSuccess}`,
        props<{ data: UserReferenceDataDto }>()
    );

    readonly GetCreatedByUsersFailure = createAction(`[${this.module}]${CommonAttachmentsActionsNames.GetCreatedByUsersFailure}`, props<{ error: any }>());

    constructor(@Inject(IModule) private module: AppModules) {}
}
