import { Component, ViewChild, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { Acceptor } from '../mazars-dialog/acceptor';
import { ManageTagsDetailsDto, TagDto } from '@app/modules/real-property-tax/real-property-tax-proxies';

@Component({
    selector: 'mazars-manage-tags-modal',
    templateUrl: './mazars-manage-tags-modal.component.html',
})
export class MazarsManageTagsModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('manageTagsModal', { static: true }) modal: MazarsDialogComponent;
    @Input('uid') uid: string;

    form: FormGroup;
    availableTags: string[];
    nonUniformTags: string[] = [];
    uniformTags: TagDto[] = []
    referenceDataLoading: boolean;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
    }

    show(details: ManageTagsDetailsDto): DialogResult {
        this.form.reset();
        if (details) {
            this.nonUniformTags = details.nonUniformTags;
            this.form.patchValue({ tags: details.uniformTags });
            this.availableTags = details.availableTags;
        }
        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor): void {
        let result = this.form.value;
        acceptor.accept(result);
    }

    private createForm(): void {
        this.form = this.fb.group({
            tags: [null],
        });
    }
}
