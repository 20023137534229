import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MappingReferenceDataService } from '../mappings/mapping-reference-data.service';

type NewType = Observable<any>;

@Injectable()
export class MappingReferenceDataResolver implements Resolve<any> {
    constructor(private mappingReferenceDataService: MappingReferenceDataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): NewType | Promise<any> | any {
        this.mappingReferenceDataService.load();
        return true;
    }
}
