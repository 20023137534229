<mz-create-update-dialog
    #dialog
    [uid]="uid + '-communication'"
    [titleForCreate]="l('Create')"
    [titleForUpdate]="l('Edit')"
    [canSubmit]="form?.touched && form?.valid"
    [createUpdateFacade]="communicationCreateUpdateFacade"
    [createFormCallback]="createFormCallback"
    [model2FormCallback]="model2FormCallback"
    [form2CreateModelCallback]="form2CreateModelCallback"
    [form2UpdateModelCallback]="form2UpdateModelCallback"
    bodyStyleClass="modal-body p-0"
>
    <form [formGroup]="form" *ngIf="form != null" (ngSubmit)="dialog.submit()" autocomplete="off">
        <div *ngIf="!limitEdit" class="form-group card-select">
            <label for="Type" class="mb-4 text-color-primary">{{ l('Type') }}</label>
            <app-mazars-card-input
                [uid]="'card-select-' + communicationId"
                [options]="typeItems"
                [formControl]="form.controls.type"
                [hasEmpty]="false"
            ></app-mazars-card-input>
        </div>
        <div class="content text-color-primary">
            <div *ngIf="!limitEdit" class="form-group">
                <label for="Participants">{{ l('Participants') }}</label>
                <app-mazars-input-text [uid]="'Participants'" [formControl]="form.controls.participants"></app-mazars-input-text>
                <app-mazars-validation-message
                    [uid]="'Participants'"
                    [key]="'Participants'"
                    [control]="form.controls.participants"
                    [submitting]="validation"
                ></app-mazars-validation-message>
            </div>

            <div *ngIf="!limitEdit" class="form-group">
                <label for="Subject">{{ l('Subject') }}</label>
                <app-mazars-input-text [uid]="'Subject'" [formControl]="form.controls.subject"></app-mazars-input-text>
                <app-mazars-validation-message [uid]="'Subject'" [key]="'Subject'" [control]="form.controls.subject" [submitting]="validation"></app-mazars-validation-message>
            </div>

            <div *ngIf="!limitEdit" class="form-group">
                <label for="Date">{{ l('Date') }}</label>
                <app-mazars-datetimepicker [uid]="'Date'" [placeholder]="l('Date')" [formControl]="form.controls.date"></app-mazars-datetimepicker>
                <app-mazars-validation-message [uid]="'Date'" [key]="'Date'" [control]="form.controls.date" [submitting]="validation"></app-mazars-validation-message>
            </div>

            <div class="form-group">
                <label for="Tags">{{ l('Tags') }}</label>
                <mazars-tags uid="Tags" placeholder="{{ l('Tags') }}" [availableTags]="availableTags" [formControl]="form.controls.tags"></mazars-tags>
            </div>

            <div *ngIf="!limitEdit" class="form-group">
                <mazars-editor
                    #meditor
                    [uid]="'Content'"
                    [commonQuillMentionService]="commonQuillMentionService"
                    [availableShortcuts]="availableShortcuts"
                    [formControl]="form.controls.content"
                ></mazars-editor>
            </div>
        </div>
    </form>
</mz-create-update-dialog>
