<div class="d-flex justify-content-end" *ngIf="isVisible && !isEditing && canBeEdited">
    <app-mazars-actions-menu [uid]="'acr' + record?.id" [actionItems]="actionItems"></app-mazars-actions-menu>
</div>

<div class="d-flex justify-content-end align-items-center row-height" *ngIf="isEditing">
    <i *ngIf="isEditing" (click)="stopEditing(false)" aria-hidden="true" class="pi pi-check mz-green ml-2"></i>
    <i *ngIf="isEditing" (click)="stopEditing(true)" aria-hidden="true" class="pi pi-times mz-red ml-2"></i>
</div>
<div class="d-flex justify-content-center align-items-center row-height" *ngIf="showDeleteRow">
    <i *ngIf="showDeleteRow" class="pi pi-trash icon-btn-sm" style="font-size: 0.9rem" aria-hidden="true" (click)="onDeleteRowClick($event)"></i>
</div>
