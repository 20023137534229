<div class="mz-container">
    <app-mazars-grid #mazarsTable [uid]="'soverview'" [primengTableHelper]="primengTableHelper"
        [actionItems]="actionItems" [bulkOperationService]="bulkOperationService"
        [headerActionItems]="headerActionItems" [isActionColumnHidden]="false"
        [columnDefinitions]="columnDefinitions" (getAllRecords)="getSurveys($event)" 
        [hasGridSettings]="false">
        <ng-template mazarsTemplate="nameTemplate" let-option>
            <div class="d-flex">
                <div class="d-flex align-items-center" [mazarsTooltip]="option.name">
                    <a class="underline-link" [routerLink]="this.redirectPath + '/' + option.id">
                        {{ option.name }}
                    </a>
                </div>
            </div>
        </ng-template>
        <ng-template mazarsTemplate="taglistTemplate" let-option>
            <mazars-tags-list [tagModel]="option.tags" [uid]="'surveys-taglist-' + option.id"></mazars-tags-list>
        </ng-template>

        <ng-template mazarsTemplate="statusTemplate" let-option>
            <span class="label-inline" *ngIf="option.isDisabled">{{ ld('Inactive') }}</span>
            <span class="label-inline" *ngIf="!option.isDisabled">{{ ld('Active') }}</span>
        </ng-template>
    </app-mazars-grid>
</div>
