import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class MazarsChatSignalrService extends AppComponentBase {
    communicationsHub: HubConnection;
    isHubConnected = false;

    constructor(injector: Injector, public _zone: NgZone) {
        super(injector);
    }

    configureConnection(connection): void {
        // Set the common hub
        this.communicationsHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    reject();
                } else {
                    connection
                        .start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose((e) => {
            this.isHubConnected = false;

            if (e) {
                abp.log.debug('Communications hub connection closed with error: ' + e);
            } else {
                abp.log.debug('Communications hub disconnected');
            }

            start().then(() => {
                this.isHubConnected = true;
            });
        });

        // Register to get communication messages
        this.registerCommunicationsHubEvents(connection);
    }

    registerCommunicationsHubEvents(connection): void {
        connection.on('Pillar2ProjectCommunicationMessage', (message) => {
            abp.event.trigger('app.pillar2.projectCommunication.messageRecieved', message);
        });

        connection.on('ChecklistsWorkflowCommunicationMessage', (message) => {
            abp.event.trigger('app.checklists.workflowCommunication.messageRecieved', message);
        });

        connection.on('Dac6ReportCommunicationMessage', (message) => {
            abp.event.trigger('app.dac6.reportCommunication.messageRecieved', message);
        });

        connection.on('RealPropertyTaxEconomicUnitCommunicationMessage', (message) => {
            abp.event.trigger('app.realPropertyTax.economicUnit.messageRecieved', message);
        });

        connection.on('AccountMappingMappingCommunicationMessage', (message) => {
            abp.event.trigger('app.accountMapping.mapping.messageRecieved', message);
        });
    }

    init(): void {
        console.log('init communications hub');
        this._zone.runOutsideAngular(() => {
            abp.signalr
                .startConnection(abp.appPath + 'signalr-communications', (connection) => {
                    this.configureConnection(connection);
                })
                .then(() => {
                    this.isHubConnected = true;
                });
        });
    }
}
