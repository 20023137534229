import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SecurityContext, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PermissionCheckerService } from 'abp-ng2-module';

@Component({
    selector: 'app-mazars-input-password',
    templateUrl: './mazars-input-password.component.html',
    styleUrls: ['./mazars-input-password.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MazarsInputPasswordComponent),
            multi: true,
        },
    ],
})
export class MazarsInputPasswordComponent implements ControlValueAccessor, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() placeholder?: string;
    @Input() isDisabled: boolean;
    @Input() padding: string;
    @Input() tooltipText: string;
    @Input() inputStyleClass = 'form-control';
    @Input() styleClass = 'd-block w-100';
    @Input() maskPermission: string;
    @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();

    innerValue: string;
    securityContext = SecurityContext;
    showMask = true;
    permission: any;

    constructor(permissionChecker: PermissionCheckerService) {
        this.permission = permissionChecker;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maskPermission.currentValue) {
            this.showMask = this.permission.isGranted(changes.maskPermission.currentValue);
        }
    }

    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChange(this.innerValue);
        }
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlurChange() {
        this.onTouched();
        this.onBlur.emit();
    }
}
