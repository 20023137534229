<div class="d-flex flex-column position-relative languages-container" (click)="toggleLanguagesMenu()" [attr.data-testid]="'toggle_language_menu'">
    <div class="d-flex p-4">
        <i aria-hidden="true" [class]="currentLanguage.icon"></i>
    </div>
    <div [ngClass]="{ show: isSubMenuActive }" class="fw-bold fs-7 languages-sub-container position-absolute menu-state-bg-light-primary">
        <div class="menu-item px-3" *ngFor="let language of languages">
            <a href="javascript:;" (click)="changeLanguage(language.name)" class="menu-link px-3" [attr.data-testid]="'change_language_menu_link'">
                <span class="d-flex symbol symbol-20px me-4">
                    <i aria-hidden="true" [class]="language.icon"></i>
                </span>
                {{ language.displayName }}
            </a>
        </div>
    </div>
</div>