import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable } from 'rxjs/internal/Observable';
import { of, Subject } from 'rxjs';
import { TableStateService } from '@shared/utils/table-state.service';
import { AppAuthService } from './app-auth.service';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _refreshTokenService: RefreshTokenService,
        private _tableStateService: TableStateService,
        private _appAuthService: AppAuthService,
    ) {}

    canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        if (UrlHelper.isInstallUrl(location.href)) {
            return of(true);
        }

        if (!this._sessionService.user) {
            let sessionObservable = new Subject<any>();
            this._refreshTokenService.tryAuthWithRefreshToken().subscribe(
                (autResult: boolean) => {
                    if (autResult) {
                        sessionObservable.next(true);
                        sessionObservable.complete();
                        location.reload();
                    } else {
                        sessionObservable.next(false);
                        sessionObservable.complete();
                        this._tableStateService.clearAllTableState();
                        this.redirectToStartPage();
                    }
                },
                (error) => {
                    sessionObservable.next(false);
                    sessionObservable.complete();
                    this._tableStateService.clearAllTableState();
                    this.redirectToStartPage();
                },
            );
            return sessionObservable;
        } else {
            //Added by mazars
            const tenancyName = state?.root?.queryParams?.tn;
            const sessionTenancyName = this._sessionService.tenant?.tenancyName ?? '';

            //Check if there is tenancy query parameter and if it's different from the session tenant
            if (tenancyName || tenancyName === '') {
                if (tenancyName != sessionTenancyName) {
                    this._appAuthService.logout(true, UrlHelper.initialUrl);
                } else {
                    const params = { ...state.root.queryParams };
                    delete params.tn;
                    this._router.navigate([state.url.split('?')[0]], { queryParams: params });
                }

                return of(false);
            }
        }

        if (!data || !data['permission']) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    //Added by Mazars
    //Redirect to start.digitaltaxportal.com only if environment is production and user is not logged in
    redirectToStartPage() {
        if (location?.host === 'www.digitaltaxportal.com' || location?.host === 'digitaltaxportal.com') {
            window.location.href = 'https://start.digitaltaxportal.com/';
        } else {
            this._router.navigate(['/account/login']);
        }
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            if (location?.host === 'www.digitaltaxportal.com' || location?.host === 'digitaltaxportal.com') {
                window.location.href = 'https://start.digitaltaxportal.com/';
                return '/';
            } else {
                return '/account/login';
            }
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
            return '/app/admin/hostDashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
            return '/app/main/dashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }

        return '/app/notifications';
    }
}
