export enum MainMenuTab {
    Forms = 'mi-forms',
    DynamicTemplates = 'mi-dynamic-templates',
    Settings = 'mi-settings',
    Profiles = 'mi-profiles',
}

export enum DynamicTemplatesMenuTab {
    General = 'mi-general',
    StructureConfiguration = 'mi-structure-configuration',
    LabelsConfiguration = 'mi-labels-configuration',
    Preview = 'mi-preview'
}

export enum FormsMenuTab {
    General = 'mi-general',
    FormData = 'mi-form-data',
    Communication = 'mi-communications',
    AdditionalHistory = 'mi-additional-history',
    Submission = 'mi-submission',
    Attachments = 'mi-attachments',
}

export type IroMenuTab = MainMenuTab | DynamicTemplatesMenuTab | FormsMenuTab;
	