import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IMazarsColumnDefinitionDto, IMazarsGridDefinitionDto, IMazarsGridDefinitionInput } from '@shared/service-proxies/common-interfaces';
import { Observable } from 'rxjs';
import { CommonGridConfigurationActions } from '../actions/common-grid-configuration.actions';
import { CommonGridConfigurationSelectors, CommonGridConfigurationSelectorsToken } from '../selectors/common-grid-configuration.selectors';
import { CommonGridConfigurationState } from '../state/common-grid-configuration.state';

@Injectable()
export class CommonGridConfigurationFacade {
    public readonly gridColumnSettings$: Observable<IMazarsGridDefinitionDto> = this.store?.pipe(select(this.selectors?.getGridColumnSettings));
    public readonly sorting$: Observable<string> = this.store?.pipe(select(this.selectors?.getSorting));
    public readonly loaded$: Observable<boolean> = this.store?.pipe(select(this.selectors?.loaded));
    public readonly setGridColumnSettings$ = this.actions$?.pipe(ofType(this.commonGridConfigurationActions?.SetGridColumnSettingsSuccess));

    public readonly resetGridColumnSettings$ = this.actions$?.pipe(ofType(this.commonGridConfigurationActions?.ResetGridColumnSettingsSuccess));

    public readonly gridColumnSettingsConfigChanged$: Observable<IMazarsGridDefinitionDto> = this.actions$?.pipe(
        ofType(this.commonGridConfigurationActions?.ChangeGridColumnSettingsConfig)
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<CommonGridConfigurationState>,
        @Inject(CommonGridConfigurationSelectorsToken) private readonly selectors: CommonGridConfigurationSelectors,
        private commonGridConfigurationActions: CommonGridConfigurationActions
    ) {}

    getGridColumnSettings(input: string) {
        this.store?.dispatch(this.commonGridConfigurationActions?.GetGridColumnSettingsRequest({ input }));
    }

    setGridColumnSettings(input: IMazarsGridDefinitionInput | undefined) {
        this.store?.dispatch(this.commonGridConfigurationActions?.SetGridColumnSettingsRequest({ input }));
    }

    resetGridColumnSettings(input: string) {
        this.store.dispatch(this.commonGridConfigurationActions?.ResetGridColumnSettingsRequest({ input }));
    }

    changeGridColumnConfig(data: IMazarsGridDefinitionDto) {
        this.store?.dispatch(this.commonGridConfigurationActions?.ChangeGridColumnSettingsConfig({ data }));
    }

    setSorting(input: string) {
        this.store?.dispatch(this.commonGridConfigurationActions.SetSorting( { input }));
    }

    clearData() {
        this.store?.dispatch(this.commonGridConfigurationActions.ClearData());
    }
}
