<div class="card">
    <div class="mz-container mz-grid primeng-datatable-container" [busyIf]="primengTableHelper.isLoading" class="mb-0">
        <p-treeTable
            #dataTable
            (onLazyLoad)="getRecords($event)"
            [value]="primengTableHelper.records"
            rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
            [paginator]="false"
            [lazy]="true"
            [scrollable]="false"
            ScrollWidth="100%"
            [columns]="columnDefinitions"
            [resizableColumns]="primengTableHelper.resizableColumns"
            dataKey="id"
            (onRowExpand)="handleRowExpand($event)"
            [styleClass]="'mz-grid'"
            dataKey="{{ dataKey }}"
            [attr.data-testid]="uid"
        >
            <ng-template pTemplate="header">
                <tr class="mz-grid-tr">
                    <ng-container *ngFor="let cd of columnDefinitions">
                        <th [id]="cd.label" class="mz-grid-th" [ngStyle]="{ width: cd && cd.width ? cd.width + 'px' : 'auto' }">
                            <div class="d-flex flex-row" [mazarsTooltip]="cd.label" [escape]="false">
                                <div class="text-truncate overflow-hidden text-nowrap">
                                    <span>{{ cd.label }}</span>
                                </div>
                            </div>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record="$implicit" let-i="rowIndex" let-columns="columnDefinitions" let-expanded="expanded">
                <tr class="mz-grid-tr">
                    <td
                        class="mz-grid-td"
                        *ngFor="let col of columnDefinitions; let i = index"
                        [hidden]="col.isColumnHidden"
                        [ngStyle]="{
                            width: col && col.width ? col.width + 'px' : 'auto',
                            'max-width': col && col.maxWidth ? col.maxWidth + 'px' : '',
                            'background-color': record && record.style == 'warning' ? 'rgba(240,255,0,.15)' : ''
                        }"
                        [ngClass]="getBackgroundColorClass(record)"
                    >
                        <ng-container *ngIf="col.template == null">
                            <div class="d-flex flex-row">
                                <p-treeTableToggler [rowNode]="record" *ngIf="i === 0"></p-treeTableToggler>
                                <span
                                    [innerHTML]="getColumnValue(col, record) | safe: securityContext.HTML"
                                    class="mz-data-cell-truncate"
                                    [mazarsTooltip]="getColumnValue(col, record)"
                                    [escape]="false"
                                    tooltipPosition="bottom"
                                ></span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="col.template != null">
                            <p-treeTableToggler [rowNode]="record" *ngIf="i === 0"></p-treeTableToggler>
                            <ng-container
                                [ngTemplateOutlet]="getTemplate(col.template)"
                                [ngTemplateOutletContext]="{ $implicit: record.node, index: i }"
                            ></ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
        <div *ngIf="primengTableHelper.totalRecordsCount == 0" class="mz-grid-nodata">
            {{ l('NoData') }}
        </div>
    </div>
</div>
<div class="primeng-paging-container">
    <p-paginator
        [rows]="primengTableHelper.defaultRecordsCountPerPage"
        #paginator
        (onPageChange)="getRecords($event)"
        [totalRecords]="primengTableHelper.totalRecordsCount"
        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount"
    ></p-paginator>
</div>
