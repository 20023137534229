<p-checkbox
    inputId="{{ uid }}"
    [binary]="binary"
    [label]="label"
    [labelStyleClass]="labelStyleClass"
    [(ngModel)]="currentValue"
    [value]="value"
    [attr.data-testid]="uid + '-checkbox'"
    [disabled]="isDisabled"
    (onBlur)="onBlur()"
    (onChange)="handleOnChange()"
></p-checkbox>
