<input
    [imask]="mask"
    [unmask]="true"
    (complete)="onInputChange()"
    type="text"
    id="{{ uid }}"
    name="{{ uid }}"
    class="form-control p-inputtext"
    placeholder="{{ placeholder }}"
    [style.padding]="padding"
    [attr.data-testid]="uid"
    [(ngModel)]="value"
    [disabled]="disabled || isDisabled"
    (blur)="onBlurChange()"
    [mazarsTooltip]="tooltipText"
    [escape]="false"
    tooltipPosition="bottom"
    [style]="inlineStyle"
    (paste)="onPaste($event)"
/>
<span [attr.data-testid]="uid + '-warning-message'" *ngIf="showWarning" class="text-warning">{{ ld('Validation_NumberIsTooLong') }}</span>
