import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { IItem } from '../../interfaces/item.interface';

@Component({
    selector: 'app-mazars-card-input',
    templateUrl: './mazars-card-input.component.html',
    styleUrls: ['./mazars-card-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsCardInputComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsCardInputComponent<TKey> implements ControlValueAccessor, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() options: IItem<TKey>[];
    @Input() isDisabled: boolean;
    @Input() hasEmpty: boolean;

    selectItems: SelectItem<IItem<TKey>>[];

    writtenItemId: TKey;
    selectedItem: IItem<TKey>;
    constructor() {}

    writeValue(obj: TKey): void {
        if (Number(obj) || String(obj) || obj === null) {
            this.writtenItemId = obj;
        } else if (obj?.hasOwnProperty('value')) {
            this.writtenItemId = obj['value'];
        }

        this.selectedItem = null;
        for (let i of this.selectItems) {
            if (i.value.id === obj) {
                this.selectedItem = i.value;
                return;
            }
        }
    }

    ngOnChanges(): void {
        this.selectItems = this.convertOptionsToSelectItems(this.options);

        // original value still contained in list?
        this.selectedItem = null;
        for (let i of this.selectItems) {
            if (this.writtenItemId === i.value.id) {
                this.selectedItem = i.value;
                break;
            }
        }

        // default to first element
        if (!this.hasEmpty && this.selectedItem == null && this.selectItems.length > 0) {
            this.selectedItem = this.selectItems[0].value;
        }

        // need to notify
        if (this.writtenItemId !== this.selectedItem?.id) {
            this.onInputChange();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onInputChange() {
        this.writtenItemId = this.selectedItem?.id;
        this.onChange(this.writtenItemId);
    }

    private convertOptionsToSelectItems(options: IItem<TKey>[]): SelectItem[] {
        return options
            ? options.map(
                  (o) =>
                      ({
                          value: o,
                          label: o.text,
                      } as SelectItem)
              )
            : [];
    }
}
