import { Component, forwardRef, Injector, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs';
import { IInvolvedPartyConfig } from '../../interfaces/involved-party-config';

@Component({
    selector: 'app-address-sub-form',
    templateUrl: './address-sub-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressSubFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressSubFormComponent),
            multi: true,
        },
    ],
})
export class AddressSubFormComponent extends AppComponentBase implements ControlValueAccessor, OnDestroy {
    @Input() uid: string;
    @Input() submitting: boolean;
    @Input() countries: any[];
    @Input() partyConfig: IInvolvedPartyConfig;

    form: FormGroup;
    subscriptions: Subscription[] = [];
    isDisabled: boolean;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    get value(): any {
        return this.form.value;
    }

    set value(value: any) {
        this.form.patchValue(value);
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { address: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private createForm(): void {
        this.form = this.fb.group({
            countryCode: [null, [Validators.required]],
            postalCode: [null, [Validators.maxLength(200)]],
            city: [null, [Validators.maxLength(200)]],
            street: [null, [Validators.maxLength(200)]],
            buildingIdentifier: [null, [Validators.maxLength(200)]],
            floor: [null, [Validators.maxLength(200)]],
            suite: [null, [Validators.maxLength(200)]],
            postbox: [null, [Validators.maxLength(200)]],
            district: [null, [Validators.maxLength(200)]],
        });
    }
}
