import { Component, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationSettingsServiceProxy } from '@shared/service-proxies/general-proxies';
import {
    NotificationSubscriptionDto,
} from '@shared/service-proxies/service-proxies';
import { map as _map } from 'lodash-es';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'notificationSettingsModal',
    templateUrl: './notification-settings-modal.component.html',
})
export class NotificationSettingsModalComponent extends AppComponentBase {
    @ViewChild('modal', { static: true }) modal: ModalDirective;

    saving = false;

    tabs: any;
    constructor(injector: Injector, private notificationSettingsServiceProxy: NotificationSettingsServiceProxy) {
        super(injector);
    }

    show() {
        this.getSettings(() => {
            this.modal.show();
        });
    }

    save(): void {
        let input: NotificationSubscriptionDto[] = [];
        this.tabs.forEach((tab) => {
            const notificationSubscription = tab.notificationSettings.map((n) => {
                let subscription = new NotificationSubscriptionDto();
                subscription.name = n.name;
                subscription.isSubscribedBell = n.isSubscribedBell;
                subscription.isSubscribedEmail = n.isSubscribedBell ? n.isSubscribedEmail : false;
                return subscription;
            }) as NotificationSubscriptionDto[];
            input = [...input, ...notificationSubscription];
        });

        this.saving = true;
        this.notificationSettingsServiceProxy
            .setNotificationSettingsForCurrentUser(input)
            .pipe(finalize(() => (this.saving = false)))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
            });
    }

    close(): void {
        this.modal.hide();
    }

    private getSettings(callback: () => void) {
        this.notificationSettingsServiceProxy.getNotificationSettingsForCurrentUser().subscribe((result) => {
            const modules = [...new Set(result.map((item) => item.moduleIdentifier))];
            this.tabs = modules.map((str, index) => ({ name: str, notificationSettings: result.filter((r) => r.moduleIdentifier.includes(str)) }));
            callback();
        });
    }
}
