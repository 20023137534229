import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-mazars-switch',
    templateUrl: './mazars-switch.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsSwitchComponent,
        },
    ],
})
export class MazarsSwitchComponent implements ControlValueAccessor {
    @Input({ required: true }) uid: string;
    @Output() onToggleChanged = new EventEmitter<boolean>();
    @Input() isDisabled: boolean;

    value: boolean;

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    handleChange() {
        this.onChange(this.value);
        this.onToggleChanged.emit(this.value);
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlur() {
        this.onTouched();
    }
}
