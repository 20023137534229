import { ErrorHandler, Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { LocalizationService } from 'abp-ng2-module';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private localization: LocalizationService) {}

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            const localizedText = this.localization.localize('NewVersionAlertMessage', AppConsts.localization.defaultLocalizationSourceName);
            alert(localizedText);
            window.location.reload();
        } else if (console) {
            console.error(error);
        }
    }
}
