<ng-container [formGroup]="form">
    <div class="form-group">
        <app-mazars-switch [uid]="'GeneratePdfAction_AddSignatureField'" [formControl]="form.controls.addSignatureField"></app-mazars-switch>
        <span class="ml-4 mt-1 align-middle" style="position: absolute">
            {{ l('GeneratePdf_AddSignatureFieldToggle') }}
        </span>
    </div>
    <div class="form-group">
        <app-mazars-switch [uid]="'GeneratePdfAction_IncludeReadOnlyValues'" [formControl]="form.controls.includeReadOnlyValues"></app-mazars-switch>
        <span class="ml-4 mt-1 align-middle" style="position: absolute">
            {{ l('GeneratePdf_IncludeReadOnly') }}
            <app-mazars-information-icon uid="GeneratePdf_IncludeReadOnly_Tooltip" [message]="l('GeneratePdf_IncludeReadOnly_Tooltip')"></app-mazars-information-icon>
        </span>
    </div>
</ng-container>
