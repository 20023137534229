import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectReferenceDataService } from '../projects/project-reference-data.service';

@Injectable()
export class ProjectReferenceDataResolver implements Resolve<any> {
    constructor(private projectReferenceDataService: ProjectReferenceDataService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.projectReferenceDataService.load();
        return true;
    }
}
