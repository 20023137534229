import { Component, Input, Injector, SecurityContext } from '@angular/core';
import { IPropertyOwnersDto } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'mazars-property-owners-list',
    templateUrl: './mazars-property-owners-list.component.html',
    styleUrls: ['./mazars-property-owners-list.component.css'],
})
export class MazarsPropertyOwnersListComponent extends AppComponentBase {
    @Input({ required: true }) uid: string;
    @Input() propertyOwners: IPropertyOwnersDto[];
    securityContext = SecurityContext;

    constructor(injector: Injector) {
        super(injector);
    }

    get tooltipText(): string {
        let text = '';
        this.propertyOwners.forEach((po) => {
            text += po.name;
            let lastElement = this.propertyOwners[this.propertyOwners.length - 1];
            if (lastElement && lastElement.name !== po.name) {
                text += ', ';
            }
        });
        return text;
    }
}
