import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';
import { IActionInfo } from './action-info';
import { MazarsActionsDropdownMenuService } from './mazars-actions-dropdown-menu.service';

@Component({
    selector: 'app-mazars-actions-dropdown-menu',
    templateUrl: './mazars-actions-dropdown-menu.component.html',
    styleUrls: ['./mazars-actions-dropdown-menu.component.css'],
})
export class MazarsActionsDropdownMenuComponent implements OnInit, OnDestroy, OnChanges {
    @Input({ required: true }) uid: string;
    @Input() items: IActionInfo[];
    @Input() fontSize: string;
    @Input() btnSize: string;
    @Input() vertical: boolean;
    @Input() icon = 'flaticon-more-1';
    @Output() itemClick: EventEmitter<IActionInfo> = new EventEmitter<IActionInfo>();
    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

    private subscriptions: Subscription[] = [];

    constructor(private mazarsActionsDropdownMenuService: MazarsActionsDropdownMenuService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.checkSeparators();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.dropdown.onShown.subscribe(() => {
                this.mazarsActionsDropdownMenuService.onActionMenuOpen.next(this.uid);
            })
        );

        this.subscriptions.push(
            this.mazarsActionsDropdownMenuService.onActionMenuOpen.subscribe((uid) => {
                if (uid !== this.uid) {
                    this.dropdown.hide();
                }
            })
        );

        this.checkSeparators();
    }

    checkSeparators() {
        let lastSeparatorIndex: number;
        let isLastSeparatorHidden = true;
        let lastSeparatorFound = false;

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].separator) {
                if (lastSeparatorFound) {
                    this.items[lastSeparatorIndex].visible = !isLastSeparatorHidden;
                }

                lastSeparatorIndex = i;
                isLastSeparatorHidden = true;
                lastSeparatorFound = true;
            } else {
                if (this.items[i].visible) {
                    isLastSeparatorHidden = false;
                }

                if (i + 1 === this.items.length && lastSeparatorFound) {
                    this.items[lastSeparatorIndex].visible = !isLastSeparatorHidden;
                }
            }
        }
    }

    onClick(event: any, item: IActionInfo): any {
        this.dropdown.hide();
        this.itemClick.emit(<IActionInfo>{
            visible: item.visible,
            label: item.label,
            icon: item.icon,
        });

        if (item.action) {
            item.action();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
