<form [formGroup]="form">
    <ng-container formArrayName="descriptions">
        <ng-container *ngFor="let description of getControls(); let i = index">
            <div class="row" [formGroup]="description">
                <div class="col-lg-11 col-md-11">
                    <div class="form-group">
                        <label for="{{ uid }}-{{ i }}">{{ textAreaLabel }} *</label>
                        <app-mazars-information-icon uid="DescriptionInformationIcon" [message]="l('DescriptionInformationIcon')"></app-mazars-information-icon>
                        <app-mazars-input-textarea [uid]="uid + '-' + i" formControlName="text" [maxLength]="validatorsConsts.maxDescriprionTextAreaLength"></app-mazars-input-textarea>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <app-mazars-dropdown
                                    [uid]="uid + i"
                                    [options]="languages"
                                    [hasEmpty]="hasEmpty"
                                    formControlName="language"
                                ></app-mazars-dropdown>
                                <app-mazars-validation-message
                                    [uid]="uid + '-language-' + i"
                                    [key]="'Language'"
                                    [control]="description.controls.language"
                                    [submitting]="submitting"
                                ></app-mazars-validation-message>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 align-self-center">
                    <i role="button" *ngIf="descriptions.length > 1" class="flaticon-delete" (click)="removeDescription(i)" aria-hidden="true"></i>
                </div>
            </div>
        </ng-container>
        <button *ngIf="(!limit || descriptions.length < limit) && !isDisabled" type="button" class="btn btn-primary blue" (click)="addDescription()">
            {{ buttonLabel }}
        </button>
    </ng-container>
</form>
