<h5 class="mt-5">{{ ld('ChancelleryView') }}</h5>
<div class="row mb-3">
    <div class="col-12">
        <app-mazars-input-checkbox
            uid="isChancelleryActive"
            [binary]="true"
            [label]="ld('IsChancelleryActive')"
            [labelStyleClass]="'mb-0'"
            [formControl]="form.controls.isChancelleryViewActive"
        ></app-mazars-input-checkbox>
    </div>
</div>
<section *ngIf="form.controls.isChancelleryViewActive.value" [formGroup]="form">
    <div class="row">
        <div class="col-4 d-flex align-items-center">
            <label for="chancelleryViewClientsEndpointUrl">{{ ld('ChancelleryClientsEndpointUrl') }}</label>
        </div>
        <div class="col-8">
            <app-mazars-input-text
                uid="chancelleryViewClientsEndpointUrl"
                [placeholder]="ld('ChancelleryClientsEndpointUrl')"
                formControlName="chancelleryViewClientsEndpointUrl"
            ></app-mazars-input-text>
            <app-mazars-validation-message
                [uid]="'chancelleryClientsEndpointUrl'"
                [key]="l('ChancelleryClientsEndpointUrl')"
                [control]="form.controls.chancelleryViewClientsEndpointUrl"
            ></app-mazars-validation-message>
        </div>
    </div>
    <div class="row">
        <div class="col-4 d-flex align-items-center">
            <label for="chancelleryViewProjectsEndpointUrl">{{ ld('ChancelleryProjectsEndpointUrl') }}</label>
        </div>
        <div class="col-8">
            <app-mazars-input-text
                uid="chancelleryViewProjectsEndpointUrl"
                [placeholder]="ld('ChancelleryProjectsEndpointUrl')"
                formControlName="chancelleryViewProjectsEndpointUrl"
            ></app-mazars-input-text>
            <app-mazars-validation-message
                [uid]="'chancelleryProjectsEndpointUrl'"
                [key]="l('ChancelleryProjectsEndpointUrl')"
                [control]="form.controls.chancelleryViewProjectsEndpointUrl"
            ></app-mazars-validation-message>
        </div>
    </div>
    <div class="row">
        <div class="col-4 d-flex align-items-center">
            <label for="chancelleryViewUsername">{{ ld('ChancelleryUsername') }}</label>
        </div>
        <div class="col-8">
            <app-mazars-input-text
                [uid]="'chancelleryViewUsername'"
                [placeholder]="ld('ChancelleryUsername')"
                formControlName="chancelleryViewUsername"
            ></app-mazars-input-text>
            <app-mazars-validation-message
                [uid]="'chancelleryUsername'"
                [key]="l('ChancelleryUsername')"
                [control]="form.controls.chancelleryViewUsername"
            ></app-mazars-validation-message>
        </div>
    </div>
    <div class="row">
        <div class="col-4 d-flex align-items-center">
            <label for="Passphrase">{{ ld('ChancelleryPassword') }}</label>
        </div>
        <div class="col-8">
            <app-mazars-input-password
                [uid]="'Passphrase'"
                [placeholder]="ld('ChancelleryPassword')"
                formControlName="chancelleryViewPassword"
            ></app-mazars-input-password>
            <app-mazars-validation-message
                [uid]="'chancelleryPassword'"
                [key]="l('chancelleryViewPassword')"
                [control]="form.controls.chancelleryViewPassword"
            ></app-mazars-validation-message>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex">
            <app-mazars-switch [uid]="'chancelleryRequiredClientSwitch'" formControlName="chancelleryViewIsClientRequired"></app-mazars-switch>
            <span class="ml-3">
                {{ ld('ChancelleryIsClientRequired') }}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex">
            <app-mazars-switch [uid]="'chancelleryRequiredProjectSwitch'" formControlName="chancelleryViewIsProjectRequired"></app-mazars-switch>
            <span class="ml-3">
                {{ ld('ChancelleryIsProjectRequired') }}
            </span>
        </div>
    </div>
</section>
