export interface IContextMenuActionInfo {
    label: string;
    type: ContextMenuActionType;
    value?: any;
    action?: (event?: any) => void;
    icon?: string;
}

export enum ContextMenuActionType {
    Switch,
    Button
}