import { Component, Injector, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { IFileDownloadDto } from '@shared/service-proxies/general-proxies';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-mazars-preview-modal',
    templateUrl: './mazars-preview-modal.component.html',
})
export class MazarsPreviewModalComponent extends AppComponentBase {
    @ViewChild('mazarsPreviewModalComponent', { static: true }) modal: MazarsDialogComponent;

    @Input('uid') uid: string;
    file: IFileDownloadDto;
    supportedTypes = ['application/pdf', 'image/svg+xml', 'image/png', 'image/jpeg'];
    previewSrc = '';
    isPdf = false;
    showFile = false;
    title = '';
    constructor(injector: Injector, public domSanitizer: DomSanitizer) {
        super(injector);
        (window as any).pdfWorkerSrc = '/assets/pdfjs/pdf.worker.min.js';
    }
    show(data: IFileDownloadDto): DialogResult {
        this.previewSrc = '';
        this.isPdf = false;
        this.showFile = false;
        this.title = '';
        if (this.supportedTypes.find((option) => option === data.mimeType)) {
            this.title = data.fileName;
            this.file = data;
            const base64 = 'data:' + data.mimeType + ';base64,' + data.content;
            this.isPdf = data.mimeType === 'application/pdf';
            fetch(base64)
                .then((res) => res.blob())
                .then((value) => {
                    this.previewSrc = URL.createObjectURL(value);
                    this.showFile = true;
                });
            return this.modal.show();
        }
    }
}
