import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MazarsStatusChange } from './mazars-status-change';
import { MazarsStatusEditModalComponent } from './mazars-status-edit-modal.component';

@Component({
    selector: 'app-mazars-status-edit',
    templateUrl: './mazars-status-edit.component.html',
})
export class MazarsStatusEditComponent extends AppComponentBase {
    @ViewChild('mazarsStatusEditModal', { static: true })
    mazarsStatusEditModal: MazarsStatusEditModalComponent;

    @Input('uid') uid: string;
    @Input('statusValue') statusValue: string;
    @Input('statusReason') statusReason: string;
    @Input('statusItems') statusItems: IItem<string>[];

    @Output() onStatusChange: EventEmitter<MazarsStatusChange> = new EventEmitter<MazarsStatusChange>();

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    showStatusEditModal() {
        this.mazarsStatusEditModal.show(this.statusValue, this.statusItems).then((statusChange) => {
            this.onStatusChange.emit(<MazarsStatusChange>{
                value: statusChange.value,
                reason: statusChange.reason,
            });
        });
    }
}
