import { DueDateStatus } from '@app/modules/real-property-tax/real-property-tax-proxies';
import { AppConsts } from '@shared/AppConsts';
import { DueDateStatuses } from './due-date-statuses';

export class DueDateSettings {
    static getIcon(status: DueDateStatus | string): string {
        switch (status) {
            case DueDateStatus.Urgent:
            case DueDateStatuses.Urgent: {
                return 'fa fa-exclamation-triangle';
            }
            case DueDateStatus.OverDue:
            case DueDateStatuses.OverDue: {
                return 'fa fa-exclamation';
            }
            case DueDateStatus.InPlan:
            case DueDateStatuses.InPlan: {
                return 'fa noicon';
            }
            default: {
                return '';
            }
        }
    }

    static getIconColor(status: DueDateStatus | string): string {
        switch (status) {
            case DueDateStatus.Urgent:
            case DueDateStatuses.Urgent: {
                return 'var(--bs-yellow)';
            }
            case DueDateStatus.OverDue:
            case DueDateStatuses.OverDue: {
                return 'var(--bs-red)';
            }
            default: {
                return '';
            }
        }
    }

    static getIconTooltip(status: DueDateStatus | string): string {
        switch (status) {
            case DueDateStatus.Urgent:
            case DueDateStatuses.Urgent: {
                return this.l('DeadlineDates_FirstReminderTooltip');
            }
            case DueDateStatus.OverDue:
            case DueDateStatuses.OverDue: {
                return this.l('DeadlineDates_DueDateTooltip');
            }
            default: {
                return '';
            }
        }
    }

    static l(key: string): string {
        const source = abp.localization.getSource(AppConsts.localization.defaultLocalizationSourceName);
        return source(key);
    }
}
