<ag-grid-angular
    #agGrid
    [id]="uid"
    [class]="styleClass" 
    [headerHeight]="headerHeight"
    [columnDefs]="columnDefinitions"
    [defaultColDef]="defaultColumnDefinition"
    [autoGroupColumnDef]="autoGroupColumnDefinition"
    [rowData]="data"
    [treeData]="isTreeData"
    [animateRows]="true"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [getDataPath]="getDataPath"
    [getRowId]="getRowId"
    [rowDragManaged]="rowDragManaged"
    [rowDragMultiRow]="rowDragMultiRow"
    [suppressRowDrag]="suppressRowDrag"
    [rowSelection]="rowSelection"
    [suppressRowClickSelection]="true"
    [suppressContextMenu]="true"
    [pinnedBottomRowData]="pinnedBottomRowData"
    [groupIncludeFooter]="groupIncludeFooter"
    [editType]="editType"
    [overlayNoRowsTemplate]="noDataText"
    (gridReady)="onGridReady($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    (rowValueChanged)="onRowValueChanged($event)"
    (gridSizeChanged)="onGridSizeChanged()"
    (firstDataRendered)="onFirstDataRendered($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (rowSelected)="onRowSelected($event)"
    (columnResized)="onColumnResized($event)"
    [domLayout]="autoHeight ? 'autoHeight': 'normal'"
    [style]="style"
    [alwaysShowVerticalScroll]="alwaysShowVerticalScroll"
    (window:resize)="onWindowResize()"
    [tooltipShowDelay]="500"
    colResizeDefault='shift'
></ag-grid-angular>
