import { AfterViewInit, Directive, ElementRef, Inject, Input, NgZone, OnChanges, PLATFORM_ID, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Directive({
    selector: '[mazarsTooltip]',
})
export class MazarsTooltipDirective extends Tooltip implements AfterViewInit, OnChanges {
    @Input('mazarsTooltip') text: string | undefined;

    constructor(
        @Inject(PLATFORM_ID) platformId,
        public el: ElementRef,
        public zone: NgZone,
        public config: PrimeNGConfig,
        renderer: Renderer2,
        viewContainer: ViewContainerRef,
    ) {
        super(platformId, el, zone, config, renderer, viewContainer);
    }

    ngOnChanges(simpleChange: SimpleChanges) {
        if (simpleChange.tooltipPosition) {
            this.setOption({ tooltipPosition: simpleChange.tooltipPosition.currentValue });
        }

        if (simpleChange.tooltipEvent) {
            this.setOption({ tooltipEvent: simpleChange.tooltipEvent.currentValue });
        }

        if (simpleChange.appendTo) {
            this.setOption({ appendTo: simpleChange.appendTo.currentValue });
        }

        if (simpleChange.positionStyle) {
            this.setOption({ positionStyle: simpleChange.positionStyle.currentValue });
        }

        if (simpleChange.tooltipStyleClass) {
            this.setOption({ tooltipStyleClass: simpleChange.tooltipStyleClass.currentValue });
        }

        if (simpleChange.tooltipZIndex) {
            this.setOption({ tooltipZIndex: simpleChange.tooltipZIndex.currentValue });
        }

        if (simpleChange.escape) {
            this.setOption({ escape: simpleChange.escape.currentValue });
        }

        if (simpleChange.showDelay) {
            this.setOption({ showDelay: simpleChange.showDelay.currentValue });
        }

        if (simpleChange.hideDelay) {
            this.setOption({ hideDelay: simpleChange.hideDelay.currentValue });
        }

        if (simpleChange.life) {
            this.setOption({ life: simpleChange.life.currentValue });
        }

        if (simpleChange.positionTop) {
            this.setOption({ positionTop: simpleChange.positionTop.currentValue });
        }

        if (simpleChange.positionLeft) {
            this.setOption({ positionLeft: simpleChange.positionLeft.currentValue });
        }

        if (simpleChange.disabled) {
            this.setOption({ disabled: simpleChange.disabled.currentValue });
        }

        if (simpleChange.text) {
            this.setOption({ tooltipLabel: simpleChange.text.currentValue });

            if (this.active) {
                if (simpleChange.text.currentValue) {
                    if (this.container && this.container.offsetParent) {
                        this.updateText();
                        this.align();
                    } else {
                        this.show();
                    }
                } else {
                    this.hide();
                }
            }
        }

        if (simpleChange.autoHide) {
            this.setOption({ autoHide: simpleChange.autoHide.currentValue });
        }

        if (simpleChange.tooltipOptions) {
            this._tooltipOptions = { ...this._tooltipOptions, ...simpleChange.tooltipOptions.currentValue };
            this.deactivate();

            if (this.active) {
                if (this.getOption('tooltipLabel')) {
                    if (this.container && this.container.offsetParent) {
                        this.updateText();
                        this.align();
                    } else {
                        this.show();
                    }
                } else {
                    this.hide();
                }
            }
        }
    }
}
