import { Component, Injector, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CloseableDialogResult } from './closeable-dialog-result';
import { MazarsDialogComponent } from './mazars-dialog.component';

@Component({
    selector: 'mz-closeable-dialog',
    templateUrl: './mazars-closeable-dialog.component.html',
})
export class MazarsCloseableDialogComponent extends AppComponentBase {
    @ViewChild('dialog') dialog: MazarsDialogComponent;

    @Input({ required: true }) uid: string;
    title: string;
    size: string = null;
    closeButtonLabel: string = null;

    constructor(injector: Injector) {
        super(injector);
    }

    show(title: string, size: string = null, closeButtonLabel: string = null): CloseableDialogResult {
        this.title = title;
        this.size = size;
        this.closeButtonLabel = closeButtonLabel;
        return new CloseableDialogResult((finish) => {
            this.dialog?.show().then(
                () => {
                    /* do nothing */
                },
                () => finish()
            );
        });
    }

    close(): void {
        this.dialog.cancel();
    }
}
