import { Component, ViewChild, Injector, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AttachmentType } from './attachment-type';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { Acceptor } from '../mazars-dialog/acceptor';
import { CommonAttachmentsFacade } from '../../state-management/facades/common-attachments.facade';
import { IAttachmentDto, ICreateAttachmentInput, IUpdateAttachmentInput } from '@shared/service-proxies/common-interfaces';
import { FileParameter } from '@app/modules/master-data-management/master-data-management-proxies';
import { MazarsUploadButtonComponent } from '../mazars-upload-button/mazars-upload-button.component';

@Component({
    selector: 'mazars-attachments-modal',
    templateUrl: './mazars-attachments-modal.component.html',
})
export class MazarsAttachmentsModalComponent extends AppComponentBase implements OnChanges {
    @ViewChild('modal', { static: true }) modal: MazarsDialogComponent;
    @ViewChild('uploadFile') uploadFile: MazarsUploadButtonComponent;

    @Input({ required: true }) uid: string;

    form: FormGroup = null;
    saving = false;
    validation = false;

    referenceId: number;
    attachmentId?: number;

    availableTags: string[];
    availableTypes = AttachmentType;
    typeItems: IItem<string>[] = Object.keys(AttachmentType).map((t) => <IItem<string>>{ id: t, text: this.l(t) });

    fileAcceptedTypes = '';
    isFileUploading = false;

    private acceptor: Acceptor;
    constructor(
        injector: Injector,
        private fb: FormBuilder,
        private commonAttachmentsFacade: CommonAttachmentsFacade,
    ) {
        super(injector);
    }

    ngOnChanges(): void {
        this.createForm();
    }

    show(referenceId: number, attachment: IAttachmentDto, fileAcceptedTypes?: string[]) {
        this.uploadFile?.createForm();
        this.form.reset();
        this.referenceId = referenceId;
        this.attachmentId = attachment?.id;
        this.validation = false;

        this.ModelToForm(attachment);

        this.addDataSubscriptions();
        if (fileAcceptedTypes) {
            let _result = fileAcceptedTypes.join(',.');
            if (_result.length > 0) {
                _result = '.' + _result;
            }
            this.fileAcceptedTypes = _result;
        } else {
            this.commonAttachmentsFacade.getAllowedFileTypes();
        }
        this.commonAttachmentsFacade.getAllAttachmentTags('');
        return this.modal.show();
    }

    beforeSubmit(acceptor: Acceptor) {
        this.validation = true;

        if (!this.form.valid) {
            return;
        }

        this.saving = true;
        if (this.attachmentId == null) {
            this.createAttachment(acceptor);
        } else {
            this.updateAttachment(acceptor);
        }
    }

    removeFile(): void {
        this.form.patchValue({ urlOrFiletoken: null });
    }

    onSelectFile(file: File) {
        if (!file) {
            this.form.patchValue({ urlOrFiletoken: null });
        } else {
            this.isFileUploading = true;
            this.commonAttachmentsFacade.uploadFile(<FileParameter>{
                data: file,
                fileName: file.name,
            });
        }
    }

    private addDataSubscriptions() {
        this.subscriptions.push(
            this.commonAttachmentsFacade.allAttachmentsTags$.subscribe((result) => {
                if (result) {
                    this.availableTags = result;
                }
            }),
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.allAllowedFileTypes$.subscribe((result) => {
                if (result) {
                    let _result = result.join(',.');
                    if (_result.length > 0) {
                        _result = '.' + _result;
                    }
                    this.fileAcceptedTypes = _result;
                }
            }),
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.uploadedFile$.subscribe((fileToken) => {
                if (fileToken) {
                    this.onFileUploaded(fileToken);
                    this.isFileUploading = false;
                }
            }),
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.entityUpdatedSuccessfully$.subscribe(() => {
                if (this.acceptor) {
                    this.acceptor.accept();
                    this.acceptor = null;
                }
                this.saving = false;
            }),
        );
        this.subscriptions.push(
            this.commonAttachmentsFacade.createdAttachment$.subscribe((result) => {
                if (result) {
                    if (this.acceptor) {
                        this.acceptor.accept(result);
                        this.acceptor = null;
                    }
                    this.saving = false;
                }
            }),
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.uploadedFileError$.subscribe(() => {
                this.onUploadFailed();
                this.isFileUploading = false;
            }),
        );
    }

    private createForm(): void {
        this.form = this.fb.group({
            type: [null],
            urlOrFiletoken: [null, [Validators.required, Validators.maxLength(400)]],
            note: [null, Validators.maxLength(1024)],
            dateOfAttachment: [null],
            tags: [null],
        });
        this.form.controls.type.valueChanges.subscribe((v) => this.form.patchValue({ urlOrFiletoken: null }));
    }

    private ModelToForm(model: IAttachmentDto): void {
        this.form.reset();
        this.form.setValue({
            type: model?.type ?? this.availableTypes.File,
            urlOrFiletoken: model?.name ?? null,
            note: model?.note ?? null,
            dateOfAttachment: model?.dateOfAttachment ?? null,
            tags: model?.tags ?? [],
        });
    }

    private Form2CreateModel(): ICreateAttachmentInput {
        let name = null;
        let fileToken = null;
        let url = null;
        switch (this.form.value.type) {
            case AttachmentType.File:
                fileToken = this.form.value.urlOrFiletoken;
                break;
            case AttachmentType.Url:
                url = this.form.value.urlOrFiletoken;
                break;
        }
        return <ICreateAttachmentInput>{
            referenceId: this.referenceId,
            note: this.form.value.note ?? '',
            type: this.form.value.type,
            fileToken: fileToken,
            url: url,
            dateOfAttachment: this.form.value.dateOfAttachment,
            tags: this.form.value.tags,
        };
    }

    private Form2UpdateModel(): IUpdateAttachmentInput {
        return <IUpdateAttachmentInput>{
            note: this.form.value.note ?? '',
            dateOfAttachment: this.form.value.dateOfAttachment,
            tags: this.form.value.tags,
            id: this.attachmentId,
            referenceId: this.referenceId,
        };
    }

    private createAttachment(acceptor: Acceptor): void {
        let attachment = this.Form2CreateModel();
        this.acceptor = acceptor;
        this.commonAttachmentsFacade.create(attachment);
    }

    private updateAttachment(acceptor: Acceptor): void {
        let attachment = this.Form2UpdateModel();
        this.acceptor = acceptor;
        this.commonAttachmentsFacade.update(attachment);
    }

    private onFileUploaded(fileToken: string): void {
        this.form.patchValue({ urlOrFiletoken: fileToken });
    }

    private onUploadFailed(): void {
        this.form.patchValue({ urlOrFiletoken: null });
    }
}
