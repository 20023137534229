<mz-dialog
    #editModal
    [uid]="uid"
    [title]="l('ChangeWorkItemStatus')"
    [canSubmit]="form.valid"
    [submitButtonLabel]="l('Adopt')"
    (beforeSubmit)="beforeSubmit($event)"
>
    <form [formGroup]="form" (ngSubmit)="editModal.submit()" autocomplete="off">
        <div class="form-group">
            <label for="statusValue">{{ l('WorkItemStatus') }}</label>
            <app-mazars-dropdown
                [uid]="uid + '_statusValue'"
                [options]="statusItems"
                [hasEmpty]="false"
                [filter]="false"
                formControlName="statusValue"
                placeholder="{{ l('WorkItemStatus') }}"
            >
                <ng-template mazarsTemplate let-option>
                    <app-mazars-status
                        [statusValue]="option.id"
                        [isEditable]="false"
                        [uid]="'edit-modal-WorkItemStatus-option' + option.id"
                    ></app-mazars-status>
                </ng-template>
            </app-mazars-dropdown>
        </div>

        <div class="form-group">
            <label for="statusReason">{{ l('WorkItemStatusReason') }}</label>
            <app-mazars-input-text
                [uid]="uid + '_statusReason'"
                [placeholder]="l('WorkItemStatusReason')"
                formControlName="statusReason"
            ></app-mazars-input-text>
            <app-mazars-validation-message
                [key]="'statusReason'"
                [control]="form.controls.statusReason"
                [uid]="uid + '_statusReason'"
            ></app-mazars-validation-message>
        </div>
    </form>
</mz-dialog>
