<!-- begin::Quick Sidebar -->
<div
    id="kt_explore"
    data-kt-drawer="true"
    data-kt-drawer-name="explore"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_explore_toggle"
    data-kt-drawer-close="#kt_explore_close"
    class="bg-body drawer drawer-end"
>
    <div class="card shadow-none rounded-0 w-100">
        <div id="kt_explore_header" class="card-header">
            <h3 class="card-title fw-bolder text-gray-700">
                {{ 'SelectATheme' | localize }}
            </h3>
            <div class="card-toolbar">
                <button type="button" id="kt_explore_close" class="btn btn-sm btn-icon btn-active-light-primary me-n5">
                    <span class="svg-icon svg-icon-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect
                                opacity="0.5"
                                x="6"
                                y="17.3137"
                                width="16"
                                height="2"
                                rx="1"
                                transform="rotate(-45 6 17.3137)"
                                fill="black"
                            ></rect>
                            <rect
                                x="7.41422"
                                y="6"
                                width="16"
                                height="2"
                                rx="1"
                                transform="rotate(45 7.41422 6)"
                                fill="black"
                            ></rect>
                        </svg>
                    </span>
                    <!---->
                </button>
            </div>
        </div>
        <div id="kt_explore_body" class="card-body">
            <div
                id="kt_explore_scroll"
                data-kt-scroll="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-wrappers="#kt_explore_body"
                data-kt-scroll-dependencies="#kt_explore_header"
                data-kt-scroll-offset="5px"
                class="scroll-y me-n5 pe-5"
            >
                <div class="row g-5">
                    <div
                        class="col-6"
                        *ngFor="
                            let theme of [
                                'default',
                                'theme2',
                                'theme3',
                                'theme4',
                                'theme5',
                                'theme6',
                                'theme7',
                                'theme8',
                                'theme9',
                                'theme10',
                                'theme11',
                                'theme12',
                                'theme13'
                            ]
                        "
                        (click)="changeTheme(theme)"
                    >
                        <div
                            [ngClass]="{ 'border-success': theme == currentThemeName }"
                            class="overlay overflow-hidden position-relative border border-4 rounded"
                        >
                            <div class="overlay-wrapper">
                                <img
                                    [src]="'/assets/common/images/metronic-themes/' + theme + '.png' | safe: securityContext.URL "
                                    alt="demo"
                                    class="w-100"
                                />
                            </div>
                            <div class="overlay-layer bg-dark bg-opacity-10">
                                <button href="" class="btn btn-sm btn-success shadow">
                                    {{ getLocalizedThemeName(theme) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end::Quick Sidebar -->
