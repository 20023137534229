import { Component, ViewChild, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Table } from 'primeng/table';
import { MazarsDialogComponent } from '../mazars-dialog/mazars-dialog.component';
import { DialogResult } from '../mazars-dialog/dialog-result';
import { Acceptor } from '../mazars-dialog/acceptor';
import { CommonCommunicationsFacade } from '../../state-management/facades/common-communications.facade';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { ISaveAllItems, IWhiteListOverviewDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'mazars-whitelist-modal',
    templateUrl: './mazars-whitelist-modal.component.html',
    styleUrls: ['./mazars-whitelist-modal.component.css'],
})
export class MazarsWhitelistModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('modal', { static: true }) modal: MazarsDialogComponent;
    @ViewChild('table') table: Table;
    @Input({ required: true }) uid: string;

    domainRegex = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
    emailRegex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    whitelist: IWhiteListOverviewDto[];
    channelId: number;
    emailOrDomainErrorDef = { error: 'emailOrDomain', localizationKey: 'InvalidEmailOrDomain' };
    emailTouched = false;
    acceptor: Acceptor;
    hasDeletePermission: boolean;
    hasEditPermission: boolean;

    constructor(injector: Injector, public communicationsFacade: CommonCommunicationsFacade) {
        super(injector);
    }

    ngOnInit(): void {
        this.registerSubscriptions();
    }

    isValidDomain(domain: any) {
        if (!domain) {
            return false;
        }
        return domain.match(this.domainRegex);
    }

    isCorrectEmail(emailAddress: any) {
        if (!emailAddress) {
            return false;
        }
        return emailAddress.match(this.emailRegex);
    }

    isValidEmailOrDomain(whitelistEntry: any, emailAddressInput: any) {
        let result = this.isCorrectEmail(whitelistEntry.email) || this.isValidDomain(whitelistEntry.email);
        if (!result) {
            emailAddressInput.control.setErrors({ emailOrDomain: true });
        }
        return result;
    }

    isDataValid(): boolean {
        let isValid = true;
        if (this.whitelist) {
            this.whitelist.forEach((whitelistEntry) => {
                let isEmailValid = whitelistEntry.email ? this.isCorrectEmail(whitelistEntry.email) || this.isValidDomain(whitelistEntry.email) : false;
                if (!isEmailValid) {
                    isValid = false;
                }
            });
        }
        return isValid;
    }

    onEditComplete(event) {
        this.refreshEmailTouched();
    }

    beforeSubmit(acceptor: Acceptor): void {
        let saveAllWhiteListInput = this.ItemsToSaveModel();
        this.acceptor = acceptor;
        this.communicationsFacade.saveAllWhitelist(saveAllWhiteListInput);
    }

    deleteWhitelistEntry(rowIndex: number): void {
        this.message.confirm('', this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                const removeCount = 1;
                this.whitelist.splice(rowIndex, removeCount);
                this.refreshEmailTouched();
            }
        });
    }

    getWhitelist() {
        if (this.channelId) {
            this.communicationsFacade.getAllWhitelist(this.channelId);
        }
    }

    addWhitelistEntry() {
        this.refreshEmailTouched();
        let whitelistEntry = {} as IWhiteListOverviewDto;
        whitelistEntry.id = null;
        this.whitelist.push(whitelistEntry);
        setTimeout(() => {
            const element = document.getElementById(`domain-or-email${this.whitelist.length - 1}`) as HTMLElement;
            if (element) {
                element.click();
            }
        });
    }

    show(channelId: number, hasDeletePermission = true, hasEditPermission = true): DialogResult {
        this.hasDeletePermission = hasDeletePermission;
        this.hasEditPermission = hasEditPermission;
        this.channelId = channelId;
        this.getWhitelist();
        return this.modal.show();
    }

    private ItemsToSaveModel() {
        let input = {} as any;
        input.channelId = this.channelId;
        input.items = [];
        this.whitelist.forEach((whitelistEntry) => {
            input.items.push(this.initItemToSave(whitelistEntry));
        });
        return input;
    }

    private initItemToSave(whitelistEntry: IWhiteListOverviewDto): ISaveAllItems {
        let item = {} as ISaveAllItems;
        item.id = whitelistEntry.id;
        item.channelId = this.channelId;
        item.email = whitelistEntry.email;
        return item;
    }

    private refreshEmailTouched() {
        this.emailTouched = !this.isDataValid();
    }

    private registerSubscriptions() {
        this.subscriptions.push(
            this.communicationsFacade.allWhitelist$.subscribe((result) => {
                if (result) {
                    this.whitelist = _cloneDeep(result);
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.saveAllWhitelistSuccessful$.subscribe(() => {
                if (this.acceptor) {
                    this.acceptor.accept();
                    this.acceptor = null;
                }
            })
        );

        this.subscriptions.push(
            this.communicationsFacade.saveAllWhitelistError$.subscribe((error) => {
                if (error && this.acceptor) {
                    this.acceptor.reject();
                    this.acceptor = null;
                }
            })
        );
    }
}
