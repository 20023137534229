import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IProcessDto } from '@shared/service-proxies/common-interfaces';

@Component({
    selector: 'app-mazars-process-state',
    templateUrl: './mazars-process-state.component.html',
    styleUrls: ['./mazars-process-state.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsProcessStateComponent implements OnInit {
    @Input({ required: true }) uid: string;
    @Input() phase: IProcessDto;
    @Input() icon: string;
    @Input() localizer: (key: string) => string;

    iconClass: string[];

    ngOnInit(): void {
        this.iconClass = [this.icon, 'mz-process-icon'];
        if (this.phase && this.phase.stateType) {
            this.iconClass.push(this.phase.stateType.toString().toLowerCase());
        }
    }

    l(value: string): string {
        let result = this.localizer ? this.localizer(value) : value;
        if (!result) {
            result = value;
        }
        return result;
    }
}
