import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EntityDtoOfGuid, NotificationServiceProxy, SetNotificationAsReadOutput } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import * as Push from 'push.js'; // if using ES6
import { NotificationSettingsModalComponent } from './notification-settings-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { isObservable, Observable } from 'rxjs';
import { MazarsDownloadService } from '@app/modules/mazars-common/services/mazars-download.service';
import { HttpClient } from '@angular/common/http';
import { NotificationDetailModalComponent } from './detail-modal/notification-detail-modal.component';
import { FileDownloadDto } from '@shared/service-proxies/general-proxies';
import { MazarsNotifyService } from '@app/shared/common/toast/mazars-notify.service';

export interface IFormattedUserNotification {
    userNotificationId: string;
    text: string;
    time: string;
    creationTime: DateTime;
    icon: string;
    state: String;
    data: any;
    url: string | Observable<FileDownloadDto>;
    dialog: DialogInfo;
    isUnread: boolean;
    severity: abp.notifications.severity;
    iconFontClass: string;
    notificationName: string;
}

export interface DialogInfo {
    isHtml: boolean;
    severity: abp.notifications.severity;
    title: string;
    message: string;
    content?: string;
    listElements: string[];
    attempts: any[];
}

@Injectable()
export class UserNotificationHelper extends AppComponentBase {
    settingsModal: NotificationSettingsModalComponent;
    detailModal: NotificationDetailModalComponent;

    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _dateTimeService: DateTimeService,
        private _mazarsDownloadService: MazarsDownloadService,
        private http: HttpClient,
        private mazarsNotifyService: MazarsNotifyService
    ) {
        super(injector);
    }

    getUrl(userNotification: abp.notifications.IUserNotification): string | Observable<FileDownloadDto> {
        switch (userNotification.notification.notificationName) {
            case 'App.NewUserRegistered':
                return '/app/admin/users?filterText=' + userNotification.notification.data.properties.emailAddress;
            case 'App.NewTenantRegistered':
                return '/app/admin/tenants?filterText=' + userNotification.notification.data.properties.tenancyName;
            case 'App.GdprDataPrepared':
                return (
                    AppConsts.remoteServiceBaseUrl +
                    '/File/DownloadBinaryFile?id=' +
                    userNotification.notification.data.properties.binaryObjectId +
                    '&contentType=application/zip&fileName=collectedData.zip'
                );
            case 'App.DownloadInvalidImportUsers':
                return (
                    AppConsts.remoteServiceBaseUrl +
                    '/File/DownloadTempFile?fileToken=' +
                    userNotification.notification.data.properties.fileToken +
                    '&fileType=' +
                    userNotification.notification.data.properties.fileType +
                    '&fileName=' +
                    userNotification.notification.data.properties.fileName
                );
            //Add your custom notification names to navigate to a URL when user clicks to a notification.
        }

        //No url for this notification
        return '';
    }

    getDialog(userNotification: abp.notifications.IUserNotification): DialogInfo {
        try {
            if (userNotification.notification.data.properties.dialog) {
                let dialog = JSON.parse(userNotification.notification.data.properties.dialog) as DialogInfo;
                dialog.message = abp.notifications.getFormattedMessageFromUserNotification(userNotification);
                dialog.severity = userNotification.notification.severity;
                dialog.attempts = userNotification.notification?.data?.properties?.Notification?.Attempts;
                return dialog;
            } else if (userNotification.notification?.data?.properties?.Notification?.Attempts && userNotification.notification?.data?.properties?.Notification?.Attempts.length > 0) {
                let dialog = { attempts: userNotification.notification?.data?.properties?.Notification?.Attempts } as DialogInfo;
                return dialog;
            }
        } catch (e) {
            abp.log.error(e);
        }
        return null;
    }

    /* PUBLIC functions ******************************************/

    getUiIconBySeverity(severity: abp.notifications.severity): string {
        switch (severity) {
            case abp.notifications.severity.SUCCESS:
                return 'fas fa-check-circle';
            case abp.notifications.severity.WARN:
                return 'fas fa-exclamation-triangle';
            case abp.notifications.severity.ERROR:
                return 'fas fa-exclamation-circle';
            case abp.notifications.severity.FATAL:
                return 'fas fa-bomb';
            case abp.notifications.severity.INFO:
            default:
                return 'fas fa-info-circle';
        }
    }

    getIconFontClassBySeverity(severity: abp.notifications.severity): string {
        switch (severity) {
            case abp.notifications.severity.SUCCESS:
                return ' text-success';
            case abp.notifications.severity.WARN:
                return ' text-warning';
            case abp.notifications.severity.ERROR:
                return ' text-danger';
            case abp.notifications.severity.FATAL:
                return ' text-danger';
            case abp.notifications.severity.INFO:
            default:
                return ' text-info';
        }
    }

    showToastBySeverity(severity: abp.notifications.severity, message: string, title?: string, options?: any): void {
        switch (severity) {
            case abp.notifications.severity.SUCCESS:
                this.mazarsNotifyService.success(message, title, options);
                break;
            case abp.notifications.severity.WARN:
                this.mazarsNotifyService.warn(message, title, options);
                break;
            case abp.notifications.severity.ERROR:
                this.mazarsNotifyService.error(message, title, options);
                break;
            case abp.notifications.severity.FATAL:
                this.mazarsNotifyService.error(message, title, options);
                break;
            case abp.notifications.severity.INFO:
            default:
                this.mazarsNotifyService.info(message, title, options);
                break;
        }
    }

    format(userNotification: abp.notifications.IUserNotification, truncateText?: boolean): IFormattedUserNotification {
        this.localizeNotificationParameters(userNotification);
        let formatted: IFormattedUserNotification = {
            userNotificationId: userNotification.id,
            text: abp.notifications.getFormattedMessageFromUserNotification(userNotification),
            time: this._dateTimeService.formatDate(userNotification.notification.creationTime, 'yyyy-LL-dd HH:mm:ss'),
            creationTime: userNotification.notification.creationTime as any,
            icon: this.getUiIconBySeverity(userNotification.notification.severity),
            state: abp.notifications.getUserNotificationStateAsString(userNotification.state),
            data: userNotification.notification.data,
            url: this.getUrl(userNotification),
            dialog: this.getDialog(userNotification),
            isUnread: userNotification.state === abp.notifications.userNotificationState.UNREAD,
            severity: userNotification.notification.severity,
            iconFontClass: this.getIconFontClassBySeverity(userNotification.notification.severity),
            notificationName: userNotification?.notification?.notificationName
        };

        if (truncateText || truncateText === undefined) {
            formatted.text = abp.utils.truncateStringWithPostfix(formatted.text, 100);
        }

        return formatted;
    }

    show(userNotification: abp.notifications.IUserNotification): void {
        this.localizeNotificationParameters(userNotification);
        let url = this.getUrl(userNotification);
        let dialog = this.getDialog(userNotification);
        this.triggerEvents(userNotification);

        if (userNotification?.notification?.data?.properties?.toastMessage) {
            let message = abp.localization.localize(
                userNotification.notification.data.properties.toastMessage,
                userNotification.notification.data.properties.Message.sourceName
            );
            let messageToShow;
            if (message.length > 100) {
                messageToShow = message.slice(0, 100) + '...';
              } else {
                messageToShow = message;
            }
            this.mazarsNotifyService.info(messageToShow);
        } else {
            //Application notification
            let message = abp.notifications.getFormattedMessageFromUserNotification(userNotification);
            let action;
            if (url) {
                action = () => this.openUrl(url);
            } else if (dialog) {
                action = () => this.openDetailModal(dialog);
            }
            let messageToShow;
            if (message.length > 100) {
                messageToShow = message.slice(0, 100) + '...';
              } else {
                messageToShow = message;
            }
            this.showToastBySeverity(userNotification.notification.severity, messageToShow, undefined, { action });
        }
        if (Push.default.Permission.has()) {
            //Desktop notification
            Push.default.create('Digital Tax Portal', {
                body: this.format(userNotification).text,
                icon: abp.appPath + 'assets/common/images/app-logo-on-dark-sm.svg',
                timeout: 6000,
                onClick: function () {
                    window.focus();
                    this.close();
                },
            });
        }
    }

    shouldUserUpdateApp(): void {
        this._notificationService.shouldUserUpdateApp().subscribe((result) => {
            if (result) {
                abp.message.confirm(null, this.l('NewVersionAvailableNotification'), (isConfirmed) => {
                    if (isConfirmed) {
                        this.http.get(AppConsts.remoteServiceBaseUrl + 'BrowserCacheCleaner/Clear', { withCredentials: true }).subscribe((result: boolean) => {
                            if (result) {
                                const url = new URL(location.href);
                                url.searchParams.append('t', new Date().getTime().toString());
                                location.href = url.toString();
                            }
                        });
                    }
                });
            }
        });
    }

    setAllAsRead(callback?: () => void): void {
        this._notificationService.setAllNotificationsAsRead().subscribe(() => {
            abp.event.trigger('app.notifications.refresh');
            if (callback) {
                callback();
            }
        });
    }

    setAsRead(userNotificationId: string, callback?: (userNotificationId: string) => void): void {
        const input = new EntityDtoOfGuid();
        input.id = userNotificationId;
        this._notificationService.setNotificationAsRead(input).subscribe((result: SetNotificationAsReadOutput) => {
            abp.event.trigger('app.notifications.read', userNotificationId, result.success);
            if (callback) {
                callback(userNotificationId);
            }
        });
    }

    openSettingsModal(): void {
        this.settingsModal.show();
    }

    //Added by mazars
    openDetailModal(dialogInfo: DialogInfo) {
        this.detailModal.show(dialogInfo);
    }

    openUrl(url: string | Observable<FileDownloadDto>) {
        if (url && typeof url === 'string') {
            location.href = url;
        } else if (url && isObservable(url)) {
            url.subscribe((fileDownloadDto) => {
                this._mazarsDownloadService.triggerBrowserDownload(fileDownloadDto);
            });
        }
    }

    private localizeNotificationParameters(userNotification: abp.notifications.IUserNotification) {
        for (let key in userNotification.notification.data.properties) {
            if (key.startsWith('localizable_')) {
                let splittedProperty = userNotification.notification.data.properties[key].split('__');
                if (splittedProperty.length === 2) {
                    userNotification.notification.data.properties[key] = abp.localization.localize(splittedProperty[0], splittedProperty[1]);
                }
            }
        }
    }

    private triggerEvents(userNotification: abp.notifications.IUserNotification) {
        abp.event.trigger(userNotification.notification.notificationName, userNotification?.notification);
    }
}
