<div>
    <app-mazars-input-checkbox
    [uid]="uid + '-yes-checkbox'"
    class="mr-5"
    (onToggleChanged)="onChangeHandler()"
    [name]="uid"
    [value]="YesNoAnswerType.Yes"
    [label]="l('Yes')"
    [(ngModel)]="selectedItems"
    [disabled]="isDisabled">
    </app-mazars-input-checkbox>
    <app-mazars-input-checkbox
    [uid]="uid + '-no-checkbox'"
    (onToggleChanged)="onChangeHandler()"
    [name]="uid"
    [value]="YesNoAnswerType.No"
    [label]="l('No')"
    [(ngModel)]="selectedItems"
    [disabled]="isDisabled">
    </app-mazars-input-checkbox>
</div>
