import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MazarsMessage } from './mazars-message';
/**
 * MazarsMessage service used in messages and toast components.
 * @group Service
 */
@Injectable({
    providedIn: 'platform',
})
export class MazarsMessageService {
    private messageSource = new Subject<MazarsMessage | MazarsMessage[]>();
    private clearSource = new Subject<string>();

    messageObserver = this.messageSource.asObservable();
    clearObserver = this.clearSource.asObservable();

    add(message: MazarsMessage) {
        if (message) {
            this.messageSource.next(message);
        }
    }

    addAll(messages: MazarsMessage[]) {
        if (messages && messages.length) {
            this.messageSource.next(messages);
        }
    }

    clear(key?: string) {
        this.clearSource.next(key || null);
    }
}
