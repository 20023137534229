<form [formGroup]="form">
    <app-mazars-dropdown
        appendTo="body"
        [uid]="'Template-' + uid"
        formControlName="taskStatus"
        [options]="allStatuses"
        [removeSelectedValueFromOptions]="true"
        [handleDeletedEntry]="false"
        (onSelectedItemChanged)="onStatusChange()">
        <ng-template mazarsTemplate let-item>
            <span [class]="item.iconColor">
                <i class="fas fa-circle me-1"></i>
            </span>
            {{ l(item.text) }}
        </ng-template>
    </app-mazars-dropdown>
</form>
