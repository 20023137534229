<p-pickList
    [attr.data-testid]="uid"
    [source]="sourceItems"
    [target]="targetItems"
    [dragdrop]="!disabled"
    [responsive]="true"
    [sourceStyle]="{ height: '30rem' }"
    [targetStyle]="{ height: '30rem' }"
    [showSourceControls]="false"
    [showTargetControls]="false"
    breakpoint="900px"
    (onMoveToSource)="handleOnMoveToSource($event)"
    (onMoveToTarget)="handleOnMoveToTarget($event)"
    filterBy="name"
    (onSourceReorder)="handleOnSourceReorder($event)"
    (onTargetReorder)="handleOnTargetReorder($event)"
    (onTargetSelect)="handleOnTargetSelect($event)"
    [styleClass]="disabled ? 'mazars-list-picker-disabled' : ''"
    [metaKeySelection]="true"
>
    <ng-template let-item pTemplate="item">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
    </ng-template>
    <ng-template let-sourceHeader pTemplate="sourceHeader">
        <ng-container *ngTemplateOutlet="sourceHeaderTemplate; context: { $implicit: sourceHeader }"></ng-container>
    </ng-template>
    <ng-template let-targetHeader pTemplate="targetHeader">
        <ng-container *ngTemplateOutlet="targetHeaderTemplate; context: { $implicit: targetHeader }"></ng-container>
    </ng-template>
</p-pickList>
