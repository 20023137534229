import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IInvolvedPartyConfig } from '../../interfaces/involved-party-config';
import { OrganisationNameInput } from '@app/modules/dac6/dac6-proxies';

@Component({
    selector: 'app-organisation-names-sub-form',
    templateUrl: './organisation-names-sub-form.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrganisationNamesSubFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OrganisationNamesSubFormComponent),
            multi: true,
        },
    ],
})
export class OrganisationNamesSubFormComponent extends AppComponentBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() submitting: boolean;
    @Input() languages: any[];
    @Input() uid: string;
    @Input() partyConfig: IInvolvedPartyConfig;

    form: FormGroup;
    subscriptions: Subscription[] = [];
    isDisabled: boolean;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    get organisationNames(): FormArray {
        return this.form.controls.values as FormArray;
    }

    get value(): OrganisationNameInput[] {
        return this.form.value as OrganisationNameInput[];
    }

    set value(values: OrganisationNameInput[]) {
        values = this.checkorganisationNamesCollection(values);
        if (values) {
            this.organisationNames.clear();
            values.forEach((organisationName) => {
                const organisationNameForm = this.createOrganisationNameForm();
                organisationNameForm.patchValue(organisationName);
                this.organisationNames.push(organisationNameForm);
            });

            if (this.isDisabled) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        }
    }

    onChange: any = () => {
        // This is intentional
    };

    onTouched: any = () => {
        // This is intentional
    };

    ngOnInit(): void {
        this.createForm();

        this.subscriptions.push(
            this.form.valueChanges.subscribe((value) => {
                this.onChange(value);
                this.onTouched();
            })
        );
    }

    addOrganisationName(): void {
        this.organisationNames.push(this.createOrganisationNameForm());
    }

    createOrganisationNameForm(): FormGroup {
        return this.fb.group({
            name: [null],
            language: [null],
        });
    }

    removeOrganisationName(index: number): void {
        this.organisationNames.removeAt(index);
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        } else {
            this.form.reset();
        }

        this.onChange(obj);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate(_: FormControl) {
        return this.form.valid ? null : { organisationNames: { valid: false } };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getControls() {
        return this.organisationNames.controls as FormGroup[];
    }

    private checkorganisationNamesCollection(values: any) {
        if (values instanceof Array) {
            return values;
        } else if (values && values.values instanceof Array) {
            return values.values;
        } else {
            return null;
        }
    }

    private createForm(): void {
        this.form = this.fb.group({
            values: this.fb.array([]),
        });
    }
}
