import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
    selector: 'decimal-editor',
    template: `
        <app-mazars-input-number
            #input
            [showButtons]="false"
            [mode]="'decimal'"
            [useGrouping]="true"
            [minFractionDigits]="2"
            [maxFractionDigits]="undefined"
            [placeholder]="placeholder"
            [tooltipText]="tooltipText"
            [height]="42"
            [(ngModel)]="value"
            [uid]="'decimal-editor-' + placeholder"
        ></app-mazars-input-number>
    `,
})
export class DecimalEditorComponent implements ICellEditorAngularComp {
    @ViewChild('input', {read: ElementRef}) input: ElementRef;
    public value!: number;
    public placeholder: string;
    public tooltipText: string;

    agInit(params: ICellEditorParams): void {
        this.value = params.value ? parseFloat(params.value) : params.value;
        this.placeholder = params.data.placeholder;
        this.tooltipText = params.data.tooltipText;
    }

    focusIn() {
        setTimeout(() => this.input.nativeElement.querySelector('input').focus());
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }
}
