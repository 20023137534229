import { Inject, Injectable } from '@angular/core';
import { createAction, props } from '@ngrx/store';
import { AppModules, IModule } from '@shared/AppEnums';

export enum MazarsWizardActionsNames {
    Init = '[MazarsWizard] Mazars Wizard Init',
    SetActiveStep = '[MazarsWizard] Set Active Step',
    Clear = '[MazarsWizard] Clear',

}

@Injectable()
export class MazarsWizardActions {
    readonly Init = createAction(`[${this.module}]${MazarsWizardActionsNames.Init}`);

    readonly SetActiveStep = createAction(`[${this.module}]${MazarsWizardActionsNames.SetActiveStep}`, props<{ data: number }>());
    readonly Clear = createAction(`[${this.module}]${MazarsWizardActionsNames.Clear}`);

    constructor(@Inject(IModule) private module: AppModules) {}
}
