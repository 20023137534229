import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressSubFormComponent } from './address-sub-form/address-sub-form.component';
import { ComponentsModule } from '@app/modules/mazars-common/components/components.module';
import { TinSubFormComponent } from './tin-sub-form/tin-sub-form.component';
import { OtherDetailsSubFormComponent } from './other-details-sub-form/other-details-sub-form.component';
import { IndividualIdentificationSubFormComponent } from './individual-identification-sub-form/individual-identification-sub-form.component';
import { OrganisationNamesSubFormComponent } from './organisation-names-sub-form/organisation-names-sub-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { LiabilitySubFormComponent } from './liability-sub-form/liability-sub-form.component';

@NgModule({
  declarations: [
    AddressSubFormComponent,
    TinSubFormComponent,
    OtherDetailsSubFormComponent,
    IndividualIdentificationSubFormComponent,
    OrganisationNamesSubFormComponent,
    LiabilitySubFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CheckboxModule,
  ],
  exports: [
    AddressSubFormComponent,
    TinSubFormComponent,
    OtherDetailsSubFormComponent,
    IndividualIdentificationSubFormComponent,
    OrganisationNamesSubFormComponent,
    LiabilitySubFormComponent
  ]
})
export class SubFormsModule { }
