import { Component, Injector, ViewEncapsulation, ViewChild, OnInit, Input, SecurityContext } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';
import { MazarsAttachmentsModalComponent } from './mazars-attachments-modal.component';
import { IActionInfo } from '@app/modules/mazars-common/components/mazars-actions-dropdown-menu/action-info';
import { IColumnDefinition } from '@app/modules/mazars-common/components/mazars-grid/grid-interfaces';
import { DatePipe } from '@angular/common';
import { MazarsGridComponent } from '@app/modules/mazars-common/components/mazars-grid/mazars-grid.component';
import { ActionIcon } from '@app/modules/mazars-common/components/mazars-actions-dropdown-menu/action-icon';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AttachmentType } from './attachment-type';
import { MazarsDownloadService } from '@app/modules/mazars-common/services/mazars-download.service';
import { CommonAttachmentsFacade } from '../../state-management/facades/common-attachments.facade';
import { IAttachmentDto, IAttachmentOverviewDto, IAttachmentsFilterRequest } from '@shared/service-proxies/common-interfaces';
import { TableStateService } from '@shared/utils/table-state.service';
import { CommonGridConfigurationFacade } from '@app/modules/mazars-common/state-management/facades/common-grid-configuration.facade';
import { MazarsPreviewModalComponent } from '../mazars-preview-modal/mazars-preview-modal.component';
import { ISearchBarFilter } from '../mazars-searchbar/search-bar-filter.interface';
import { FilterType } from '../mazars-searchbar/filter-type';

@Component({
    selector: 'app-mazars-attachments',
    templateUrl: './mazars-attachments.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MazarsAttachmentsComponent extends AppComponentBase implements OnInit {
    @ViewChild('mazarsAttachmentsModal', { static: true }) mazarsAttachmentsModal: MazarsAttachmentsModalComponent;
    @ViewChild('mazarsTable', { static: true }) overviewTable: MazarsGridComponent;
    @ViewChild('filePreviewDialog', { static: true }) filePreviewDialog: MazarsPreviewModalComponent;

    @Input('referenceId') referenceId: number;
    @Input('hasCreatePermission') hasCreatePermission = false;
    @Input('hasEditPermission') hasEditPermission = false;
    @Input('hasDeletePermission') hasDeletePermission = false;
    @Input('absoluteModuleRootPath') absoluteModuleRootPath: string;
    @Input('includeRefIdInLink') includeRefIdInLink: boolean = true;
    @Input() tableId: string;

    filterText = '';

    typeItems: IItem<string>[] = Object.keys(AttachmentType).map((t) => <IItem<string>>{ id: t, text: this.l(t) });
    tagsItems: IItem<string>[] = [];
    createdByItems: IItem<number>[];

    securityContext = SecurityContext;
    filtersDefinitions: { [key: string]: ISearchBarFilter };
    columnDefinitions: IColumnDefinition[] = [
        {
            label: this.l('References'),
            field: 'fileContributionInfos',
            template: 'fileContributionInfosTemplate',
            isColumnHidden: true,
        },
        {
            label: this.l('Note'),
            field: 'note',
            isColumnHidden: true,
        },
        {
            label: this.l('DateOfAttachment'),
            sortableName: 'dateOfAttachment',
            field: 'dateOfAttachment',
            customRenderer: (v) => this.datePipe.transform(v),
            isColumnHidden: true,
        },
        {
            label: this.l('CreatedBy'),
            sortableName: 'creatorUserId',
            field: 'createdByModifier',
            isColumnHidden: true,
        },
        {
            label: this.l('LastUpdateBy'),
            sortableName: 'lastModifierUserId',
            field: 'lastModifier',
            isColumnHidden: true,
        },
    ];

    constructor(
        injector: Injector,
        public gridConfigurationFacade: CommonGridConfigurationFacade,
        private datePipe: DatePipe,
        private downloadService: MazarsDownloadService,
        private commonAttachmentsFacade: CommonAttachmentsFacade,
        private tableStateService: TableStateService
    ) {
        super(injector);
    }

    setFilterDefinitions() {
        this.filtersDefinitions = {
            typeFilter: {
                items: this.typeItems,
                placeholder: this.l('Type'),
                type: FilterType.Multiselect,
            },
            tagsFilter: {
                items: this.tagsItems,
                placeholder: this.l('Tags'),
                type: FilterType.Multiselect,
            },
            createdByFilter: {
                items: this.createdByItems,
                placeholder: this.l('CreatedBy'),
                type: FilterType.Multiselect,
                isUserOrOrganization: true
            },
        };
    }


    ngOnInit(): void {

        this.commonAttachmentsFacade.getCreatedByUsers(this.referenceId);
        this.addDataSubscriptions();
        this.commonAttachmentsFacade.getAllAttachmentTags('');
        this.setFilterDefinitions()
    }

    setFilter(filters: any): void {
        this.filterText = filters?.filter;
        this.getAttachments();
    }

    getActionItems = (attachment: IAttachmentOverviewDto) =>
        <IActionInfo[]>[
            {
                visible: attachment.type === AttachmentType.File,
                label: this.l('Download'),
                icon: ActionIcon.Download,
                action: () => this.downloadFile(attachment.id),
            },
            {
                visible: attachment.type === AttachmentType.Url,
                label: this.l('Visit'),
                icon: ActionIcon.Visit,
                action: () => window.open(attachment.url, '_blank'),
            },
            {
                visible: this.hasEditPermission && (!attachment.fileContributionInfos?.length || attachment.fileContributionInfos.every((i) => i.isModifiable)),
                label: this.l('Edit'),
                icon: ActionIcon.Edit,
                action: () => this.showCreateUpdateModal(attachment),
            },
            {
                visible:
                    this.hasDeletePermission && (!attachment.fileContributionInfos?.length || attachment.fileContributionInfos.every((i) => i.isModifiable)),
                label: this.l('Delete'),
                icon: ActionIcon.Delete,
                requireConfirmation: true,
                confirmationMessage: this.l('DeleteAttachmentAreYouSure'),
                action: () => this.deleteAttachment(attachment.id),
            },
        ];

    showCreateModal() {
        this.showCreateUpdateModal(null);
    }

    getAttachments(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.overviewTable.paginator.changePage(0);

            if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
                return;
            }
        }
        this.primengTableHelper.showLoadingIndicator();
        const filters = this.tableStateService.getFilters(this.tableId);
        const input = {} as IAttachmentsFilterRequest;
        input.referenceId = this.referenceId;
        input.filter = this.filterText;
        input.typeFilter = filters?.typeFilter && filters.typeFilter.length > 0 ? filters.typeFilter : [];
        input.tagsFilter = filters?.tagsFilter && filters.tagsFilter.length > 0 ? filters.tagsFilter : [];
        input.createdByFilter = filters?.createdByFilter && filters.createdByFilter.length > 0 ? filters.createdByFilter : [];
        input.sorting = this.primengTableHelper.getSorting(this.overviewTable.dataTable) || null;
        input.skipCount = this.primengTableHelper.getSkipCount(this.overviewTable.paginator, event);
        input.maxResultCount = this.primengTableHelper.getMaxResultCount(this.overviewTable.paginator, event);
        this.commonAttachmentsFacade.getAllAttachments(input);
    }

    reloadPage(): void {
        this.commonAttachmentsFacade.getAllAttachmentTags('');
        this.commonAttachmentsFacade.getCreatedByUsers(this.referenceId);
        this.getAttachments();
    }

    previewFile(attachmentId: number) {
        this.commonAttachmentsFacade.previewFile(this.referenceId, attachmentId);
    }

    private addDataSubscriptions() {
        this.subscriptions.push(
            this.commonAttachmentsFacade.allAttachments$.subscribe((result) => {
                if (result) {
                    this.primengTableHelper.totalRecordsCount = result.totalCount;
                    this.primengTableHelper.records = _cloneDeep(result.items);
                    this.primengTableHelper.hideLoadingIndicator();
                }
            })
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.allAttachmentsTags$.subscribe((result) => {
                if (result) {
                    this.tagsItems = result.map((t) => <IItem<string>>{ id: t, text: t, icon: 'pi pi-tag' });
                    this.setFilterDefinitions();
                }
            })
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.entityDeletedSuccessfully$.subscribe(() => {
                this.reloadPage();
                this.notify.success(this.l('SuccessfullyDeleted'));
            })
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.downloadedFile$.subscribe((result) => {
                if (result) {
                    this.downloadService.triggerBrowserDownload(result);
                }
            })
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.previewedFile$.subscribe((result) => {
                if (result) {
                    this.filePreviewDialog.show(result);
                }
            })
        );

        this.subscriptions.push(
            this.gridConfigurationFacade.gridColumnSettingsConfigChanged$.subscribe((config: any) => {
                if (config?.data && config.data.configuration?.length > 0) {
                    this.onConfigChanged(config?.data, this.columnDefinitions);
                }
            })
        );

        this.subscriptions.push(
            this.commonAttachmentsFacade.createdByUsers$.subscribe((result) => {
                if (result) {
                    this.createdByItems = result.map((c) => <IItem<number>>{ id: c.id, text: c.text, source: c });;
                } else {
                    this.createdByItems = [];
                }
                this.setFilterDefinitions();
            })
        )
    }
    private showCreateUpdateModal(attachment: IAttachmentDto): void {
        this.mazarsAttachmentsModal.show(this.referenceId, attachment).then(() => {
            this.reloadPage();
            this.notify.success(attachment == null ? this.l('SuccessfullyCreated') : this.l('SuccessfullySaved'));
        });
    }

    private downloadFile(attachmentId: number) {
        this.commonAttachmentsFacade.downloadFile(this.referenceId, attachmentId);
    }

    private deleteAttachment(attachmentId: number): void {
        this.commonAttachmentsFacade.delete(this.referenceId, attachmentId);
    }

}
