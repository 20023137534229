<ng-container>
    <h5 *ngIf="showHeadline" class="mb-2">
        {{ l('PermissionAccessRestriction_Headline') }}
    </h5>
    <p class="mb-0">
        {{ subHeadline ?? l('PermissionAccessRestriction_Subheadline') }}
        <app-mazars-information-icon uid="PermissionAccessRestriction_Tooltip" [message]="l('PermissionAccessRestriction_Tooltip')"></app-mazars-information-icon>
    </p>
    <div class="form-group p-field-checkbox mb-0 mt-1">
        <app-mazars-input-checkbox
            [binary]="true"
            [label]="l('PermissionAccessRestriction_Checkbox')"
            [labelStyleClass]="'mb-0'"
            [formControl]="control"
            [uid]="uid + '_Checkbox'"
        ></app-mazars-input-checkbox>
    </div>
    <small *ngIf="control.value" class="section-heading ml-5">
        {{ checkBoxMessage }}
    </small>
</ng-container>
