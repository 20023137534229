import { Inject, Injectable } from '@angular/core';
import { createAction, props } from '@ngrx/store';
import { AppModules, IModule } from '@shared/AppEnums';
import { IMazarsGridDefinitionDto, IMazarsGridDefinitionInput, IMazarsColumnDefinitionDto } from '@shared/service-proxies/common-interfaces';

export enum CommonGridConfigurationActionsNames {
    Init = '[GridConfiguration] Task Grid Configuration Init',

    GetGridColumnSettingsRequest = '[GridConfiguration] Get Grid Column Settings Request',
    GetGridColumnSettingsSuccess = '[GridConfiguration] Get Grid Column Settings Success',
    GetGridColumnSettingsFailure = '[GridConfiguration] Get Grid Column Settings Failure',

    SetGridColumnSettingsRequest = '[GridConfiguration] Set Grid Column Settings Request',
    SetGridColumnSettingsSuccess = '[GridConfiguration] Set Grid Column Settings Success',
    SetGridColumnSettingsFailure = '[GridConfiguration] Set Grid Column Settings Failure',

    ResetGridColumnSettingsRequest = '[GridConfiguration] Reset Grid Column Settings Request',
    ResetGridColumnSettingsSuccess = '[GridConfiguration] Reset Grid Column Settings Success',
    ResetGridColumnSettingsFailure = '[GridConfiguration] Reset Grid Column Settings Failure',

    ChangeGridColumnSettingsConfig = '[GridConfiguration] Change Grid Column Settings config',
    GridColumnSettingsConfigChanged = '[GridConfiguration] Grid Column Settings config changed',

    SetSorting = '[GridConfiguration] Set Sorting',
    ClearData = '[GridConfiguration] Clear Data',

}

@Injectable()
export class CommonGridConfigurationActions {
    readonly Init = createAction(`[${this.module}]${CommonGridConfigurationActionsNames.Init}`);

    readonly GetGridColumnSettingsRequest = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.GetGridColumnSettingsRequest}`,
        props<{ input: string }>()
    );
    readonly GetGridColumnSettingsSuccess = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.GetGridColumnSettingsSuccess}`,
        props<{ data: IMazarsGridDefinitionDto }>()
    );

    readonly GetGridColumnSettingsFailure = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.GetGridColumnSettingsFailure}`,
        props<{ error: any }>()
    );

    readonly SetGridColumnSettingsRequest = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.SetGridColumnSettingsRequest}`,
        props<{ input: IMazarsGridDefinitionInput | undefined }>()
    );

    readonly SetGridColumnSettingsSuccess = createAction(`[${this.module}]${CommonGridConfigurationActionsNames.SetGridColumnSettingsSuccess}`);

    readonly SetGridColumnSettingsFailure = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.SetGridColumnSettingsFailure}`,
        props<{ error: any }>()
    );

    readonly ResetGridColumnSettingsRequest = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.ResetGridColumnSettingsRequest}`,
        props<{ input: string }>()
    );

    readonly ResetGridColumnSettingsSuccess = createAction(`[${this.module}]${CommonGridConfigurationActionsNames.ResetGridColumnSettingsSuccess}`);

    readonly ResetGridColumnSettingsFailure = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.ResetGridColumnSettingsFailure}`,
        props<{ error: any }>()
    );

    readonly ChangeGridColumnSettingsConfig = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.ChangeGridColumnSettingsConfig}`,
        props<{ data: IMazarsGridDefinitionDto }>()
    );

    readonly GridColumnSettingsConfigChanged = createAction(`[${this.module}]${CommonGridConfigurationActionsNames.GridColumnSettingsConfigChanged}`);

    readonly SetSorting = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.SetSorting}`,
        props<{ input: string | undefined }>()
    );

    readonly ClearData = createAction(
        `[${this.module}]${CommonGridConfigurationActionsNames.ClearData}`
    );

    constructor(@Inject(IModule) private module: AppModules) {}
}
