export enum ActionIcon {
    Edit = 'far fa-edit fa-fw',
    Open = 'far fa-edit fa-fw',
    Archive = 'fas fa-archive fa-fw',
    Delete = 'far fa-trash-alt fa-fw',
    Send = 'far fa-paper-plane fa-fw',
    Visit = 'fas fa-external-link-alt fa-fw',
    Download = 'fas fa-download fa-fw',
    DownloadPdf = 'fas fa-file-pdf fa-fw',
    DownloadExcel = 'far fa-file-excel fa-fw',
    File = 'fas fa-file fa-fw',
    Calculate = 'fas fa-calculator fa-fw',
    Create = 'fas fa-plus fa-fw',
    Mail = 'fas fa-envelope fa-fw',
    AssignPartyRole = 'fa fa-arrow-right fa-fw',
    Import = 'pi pi-upload fa-fw',
    Lock = 'pi pi-lock fa-fw',
    Validate = 'pi pi-check fa-fw',
    CheckCircle = 'pi pi-check-circle fa-fw',
    FileSearch = 'fas fa-file-search fa-fw',
    FileCheck = 'fas fa-file-check fa-fw',
    Search = 'fas fa-search fa-fw',
    LeftArrow = 'pi pi-arrow-left fa-fw',
    Sync = 'fas fa-sync fa-fw',
    Comment = 'fas fa-comment fa-fw',
    History = 'fas fa-history fa-fw',
    SendInverse = 'fas fa-paper-plane fa-fw',
    Convert = 'fas fa-recycle fa-fw',
    Access = 'far fa-eye fa-fw',
    AccessDenied = 'far fa-eye-slash fa-fw',
    Reanalyze = 'fas fa-spinner fa-fw',
    DownloadToLine = 'pi pi-download fa-fw',
    Question = 'pi pi-question fa-fw',
    Print = 'fas fa-print fa-fw',
    Expand = 'fas fa-expand fa-fw',
    Unarchive = 'fas fa-box-open fa-fw',
    Participants = 'fas fa-users fa-fw',
}
