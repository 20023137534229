<div
    class="truncate"
    [mazarsTooltip]="tooltipText"
    [escape]="false"
    tooltipPosition="bottom"
    [attr.data-testid]="uid"
>
    <span *ngFor="let tag of tags" class="label label-inline label-secondary m-1 text-nowrap" [ngClass]="tag.name !== '...' ? 'mz-tag-item': ''">
        <i *ngIf="tag.name !== '...'" aria-hidden="true" class="pi pi-tag mr-1"></i>
        {{ tag.name }}
    </span>
</div>
