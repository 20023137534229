import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DeleteRowCellRendererParams } from './delete-row-cell-renderer-params';

@Component({
    selector: 'delete-row-cell-renderer',
    template: `
        <i *ngIf="showDeleteRow" class="pi pi-trash icon-btn-sm" style="font-size: 0.9rem;" aria-hidden="true" (click)="onDeleteRowClick($event)"></i>
    `,
})
export class DeleteRowCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParams;
    deleteRow: (params: any) => void;
    showDeleteRow: boolean = false;

    refresh(): boolean {
        return false;
    }

    agInit(params: ICellRendererParams & DeleteRowCellRendererParams): void {
        this.showDeleteRow = params.value ?? false;
        this.params = params;
        this.deleteRow = params.deleteRow;
    }

    onDeleteRowClick(event) {
        this.deleteRow(this.params);
    }
}
