<div class="form-group">
    <label for="File">{{ title }}</label>
    <p-fileUpload
        mode="basic"
        id="File"
        [name]="name"
        customUpload="true"
        (uploadHandler)="handleOnSelectFile($event)"
        [auto]="true"
        [files]="files"
        [accept]="accept"
        [chooseLabel]="ld('Browse')"
        [maxFileSize]="maxFileSize"
        (onSelect)="onSelect.emit($event)"
        [disabled]="isDisabled || isLoading"
    >
        <ng-template pTemplate="chooseicon">
            <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
            <i *ngIf="!isLoading" class="pi pi-plus"></i>
        </ng-template>
    </p-fileUpload>
</div>
<ng-container *ngTemplateOutlet="validationTemplate"></ng-container>

<div *ngIf="form.value.fileName && !isLoading" class="form-group mazars3 p-1">
    <div class="alert alert-custom alert-white alert-bold m-1" role="alert">
        <span class="alert-text">
            {{ form.value.fileName }}
        </span>
        <button type="button" class="btn p-0 vertical-align-top">
            <span (click)="removeFile()">
                <i aria-hidden="true" class="la la-close"></i>
            </span>
        </button>
    </div>
</div>
