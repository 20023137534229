import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ICommunicationDto, ICreateChannelInput, IEditChannelInput } from '@shared/service-proxies/common-interfaces';
import { Observable, of } from 'rxjs';
import { IMazarsCreateUpdateFacade } from '../../components/mazars-dialog/mazars-create-update-facade';
import { CommonCommunicationsActions } from '../actions/common-communications.actions';
import { CommonCommunicationsSelectors, CommonCommunicationsSelectorsToken } from '../selectors/common-communication.selectors';
import { CommonCommunicationsState } from '../state/common-communications.state';

@Injectable()
export class ChannelCreateUpdateFacade implements IMazarsCreateUpdateFacade<any, ICreateChannelInput, IEditChannelInput> {
    getEntity$: Observable<ICommunicationDto> = of(null);
    entityUpdatedSuccessfully$: Observable<void> = this.actions$.pipe(ofType(this.commonCommunicationsActions.UpdateChannelSuccess));
    entityCreatedSuccessfully$: Observable<void> = this.actions$.pipe(ofType(this.commonCommunicationsActions.CreateChannelSuccess));
    createOrUpdateError$: Observable<any> = this.store.pipe(select(this.selectors.getChannelError));

    constructor(
        private readonly store: Store<CommonCommunicationsState>,
        private readonly actions$: Actions,
        @Inject(CommonCommunicationsSelectorsToken) private readonly selectors: CommonCommunicationsSelectors,
        private commonCommunicationsActions: CommonCommunicationsActions
    ) {}

    getById(id: number) {
        // not implemented
    }

    create(input: ICreateChannelInput) {
        this.store.dispatch(this.commonCommunicationsActions.CreateChannelRequest({ input: input }));
    }

    update(input: IEditChannelInput) {
        this.store.dispatch(this.commonCommunicationsActions.UpdateChannelRequest({ input: input }));
    }
}
