import { InjectionToken } from '@angular/core';

export const IModule = new InjectionToken<AppModules>('IModule');

export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export class AppModules {
    static MasterDataManagementModule = 'MasterDataManagementModule';
    static Dac6Module = 'Dac6Module';
    static RealPropertyTaxModule = 'RealPropertyTaxModule';
    static AccountMappingModule = 'AccountMappingModule';
    static ChecklistsModule = 'ChecklistsModule';
    static Pillar2Module = 'Pillar2Module';
    static IroModule = 'IroModule';
}
