import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';
import {
    IFeedbackItem,
    IFeedbacksServiceProxy,
    ISubmissionItem,
    ISubmissionsServiceProxy,
} from './submission-interfaces';

@Component({
    selector: 'mazars-submission-item',
    templateUrl: './mazars-submission-item.component.html',
})
export class MazarsSubmissionItemComponent extends AppComponentBase implements OnChanges {
    @Input() submission: ISubmissionItem;
    @Input() feedback: IFeedbackItem;
    @Input() submissionsServiceProxy: ISubmissionsServiceProxy;
    @Input() feedbackServiceProxy: IFeedbacksServiceProxy;
    subtitle: string;

    constructor(injector: Injector, private dateTimeService: DateTimeService) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.submission?.feedback?.externalDate) {
            this.subtitle = this.l(
                'Submission_Feedback_Subtitle',
                this.getDate(this.submission?.feedback?.externalDate)
            );
        } else if (this.submission?.feedback?.createdOn) {
            this.subtitle = this.l('Submission_Feedback_Subtitle', this.getDate(this.submission?.feedback?.createdOn));
        } else {
            this.subtitle = '';
        }
    }

    getDate(date: DateTime): string {
        return date ? this.dateTimeService.formatDate(date, 'dd.MM.yyyy') : '';
    }
}
