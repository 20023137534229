import { Component, Injector, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Acceptor } from '../../mazars-dialog/acceptor';
import { MazarsDialogComponent } from '../../mazars-dialog/mazars-dialog.component';

@Component({
    selector: 'app-mazars-attach-survey-modal',
    templateUrl: './mazars-attach-survey-modal.component.html',
    styles: [],
})
export class MazarsAttachSurveyModalComponent extends AppComponentBase implements OnChanges {
    @ViewChild('addModal', { static: true }) modal: MazarsDialogComponent;
    @Input({ required: true }) uid: string;
    surveys: any[];

    form: FormGroup = null;
    saving = false;

    constructor(injector: Injector, private fb: FormBuilder) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.createForm();
    }

    show(surveys: any[]) {
        this.form.reset();
        this.surveys = surveys;
        return this.modal.show();
    }

    createForm() {
        this.form = this.fb.group({
            surveyId: [null, Validators.required],
        });
    }

    beforeSubmit(acceptor: Acceptor) {
        if (this.form.valid) {
            acceptor.accept(this.form.value);
        }
    }

    onDestroy(): void {
        // to be implemented if need it
    }
}
