import { AfterViewInit, Component, Injector, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonQuillMentionService } from '@app/shared/common/quill/common-quill-mention.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription, timer } from 'rxjs';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { IItem } from '../../interfaces/item.interface';

@Component({
    selector: 'mazars-editor',
    templateUrl: './mazars-editor.component.html',
    styleUrls: ['./mazars-editor.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MazarsEditorComponent,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsEditorComponent extends AppComponentBase implements AfterViewInit, ControlValueAccessor {
    @ViewChild('editor') editor: QuillEditorComponent;

    @Input({ required: true }) uid: string;
    @Input() commonQuillMentionService: CommonQuillMentionService;
    @Input() availableShortcuts: IItem<string>[];
    @Input() placeholder?: string;
    @Input() rows?: number = 5;

    subscriptions: Subscription[] = [];
    quillEditor: Quill;
    isDisabled: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    onChange = (_) => {
        // This is intentional
    };

    onTouched = () => {
        // This is intentional
    };

    ngAfterViewInit(): void {
        let quillEditor = this.editor.quillEditor;
        this.editor.modules = this.commonQuillMentionService.getModules(quillEditor);
    }

    getValue(): string {
        return JSON.stringify(this.getTrimmedContent());
    }

    editorCreated(editor: Quill) {
        this.quillEditor = editor;
        this.editor.quillEditor = this.quillEditor;

        this.quillEditor.setContents(null, 'api');
    }

    contentChanged(contentObj) {
        let content = JSON.stringify(this.getTrimmedContent());
        this.onChange(content);
        this.onTouched();
    }

    writeValue(content: any): void {
        let contentObj: any = content;
        try {
            contentObj = JSON.parse(content);
        } catch (e) {
            contentObj = content;
        }

        this.subscriptions.push(
            timer(0).subscribe((_) => {
                this.editor.quillEditor.setContents(contentObj, 'api');
            })
        );
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    getTrimmedContent() {
        const delta = this.editor.quillEditor.getContents();
    
        let leadingFixed = false;
        let newDelta = [];
        let tempDelta = [];
    
        if(delta.ops.length === 1) {
            // If there is only one entry, check if it's a string and trim leading and ending LF
            let { insert, attributes } = delta.ops[0];
            if(typeof(insert) === 'string') {
            insert = insert.replace(/^\s+|\s+$/g, '');
            }
            newDelta = [{ insert, attributes }];
        } else {
            // Else go through all the insert entries
            delta.ops.forEach(({ insert, attributes }, idx) => {
            // Create a boolean to indicate if we're at the last entry
            const isLast = idx === delta.ops.length - 1;
    
            // If the entry is a string (not image/asset)
            if(typeof(insert) === 'string') {
                // If we haven't fixed the leading
                if(!leadingFixed) {
                // If the entry begins with LFs
                if(/^\s+/.test(insert)) {
                    // Create a string witrh clean leading LFs
                    let cleanText = insert.replace(/^\s+/, '');
    
                    // If there is text after cleaning the LFs
                    if(cleanText.length > 0) {
                    // Add the text to the newDelta
                    newDelta.push({
                        insert: cleanText,
                        attributes
                    });
                    // Set leading flag to indicate we've fixed the leading
                    leadingFixed = true;
                    }
                // Else if the entry does not start with LFs
                } else {
                    // If the entry does not begin with LFs
                    // Add any pending entries that may exists in tempDelta to the newDelta
                    newDelta = newDelta.concat(tempDelta);
                    // Add the existing entry
                    newDelta.push({
                    insert,
                    attributes
                    });
                    // Clean the any pending entries
                    tempDelta = [];
                    // And set the leading flag to indicate we've fixed the leading
                    leadingFixed = true;
                }
                // Else if we have fixed the leading
                } else {
                    // If there an entry with ending LFs
                    if(/[\s]+$/.test(insert)) {
                        // Create a string witrh clean ending LFs
                        let cleanText = insert.replace(/\s+$/, '');
        
                        // If this is the last entry
                        if(isLast) {
                        // If there is text after cleaning the LFs
                        if(cleanText.length > 0) {
                            // Add any pending entries that may exists in tempDelta to the newDelta
                            newDelta = newDelta.concat(tempDelta);
                            // Add the cleaned entry
                            newDelta.push({
                            insert: cleanText,
                            attributes
                            });
                        }
                        // Else if this is not the last entry
                        } else {
                            // If there is text after cleaning the LFs
                            if(cleanText.length > 0) {
                                // Add any pending entries that may exists in tempDelta to the newDelta
                                newDelta = newDelta.concat(tempDelta);
                                // Add the existing entry
                                newDelta.push({
                                insert,
                                attributes
                                });
                                // Clean the any pending entries
                                tempDelta = [];
                            // Else if there is no text after cleaning the LFs
                            } else {
                                // Add the entry to the temp deltas so to use them later if its needed
                                tempDelta.push({ insert, attributes });
                            }
                        }
                    // Else if the entry does not end with LFs
                    } else {
                        // Add any pending entries that may exists in tempDelta to the newDelta
                        newDelta = newDelta.concat(tempDelta);
                        // Add the existing entry
                        newDelta.push({
                        insert,
                        attributes
                        });
                        // Clean the any pending entries
                        tempDelta = [];
                    }
                }
            // If the entry is not a string
            } else {
                // Then all leading text/line feeds have been cleared if there were any
                // so, it's safe to set the leading flag
                leadingFixed = true;
                // Add any pending entries that may exists in tempDelta to the newDelta
                newDelta = newDelta.concat(tempDelta);
                // Add the existing entry
                newDelta.push({
                insert,
                attributes
                })
                // Clean the any pending entries
                tempDelta = [];
            }
            });
        }
    
        return newDelta;
    }
}
