import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Dac6ComponentBase } from '../../../modules/dac6/dac6-component-base';
import { IItem } from '@app/modules/mazars-common/interfaces/item.interface';

import {
    AssignableUserDto,
    CountryReferenceDataDto,
    Dac6TaxOfficeProfilesServiceProxy,
    IntermediaryCapacityType,
    IntermediaryNexusType,
    LiabilityType,
    ReportCustomGrantsServiceProxy,
    ReportReasonType,
    ReportReferenceDataServiceProxy,
    TaxOfficeProfileDto,
    TaxpayerCapacityType,
    TaxpayerNexusType,
    UnspecifiedPartyRoleType,
} from '@app/modules/dac6/dac6-proxies';
import { DueDateSettings } from '@app/modules/mazars-common/components/mazars-deadline-dates/due-date-settings';

@Injectable({
    providedIn: 'root',
})
export class ReportReferenceDataService extends Dac6ComponentBase {
    reportReasons: IItem<number>[];
    taxpayerLiabilityRoles: IItem<number>[];
    taxpayerConnectionToCountry: IItem<number>[];
    intermediaryLiabilityRoles: IItem<number>[];
    intermediaryConnectionToCountry: IItem<number>[];
    roleTypes: IItem<number>[];
    liabilityType: IItem<number>[];
    reportSupportedCountries: IItem<string>[];
    reportTemplatesTypeValues: IItem<string>[];
    reportTypes: IItem<string>[] = [];
    reportProcessStates: IItem<string>[] = [];
    dueDateStatuses: IItem<string>[] = [];
    mainBenefitTestRadioItems: IItem<boolean>[];

    constructor(
        injector: Injector,
        private reportCustomGrantsServiceProxy: ReportCustomGrantsServiceProxy,
        private reportReferenceDataServiceProxy: ReportReferenceDataServiceProxy,
        private dac6TaxOfficeProfilesServiceProxy: Dac6TaxOfficeProfilesServiceProxy
    ) {
        super(injector);
    }

    public load() {
        this.getReportReasons()
            .pipe(take(1))
            .subscribe((reportReasons) => {
                this.reportReasons = reportReasons;
            });

        this.getTaxpayerLiabilityRoles()
            .pipe(take(1))
            .subscribe((liabilityRoles) => {
                this.taxpayerLiabilityRoles = liabilityRoles;
            });

        this.getTaxpayerConnectionToCountry()
            .pipe(take(1))
            .subscribe((connectionToCountry) => {
                this.taxpayerConnectionToCountry = connectionToCountry;
            });

        this.getIntermediaryLiabilityRoles()
            .pipe(take(1))
            .subscribe((liabilityRoles) => {
                this.intermediaryLiabilityRoles = liabilityRoles;
            });

        this.getIntermediaryConnectionToCountry()
            .pipe(take(1))
            .subscribe((connectionToCountry) => {
                this.intermediaryConnectionToCountry = connectionToCountry;
            });

        this.getRoleTypes()
            .pipe(take(1))
            .subscribe((roleTypes) => {
                this.roleTypes = roleTypes;
            });

        this.getLiabilityTypes()
            .pipe(take(1))
            .subscribe((liabilityTypes) => {
                this.liabilityType = liabilityTypes;
            });

        this.getSupportedCountries()
            .pipe(take(1))
            .subscribe((countries) => {
                this.reportSupportedCountries = countries as IItem<string>[];
            });

        this.getReportTemplateTypeValues()
            .pipe(take(1))
            .subscribe((reportTemplatesTypeValues) => {
                this.reportTemplatesTypeValues = reportTemplatesTypeValues;
            });

        this.getReportTypes()
            .pipe(take(1))
            .subscribe((reportTypes) => {
                this.reportTypes = reportTypes;
            });

        this.getReportProcessStates()
            .pipe(take(1))
            .subscribe((reportProcessStates) => {
                this.reportProcessStates = reportProcessStates;
            });

        this.getReportDueDateStatuses()
            .pipe(take(1))
            .subscribe((duedateTypes) => {
                this.dueDateStatuses = duedateTypes;
            });

        this.getMainBenefitTestRadioItems()
            .pipe(take(1))
            .subscribe((radioItems) => {
                this.mainBenefitTestRadioItems = radioItems;
            });
    }

    getAllPossibleUsersOverview(reportId: number, permissionName: string, excludeUsers: boolean = true): Observable<AssignableUserDto[]> {
        return this.getAllPossibleUsers(reportId, permissionName, excludeUsers).pipe(
            take(1),
            map((result) =>
                result.map(
                    (user) =>
                        ({
                            id: user.id,
                            fullName: user.text,
                        } as AssignableUserDto)
                )
            )
        );
    }

    getAllPossibleUsers(reportId: number, permissionName: string, excludeUsers: boolean = true): Observable<IItem<number>[]> {
        return this.reportCustomGrantsServiceProxy.getAllPossibleUsers(reportId, permissionName, excludeUsers).pipe(
            take(1),
            map((result) =>
                result.items.map(
                    (user) =>
                        ({
                            id: user.userOrOrganizationId,
                            text: user.displayName,
                        } as IItem<number>)
                )
            )
        );
    }

    getActiveTaxAuthorities(): Observable<IItem<number>[]> {
        return this.isGranted('Dac6.Profiles')
            ? this.dac6TaxOfficeProfilesServiceProxy.getActive().pipe(
                  take(1),
                  map((items: TaxOfficeProfileDto[]) =>
                      items.map(
                          (taxAuthority) =>
                              ({
                                  id: taxAuthority.id,
                                  text: taxAuthority.profileName,
                              } as IItem<number>)
                      )
                  )
              )
            : of([]);
    }

    getMainBenefitTestRadioItems(): Observable<IItem<boolean>[]> {
        return of([
            {
                id: false,
                text: this.ld('No'),
            },
            {
                id: true,
                text: this.ld('Yes'),
            },
        ]);
    }

    onDestroy(): void {
        // to be implemented if need it
    }

    private getTaxpayerLiabilityRoles(): Observable<IItem<number>[]> {
        return of(
            Object.keys(TaxpayerCapacityType)
                .map((key) => TaxpayerCapacityType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (capacityType: string) =>
                        ({
                            id: TaxpayerCapacityType[capacityType],
                            text: this.l(`CapacityType_${capacityType}`),
                        } as IItem<number>)
                )
        );
    }

    private getTaxpayerConnectionToCountry(): Observable<IItem<number>[]> {
        return of(
            Object.keys(TaxpayerNexusType)
                .map((key) => TaxpayerNexusType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (nexusType: string) =>
                        ({
                            id: TaxpayerNexusType[nexusType],
                            text: this.l(`NexusType_${nexusType}`),
                        } as IItem<number>)
                )
        );
    }

    private getIntermediaryLiabilityRoles(): Observable<IItem<number>[]> {
        return of(
            Object.keys(IntermediaryCapacityType)
                .map((key) => IntermediaryCapacityType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (capacityType: string) =>
                        ({
                            id: IntermediaryCapacityType[capacityType],
                            text: this.l(`CapacityType_${capacityType}`),
                        } as IItem<number>)
                )
        );
    }

    private getIntermediaryConnectionToCountry(): Observable<IItem<number>[]> {
        return of(
            Object.keys(IntermediaryNexusType)
                .map((key) => IntermediaryNexusType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (nexusType: string) =>
                        ({
                            id: IntermediaryNexusType[nexusType],
                            text: this.l(`NexusType_${nexusType}`),
                        } as IItem<number>)
                )
        );
    }

    private getReportReasons(): Observable<IItem<number>[]> {
        return of(
            Object.keys(ReportReasonType)
                .map((key) => ReportReasonType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (reportReason: string) =>
                        ({
                            id: ReportReasonType[reportReason],
                            text: this.l(`ReportReason_${reportReason}`),
                        } as IItem<number>)
                )
        );
    }

    private getRoleTypes(): Observable<IItem<number>[]> {
        return of(
            Object.keys(UnspecifiedPartyRoleType)
                .map((key) => UnspecifiedPartyRoleType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (roleType: string) =>
                        ({
                            id: UnspecifiedPartyRoleType[roleType],
                            text: this.l(`UnspecifiedParty_RoleType_${roleType}`),
                        } as IItem<number>)
                )
        );
    }

    private getLiabilityTypes(): Observable<IItem<number>[]> {
        return of(
            Object.keys(LiabilityType)
                .map((key) => LiabilityType[key])
                .filter((value) => typeof value === 'string')
                .map(
                    (liabilityType: string) =>
                        ({
                            id: LiabilityType[liabilityType],
                            text: this.l(`UnspecifiedParty_RoleType_${liabilityType}`),
                        } as IItem<number>)
                )
        );
    }

    private getSupportedCountries(): Observable<CountryReferenceDataDto[]> {
        return this.reportReferenceDataServiceProxy.getSupportedCountries().pipe(
            take(1),
            map((countries) => this.mapReceivedCountries(countries))
        );
    }

    private mapReceivedCountries(countries: CountryReferenceDataDto[]): CountryReferenceDataDto[] {
        return countries.map(
            (country) =>
                ({
                    id: country.id,
                    text: this.l(`Country_${country.text}`),
                } as CountryReferenceDataDto)
        );
    }

    private getReportTemplateTypeValues(): Observable<IItem<string>[]> {
        return this.reportReferenceDataServiceProxy.getReportTemplateTypeValues().pipe(
            take(1),
            map((reportTemplateTypeValues) => this.mapReportTemplateTypeValues(reportTemplateTypeValues))
        );
    }

    private getReportTypes(): Observable<IItem<string>[]> {
        return this.reportReferenceDataServiceProxy.getReportTypes().pipe(
            take(1),
            map((reportTypes) => this.mapReportTypes(reportTypes))
        );
    }

    private getReportProcessStates(): Observable<IItem<string>[]> {
        return this.reportReferenceDataServiceProxy.getReportProcessStates().pipe(
            take(1),
            map((reportProcessStates) => this.mapReportProcessStates(reportProcessStates))
        );
    }

    private mapReportTemplateTypeValues(reportTemplateTypeValues: string[]): IItem<string>[] {
        return reportTemplateTypeValues.map(
            (reportTemplateTypeValue) =>
                ({
                    id: reportTemplateTypeValue,
                    text: this.l(`ReportTemplateType_${reportTemplateTypeValue}`),
                } as IItem<string>)
        );
    }

    private mapReportTypes(reportTypes: string[]): IItem<string>[] {
        return reportTypes.map(
            (reportType) =>
                ({
                    id: reportType,
                    text: this.l(`ReportType_${reportType}`),
                } as IItem<string>)
        );
    }

    private mapReportProcessStates(reportProcessStates: string[]): IItem<string>[] {
        return reportProcessStates.map(
            (reportProcessState) =>
                ({
                    id: reportProcessState,
                    text: this.l(`WorkItemProcess_${reportProcessState}`),
                } as IItem<string>)
        );
    }

    private getReportDueDateStatuses(): Observable<IItem<string>[]> {
        return this.reportReferenceDataServiceProxy.getReportDueDateStatuses().pipe(
            take(1),
            map((dueDateStatus) => this.mapReportDueDateStatuses(dueDateStatus))
        );
    }

    private mapReportDueDateStatuses(dueDateStatuses: string[]): IItem<string>[] {
        return dueDateStatuses.map((dueDateStatus) => {
            let status = {
                id: dueDateStatus,
                text: this.ld(`DueDateStatus_${dueDateStatus}`),
                icon: '',
            } as IItem<string>;
            status.icon = DueDateSettings.getIcon(dueDateStatus);
            status.iconColor = DueDateSettings.getIconColor(dueDateStatus);
            status.tooltip = DueDateSettings.getIconTooltip(dueDateStatus);
            return status;
        });
    }
}
